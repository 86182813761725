import { Heading } from "@chakra-ui/layout";
import { Flex, Input, Text } from "@chakra-ui/react";
import { useFormikContext } from "formik";
import { InputControl } from "formik-chakra-ui";
import { FunctionComponent } from "react";
import { SwitchInput } from "src/components/Inputs/SwitchInput";
import * as GQL from "src/types/graphql";
import { FormikOrganizationForm } from "../Form";

export const ScheduleExportToggle: FunctionComponent = () => {
  const form = useFormikContext<FormikOrganizationForm>();

  return (
    <>
      <Heading as="h3" size="md">
        Exports
      </Heading>
      <Flex direction="column">
        <SwitchInput<boolean>
          label="Scheduling"
          name={`organization_configs[${GQL.organization_config_type_enum.ScheduleExport}].enabled`}
          checked={[true, ""]}
          unchecked={[false, ""]}
          formLabelProps={{ flexDirection: "row-reverse" }}
        />
        <Flex direction="column" flex={1} marginLeft={12} gap={2}>
          <Text fontSize="xs" color="blackAlpha.700">
            Enables the organization to set up scheduled and recurring exports.
          </Text>
          {form.values.organization_configs[
            GQL.organization_config_type_enum.ScheduleExport
          ]?.enabled && (
            <InputControl
              name={`organization_configs[${GQL.organization_config_type_enum.ScheduleExport}].data.secretArn`}
              label="Secret ARN"
            >
              <Input size="sm" placeholder="Enter ARN suffix" />
            </InputControl>
          )}
        </Flex>
      </Flex>
    </>
  );
};
