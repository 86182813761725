import { Heading } from "@chakra-ui/layout";
import { Flex, FormControl } from "@chakra-ui/react";
import { FunctionComponent, useCallback } from "react";
import { SwitchInput } from "src/components/Inputs/SwitchInput";
import { FormikOrganizationForm } from "../Form";
import { useFormikContext } from "formik";
import { FormInput } from "src/components/Inputs/FormInput";
import * as GQL from "src/types/graphql";

export const AccountLookupToggle: FunctionComponent = () => {
  const form = useFormikContext<FormikOrganizationForm>();
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const supportUrl = event.target.value;
      form.setValues((values) => {
        const updatedValues = {
          ...values,
          organization_configs: {
            ...(values.organization_configs ?? {}),
            [GQL.organization_config_type_enum.AccountLookup]: {
              enabled: true,
              data: {
                supportUrl,
              },
            },
          },
        };
        return updatedValues;
      });
    },
    [form]
  );

  const accountLookupValue =
    form.values.organization_configs[
      GQL.organization_config_type_enum.AccountLookup
    ];
  return (
    <>
      <Heading as="h3" size="md">
        Account Lookup
      </Heading>
      <Flex direction="column">
        <SwitchInput<boolean>
          label="Enable Account Lookup"
          name={`organization_configs[${GQL.organization_config_type_enum.AccountLookup}].enabled`}
          checked={[true, ""]}
          unchecked={[false, ""]}
          formLabelProps={{ flexDirection: "row-reverse" }}
        />
        {form.values.organization_configs[
          GQL.organization_config_type_enum.AccountLookup
        ]?.enabled && (
          <Flex direction="column" marginLeft={12}>
            <FormControl>
              <FormInput
                label="Support Url"
                name="supportUrl"
                onChange={handleChange}
                value={accountLookupValue.data.supportUrl}
              />
            </FormControl>
          </Flex>
        )}
      </Flex>
    </>
  );
};
