import { ApolloError } from "@apollo/client";
import React from "react";
import {
  useBoundariesMap,
  useFormAddressPosition,
} from "src/components/Boundary/useBoundary";
import { useGradeAnswer } from "src/components/Form/useGradeAnswer";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import { GET_FORM_ANSWERS_BY_ID } from "src/scenes/parent/forms/graphql/queries";
import { getFormikInitialValues } from "src/services/formTemplate/answer";
import {
  getEligibleSchools,
  getEligibleSchoolsByLocation,
} from "src/services/formTemplate/preRankingSection";
import { getCompleteApplicableQuestions } from "src/services/formTemplate/question";
import * as AF from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import * as RD from "src/types/remoteData";
import { GET_SCHOOLS_FOR_FORM } from "./graphql/queries";

type School = {
  readonly id: uuid;
  readonly name: string;
  readonly street_address: string | null;
};

type Props = {
  readonly formId: uuid;
  readonly preRankingSection: AF.PreRankingSection<AF.WithId>;
  readonly selectedSchools: readonly School[];
};
export function useEligibleSchools({
  formId,
  preRankingSection,
  selectedSchools,
}: Props) {
  const boundariesMapRemoteData = useBoundariesMap({ formId });
  const gradeConfigRemoteData = useGradeAnswer({ formId });

  const { remoteData: answersRemoteData } = useRemoteDataQuery<
    GQL.GetFormAnswersById,
    GQL.GetFormAnswersByIdVariables
  >(GET_FORM_ANSWERS_BY_ID, {
    variables: { form_id: formId },
    fetchPolicy: "no-cache",
  });
  const { remoteData: getSchoolsRemoteData } = useRemoteDataQuery<
    GQL.GetSchoolsForFormTemplate,
    GQL.GetSchoolsForFormTemplateVariables
  >(GET_SCHOOLS_FOR_FORM, {
    variables: {
      form_id: formId,
    },
    fetchPolicy: "no-cache",
  });

  const addressLocationRemoteData = useFormAddressPosition({
    formId,
  }).mapError((e) => new ApolloError({ errorMessage: e.message }));

  return React.useMemo(() => {
    return RD.toTuple5(
      getSchoolsRemoteData,
      answersRemoteData,
      addressLocationRemoteData,
      boundariesMapRemoteData,
      gradeConfigRemoteData
    ).map(
      ([
        getSchoolsData,
        answersData,
        addressLocation,
        boundariesMap,
        gradeConfigId,
      ]) => {
        const { questions } = preRankingSection;
        // calculate eligible schools
        const answers = getFormikInitialValues(
          questions,
          answersData.form_answer,
          answersData.grades_answer,
          answersData.form_address,
          answersData.custom_question_answer
        );
        const completeQuestions = getCompleteApplicableQuestions(
          questions,
          answers,
          { rankedSchoolIds: [], previousFormSchoolIds: [] }
        );
        type GQLSchool = GQL.GetSchoolsForFormTemplate_school_by_form_school;
        const allSchools: School[] = getSchoolsData.school_by_form
          .map(({ school }) => school)
          .filter((school): school is GQLSchool => school !== null);

        let eligibleSchools: School[] = getEligibleSchools(
          allSchools,
          completeQuestions,
          answers
        ).filter(
          (school) =>
            // remove schools that are already selected
            !selectedSchools
              .map((school: School) => school.id)
              .includes(school.id)
        );

        eligibleSchools = eligibleSchools.filter(
          (school) =>
            // remove schools that are already selected
            !selectedSchools
              .map((school: School) => school.id)
              .includes(school.id)
        );

        eligibleSchools = getEligibleSchoolsByLocation(
          eligibleSchools,
          questions,
          gradeConfigId,
          boundariesMap,
          addressLocation
        );
        return eligibleSchools;
      }
    );
  }, [
    addressLocationRemoteData,
    answersRemoteData,
    boundariesMapRemoteData,
    getSchoolsRemoteData,
    gradeConfigRemoteData,
    preRankingSection,
    selectedSchools,
  ]);
}
