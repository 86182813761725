import {
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Radio,
  Spacer,
  Text,
} from "@chakra-ui/react";
import { format, fromZonedTime, toZonedTime } from "date-fns-tz";
import { Form, Formik } from "formik";
import {
  InputControl,
  RadioGroupControl,
  SelectControl,
  TextareaControl,
} from "formik-chakra-ui";
import { useEffect, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Loading } from "src/components/Feedback/Loading";
import { SwitchInput } from "src/components/Inputs/SwitchInput";
import { AdminFormButtons } from "src/components/Layout/AdminFormButtons";
import { useOrgConfig } from "src/components/Providers/OrgConfigProvider";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useOrganization } from "src/hooks/useOrganization";
import useUser from "src/hooks/useUser";
import { OrgAdmin } from "src/services/url";
import { Status } from "src/types/authData";
import { organization_config_type_enum } from "src/types/graphql";
import { HasuraRole } from "src/types/hasuraRole";
import { BulkExportState } from ".";
import {
  ExportRequest,
  ScheduleRequest,
  SFTPAuthMethod,
} from "../../dataServices/exports/types";
import { useExportManagementAPI } from "../../dataServices/exports/useExportManagementAPI";
import { DateTimePicker } from "./DateTimePicker";
import {
  getPresetTimeOptions,
  PresetTimeOption,
  REPEAT_MODE_OPTIONS,
  RepeatMode,
  repeatModes,
} from "./dateUtils";

enum ExportTimeMode {
  Now = "now",
  Later = "later",
}

type BulkExportFormType = {
  exportName: string;
  emailEnabled: boolean;
  sftp: {
    enabled: boolean;
    config?: {
      host: string;
      port: number;
      username: string;
      authMethod: SFTPAuthMethod;
      secret: string;
      path: string;
    };
  };
  exportTime: {
    mode: ExportTimeMode;
    presetTime?: string; // Date ISO string or "custom"
    customTime?: string; // Date ISO string
    repeat?: {
      enabled: boolean;
      mode?: RepeatMode;
    };
  };
};

export const BulkExportForm: React.FC<BulkExportState> = ({
  formTemplateId,
  selection,
  questionIdsInOrder,
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const user = useUser();
  const toast = useAvelaToast();
  const api = useExportManagementAPI();

  const organization = useOrganization();
  const organizationId = organization?.hasData() ? organization.data.id : "";
  const timezoneName = organization?.hasData()
    ? organization.data.timezone_name
    : "";

  const presetTimeOptions: PresetTimeOption[] = useMemo(() => {
    const currentTime = new Date();
    return getPresetTimeOptions(currentTime, timezoneName);
  }, [timezoneName]);

  const { fetchOrgConfig, ScheduleExport } = useOrgConfig();
  const { enabled: scheduleEnabled = false, data: scheduleData } =
    ScheduleExport || {};

  useEffect(() => {
    if (organizationId) {
      fetchOrgConfig(organizationId, [
        organization_config_type_enum.ScheduleExport,
      ]);
    }
  }, [organizationId, fetchOrgConfig]);

  const validate = (values: BulkExportFormType) => {
    const errors: { [key: string]: string } = {};
    if (values.sftp.enabled) {
      if (values.sftp.config) {
        const { host, port, path, username, authMethod, secret } =
          values.sftp.config;
        if (!(host && port && path && username && authMethod && secret)) {
          errors["sftp.config"] = "All SFTP config fields are required";
        }
      } else {
        errors["sftp.config"] = "SFTP config is required";
      }
    }
    if (values.exportTime.mode === ExportTimeMode.Later) {
      if (!values.exportTime.presetTime) {
        errors["exportTime.presetTime"] = "Time is required";
      }
      if (
        values.exportTime.presetTime === "custom" &&
        !values.exportTime.customTime
      ) {
        errors["exportTime.customTime"] = "Time is required";
      }
      if (
        values.exportTime.repeat?.enabled &&
        !values.exportTime.repeat?.mode
      ) {
        errors["exportTime.repeat.mode"] = "Repeat mode is required";
      }
    }
    return errors;
  };

  const onSubmitHandler = async (values: BulkExportFormType) => {
    const searchParamsString = searchParams.toString();
    const organizationId = organization.toNullable()?.id;
    if (user.status !== Status.OK && user.status !== Status.NOT_VERIFIED) {
      throw new Error("Can't find current user");
    }
    const login = user.data.loginType;
    if (login.type !== "email") {
      throw new Error("User does not have email");
    }
    const email = login.email;

    const postData: ExportRequest = {
      exportConfig: {
        searchParamsString,
        selection,
        formTemplateId,
      },
      exportName: values.exportName,
      email,
      sftpConfig: values.sftp.config,
      questionIdsInOrder,
    };

    try {
      let exportSubmittedText: string = "";
      if (values.exportTime.mode === ExportTimeMode.Now) {
        await api.createExport(organizationId ?? "", postData);
        exportSubmittedText =
          "You'll receive an email once your export is ready.";
      }
      if (values.exportTime.mode === ExportTimeMode.Later) {
        const startTime = getSelectedTime(values);
        const repeatMode = values.exportTime.repeat?.enabled
          ? values.exportTime.repeat.mode
          : null;
        if (!startTime || repeatMode === undefined || !organizationId) {
          throw new Error("Form is missing required values");
        }
        const schedulePostData: ScheduleRequest = {
          ...postData,
          scheduleConfig: {
            startTime: startTime.toISOString(),
            repeatMode,
          },
          orgConfig: scheduleData?.secretArn ?? "",
        };
        await api.scheduleExport(organizationId, schedulePostData);
        const zonedTime = toZonedTime(startTime, timezoneName);
        exportSubmittedText = `You'll receive an email once your export is ready, which will run ${
          repeatMode
            ? REPEAT_MODE_OPTIONS[repeatMode].formatDescription(zonedTime)
            : `on ${format(zonedTime, "MMMM d, yyyy 'at' h:mm aa")}`
        }.`;
      }
      navigate(
        organization
          .map((org) =>
            OrgAdmin.Forms.index({
              organization: org,
              formTemplateId,
              params: searchParamsString,
            })
          )
          .withDefault("#"),
        {
          state: {
            exportSubmittedText,
            scheduled: values.exportTime.mode === ExportTimeMode.Later,
          },
        }
      );
    } catch (error: any) {
      console.error(error);
      toast({
        title: `Error exporting`,
        description:
          "Please try again later or report the problem to our support team.",
        status: "error",
      });
    }
  };

  return (
    <Formik<BulkExportFormType>
      initialValues={{
        exportName: "",
        emailEnabled: true,
        sftp: {
          enabled: false,
        },
        exportTime: {
          mode: ExportTimeMode.Now,
        },
      }}
      onSubmit={onSubmitHandler}
      validate={validate}
    >
      {(props) => {
        return (
          <Flex as={Form} direction="column" flexGrow="1" gap={3}>
            {user.status === Status.LOADING && <Loading />}
            {user.status === Status.OK && (
              <>
                <>
                  <Heading size="lg">Set a name</Heading>
                  <FormControl>
                    <FormLabel>Export name </FormLabel>
                    <InputControl name="exportName">
                      <Input placeholder="Submissions_May" />
                    </InputControl>
                    <FormHelperText>
                      Will result in a file called '
                      {props.values.exportName.length > 0
                        ? `${props.values.exportName}_<timestamp>.csv'`
                        : "form_<timestamp>.csv' (default)"}
                    </FormHelperText>
                  </FormControl>
                </>

                {[HasuraRole.ADMIN, HasuraRole.ORG_ADMIN].includes(
                  user.data.role
                ) && (
                  <>
                    <Heading size="lg" mt={4}>
                      Set delivery
                    </Heading>
                    <Text>
                      Once processed, the file will download automatically. How
                      would you like to receive the export?
                    </Text>
                    <Flex direction="column" gap={0}>
                      <SwitchInput<boolean>
                        label="Email"
                        name="emailEnabled"
                        formLabelProps={{
                          flexDirection: "row-reverse",
                          fontSize: "md",
                        }}
                        checked={[true, ""]}
                        isDisabled
                      />
                      <Text ml={12} fontSize="sm">
                        You’ll receive an email with the export on the email
                        address from your account.
                      </Text>
                    </Flex>
                    <Flex direction="column" gap={0}>
                      <SwitchInput<boolean>
                        label="SFTP upload"
                        name="sftp.enabled"
                        formLabelProps={{
                          flexDirection: "row-reverse",
                          fontSize: "md",
                        }}
                        checked={[true, ""]}
                      />
                      <Text ml={12} fontSize="sm">
                        We will upload the export to an external server through
                        a secure connection. The receiving server or endpoint
                        will need to have authorized Avela.
                      </Text>
                    </Flex>
                    {props.values.sftp.enabled && (
                      <Flex ml={12} direction="column" gap={4}>
                        <Flex gap={4}>
                          <InputControl
                            name="sftp.config.host"
                            label="Host"
                            flex="3 1 0"
                            inputProps={{
                              placeholder:
                                "E.g.: server.example.com or 192.168.1.1",
                            }}
                          />
                          <InputControl
                            name="sftp.config.port"
                            label="Port"
                            flex="1 1 0"
                            inputProps={{
                              placeholder: "E.g.: 22",
                              type: "number",
                            }}
                          />
                          <InputControl
                            name="sftp.config.path"
                            label="Path"
                            flex="4 1 0"
                            inputProps={{
                              placeholder: "E.g.: /users/mysftpuser",
                            }}
                          />
                        </Flex>
                        <SelectControl
                          name="sftp.config.authMethod"
                          label="Authentication method"
                          selectProps={{ placeholder: "Select an option" }}
                          w="fit-content"
                        >
                          {[SFTPAuthMethod.Password, SFTPAuthMethod.SSHKey].map(
                            (authMethod) => (
                              <option key={authMethod} value={authMethod}>
                                {authMethod}
                              </option>
                            )
                          )}
                        </SelectControl>
                        {props.values.sftp.config?.authMethod && (
                          <Flex gap={4}>
                            <InputControl
                              name="sftp.config.username"
                              label="Username"
                              inputProps={{
                                placeholder: "Enter username",
                              }}
                            />
                            {props.values.sftp.config.authMethod ===
                              SFTPAuthMethod.Password && (
                              <InputControl
                                name="sftp.config.secret"
                                label="Password"
                                inputProps={{
                                  placeholder: "Enter password",
                                }}
                              />
                            )}
                            {props.values.sftp.config.authMethod ===
                              SFTPAuthMethod.SSHKey && (
                              <TextareaControl
                                name="sftp.config.secret"
                                label="Private key"
                                textareaProps={{
                                  rows: 10,
                                  size: "sm",
                                  placeholder:
                                    "-----BEGIN OPENSSH PRIVATE KEY-----\n...\n-----END OPENSSH PRIVATE KEY-----",
                                }}
                              />
                            )}
                          </Flex>
                        )}
                      </Flex>
                    )}
                  </>
                )}

                {scheduleEnabled &&
                  [HasuraRole.ADMIN, HasuraRole.ORG_ADMIN].includes(
                    user.data.role
                  ) && (
                    <>
                      <Heading size="lg" mt={4}>
                        Set a time
                      </Heading>
                      <Text>When would you like to receive the export?</Text>
                      <RadioGroupControl name="exportTime.mode">
                        <Flex direction="column" gap={4}>
                          <Radio
                            value={ExportTimeMode.Now}
                            key={ExportTimeMode.Now}
                          >
                            <Text>Now</Text>
                            <FormHelperText>
                              Get the file as soon as the export is done
                            </FormHelperText>
                          </Radio>
                          <Radio
                            value={ExportTimeMode.Later}
                            key={ExportTimeMode.Later}
                          >
                            <Text>Later</Text>
                            <FormHelperText>
                              Set a time to run the export and receive it after
                              it is done
                            </FormHelperText>
                          </Radio>
                        </Flex>
                      </RadioGroupControl>
                      {props.values.exportTime.mode ===
                        ExportTimeMode.Later && (
                        <Flex direction="column" ml={6} mt={4} gap={4}>
                          <FormControl>
                            <FormLabel>
                              When would you like to export?
                            </FormLabel>
                            <SelectControl
                              name="exportTime.presetTime"
                              selectProps={{ placeholder: "Select an option" }}
                              w="fit-content"
                            >
                              {presetTimeOptions.map((presetTimeOption) => (
                                <option {...presetTimeOption} />
                              ))}
                              <option key="custom" value="custom">
                                Custom
                              </option>
                            </SelectControl>
                          </FormControl>
                          {props.values.exportTime.presetTime === "custom" && (
                            <FormControl>
                              <FormLabel>Select a date and time</FormLabel>
                              <DateTimePicker
                                timezoneName={timezoneName}
                                initialValue={
                                  props.values.exportTime.customTime
                                }
                                onChange={(date: Date | null) => {
                                  props.setFieldValue(
                                    "exportTime.customTime",
                                    date
                                      ? fromZonedTime(
                                          date,
                                          timezoneName
                                        ).toISOString()
                                      : undefined
                                  );
                                }}
                              />
                            </FormControl>
                          )}
                          <SwitchInput<boolean>
                            label="Repeats"
                            name={"exportTime.repeat.enabled"}
                            checked={[true, ""]}
                            unchecked={[false, ""]}
                            formLabelProps={{
                              flexDirection: "row-reverse",
                              fontSize: "md",
                              mb: 0,
                            }}
                          />
                          {props.values.exportTime.repeat?.enabled && (
                            <RadioGroupControl
                              name="exportTime.repeat.mode"
                              ml={12}
                            >
                              <Flex direction="column" gap={4}>
                                {repeatModes.map((mode) => (
                                  <Radio value={mode} key={mode}>
                                    <Text>
                                      {REPEAT_MODE_OPTIONS[mode].label}
                                    </Text>
                                    <FormHelperText>
                                      {REPEAT_MODE_OPTIONS[
                                        mode
                                      ].formatFormDescription(
                                        getSelectedTimeInTimezone(
                                          props.values,
                                          timezoneName
                                        )
                                      )}
                                    </FormHelperText>
                                  </Radio>
                                ))}
                              </Flex>
                            </RadioGroupControl>
                          )}
                        </Flex>
                      )}
                    </>
                  )}
              </>
            )}

            <Spacer minH={8} />

            <AdminFormButtons>
              <Button
                variant="outline"
                colorScheme="gray"
                onClick={() => navigate(-1)}
              >
                Back
              </Button>
              <Spacer />
              <Button
                variant="solid"
                type="submit"
                isLoading={props.isSubmitting}
                isDisabled={!props.isValid}
              >
                Export
              </Button>
            </AdminFormButtons>
          </Flex>
        );
      }}
    </Formik>
  );
};

function getSelectedTime(values: BulkExportFormType) {
  const presetTime = values.exportTime?.presetTime;
  const customTime = values.exportTime?.customTime;
  const selectedTimeString = presetTime === "custom" ? customTime : presetTime;
  return selectedTimeString ? new Date(selectedTimeString) : undefined;
}

function getSelectedTimeInTimezone(
  values: BulkExportFormType,
  timezoneName: string
) {
  const selectedTime = getSelectedTime(values);
  return selectedTime ? toZonedTime(selectedTime, timezoneName) : undefined;
}
