import { useAuth0 } from "@auth0/auth0-react";
import { BoxProps, Flex } from "@chakra-ui/layout";
import {
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
} from "@chakra-ui/react";
import React, { ReactNode } from "react";
import { RiArrowDownSLine, RiUserLine } from "react-icons/ri";
import { NavLink, useLocation } from "react-router-dom";
import { useOrganization } from "src/hooks/useOrganization";
import { useOrganizations } from "src/hooks/useOrganizations";
import useRequiredHasuraRoles from "src/hooks/useRequiredHasuraRoles";
import { useUserProfile } from "src/hooks/useUserProfile";
import * as Url from "src/services/url";
import { HasuraRole } from "src/types/hasuraRole";
import { useEnrollmentPeriod } from "../Providers/EnrollmentPeriodProvider";

interface HeaderProps extends BoxProps {
  logo: ReactNode;
}

export const Header = ({
  logo,
  ...restProps
}: HeaderProps): React.ReactElement | null => {
  const location = useLocation();
  const { isAuthenticated, isLoading } = useAuth0();
  const hasAdminPermission = useRequiredHasuraRoles([HasuraRole.ADMIN]);
  const userProfile = useUserProfile();
  const organizations = useOrganizations();
  const organization = useOrganization();
  const { fetchEnrollmentPeriods } = useEnrollmentPeriod();

  return (
    <Flex
      as="header"
      width="100%"
      height="100%"
      backgroundColor="primary.500"
      align="center"
      paddingX={4}
      gap={4}
      {...restProps}
    >
      {isLoading ? null : (
        <>
          {logo}
          {hasAdminPermission &&
            organizations
              .map(
                (orgs): React.ReactNode => (
                  <Menu autoSelect={false}>
                    <MenuButton
                      as={Button}
                      background="none"
                      _hover={{ background: "none" }}
                      _active={{ background: "none" }}
                      color="white"
                      rightIcon={<RiArrowDownSLine />}
                    >
                      {organization
                        .map((org) => org.name)
                        .withDefault("Avela Admin")}
                    </MenuButton>
                    <MenuList>
                      <MenuItem as={NavLink} to="/admin">
                        Avela Admin
                      </MenuItem>
                      {orgs.map((org) => (
                        <MenuItem
                          as={NavLink}
                          key={org.id}
                          to={Url.OrgAdmin.Enrollments.index(org)}
                          onClick={() => fetchEnrollmentPeriods(org.id)}
                        >
                          {org.name}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>
                )
              )
              .withDefault(null)}

          {isAuthenticated ? (
            <>
              <Spacer />
              <Menu>
                <MenuButton
                  as={Button}
                  background="none"
                  _hover={{ background: "none" }}
                  _active={{ background: "none" }}
                  color="white"
                >
                  <Icon as={RiUserLine} />
                  <Icon as={RiArrowDownSLine} boxSize={4} />
                </MenuButton>
                <MenuList>
                  {userProfile.hasData() && (
                    <MenuItem>
                      {userProfile.data.email_address ?? "Account"}
                    </MenuItem>
                  )}
                  <MenuItem as={NavLink} to={Url.logout(location.pathname)}>
                    Logout
                  </MenuItem>
                </MenuList>
              </Menu>
            </>
          ) : null}
        </>
      )}
    </Flex>
  );
};
