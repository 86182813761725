import { Button, Flex, Heading, Spinner, Text } from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { RiEditLine } from "react-icons/ri";
import { Card } from "src/components/Layout/Card";
import { Glossary } from "src/components/Text/Glossary";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { EnrollmentPeriodIdProvider, useGlossary } from "src/hooks/useGlossary";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import * as GQL from "src/types/graphql";
import { Dropdown } from "./Dropdown";
import {
  BULK_INSERT_GRADE_PROGRAMS,
  DELETE_GRADE_BY_PK,
} from "./graphql/mutations";
import { GET_GRADE_PROGRAMS_BY_ENROLLMENT_SCHOOL } from "./graphql/queries";
import { OrderedItem, orderedItemSortFn } from "./helpers";

type Mode = "View" | "Edit";

interface SchoolGradesCardProps {
  schoolId: string;
  schoolName: string;
  enrollmentPeriodId: string;
  gradeConfigs: GQL.GetEnrollmentSchoolsAndConfigs_grade_config[];
}

export const SchoolGradesCard: React.FC<SchoolGradesCardProps> = ({
  schoolId,
  schoolName,
  enrollmentPeriodId,
  gradeConfigs,
}) => {
  const { glossary } = useGlossary({ enrollmentPeriodId });
  const toast = useAvelaToast();
  const [mode, setMode] = useState<Mode>("View");
  const [gradePrograms, setGradePrograms] = useState<
    GQL.GetGradeProgramsByEnrollmentSchool_school_by_pk_grades[]
  >([]);
  const [gradeInitialValues, setGradeInitialValues] = useState<OrderedItem[]>(
    []
  );
  const [gradeValues, setGradeValues] = useState<OrderedItem[]>([]);

  const { remoteData, refetch } = useRemoteDataQuery<
    GQL.GetGradeProgramsByEnrollmentSchool,
    GQL.GetGradeProgramsByEnrollmentSchoolVariables
  >(GET_GRADE_PROGRAMS_BY_ENROLLMENT_SCHOOL, {
    variables: {
      enrollment_period_id: enrollmentPeriodId,
      school_id: schoolId,
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const gradePrograms = data.school_by_pk?.grades ?? [];
      const grades = gradePrograms
        .map((gradeProgram) => ({
          id: gradeProgram.id,
          label: gradeProgram.grade_config.label,
          order: gradeProgram.grade_config.order,
        }))
        .sort(orderedItemSortFn);
      setGradePrograms(gradePrograms);
      setGradeInitialValues(grades);
      setGradeValues(grades);
    },
  });

  const [deleteGradeByPk, { remoteData: deleteGrade }] = useRemoteDataMutation<
    GQL.DeleteGradeByPk,
    GQL.DeleteGradeByPkVariables
  >(DELETE_GRADE_BY_PK);

  const [bulkInsertGradePrograms, { remoteData: updateGrades }] =
    useRemoteDataMutation<
      GQL.BulkInsertGradePrograms,
      GQL.BulkInsertGradeProgramsVariables
    >(BULK_INSERT_GRADE_PROGRAMS);

  const isLoading =
    deleteGrade.isLoading() ||
    updateGrades.isLoading() ||
    remoteData.isLoading();

  const handleSubmit = useCallback(async () => {
    const gradesToAttach = gradeValues?.filter((value) =>
      gradeInitialValues.every((initVal) => value.id !== initVal.id)
    );
    const gradesToDetach = gradeInitialValues.filter((initVal) =>
      gradeValues?.every((value) => value.id !== initVal.id)
    );
    const formattedGradesToAttach: GQL.grade_insert_input[] =
      gradesToAttach?.map((gradeToAttach) => ({
        school_id: schoolId,
        enrollment_period_id: enrollmentPeriodId,
        grade_config_id: gradeToAttach.id,
      }));

    try {
      if (formattedGradesToAttach?.length) {
        await bulkInsertGradePrograms({
          variables: {
            grade_programs: formattedGradesToAttach,
          },
        });
      }

      if (gradesToDetach?.length) {
        await Promise.all(
          gradesToDetach.map(async (gradeToDetach) => {
            await deleteGradeByPk({
              variables: { id: gradeToDetach.id },
            });
          })
        );
      }

      await refetch();
      toast({
        id: "update-grades-success",
        title: glossary`School updated`,
        isClosable: true,
        status: "success",
        position: "bottom-right",
      });
      setMode("View");
    } catch (err) {
      toast({
        id: "update-grades-error",
        title: glossary`Error updating school`,
        description:
          "Please try again later or report the problem to our support team.",
        isClosable: true,
        status: "error",
      });
    }
  }, [
    gradeValues,
    gradeInitialValues,
    schoolId,
    enrollmentPeriodId,
    refetch,
    toast,
    glossary,
    bulkInsertGradePrograms,
    deleteGradeByPk,
  ]);

  return (
    <EnrollmentPeriodIdProvider enrollmentPeriodId={enrollmentPeriodId}>
      <Card
        display="flex"
        flexDirection="column"
        showBorder
        gap={4}
        padding={6}
        width="100%"
      >
        <Flex justifyContent="space-between">
          <Heading fontSize="lg" mb={4}>
            <Text as="span" color="blackAlpha.800">
              {schoolName}{" "}
            </Text>
            <Text as="span" color="gray.500">
              | {schoolId}
            </Text>
          </Heading>
          {mode === "View" && (
            <Button
              colorScheme="gray"
              variant="outline"
              leftIcon={<RiEditLine />}
              onClick={() => setMode("Edit")}
            >
              <Glossary>Edit school</Glossary>
            </Button>
          )}
        </Flex>
        {isLoading ? (
          <Spinner color="primary.500" />
        ) : mode === "View" ? (
          <Flex direction="column" gap={2}>
            <Text fontSize="xs" color="gray.800">
              Grades
            </Text>
            {gradePrograms.length === 0 && (
              <Text as="i" color="blackAlpha.700">
                No grades
              </Text>
            )}
            <Text color="blackAlpha.700">
              {gradeInitialValues.map((grade) => grade.label).join(", ")}
            </Text>
          </Flex>
        ) : (
          <>
            <Card
              display="flex"
              flexDirection="column"
              showBorder
              padding={6}
              width="100%"
            >
              <Dropdown
                itemType="Grades"
                options={gradeConfigs}
                initialValues={gradeInitialValues}
                onChange={(grades: OrderedItem[]) => {
                  setGradeValues(grades.sort(orderedItemSortFn));
                }}
              />
            </Card>
            <Flex gap="3" justifyContent="right" paddingY="3">
              <Button
                variant="ghost"
                colorScheme="gray"
                onClick={() => {
                  setMode("View");
                  setGradeValues(gradeInitialValues);
                }}
              >
                Cancel
              </Button>
              <Button onClick={handleSubmit}>
                <Glossary>Update school</Glossary>
              </Button>
            </Flex>
          </>
        )}
      </Card>
    </EnrollmentPeriodIdProvider>
  );
};
