import { ApolloError } from "@apollo/client";
import { identity } from "lodash";
import React from "react";
import { ApolloError as ApolloErrorView } from "src/components/Feedback/ApolloError";
import { GenericError } from "src/components/Feedback/GenericError";
import { RemoteDataView } from "src/components/Layout/RemoteDataView";
import { FORM_STATUS_DESCRIPTION_MAX_CHARS } from "src/constants";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import { useSendMessage } from "src/hooks/useSendMessage";
import { getRecipientsForApplicant } from "src/services/person";
import * as GQL from "src/types/graphql";
import * as RD from "src/types/remoteData";
import { FETCH_FORM_STATUSES_BY_ID_STATUS } from "../../enrollmentPeriods/scenes/FormTemplates/graphql/queries";
import { NextStepFormValues, NextStepMenu } from "../components/NextStepMenu";
import { INSERT_ACTION_ITEM, UPDATE_ACTION_ITEM } from "../graphql/mutations";
import { FETCH_ACTION_ITEMS } from "../graphql/queries";

interface Props {
  form: GQL.GetFormViewById_form_by_pk;
}

const mapInitialValues = (
  actionItems: GQL.ActionItemFragment | null | undefined,
  formStatusDescription: GQL.FormStatusDescriptionFragment | null | undefined
): NextStepFormValues => ({
  id: actionItems?.id,
  summary: actionItems?.summary || formStatusDescription?.description || "",
  enrollmentDescription: formStatusDescription?.description || "",
  showRequired: false,
  sendMessage: false,
});

type CombinedData = {
  action_item: GQL.FetchActionItems_action_item;
  enrollment_status_description: GQL.FormStatusDescriptionFragment;
};

function handleError(error: Error | ApolloError): React.ReactElement {
  console.error(error);

  if (error instanceof ApolloError) {
    return <ApolloErrorView error={error} />;
  }

  return <GenericError />;
}

export const NextStepMenuSingle = ({ form }: Props) => {
  const toast = useAvelaToast();
  const { handleSendMessage } = useSendMessage(form.form_template.id);
  const formParents = getRecipientsForApplicant(form.person);

  const formId = form.id;

  const { remoteData: actionItemData } = useRemoteDataQuery<
    GQL.FetchActionItems,
    GQL.FetchActionItemsVariables
  >(FETCH_ACTION_ITEMS, {
    variables: {
      form_id: formId,
      status: GQL.action_item_status_enum.Pending,
    },
    fetchPolicy: "no-cache",
  });

  const { remoteData: enrollmentStatusData } = useRemoteDataQuery<
    GQL.FetchFormStatusesByIdStatus,
    GQL.FetchFormStatusesByIdStatusVariables
  >(FETCH_FORM_STATUSES_BY_ID_STATUS, {
    variables: {
      form_template_id: form.form_template.id,
      form_status: [form.status],
    },
    fetchPolicy: "no-cache",
  });

  const nextStepData: RD.RemoteData<ApolloError, CombinedData> = React.useMemo(
    () =>
      RD.toTuple(actionItemData, enrollmentStatusData)
        .mapError<ApolloError>(identity)
        .andThen(([fetchActionItems, fetchEnrollmentStatuses]) => {
          try {
            return RD.success({
              action_item: fetchActionItems.action_item[0],
              enrollment_status_description:
                fetchEnrollmentStatuses.form_status_description[0],
            } as CombinedData);
          } catch (error: unknown) {
            console.error(error);
            return RD.failure<ApolloError, CombinedData>(error as ApolloError);
          }
        }),
    [actionItemData, enrollmentStatusData]
  );

  const refetchQueries = [FETCH_ACTION_ITEMS];

  const [insertActionItem] = useRemoteDataMutation<
    GQL.InsertActionItem,
    GQL.InsertActionItemVariables
  >(INSERT_ACTION_ITEM);

  const [updateActionItem] = useRemoteDataMutation<
    GQL.UpdateActionItem,
    GQL.UpdateActionItemVariables
  >(UPDATE_ACTION_ITEM);

  const upsertActionItem = async (
    { id, summary, enrollmentDescription }: NextStepFormValues,
    initialValues: NextStepFormValues
  ) => {
    if (!summary && summary.length === 0) {
      throw new Error("A status description is required.");
    }

    if (summary.length > FORM_STATUS_DESCRIPTION_MAX_CHARS) {
      throw new Error("Status description excceded limit.");
    }

    const useEnrollmentDescription = summary === enrollmentDescription;

    if (useEnrollmentDescription && !id) return; // do nothing

    if (id) {
      await updateActionItem({
        variables: {
          id,
          status: useEnrollmentDescription
            ? GQL.action_item_status_enum.Canceled
            : GQL.action_item_status_enum.Pending,
          summary: useEnrollmentDescription ? initialValues.summary : summary,
        },
        refetchQueries,
        awaitRefetchQueries: true,
      });
    } else {
      await insertActionItem({
        variables: {
          form_id: formId,
          summary,
        },
        refetchQueries,
        awaitRefetchQueries: true,
      });
    }

    toast({
      title: "Success",
      description: "Status description have been updated",
      status: "success",
      duration: 2000,
    });
  };

  const handleSubmit = async (
    values: NextStepFormValues,
    initialValues: NextStepFormValues
  ): Promise<boolean> => {
    try {
      upsertActionItem(values, initialValues);

      // if sendMessage is checked then navigate to new message route after a sight delay,
      // so it allows user to see status is updated
      if (values.sendMessage) {
        const parentIds = formParents.map((parent) => parent.id);
        setTimeout(() => {
          handleSendMessage(
            parentIds,
            [form.person.id],
            [form.id],
            values.summary
          );
        }, 200);
      }
      return true;
    } catch (e) {
      console.error(e);
      toast.error({
        title: "Something went wrong",
        description: "Please try again later or contact our team",
      });
      return false;
    }
  };

  return (
    <RemoteDataView error={handleError} remoteData={nextStepData}>
      {(data) => {
        const initialValues = mapInitialValues(
          data.action_item,
          data.enrollment_status_description
        );

        return (
          <NextStepMenu
            handleSubmit={handleSubmit}
            initialValues={initialValues}
          />
        );
      }}
    </RemoteDataView>
  );
};
