import { Button, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { Confetti } from "src/components/Confetti";
import { Card } from "src/components/Layout/Card";
import { ParentRemoteDataLayout } from "src/components/Layout/Parent/ParentRemoteDataLayout";
import { ParentFormsLayout } from "src/components/Layout/ParentFormsLayout";
import { useOrganization } from "src/hooks/useOrganization";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import { ReactComponent as AdmissionsSvg } from "src/images/admissions.svg";
import { WEGLOT_SKIP_CLASS } from "src/plugins/weglot/constants";
import { maybePluralize } from "src/services/format";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { AcceptOffer } from "./formTemplates/components/ActionItem/AcceptOffer";
import { DeclineOffer } from "./formTemplates/components/ActionItem/DeclineOffer";
import {
  GET_ACCEPTED_OFFERS,
  GET_OFFERS,
} from "./formTemplates/graphql/queries";

export const Offers: React.FC = () => {
  const { appId } = useParams();
  const organization = useOrganization();
  const [acceptedOffer, setAcceptedOffer] =
    React.useState<GQL.GetAcceptedOffers_form_form_school_ranks>();

  const { remoteData: offersData } = useRemoteDataQuery<
    GQL.GetOffers,
    GQL.GetOffersVariables
  >(GET_OFFERS, {
    variables: {
      form_id: appId ?? "",
    },
    fetchPolicy: "cache-and-network",
  });

  const { remoteData: acceptedOffersData } = useRemoteDataQuery<
    GQL.GetAcceptedOffers,
    GQL.GetAcceptedOffersVariables
  >(GET_ACCEPTED_OFFERS, {
    variables: {
      form_id: appId ?? "",
    },
    fetchPolicy: "cache-and-network",
  });

  React.useEffect(() => {
    if (acceptedOffersData.hasData()) {
      acceptedOffersData.data.form[0]?.form_school_ranks.forEach(
        (formSchoolRank) => {
          if (
            formSchoolRank.offers[0]?.status === GQL.offer_status_enum.Accepted
          ) {
            return setAcceptedOffer(formSchoolRank);
          }
        }
      );
    }
  }, [acceptedOffersData]);

  let heading = "Offers";
  if (offersData.hasData()) {
    const firstName = offersData.data.form[0]?.person?.first_name;
    if (firstName) {
      heading = `Offers for ${firstName}`;
    }
  }

  const OffersError = (
    <Flex direction="column" alignItems="center" gap={4} mt="30%">
      <AdmissionsSvg aria-hidden />

      <Text color="gray.600" fontSize="lg">
        Error: Can't display offers
      </Text>
      <Button
        as={Link}
        to={organization.map(Url.Parent.index).withDefault("#")}
        variant="outline"
        colorScheme="gray"
        size="sm"
      >
        Back
      </Button>
    </Flex>
  );

  return (
    <ParentFormsLayout
      heading={heading}
      backLink={{
        label: "Back",
        url: organization.map(Url.Parent.index).withDefault("#"),
      }}
    >
      <ParentRemoteDataLayout remoteData={offersData}>
        {(data) => {
          const form = data.form[0];
          if (
            !form ||
            !form.person.first_name ||
            !form.grades_answers ||
            !form.grades_answers[0] ||
            !form.grades_answers[0].grade_config
          ) {
            return OffersError;
          }
          const studentName = form.person.first_name;
          const enrollmentPeriod = form.form_template.enrollment_period.name;
          const offeredSchoolRanks = form.form_school_ranks;
          if (offeredSchoolRanks.length > 0) {
            const offerCount = maybePluralize(
              offeredSchoolRanks.length,
              "an offer",
              `${offeredSchoolRanks.length} offers`
            );
            const offerMessage = `${studentName} has received ${offerCount} for ${enrollmentPeriod}!`;

            return (
              <Flex direction="column" gap={5}>
                <Card
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  bg="green.500"
                >
                  <Confetti />
                  <AdmissionsSvg />
                  <Text textAlign="center" color="white">
                    {offerMessage}
                  </Text>
                </Card>
                {offeredSchoolRanks.map((offeredSchoolRank) => {
                  if (!offeredSchoolRank.offers[0]) {
                    console.error(
                      `Offer not found for school: ${offeredSchoolRank.school.name}`
                    );
                    return null;
                  }

                  return (
                    <Card key={offeredSchoolRank.offers[0].id} boxShadow="md">
                      <Flex direction="column" alignItems="flex-start" pb={4}>
                        <Text color="blackAlpha.700">
                          {convertToOrdinal(offeredSchoolRank.rank + 1)} choice{" "}
                          {offeredSchoolRank.rank === 0 ? <>&#127881;</> : ""}
                        </Text>
                        <Text
                          className={WEGLOT_SKIP_CLASS}
                          color="gray.700"
                          fontWeight="bold"
                        >
                          {offeredSchoolRank.school.name}
                        </Text>
                      </Flex>
                      <Flex
                        direction="row"
                        gap={4}
                        justifyContent="space-between"
                      >
                        <DeclineOffer
                          offerId={offeredSchoolRank.offers[0].id}
                          school={offeredSchoolRank.school.name}
                          isButton
                        />
                        <AcceptOffer
                          formTemplateId={form.form_template_id}
                          acceptedOfferSchoolName={acceptedOffer?.school?.name}
                          offerId={offeredSchoolRank.offers[0].id}
                          school={offeredSchoolRank.school.name}
                          studentName={studentName}
                          isButton
                        />
                      </Flex>
                    </Card>
                  );
                })}
              </Flex>
            );
          }
          return (
            <Flex direction="column" alignItems="center" gap={4} mt="30%">
              <AdmissionsSvg />
              <Text color="gray.600" fontSize="lg">
                {studentName} has no more offers
              </Text>
              <Button
                as={Link}
                to={organization.map(Url.Parent.index).withDefault("#")}
                variant="outline"
                colorScheme="gray"
                size="sm"
              >
                Back
              </Button>
            </Flex>
          );
        }}
      </ParentRemoteDataLayout>
    </ParentFormsLayout>
  );
};

const convertToOrdinal = (n: number) => {
  const suffix: string[] = ["th", "st", "nd", "rd"];
  const value = n % 100;
  return (
    n.toString() + (suffix[(value - 20) % 10] || suffix[value] || suffix[0])
  );
};
