import { useApolloClient } from "@apollo/client";
import _ from "lodash";
import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { FormTabsTypes } from "src/constants";
import { usePaginationParams } from "src/hooks/useCommonSearchParams";
import { useFormTemplatePermissions } from "src/hooks/useFormTemplatePermissions";
import { useOrganization } from "src/hooks/useOrganization";
import { usePaginatedRemoteDataQuery } from "src/hooks/usePaginatedRemoteDataQuery";
import { useRemoteDataQueryPromise } from "src/hooks/useRemoteDataQuery";
import { useSchoolAdmin } from "src/hooks/useSchoolAdmin";
import useUser from "src/hooks/useUser";
import { isNotNull } from "src/services/predicates";
import { Status } from "src/types/authData";
import * as GQL from "src/types/graphql";
import * as RD from "src/types/remoteData";
import {
  EXPORT_FORMS,
  GET_DROPOFF_FORMS_BY_FORM_TEMPLATE,
  GET_DROPOFF_FORM_IDS_BY_FORM_TEMPLATE,
} from "../graphql/queries";
import * as GQLUtils from "../graphql/utils";
import { GqlSchoolForm, SchoolFormId, SchoolRankTag } from "../types";
import { FormsListTab } from "./useFormsListTab";

type Props = {
  enrollmentPeriodId?: uuid | undefined;
  formTemplateId?: uuid | undefined;
  tabs: FormsListTab;
};
export function useDropoffFormsList({
  formTemplateId,
  enrollmentPeriodId,
  tabs,
}: Props) {
  const user = useUser();
  const claims = user.status === Status.OK ? user.data : null;
  const organization = useOrganization();
  const [searchParams] = useSearchParams();
  const { pagination } = usePaginationParams();
  const organization_id = organization.map((org) => org.id).withDefault("");
  const searchExp: GQL.assigned_form_bool_exp = {
    previous_form: {
      ...GQLUtils.buildSearchAndFilterQuery(
        searchParams,
        organization_id,
        claims
      ),
    },
    ...GQLUtils.buildDropoffFormSchoolsFilterQuery(searchParams, claims),
    ...GQLUtils.buildDropoffFormSubStatusQuery(searchParams),
    ...GQLUtils.buildDropoffFormTagsFilterQuery(searchParams),
  };
  const { isSchoolAdmin } = useSchoolAdmin();
  const { pruneFormTemplate } = useFormTemplatePermissions();

  const { usePaginatedQuery, useUnpaginatedQueryPromise } =
    usePaginatedRemoteDataQuery<
      GQL.GetDropoffFormsByFormTemplate,
      GQL.GetDropoffFormsByFormTemplateVariables
    >(GET_DROPOFF_FORMS_BY_FORM_TEMPLATE, {
      organization_id,
      form_template_id: formTemplateId ?? "",
      previous_form_template_id:
        tabs.current.type === FormTabsTypes.DropoffForms
          ? tabs.current.formTemplateId
          : "",
      limit: pagination.limit,
      offset: pagination.offset,
      search: searchExp,
      skip_rank: isSchoolAdmin,
    });
  const { remoteData, refetch } = usePaginatedQuery({
    fetchPolicy: "network-only",
    skip:
      !organization.hasData() ||
      !enrollmentPeriodId ||
      tabs.current.type !== FormTabsTypes.DropoffForms,
  });

  const dropoffFormsData = useMemo(() => {
    return remoteData.map((data) => data.assigned_form.map(toSchoolForm));
  }, [remoteData]);

  const count = useMemo(() => {
    return remoteData
      .map((data) => data.assigned_form_aggregate.aggregate?.count ?? 0)
      .withDefault(0);
  }, [remoteData]);

  const previousFormTemplateRemoteData: RD.RemoteData<
    Error,
    GQL.FormTemplateFragment
  > = useMemo(
    () =>
      remoteData
        .mapError<Error>(_.identity)
        .andThen((data) =>
          data.form_template_by_pk
            ? RD.success(pruneFormTemplate(data.form_template_by_pk))
            : RD.failure(new Error("Missing form"))
        ),
    [remoteData, pruneFormTemplate]
  );

  const gradeConfigsRemoteData: RD.RemoteData<
    Error,
    { id: uuid; label: string }[]
  > = useMemo(() => {
    return remoteData
      .mapError<Error>(_.identity)
      .map((data) => data.grade_config);
  }, [remoteData]);

  const fetchAll = useUnpaginatedQueryPromise();
  const mappedFetchAll = useCallback(async () => {
    return fetchAll().then((data) => {
      return data.data.assigned_form.map(toSchoolForm);
    });
  }, [fetchAll]);

  const fetchAllIds = useRemoteDataQueryPromise<
    GQL.GetDropoffFormIdsByFormTemplate,
    GQL.GetDropoffFormIdsByFormTemplateVariables
  >(GET_DROPOFF_FORM_IDS_BY_FORM_TEMPLATE, {
    variables: {
      form_template_id: formTemplateId ?? "",
      previous_form_template_id:
        tabs.current.type === FormTabsTypes.DropoffForms
          ? tabs.current.formTemplateId
          : "",
      search: searchExp,
    },
  });

  const mappedFetchAllIds = useCallback(async (): Promise<SchoolFormId[]> => {
    return fetchAllIds().then((data) => {
      return data.data.assigned_form.map(toSchoolFormId);
    });
  }, [fetchAllIds]);

  const apolloClient = useApolloClient();
  const fetchByIds = useCallback(
    async (ids: SchoolFormId[]) => {
      const result = await apolloClient.query<
        GQL.ExportForms,
        GQL.ExportFormsVariables
      >({
        query: EXPORT_FORMS,
        variables: {
          skip_rank: isSchoolAdmin,
          form_ids: Array.from(new Set(ids.map((id) => id.formId)).values()),
          form_school_rank_ids: ids
            .map((id) => id.formSchoolRankId)
            .filter(isNotNull),
        },
      });
      if (result.error) throw new Error(result.error.message);
      return result.data;
    },
    [apolloClient, isSchoolAdmin]
  );

  return {
    remoteData: dropoffFormsData,
    refetch,
    fetchAll: mappedFetchAll,
    fetchAllIds: mappedFetchAllIds,
    fetchByIds,
    count,
    previousFormTemplateRemoteData,
    gradeConfigsRemoteData,
  };
}

/**
 * Helpers
 */
type DropoffForm = GQL.GetDropoffFormsByFormTemplate_assigned_form;
function toSchoolForm(assignedForm: DropoffForm): GqlSchoolForm {
  const schoolAndGrade = getRelatedSchoolAndGrade(assignedForm);

  if (!assignedForm.previous_form) {
    throw new Error("Missing previous form");
  }

  return {
    form: {
      id: assignedForm.previous_form.id,
      person: assignedForm.previous_form.person,
      status: assignedForm.previous_form.status,
      form_verification_results: [],
      grades_answers: schoolAndGrade
        ? [{ grade_config: schoolAndGrade.grade }]
        : [],
      is_hidden_from_parent: null,
      // the dropoff form shouldn't have previous form
      previous_form: null,
      // the dropoff form shouldn't have previous offer
      previous_offer: null,
      // the dropoff form shouldn't have previous waitlist
      previous_waitlist: null,
      // the dropoff form shouldn't have tags
      tags: [],
    },
    form_school_rank: schoolAndGrade
      ? toFormSchoolRank(
          assignedForm,
          schoolAndGrade.school,
          schoolAndGrade.grade
        )
      : null,
    waitlist_position: null,
  };
}

type DropoffFormId = GQL.GetDropoffFormIdsByFormTemplate_assigned_form;
function toSchoolFormId(assignedForm: DropoffFormId): SchoolFormId {
  if (!assignedForm.previous_form) {
    throw new Error("Missing previous form");
  }

  const schoolAndGrade = getRelatedSchoolAndGrade(assignedForm);
  const school_rank = assignedForm.previous_form.form_school_ranks.find(
    (schoolRank) => schoolRank.school_id === schoolAndGrade?.school.id
  );

  return {
    formId: assignedForm.previous_form.id,
    schoolId: school_rank?.school_id ?? null,
    formSchoolRankId: school_rank?.id ?? null,
  };
}

function toFormSchoolRank(
  assignedForm: DropoffForm,
  school: School,
  grade: Grade
) {
  if (!assignedForm.previous_form) {
    throw new Error("Missing previous form");
  }

  const school_rank = assignedForm.previous_form.form_school_ranks.find(
    (schoolRank) => schoolRank.school_id === school.id
  );
  if (!school_rank) {
    return null;
  }

  const tags = getTags(assignedForm, school.id);

  const offer = assignedForm.previous_offer;
  const waitlist = assignedForm.previous_waitlist;

  return {
    id: school_rank.id,
    rank: school_rank.rank,
    tags,
    school: school,
    sub_status: school_rank.sub_status,
    offers: offer ? [offer] : [],
    waitlists: waitlist ? [waitlist] : [],
    lottery_order: null,
    form_school_offer_status_history:
      school_rank.form_school_offer_status_history,
  };
}

function getTags(form: DropoffForm, schoolId: uuid): SchoolRankTag[] {
  const schoolRank = form.previous_form?.form_school_ranks.find(
    (schoolRank) => schoolRank.school_id === schoolId
  );
  if (!schoolRank) return [];

  return schoolRank.tags;
}

type School = { id: uuid; name: string };
type Grade = { id: uuid; label: string };
type FormWithPreviousOfferAndWaitlist = {
  previous_offer: {
    school: School;
    grade: {
      grade_config: Grade;
    };
  } | null;
  previous_waitlist: {
    school: School;
    grade: {
      grade_config: Grade;
    };
  } | null;
};
function getRelatedSchoolAndGrade(
  form: FormWithPreviousOfferAndWaitlist
): { school: School; grade: Grade } | undefined {
  const offer = form.previous_offer;
  if (offer) {
    return {
      school: offer.school,
      grade: offer.grade.grade_config,
    };
  }

  const waitlist = form.previous_waitlist;
  if (waitlist) {
    return {
      school: waitlist.school,
      grade: waitlist.grade.grade_config,
    };
  }

  return undefined;
}
