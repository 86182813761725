import React, { useCallback, useMemo } from "react";
import { useLazyRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import * as GQL from "src/types/graphql";
import { GET_ORGANIZATION_CONFIG } from "./graphql/queries";
import {
  OrgConfigStateType,
  Config,
  ConfigDataSchemas,
} from "src/types/organizationConfig";

export interface OrgConfigContextType extends OrgConfigStateType {
  fetchOrgConfig: (
    organizationId: uuid,
    types?: GQL.organization_config_type_enum[]
  ) => void;
  isLoading: boolean;
}

interface OrgConfigProviderProps {
  children: React.ReactNode;
}

function parseConfig<T extends Config>(config: T): T {
  const parsed = ConfigDataSchemas[config.type].safeParse(config.data);
  if (!parsed.success) {
    console.error(`Error parsing org config "${config.type}":`, parsed.error);
    // This config is malformed, but we'll pass it through anyway so as not to
    // bring down the entire site.  If something goes wrong, we'll at least have
    // the parsing error message.
    return config;
  }
  return {
    ...config,
    data: parsed.data,
  };
}

const defaultOrgConfigContext: OrgConfigContextType = {
  fetchOrgConfig: async () => {
    return {};
  },
  isLoading: false,
};

const OrgConfigContext = React.createContext<OrgConfigContextType>(
  defaultOrgConfigContext
);

export function OrgConfigProvider({ children }: OrgConfigProviderProps) {
  const [fetchOrgConfigRD, fetchedOrgConfig] = useLazyRemoteDataQuery<
    GQL.GetOrganizationConfig,
    GQL.GetOrganizationConfigVariables
  >(GET_ORGANIZATION_CONFIG, {
    fetchPolicy: "network-only",
  });

  const orgConfig = useMemo(() => {
    const nextOrgConfig: OrgConfigStateType = {};

    if (fetchedOrgConfig.remoteData.hasData()) {
      fetchedOrgConfig.remoteData.data.organization_config.forEach(
        (config: Config) => {
          (nextOrgConfig[config.type] as Config) = parseConfig(config);
        }
      );
    }

    return nextOrgConfig;
  }, [fetchedOrgConfig]);

  // TODO - fetchOrgConfig can be changed to getOrgConfig, and check orgConfig before fetching
  const fetchOrgConfig = useCallback(
    async (
      organizationId: uuid,
      configTypes?: GQL.organization_config_type_enum[]
    ) => {
      const types =
        configTypes ??
        (Object.keys(
          GQL.organization_config_type_enum
        ) as GQL.organization_config_type_enum[]);

      await fetchOrgConfigRD({
        variables: { organization_id: organizationId, types },
      });
    },
    [fetchOrgConfigRD]
  );

  const isLoading = useMemo(
    () =>
      fetchedOrgConfig.remoteData.isNotAsked() ||
      fetchedOrgConfig.remoteData.isLoading(),
    [fetchedOrgConfig]
  );

  return (
    <OrgConfigContext.Provider
      value={{ ...orgConfig, fetchOrgConfig, isLoading }}
    >
      {children}
    </OrgConfigContext.Provider>
  );
}

export function useOrgConfig(): OrgConfigContextType {
  const context = React.useContext(OrgConfigContext);
  if (context === undefined) {
    throw new Error("useOrgConfig must be used within a OrgConfigContext");
  }

  return context;
}
