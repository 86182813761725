import * as Turf from "@turf/helpers";
import Immutable from "immutable";
import _ from "lodash";
import { SchoolBoundaries } from "src/components/Boundary/schemas";
import * as Boundary from "src/components/Boundary/services";
import { isLocationBoundariesFilter } from "src/components/Form/QuestionForm/formik";
import * as AF from "src/types/formTemplate";
import { FormikValues } from "../answer";
import * as question from "../question";

export interface RankedSchool {
  __typename?: "form_school_rank";
  rank: number;
  school: School;
}

export interface School {
  __typename?: "school";
  id: uuid;
  name?: string;
  grades?: Grade[];
}

export interface Grade {
  id: uuid;
  grade_config_id: uuid;
}

export type EligibilityAnswer = {
  questionId: uuid;
  eligible: School[];
  ineligible: School[];
};

export function calculateSchoolsToRemove(
  rankedSchools: readonly RankedSchool[],
  completeQuestions: readonly AF.Question<AF.WithId>[],
  answers: FormikValues,
  gradeConfigId: uuid | undefined,
  location: Turf.Position | undefined,
  boundariesMap: Immutable.Map<uuid, SchoolBoundaries>
): RankedSchool[] {
  const rankedSchoolsToRemoveByGrades = calculateSchoolsToRemoveByGrade(
    rankedSchools,
    completeQuestions,
    answers
  );

  const rankedSchoolsToRemoveByEligibilityQuestions: RankedSchool[] =
    calculateSchoolsToRemoveByEligibilityQuestions(
      rankedSchools,
      completeQuestions,
      answers
    );

  const schoolIdsToRemoveByLocation = calculateSchoolsToRemoveByLocation(
    rankedSchools.map((rankedSchool) => rankedSchool.school),
    completeQuestions,
    gradeConfigId,
    boundariesMap,
    location
  ).map((school) => school.id);

  const rankedSchoolsToRemoveByLocation = rankedSchools.filter((rankedSchool) =>
    schoolIdsToRemoveByLocation.includes(rankedSchool.school.id)
  );

  const rankedSchoolsToRemove = _.uniq(
    rankedSchoolsToRemoveByGrades.concat(
      rankedSchoolsToRemoveByEligibilityQuestions,
      rankedSchoolsToRemoveByLocation
    )
  );
  return rankedSchoolsToRemove;
}

/**
 * Calculate list of schools to be removed from the current ranked schools list based on new grade answer
 * @param rankedSchools
 * @param newGradeConfigId
 * @returns
 */
export function calculateSchoolsToRemoveByGrade(
  rankedSchools: readonly RankedSchool[],
  questions: readonly AF.Question<AF.WithId>[],
  answers: FormikValues
): readonly RankedSchool[] {
  const newGradeConfigId = getGradesAnswer(questions, answers);
  if (newGradeConfigId === undefined) {
    return rankedSchools;
  }

  const schoolsToRemove = rankedSchools.filter(
    (school) =>
      !(school.school.grades ?? [])
        .map((grade) => grade.grade_config_id)
        .includes(newGradeConfigId)
  );

  const rankedSchoolsToRemove = _.sortBy(
    schoolsToRemove
      .map((school) => {
        return rankedSchools.find((r) => r.school.id === school.school.id);
      })
      .filter(
        (rankedSchool): rankedSchool is RankedSchool =>
          rankedSchool !== undefined
      ),
    (rankedSchool) => rankedSchool.rank
  );

  return rankedSchoolsToRemove;
}

export function getGradesAnswer(
  questions: readonly AF.Question<AF.WithId>[],
  values: FormikValues
): string | undefined {
  const gradesAnswers = questions
    .filter((q) => q.type === "Grades")
    .map((q) => values[q.id])
    .filter((answer): answer is string => typeof answer === "string");

  if (gradesAnswers.length > 1) {
    // We don't support having more than 1 grades question yet
    console.error(
      "Found more than 1 grades question. We currently only support 1 grades question per form"
    );
  }

  return gradesAnswers[0];
}

export function getEligibleSchools<T extends { id: uuid }>(
  allSchools: readonly T[],
  questions: readonly AF.Question<AF.WithId>[],
  answers: FormikValues
): T[] {
  const allEligibilityQuestions = getAllEligibilityQuestions(questions);
  const allNotEligibleSchools: readonly uuid[] = getAllNotEligibleSchools(
    allEligibilityQuestions,
    answers
  );

  return allSchools.filter(
    (school) => !allNotEligibleSchools.includes(school.id)
  );
}

type SchoolForLocationFilter = {
  id: uuid;
  grades?: { id: uuid; grade_config_id: uuid }[];
};

function getSchoolsEligibilityByLocation<T extends SchoolForLocationFilter>(
  allSchools: readonly T[],
  questions: readonly AF.Question<AF.WithId>[],
  gradeConfigId: uuid | undefined,
  boundariesMap: Immutable.Map<uuid, SchoolBoundaries>,
  location: Turf.Position | undefined
): { eligible: T[]; ineligible: T[] } {
  const completeQuestions = question.getCompleteQuestions(questions);

  const locationBundariesFilter = completeQuestions.find(
    (question): question is AF.Address<AF.WithId> => {
      return (
        question.type === AF.AddressType &&
        question.filters !== undefined &&
        question.filters.find(isLocationBoundariesFilter) !== undefined
      );
    }
  );

  if (locationBundariesFilter === undefined) {
    // no location boundaries filters exists, return all schools
    return { eligible: allSchools as T[], ineligible: [] };
  }

  const { eligible, ineligible } = allSchools.reduce(
    ({ eligible, ineligible }, school) => {
      const gradeId = school.grades?.find(
        (grade) => grade.grade_config_id === gradeConfigId
      )?.id;

      const isEligible = Boundary.isEligibleToSchool(
        school.id,
        gradeId,
        location,
        boundariesMap
      );
      return isEligible
        ? { eligible: eligible.push(school), ineligible: ineligible }
        : { eligible: eligible, ineligible: ineligible.push(school) };
    },
    { eligible: Immutable.List<T>(), ineligible: Immutable.List<T>() }
  );

  return { eligible: eligible.toArray(), ineligible: ineligible.toArray() };
}

export function getEligibleSchoolsByLocation<T extends SchoolForLocationFilter>(
  allSchools: T[],
  questions: readonly AF.Question<AF.WithId>[],
  gradeConfigId: uuid | undefined,
  boundariesMap: Immutable.Map<uuid, SchoolBoundaries>,
  location: Turf.Position | undefined
): T[] {
  const { eligible } = getSchoolsEligibilityByLocation(
    allSchools,
    questions,
    gradeConfigId,
    boundariesMap,
    location
  );
  return eligible;
}

export function calculateSchoolsToRemoveByLocation<
  T extends SchoolForLocationFilter
>(
  rankedSchools: readonly T[],
  questions: readonly AF.Question<AF.WithId>[],
  gradeConfigId: uuid | undefined,
  boundariesMap: Immutable.Map<uuid, SchoolBoundaries>,
  location: Turf.Position | undefined
): T[] {
  const { ineligible } = getSchoolsEligibilityByLocation(
    rankedSchools,
    questions,
    gradeConfigId,
    boundariesMap,
    location
  );

  return ineligible;
}

export function getAllEligibilityQuestions(
  questions: readonly AF.Question<AF.WithId>[]
) {
  return questions.filter(
    (q): q is AF.SingleSelect<AF.WithId> =>
      q.type === AF.SingleSelectType &&
      q.category === AF.EligibilityCategoryType
  );
}

/**
 * Calculate list of schools to be removed from the current ranked schools list based on answers on eligibility questions.
 */
export function calculateSchoolsToRemoveByEligibilityQuestions(
  rankedSchools: readonly RankedSchool[],
  questions: readonly AF.Question<AF.WithId>[],
  answers: FormikValues
): RankedSchool[] {
  const allEligibilityQuestions = getAllEligibilityQuestions(questions);
  const allNotEligibleSchools = getAllNotEligibleSchools(
    allEligibilityQuestions,
    answers
  );
  return rankedSchools.filter((school) =>
    allNotEligibleSchools.includes(school.school.id)
  );
}

/**
 * The logic for calculating all schools that a student is not eligible for:
 *  - From any questions with "NotEligible" option, and the student picks that option as answer.
 *  - From any question with "Eligible" option, and the student does not pick that option as answer, making them ineligible to those schools.
 */
export function getAllNotEligibleSchools(
  questions: readonly AF.SingleSelect<AF.WithId>[],
  answers: FormikValues
): readonly uuid[] {
  const notEligibleSchoolsFromNotEligibleOption =
    getNotEligibleSchoolsFromNotEligibleOption(questions, answers);

  const notEligibleSchoolsFromEligibleOption =
    getNotEligibleSchoolsFromEligibleOption(questions, answers);

  return notEligibleSchoolsFromNotEligibleOption.concat(
    notEligibleSchoolsFromEligibleOption
  );
}

/**
 * If there are options with Eligible option, it means that if the student doesn't pick that option, they're not eligible for that school.
 */
export function getNotEligibleSchoolsFromEligibleOption(
  questions: readonly AF.SingleSelect<AF.WithId>[],
  answers: FormikValues
): readonly uuid[] {
  // get list of schools ids that requires the student to pick the option to be eligible for that school
  const schoolsWithEligibilityRequirement = questions.flatMap((question) => {
    if (question.category !== "Eligibility") {
      return [];
    }

    return question.options.flatMap((option) => {
      if (option.eligibilityFilter !== "Eligible") {
        return [];
      }

      // collect all eligibleSchoolIds from all options with "Eligible" filter
      return option.eligibleSchoolIds;
    });
  });

  const notEligibleSchools = schoolsWithEligibilityRequirement.filter(
    (schoolId) => {
      for (const question of questions) {
        const answerAsOption = question.options.find(
          (option) => option.id === answers[question.id]
        );
        if (
          answerAsOption === undefined ||
          answerAsOption.eligibilityFilter !== "Eligible"
        ) {
          continue;
        }

        if (answerAsOption.eligibleSchoolIds.includes(schoolId)) {
          // yay, this student is eligible for this school, let's filter out this school from the list
          return false;
        }
      }

      // keep this school since student is not eligible
      return true;
    }
  );

  return notEligibleSchools;
}

export function getNotEligibleSchoolsFromNotEligibleOption(
  questions: readonly AF.SingleSelect<AF.WithId>[],
  answers: FormikValues
): readonly uuid[] {
  const notEligibleSchoolIds: readonly uuid[] = questions.flatMap((q) => {
    const answer = answers[q.id];
    if (answer === undefined) {
      // not yet answered, so we skip this one
      return [];
    }
    if (Array.isArray(answer)) {
      // this should be impossible since this is a single select question
      console.error(
        "Invalid answer type, expecting a string for a single select question, but we got a string[] instead"
      );
      return [];
    }

    const answerAsOption = q.options.find((o) => o.id === answer);
    if (answerAsOption === undefined) {
      // this should also impossible, logging this as error, but don't crash the form.
      console.error(
        `Invalid answer, can't find the option for the answer(${answer}) for question(${q.id})`
      );
      return [];
    }

    if (answerAsOption.eligibilityFilter !== "NotEligible") {
      // we only care about not eligible for this function
      return [];
    }

    return answerAsOption.notEligibleSchoolIds;
  });

  return notEligibleSchoolIds;
}
