import { useNavigate, useParams } from "react-router-dom";
import { StepProps } from "../components/Layout/FormStepLayout";
import * as Url from "../services/url";
import { useOrganization } from "./useOrganization";
import { useContext } from "react";
import { EditFormContext } from "src/scenes/parent/forms/state/provider";

export function useFormSteps({ currentStep, steps }: StepProps) {
  const navigate = useNavigate();
  const organization = useOrganization();
  const { formId = "" } = useParams();
  const { state } = useContext(EditFormContext);

  let onPrevious: () => void;
  if (currentStep === 1) {
    onPrevious = () =>
      organization.do((org) => navigate(Url.Parent.index(org)));
  } else if (currentStep > steps.length) {
    onPrevious = () =>
      organization.do((org) =>
        navigate(Url.Parent.Form.edit(org, formId, steps.length))
      );
  } else {
    onPrevious = () =>
      organization.do((org) =>
        navigate(Url.Parent.Form.edit(org, formId, currentStep - 1))
      );
  }

  let onNext: () => void;
  // the nav state below is used in FormQuestion.tsx::scrollToQuestionAfterNavigate
  if (currentStep === steps.length) {
    // last step
    // TODO: how should we handle last step submission?
    onNext = () => {};
  } else if (currentStep > steps.length) {
    onNext = () =>
      organization.do((org) =>
        navigate(Url.Parent.Form.edit(org, formId, steps.length), {
          state: { questionId: state?.currentQuestionId },
        })
      );
  } else {
    onNext = () =>
      organization.do((org) =>
        navigate(Url.Parent.Form.edit(org, formId, currentStep + 1), {
          state: { questionId: state?.currentQuestionId },
        })
      );
  }

  return { onPrevious, onNext };
}
