import { gql } from "@apollo/client";
import { ACTION_ITEM_FRAGMENT } from "./actionItem";
import { FORM_TEMPLATE_FRAGMENT } from "./formTemplate";

export const FORM_SUMMARY_FRAGMENT = gql`
  ${ACTION_ITEM_FRAGMENT}

  fragment FormSummaryFragment on form {
    id
    submitted_at
    status
    person {
      id
      reference_id
      first_name
      last_name
      birth_date
      avatar
      updated_at
    }
    form_school_ranks(order_by: { rank: asc_nulls_last }) {
      rank
      created_at
      updated_at
      sub_status
      school {
        id
        name
      }
      offers(where: { deleted_at: { _is_null: true } }) {
        id
        status
      }
      waitlists(where: { deleted_at: { _is_null: true } }) {
        id
        waitlist_position {
          position
        }
        status
      }
      tags {
        id
        enrollment_period_tag {
          id
          name
          description
        }
      }
    }
    grades_answers {
      id
      grade_config {
        label
        id
      }
    }
    action_items {
      ...ActionItemFragment
    }
  }
`;

export const FORM_VERIFICATION_RESULT_FRAGMENT = gql`
  fragment FormVerificationResultFragment on form_verification_result {
    id
    verification_status
  }
`;

export const FORM_VERIFICATION_RESULT_EXTENDED_FRAGMENT = gql`
  ${FORM_VERIFICATION_RESULT_FRAGMENT}

  fragment FormVerificationResultExtendedFragment on form_verification_result {
    ...FormVerificationResultFragment

    form_verification {
      id
      label
    }
  }
`;

export const FORM_ANSWER_FRAGMENT = gql`
  fragment FormAnswerFragment on form_answer {
    id
    question_id
    form_question {
      question_id
      category
      question {
        type
      }
    }
    free_text_answer
    form_answer_options {
      id
      form_question_option_id
    }
    document_metadata(where: { deleted_at: { _is_null: true } }) {
      document_id
    }
  }
`;

export const GRADES_ANSWERS_FRAGMENT = gql`
  fragment GradesAnswersFragment on grades_answer {
    id
    question_id
    grade_config {
      id
      label
    }
  }
`;

export const ADDRESS_ANSWERS_FRAGMENT = gql`
  fragment AddressAnswersFragment on form_address {
    id
    question_id

    street_address
    street_address_line_2
    city
    state
    zip_code
  }
`;

export const CUSTOM_QUESTION_ANSWERS_FRAGMENT = gql`
  ${FORM_ANSWER_FRAGMENT}

  fragment CustomQuestionAnswersFragment on custom_question_answer {
    id
    question_id

    form_answer {
      ...FormAnswerFragment
    }
  }
`;

export const CUSTOM_QUESTION_ANSWER_BANK_RELATIONSHIPS_FRAGMENT = gql`
  fragment CustomQuestionAnswerBankRelationshipsFragment on custom_question_answer_bank_relationship {
    id
    custom_question_id
    person_answer_bank {
      id
      person_answer_bank_external_relationship {
        external_id
      }
    }
  }
`;

export const FORM_DISCLAIMER_FRAGMENT = gql`
  fragment FormDisclaimerFragment on form_disclaimer {
    id
    signature
    signed_at
  }
`;

export const FORM_FRAGMENT = gql`
  ${FORM_TEMPLATE_FRAGMENT}
  ${FORM_VERIFICATION_RESULT_EXTENDED_FRAGMENT}

  fragment FormFragment on form {
    id
    status
    submitted_before
    form_template {
      ...FormTemplateFragment
      enrollment_period {
        active
        name
      }
    }
    form_verification_results {
      ...FormVerificationResultExtendedFragment
    }
  }
`;
