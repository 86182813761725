import { ApolloError } from "@apollo/client";
import { Box, Button, Flex } from "@chakra-ui/react";
import { identity } from "lodash";
import React from "react";
import { RiEditLine } from "react-icons/ri";
import { Link, useParams } from "react-router-dom";
import { ApolloError as ApolloErrorView } from "src/components/Feedback/ApolloError";
import { GenericError } from "src/components/Feedback/GenericError";
import { NotFound } from "src/components/Feedback/NotFound";
import { Section } from "src/components/Form/Section";
import { Student } from "src/components/Layout/Avatar";
import { ParentRemoteDataLayout } from "src/components/Layout/Parent/ParentRemoteDataLayout";
import { ParentFormsLayoutFooter } from "src/components/Layout/ParentFormsLayoutFooter";
import { ParentFormsLayoutInner } from "src/components/Layout/ParentFormsLayoutInner";
import { ParentFormsLayoutOuter } from "src/components/Layout/ParentFormsLayoutOuter";
import { Glossary } from "src/components/Text/Glossary";
import { useOrganization } from "src/hooks/useOrganization";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import { GET_FORM_VIEW_BY_ID } from "src/scenes/orgAdmin/forms/graphql/queries";
import * as FormTemplate from "src/services/formTemplate";
import * as Url from "src/services/url";
import * as AF from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import * as RD from "src/types/remoteData";
import { StudentHeader } from "./components/Layout/StudentHeader";
import { GET_FORM_ANSWERS_BY_ID, GET_SCHOOLS_RANK } from "./graphql/queries";
import { isSectionApplicable } from "src/services/formTemplate/section";
import { usePreviousFormSchools } from "src/hooks/usePreviousFormSchools";

function handleError(error: Error | ApolloError): React.ReactElement {
  if (error instanceof ApolloError) {
    return <ApolloErrorView error={error} />;
  }

  return <GenericError />;
}

type CombinedData = {
  form: GQL.GetFormViewById_form_by_pk;
  sections: AF.Sections<AF.WithId>;
  form_answers: GQL.FormAnswerFragment[];
  grades_answers: GQL.GradesAnswersFragment[];
  address_answers: GQL.AddressAnswersFragment[];
  custom_question_answers: GQL.CustomQuestionAnswersFragment[];
  schoolsRank: GQL.GetSchoolsRank_form_school_rank[];
  student: Student;
  previousFormSchoolIds: uuid[];
};

export const ViewForm: React.FC = () => {
  const { id } = useParams();
  const organization = useOrganization();

  const { remoteData: formRemoteData } = useRemoteDataQuery<
    GQL.GetFormViewById,
    GQL.GetFormViewByIdVariables
  >(GET_FORM_VIEW_BY_ID, {
    variables: {
      form_id: id ?? "",
      organization_id: organization.toNullable()?.id ?? "",
      is_admin: false,
    },
    skip: !id || !organization.hasData(),
  });

  const { remoteData: answersRemoteData } = useRemoteDataQuery<
    GQL.GetFormAnswersById,
    GQL.GetFormAnswersByIdVariables
  >(GET_FORM_ANSWERS_BY_ID, {
    variables: {
      form_id: id ?? "",
    },
    skip: !id,
    fetchPolicy: "network-only",
  });

  const { remoteData: getSchoolsRankRemoteData } = useRemoteDataQuery<
    GQL.GetSchoolsRank,
    GQL.GetSchoolsRankVariables
  >(GET_SCHOOLS_RANK, {
    variables: {
      form_id: id ?? "",
    },
    skip: !id,
    fetchPolicy: "cache-and-network",
  });

  const previousFormSchoolsRemoteData = usePreviousFormSchools(id);

  const formData: RD.RemoteData<ApolloError | Error, CombinedData> =
    React.useMemo(
      () =>
        RD.toTuple4(
          formRemoteData,
          getSchoolsRankRemoteData,
          answersRemoteData,
          previousFormSchoolsRemoteData
        )
          .mapError<ApolloError | Error>(identity)
          .andThen(([data, schoolsRank, answers, previousFormSchoolIds]) => {
            if (!data.form_by_pk?.form_template) {
              return RD.failure<ApolloError | Error, CombinedData>(new Error());
            }
            try {
              const formTemplate = FormTemplate.fromGQL(
                data.form_by_pk?.form_template
              );

              const rankedSchoolIds = schoolsRank.form_school_rank.map(
                (rankedSchool) => rankedSchool.school.id
              );

              const applicableSections = formTemplate.sections.filter(
                (section) => {
                  return isSectionApplicable(
                    section,
                    answers.form_answer,
                    answers.grades_answer,
                    answers.form_address,
                    answers.custom_question_answer,
                    { rankedSchoolIds, previousFormSchoolIds }
                  );
                }
              ) as AF.Sections<AF.WithId>;

              return RD.success({
                form: data.form_by_pk,
                sections: applicableSections,
                form_answers: answers.form_answer,
                grades_answers: answers.grades_answer,
                address_answers: answers.form_address,
                custom_question_answers: answers.custom_question_answer,
                schoolsRank: schoolsRank.form_school_rank,
                student: {
                  first_name: data.form_by_pk.person.first_name ?? "",
                  last_name: data.form_by_pk.person.last_name ?? "",
                  avatar: data.form_by_pk.person.avatar,
                },
                previousFormSchoolIds,
              });
            } catch (error: unknown) {
              console.error(error);
              return RD.failure<ApolloError | Error, CombinedData>(
                error as Error
              );
            }
          }),
      [
        getSchoolsRankRemoteData,
        formRemoteData,
        answersRemoteData,
        previousFormSchoolsRemoteData,
      ]
    );

  if (!id) return <NotFound />;

  return (
    <ParentFormsLayoutOuter
      backLink={organization
        .map((org) => ({
          url: Url.Parent.index(org),
          label: "Back to forms",
        }))
        .withDefault(undefined)}
      heading="View form"
    >
      <ParentRemoteDataLayout error={handleError} remoteData={formData}>
        {({
          form,
          grades_answers,
          address_answers,
          custom_question_answers,
          form_answers,
          sections,
          schoolsRank,
          student,
          previousFormSchoolIds,
        }) => {
          let content;
          let footers;

          if (!form) {
            content = <NotFound />;
          } else {
            content = (
              <Flex direction="column" gap={4}>
                <Box mx={4} mt={4}>
                  <StudentHeader
                    student={student}
                    enrollmentPeriodName={
                      form.form_template.enrollment_period.name
                    }
                  />
                </Box>

                <Flex direction="column" paddingX={6}>
                  {sections.map((section) =>
                    section ? (
                      <Section
                        key={section.id}
                        form={form}
                        allSections={sections}
                        section={section}
                        form_answers={form_answers}
                        grades_answers={grades_answers}
                        address_answers={address_answers}
                        custom_question_answers={custom_question_answers}
                        schoolsRank={schoolsRank}
                        onEdit={() => {}}
                        onCloseEdit={() => {}}
                        editMode={"disabled"}
                        isLateFormEdit={false}
                        previousFormSchoolIds={previousFormSchoolIds}
                        formDisclaimer={form.form_disclaimers[0]}
                      />
                    ) : null
                  )}
                </Flex>
              </Flex>
            );

            const canEditForm =
              form.form_template.enrollment_period.active &&
              (form.status === GQL.form_status_enum.Submitted ||
                form.status === GQL.form_status_enum.InProgress);

            const canEditSchools =
              form.form_template.enrollment_period.active &&
              (form.status === GQL.form_status_enum.Verified ||
                form.status === GQL.form_status_enum.Admissions);

            if (canEditForm) {
              footers = (
                <ParentFormsLayoutFooter justifyContent="stretch">
                  <Button
                    as={Link}
                    flexGrow={1}
                    leftIcon={<RiEditLine />}
                    to={organization
                      .map((org) => Url.Parent.Form.edit(org, form.id))
                      .withDefault("#")}
                  >
                    Edit form
                  </Button>
                </ParentFormsLayoutFooter>
              );
            } else if (canEditSchools) {
              footers = (
                <ParentFormsLayoutFooter justifyContent="stretch">
                  <Button
                    as={Link}
                    flexGrow={1}
                    leftIcon={<RiEditLine />}
                    to={organization
                      .map((org) => Url.Parent.Form.lateEdit(org, form.id))
                      .withDefault("#")}
                  >
                    <Glossary>Edit schools</Glossary>
                  </Button>
                </ParentFormsLayoutFooter>
              );
            }
          }

          return (
            <ParentFormsLayoutInner footers={footers}>
              {content}
            </ParentFormsLayoutInner>
          );
        }}
      </ParentRemoteDataLayout>
    </ParentFormsLayoutOuter>
  );
};
