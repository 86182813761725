import { FunctionComponent } from "react";
import { AccountLookupResponse } from "../helpers";
import { OneOptionSignUp } from "./OneOptionSignUp";
import { TwoOptionSignUp } from "./TwoOptionSignUp";
import { AccountLookupConfig } from "src/types/organizationConfig";

export type CreateAnAccountProps = Omit<
  AccountLookupResponse,
  "parentGuardianExists | auth0UserType"
> & { config: AccountLookupConfig };

export const CreateAnAccount: FunctionComponent<CreateAnAccountProps> = (
  props
) => {
  const { emailAddress, phoneNumber } = props;

  if (emailAddress && phoneNumber) {
    return <TwoOptionSignUp {...props} />;
  }

  return <OneOptionSignUp {...props} />;
};
