import { AddressFormType } from "src/schemas/Address";
import { EligibleFilterType } from "src/schemas/formTemplateFilters";
import * as AF from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import { Answer } from "./";
import { isQuestionApplicable } from "./generalSection";
import { SelectedSchoolIds } from "./generalSection/question";

type GqlQuestionsByFormTemplate =
  GQL.ExportQuestionsAndVerifications_question_by_form_template;

export type QuestionsMap = Map<string, GqlQuestionsByFormTemplate>;

export function getQuestionIdsInOrder(
  sections: AF.Sections<AF.WithId> | undefined
) {
  return (
    sections
      ?.flatMap((section) =>
        section?.type === "PreRankingSection" ||
        section?.type === "GeneralSection"
          ? getCompleteQuestions(section.questions)
          : []
      )
      .map((q) => q.id) ?? []
  );
}

export function toQuestionMap(
  questions: GqlQuestionsByFormTemplate[]
): QuestionsMap {
  return new Map<string, GqlQuestionsByFormTemplate>(
    questions.map((q) => [q.question_id ?? "", q])
  );
}

export function getCompleteQuestions(
  questions: readonly AF.Question<AF.WithId>[]
): readonly AF.Question<AF.WithId>[] {
  const completeQuestions = questions.reduce(
    (
      accumulatedQuestions: AF.Question<AF.WithId>[],
      question: AF.Question<AF.WithId>
    ) => {
      const flattedQuestions = flattenQuestion(question);
      return [...accumulatedQuestions, ...flattedQuestions];
    },
    []
  );

  return completeQuestions;
}

export function flattenQuestion(
  question: AF.Question<AF.WithId>
): readonly AF.Question<AF.WithId>[] {
  if (!hasOptions(question)) {
    return [question];
  }

  const additionalQuestions = question.options.flatMap(
    (option) => option.additionalQuestions ?? []
  );
  return [question, ...additionalQuestions];
}

/**
 * Use this function to calculate all applicable question for this form, which will include/excluse:
 * - school specific questions.
 * - additional questions from branching based on current answer.
 */
export function getCompleteApplicableQuestions(
  questions: readonly AF.Question<AF.WithId>[],
  answers: Readonly<Answer.FormikValues>,
  selectedSchoolIds: SelectedSchoolIds
): readonly AF.Question<AF.WithId>[] {
  const completeQuestions = questions
    .filter((question) => isQuestionApplicable(question, selectedSchoolIds))
    .reduce(
      (
        accumulatedQuestions: AF.Question<AF.WithId>[],
        question: AF.Question<AF.WithId>
      ) => {
        if (!hasOptions(question)) {
          return [...accumulatedQuestions, question];
        }
        const answer = answers[question.id];

        if (answer === undefined) {
          return [...accumulatedQuestions, question];
        }

        let additionalQuestions: AF.Question<AF.WithId>[] = [];

        if (typeof answer === "string") {
          const selectedOption = question.options.find((o) => o.id === answer);

          additionalQuestions = selectedOption?.additionalQuestions ?? [];
        }

        if (Array.isArray(answer)) {
          const selectedOptions = question.options.filter((o) =>
            answer.includes(o.id)
          );

          additionalQuestions = selectedOptions.flatMap(
            (o) => o.additionalQuestions ?? []
          );
        }

        return [...accumulatedQuestions, question, ...additionalQuestions];
      },
      []
    );

  return completeQuestions;
}

export function findRequiredQuestionIds(
  questions: readonly AF.Question<AF.WithId>[]
): readonly string[] {
  return questions.filter((q) => q.requirement === "Required").map((q) => q.id);
}

export function findEmailQuestionIds(
  questions: readonly AF.Question<AF.WithId>[]
): readonly string[] {
  return questions.filter((q) => q.type === AF.EmailType).map((q) => q.id);
}

export function findPhoneNumberQuestionIds(
  questions: readonly AF.Question<AF.WithId>[]
): readonly string[] {
  return questions
    .filter((q) => q.type === AF.PhoneNumberType)
    .map((q) => q.id);
}

export function findAddressQuestionIds(
  questions: readonly AF.Question<AF.WithId>[]
): readonly string[] {
  return questions.filter((q) => q.type === AF.AddressType).map((q) => q.id);
}

export function findCustomQuestionIds(
  questions: readonly AF.Question<AF.WithId>[]
): readonly string[] {
  return questions
    .filter((q) => q.type === AF.CustomQuestionType)
    .map((q) => q.id);
}

export function hasAnswer(
  answers: Readonly<Answer.FormikValues>,
  questionId: string
): boolean {
  const answer = answers[questionId];
  if (answer === undefined || answer === null) return false;
  if (typeof answer === "string" && answer.trim() === "") return false;
  if (Array.isArray(answer) && answer.length === 0) return false;

  // Check if address answer is valid
  if (
    typeof answer === "object" &&
    Object.keys(answer).includes("street_address")
  ) {
    const { street_address, city, state, zip_code } = answer as AddressFormType;

    return !!(street_address && city && state && zip_code);
  }

  return true;
}

export type SaveStatus = "NotSaving" | "Saving" | "Saved";
export type SaveStatuses = {
  [questionId: uuid]: SaveStatus;
};

export function getSaveStatus(saveStatuses: SaveStatuses): SaveStatus {
  return Object.keys(saveStatuses).reduce(
    (previousStatus: SaveStatus, formId: uuid) => {
      const currentStatus = saveStatuses[formId] ?? "NotSaving";
      if (currentStatus === "NotSaving") {
        return previousStatus;
      }

      if (currentStatus === "Saving") {
        return "Saving";
      }

      if (previousStatus === "Saving") {
        return "Saving";
      }

      return "Saved";
    },
    "NotSaving"
  );
}

export function getAllAdditionalQuestions(
  question: GQL.QuestionFragment
): GQL.FormQuestionWithoutBranchingFragment[] {
  return [
    ...(question.form_question?.form_question_options.flatMap((option) =>
      option.additional_questions.map((aq) => aq.question)
    ) ?? []),
    ...(question.grades_question?.grades_additional_questions.flatMap(
      (gaq) => gaq.question
    ) ?? []),
    ...(question.custom_question?.custom_question_relationships.flatMap(
      (cqr) => cqr.cloned_question
    ) ?? []),
  ].filter(Boolean);
}

export function includeAllAdditionalQuestions(
  questions: GQL.QuestionFragment[]
): GQL.FormQuestionWithoutBranchingFragment[] {
  return questions.flatMap((q) => [q, ...getAllAdditionalQuestions(q)]);
}

export type VerificationQuestions = {
  verification?: AF.FormVerification<AF.WithId>;
  questions: AF.Question<AF.WithId>[];
};

export function groupByVerifications(
  questions: readonly AF.Question<AF.WithId>[]
): VerificationQuestions[] {
  const result: VerificationQuestions[] = [];

  questions.forEach((q) => {
    const verification = (q as AF.FormQuestion<AF.WithId>).formVerification;
    if (!verification) {
      result.push({ questions: [q] });
      return;
    }
    const found = result.find((vq) => vq.verification?.id === verification.id);

    if (!found) {
      result.push({
        verification,
        questions: [q],
      });
    } else {
      found.questions.push(q);
    }
  });
  return result;
}

export function toFormVerification(
  value: GQL.FormQuestionWithoutBranchingFragment
): AF.FormVerification<AF.WithId> | undefined {
  const form_verification = value.form_question?.form_verification;

  if (!form_verification) return undefined;

  return {
    id: form_verification.id,
    label: form_verification.label,
  };
}

export function findInapplicableQuestionsToDelete(
  allQuestions: readonly AF.Question<AF.WithId>[],
  applicableQuestions: readonly AF.Question<AF.WithId>[],
  answers: Readonly<Answer.FormikValues>
): uuid[] {
  /**
   * We're excluding file upload from deletion for various reason:
   * - The mechanism for triggering deletion autosave doesn't work for file upload.
   * - It's more desirable to keep the file to avoid reupload by user
   * - File upload is not showing in file export, so we don't need to worry about filtering on export.
   * - File upload can't be associated with badge, so no worry about incorrect badge.
   */
  const ignoreList = new Set(
    allQuestions
      .filter((q) => {
        return q.type === "FileUpload";
      })
      .map((q) => q.id)
  );
  const applicableQuestionIds = new Set(applicableQuestions.map((q) => q.id));
  return Object.keys(answers).filter((questionId) => {
    if (ignoreList.has(questionId)) {
      return false;
    }
    const hasAnswer = !!answers[questionId];

    return hasAnswer && !applicableQuestionIds.has(questionId);
  });
}

export function getSpecificToSchoolsQuestions(
  questions: readonly AF.Question<AF.WithId>[],
  schoolIds: uuid[]
): AF.Question<AF.WithId>[] {
  return questions.filter((question) =>
    schoolIds
      .map((schoolId) => question.specificToSchools?.includes(schoolId))
      .includes(true)
  );
}

export function hasOptions<T extends AF.WithId | AF.WithoutId>(
  question: Pick<AF.Question<T>, "type"> | { type: "" } | undefined
): question is
  | Pick<AF.SingleSelect<T>, "type">
  | Pick<AF.MultiSelect<T>, "type"> {
  if (question === undefined) {
    return false;
  }

  switch (question.type) {
    case AF.MultiSelectType:
    case AF.SingleSelectType:
      return true;

    case AF.FileUploadType:
    case AF.FreeTextType:
    case AF.GradesType:
    case AF.EmailType:
    case AF.PhoneNumberType:
    case AF.AddressType:
    case AF.CustomQuestionType:
    case "":
      return false;

    default:
      const _exhaustiveCheck: never = question.type;
      return _exhaustiveCheck;
  }
}

export function hasCustomQuestionType<T extends AF.WithId | AF.WithoutId>(
  question: Pick<AF.Question<T>, "type"> | { type: "" } | undefined
): question is Pick<AF.CustomQuestion<T>, "type"> {
  if (question === undefined) {
    return false;
  }

  switch (question.type) {
    case AF.CustomQuestionType:
      return true;

    case AF.MultiSelectType:
    case AF.SingleSelectType:
    case AF.FileUploadType:
    case AF.FreeTextType:
    case AF.GradesType:
    case AF.EmailType:
    case AF.PhoneNumberType:
    case AF.AddressType:
    case "":
      return false;

    default:
      const _exhaustiveCheck: never = question.type;
      return _exhaustiveCheck;
  }
}

export function toQuestionWithoutId(
  question: AF.Question<AF.WithId>
): AF.Question<AF.WithoutId> {
  const { id, ...questionWithoutId } = question;
  switch (questionWithoutId.type) {
    case AF.SingleSelectType:
    case AF.MultiSelectType:
      return {
        ...questionWithoutId,
        options: questionWithoutId.options.map(toOptionWithoutId),
      };

    case AF.GradesType:
      return {
        ...questionWithoutId,
        gradesAdditionalQuestions: [], //TODO deal with additional question in future task.
      };

    case AF.CustomQuestionType:
      return { ...questionWithoutId, nestedQuestions: [] }; // CQT-TODO

    case AF.FileUploadType:
    case AF.FreeTextType:
    case AF.EmailType:
    case AF.PhoneNumberType:
    case AF.AddressType:
      return { ...questionWithoutId };

    default:
      const _exhaustiveCheck: never = questionWithoutId;
      return _exhaustiveCheck;
  }
}

export function toOptionWithoutId(
  option: AF.Option<AF.WithId>
): AF.Option<AF.WithoutId> {
  const { id, additionalQuestions, ...optionWithoutId } = option;
  return {
    ...optionWithoutId,
    additionalQuestions: additionalQuestions?.map(toQuestionWithoutId),
  };
}

export function findGradesQuestion(
  questions: readonly AF.Question<AF.WithId>[]
): AF.Grades<AF.WithId> | undefined {
  const completeQuestions = getCompleteQuestions(questions);
  return completeQuestions.find(
    (question): question is AF.Grades<AF.WithId> =>
      question.type === AF.GradesType
  );
}

export function findGeoEligibilityQuestion(
  questions: readonly AF.Question<AF.WithId>[]
): AF.Address<AF.WithId> | undefined {
  const completeQuestions = getCompleteQuestions(questions);
  return completeQuestions.find(isGeoEligibilityQuestion);
}

export function isGeoEligibilityQuestion(
  question: AF.Question<AF.WithId>
): question is AF.Address<AF.WithId> {
  if (question.type !== AF.AddressType) {
    return false;
  }

  const filter = question.filters?.find(
    (filter) => filter.type === EligibleFilterType.LocationBoundariesFilter
  );
  if (filter === undefined) {
    return false;
  }

  return true;
}

export function findCustomQuestion(
  questions: readonly AF.Question<AF.WithId>[]
): AF.CustomQuestion<AF.WithId> | undefined {
  const completeQuestions = getCompleteQuestions(questions);
  return completeQuestions.find(
    (question): question is AF.CustomQuestion<AF.WithId> =>
      question.type === AF.CustomQuestionType
  );
}

export function toBaseQuestion(
  value: GQL.FormQuestionWithoutBranchingFragment
): Omit<AF.Question<AF.WithId>, "type" | "specificToSchools"> {
  return {
    id: value.id,
    key: value.key ?? undefined,
    question: value.question,
    requirement: value.is_required ? "Required" : undefined,
    link_text: value.link_text ?? undefined,
    link_url: value.link_url ?? undefined,
    permissionLevel: value.permission_level ?? undefined,
  };
}
