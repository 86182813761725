import { PropsWithChildren, useEffect } from "react";
import { useOrganization } from "src/hooks/useOrganization";
import * as GQL from "src/types/graphql";
import { useOrgConfig } from "../Providers/OrgConfigProvider";

type Props = PropsWithChildren<{
  configType: GQL.organization_config_type_enum;
}>;

export const WithConfig: React.FC<Props> = ({ children, configType }) => {
  const { fetchOrgConfig, [configType]: config } = useOrgConfig();
  const organization = useOrganization();
  const orgId = organization.toNullable()?.id ?? "";

  useEffect(() => {
    if (!orgId) return;
    fetchOrgConfig(orgId, [configType]);
  }, [orgId, fetchOrgConfig, configType]);

  return config?.enabled ? <>{children}</> : null;
};
