import * as Env from "src/services/env";
import { useState, useEffect } from "react";
import * as GQL from "src/types/graphql";
import * as RD from "src/types/remoteData";
import { useOrganizationPath } from "./useOrganizationPath";
import { ConfigData, ConfigDataSchemas } from "src/types/organizationConfig";

export const usePublicOrgConfig = <
  Type extends GQL.organization_config_type_enum
>(
  configType: Type
) => {
  const org = useOrganizationPath();

  const [orgConfig, setOrgConfig] = useState<
    RD.RemoteData<unknown, ConfigData<Type> | null>
  >(RD.notAsked());

  useEffect(() => {
    async function fetchPublicOrgConfig() {
      try {
        if (!org) {
          return;
        }

        const assetBaseUrl = Env.read().REACT_APP_ASSETS_BASE_URL;

        setOrgConfig(RD.loading());
        const response = await fetch(
          `${assetBaseUrl}/organization-config/${org}/${configType}.json`
        );
        if (!response.ok) {
          // public org config is not available, treat this as the config is disabled
          setOrgConfig(RD.success(null));
          return;
        }

        const config = ConfigDataSchemas[configType].parse(
          await response.json()
        );
        setOrgConfig(RD.success(config));
      } catch (error: unknown) {
        console.error(error);
        setOrgConfig(RD.failure(error));
      }
    }

    fetchPublicOrgConfig();
  }, [configType, org]);

  return orgConfig;
};
