import { Record, RecordOf } from "immutable";
import { PreviousForm } from "src/services/form/related";
import * as GQL from "src/types/graphql";

export interface SchoolFormId {
  formId: uuid;
  schoolId: uuid | null;
  formSchoolRankId: uuid | null;
}

export interface FormId {
  formId: uuid;
}

export const SchoolFormKeyRecordFactory = Record<SchoolFormId>({
  formId: "",
  schoolId: null,
  formSchoolRankId: null,
});

export type SchoolFormKeyRecord = RecordOf<SchoolFormId>;

export interface FormSchool {
  form: { id: uuid } | null;
  school: { id: uuid } | null;
}

export interface FormSchoolStatus {
  id: uuid;
  form_id: uuid;
  school_id: uuid;
  form: {
    status: GQL.form_status_enum;
  };
  offers: {
    id: uuid;
    status: GQL.offer_status_enum;
    assigned_forms: {
      id: uuid;
    }[];
  }[];
  waitlists: {
    id: uuid;
    status: GQL.waitlist_status_enum;
    assigned_forms: {
      id: uuid;
    }[];
  }[];
}

// Shorthand for really long type name.
// export type GqlSchoolForm =
//   GQL.GetFormsAndMetadataByEnrollmentPeriod_form_by_form_school_rank;

type Person = {
  first_name: string | null;
  last_name: string | null;
  reference_id: string | null;
  id: uuid;
};
export type GqlForm = {
  id: uuid;
  person: Person;
  status: GQL.form_status_enum;
  form_verification_results: FormVerificationResult[];
  grades_answers: Grade[];
  is_hidden_from_parent: boolean | null;
  previous_offer: { school: { name: string }; grade: Grade } | null;
  previous_waitlist: { school: { name: string }; grade: Grade } | null;
  previous_form: PreviousForm;
  tags: GqlTag[];
};
type Grade = {
  grade_config: { label: string } | null;
};
type FormVerificationResult = {
  form_verification: { id: uuid };
  verification_status: GQL.verification_status_enum;
};
type FormSchoolRank = {
  id: uuid;
  rank: number;
  tags: SchoolRankTag[];
  school: School;
  sub_status: string | null;
  offers: Offer[];
  waitlists: Waitlist[];
  lottery_order: numeric | null;
  form_school_offer_status_history: OfferStatusHistory | null;
};
type OfferStatusHistory = {
  submitted_at: timestamptz | null;
};
export type School = {
  id: uuid;
  name: string;
};
type Offer = {
  status_updated_at: timestamptz | null;
};
type Waitlist = {
  status_updated_at: timestamptz | null;
};
export type SchoolRankTag = {
  created_at: timestamptz | null;
  enrollment_period_tag: { name: string; tag_group_id: string | null };
};

export type GqlTag = {
  created_at: timestamptz | null;
  enrollment_period_tag: { name: string };
};
export type GqlSchoolForm = {
  form: GqlForm | null;
  form_school_rank: FormSchoolRank | null;
  waitlist_position: number | null;
};
