import { Flex, FlexProps, Text, VisuallyHidden } from "@chakra-ui/react";
import {
  DragDropContext,
  Draggable,
  DragStart,
  DragUpdate,
  Droppable,
  DropResult,
  ResponderProvided,
} from "@hello-pangea/dnd";
import React from "react";
import { sortList } from "src/services/formTemplate/sorter";
import { SortableItem } from "./SortableItem";

type Props<ITEM> = {
  droppableId: string;
  items: readonly ITEM[];
  toId: (item: Readonly<ITEM>) => string;
  render: (item: Readonly<ITEM>) => React.ReactNode;
  onSort: (items: readonly ITEM[]) => Promise<void>;
  helpTextId: string;
  deleteButton: {
    onClick: (item: Readonly<ITEM>) => void;
    label: string;
  };
} & { sortableItemProps?: FlexProps };

export function SortableList<ITEM>({
  droppableId,
  items,
  toId,
  render,
  onSort,
  helpTextId,
  deleteButton,
  sortableItemProps,
}: Props<ITEM>) {
  const [draggedIndex, setDraggedIndex] = React.useState<number | undefined>();
  const [itemsCache, setItemsCache] = React.useState<readonly ITEM[] | null>(
    null
  );

  const handleDragEnd = async (
    result: DropResult,
    _provided: ResponderProvided
  ) => {
    setDraggedIndex(undefined);

    if (result.destination === null) {
      return;
    }

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    if (sourceIndex === destinationIndex) return;
    const sortedItems = sortList(items, sourceIndex, destinationIndex);
    setItemsCache(sortedItems);
    try {
      await onSort(sortedItems);
    } finally {
      setItemsCache(null);
    }
  };

  const handleDragUpdate = (update: DragUpdate) => {
    setDraggedIndex(update.source.index);
  };

  const handleDragStart = (start: DragStart) => {
    setDraggedIndex(start.source.index);
  };

  return (
    <DragDropContext
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      onDragUpdate={handleDragUpdate}
    >
      <Droppable droppableId={droppableId}>
        {(provided) => (
          <Flex
            ref={provided.innerRef}
            width="100%"
            paddingY={3}
            direction="column"
            flexGrow={1}
            {...provided.droppableProps}
          >
            <Text
              alignSelf="center"
              fontSize="xs"
              fontWeight="600"
              marginBottom={1}
            >
              Drag and drop to change the order
            </Text>
            {(itemsCache ?? items).map((item, index) => {
              const id = toId(item);
              return (
                <Draggable draggableId={id} index={index} key={id}>
                  {(provided, snapshot) => (
                    <SortableItem
                      item={item}
                      index={index}
                      snapshot={snapshot}
                      provided={provided}
                      draggedIndex={draggedIndex}
                      render={render}
                      helpTextId={helpTextId}
                      deleteButton={deleteButton}
                      {...sortableItemProps}
                    ></SortableItem>
                  )}
                </Draggable>
              );
            })}

            <VisuallyHidden>{provided.placeholder}</VisuallyHidden>
          </Flex>
        )}
      </Droppable>
    </DragDropContext>
  );
}
