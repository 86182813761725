export interface LanguageMultiSelect {
  id: string;
  name: string;
}

export const transformLanguageToMultiSelect = (
  languageCodes: string[],
  getLanguageName: (code: string) => string
): LanguageMultiSelect[] => {
  return languageCodes.map((languageCode) => {
    return { id: languageCode, name: getLanguageName(languageCode) };
  });
};

export const transformMultiSelectToLanguage = (
  languages: LanguageMultiSelect[]
): string[] => {
  return languages.map((language) => language.id);
};
