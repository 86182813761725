import { Box, Icon, Progress, Text, Tooltip } from "@chakra-ui/react";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { RiCloseLine, RiErrorWarningLine } from "react-icons/ri";
import { INSERT_FORM_ANSWER } from "src/components/graphql/mutations";
import {
  GENERATE_UPLOAD_URL,
  MAX_FILE_SIZE,
  UPLOAD_FILE_STATE,
} from "src/constants";
import useAccessToken from "src/hooks/useAccessToken";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import useUser from "src/hooks/useUser";
import { normalizeToAWSAcceptableKey } from "src/services/format";
import { Status } from "src/types/authData";
import * as GQL from "src/types/graphql";

interface UploadFileItemProps {
  file: File;
  formAnswerId?: string;
  formId: string;
  questionId: string;
  formGuardianId?: string | null;
  onUploadFile: (file: File, fileId: string) => void;
  onRemoveFile: (file: File) => void;
  onFormAnswerCreation: (formAnswerId: string) => void;
  onFileUploadError: () => void;
}

export const UploadFileItem: React.FC<UploadFileItemProps> = ({
  file,
  formAnswerId,
  formId,
  questionId,
  formGuardianId,
  onUploadFile,
  onRemoveFile,
  onFormAnswerCreation,
  onFileUploadError,
}) => {
  const user = useUser();
  const accessToken = useAccessToken();

  const [uploadSucess, setUploadSucess] = useState<string | undefined>(
    undefined
  );
  const [defaultError, setDefaultError] = useState<string | undefined>(
    undefined
  );
  const [uploadingError, setUploadingError] = useState<string | undefined>(
    undefined
  );
  const [loading, setLoading] = useState<number | undefined>(undefined);

  const [insertFormAnswer] = useRemoteDataMutation<
    GQL.InsertFormAnswerOne,
    GQL.InsertFormAnswerOneVariables
  >(INSERT_FORM_ANSWER);

  const handleRequestErrors = useCallback(() => {
    setLoading(100);
    onFileUploadError();

    setTimeout(() => {
      setLoading(undefined);
      setUploadingError(undefined);
      setDefaultError(UPLOAD_FILE_STATE.error.messages.UPLOAD_ERROR_DEFAULT);
    }, 1500);
    return setUploadingError(UPLOAD_FILE_STATE.error.messages.UPLOAD_ERROR);
  }, [onFileUploadError]);

  const handleAxiosPostURL = useCallback(
    async (file: File, newFormAnswerId: string) => {
      try {
        if (
          !process.env.REACT_APP_AWS_FILE_UPLOAD_URL ||
          user.status !== Status.OK
        )
          return;

        return await axios.post(
          `${process.env.REACT_APP_AWS_FILE_UPLOAD_URL}/${GENERATE_UPLOAD_URL}`,
          {
            filename: normalizeToAWSAcceptableKey(file.name),
            owner_user_id: formGuardianId ?? user?.data.id,
            document_content_disposition: `attachment; filename=${normalizeToAWSAcceptableKey(
              file.name
            )}`,
            document_content_type: file.type,
            form_answer_id: newFormAnswerId,
          },
          {
            headers: {
              Authorization: `Bearer ${
                accessToken.status === Status.OK ? accessToken.data : ""
              }`,
            },
            onUploadProgress: (event) => {
              const progress: number = Math.round(
                (event.loaded * 100) / (event.total ?? 0)
              );
              setLoading(progress / 2);
            },
          }
        );
      } catch (err: any) {
        return err;
      }
    },
    [accessToken, user, formGuardianId]
  );

  const handleAxiosPutUrl = useCallback(
    async (file: File, postResponse: any) => {
      try {
        if (!postResponse?.data?.signed_url?.URL || user.status !== Status.OK)
          return;

        const putHeaders = {
          ...postResponse.data.signed_url.SignedHeader,
          "Content-Type": file.type,
        };
        delete putHeaders["Host"];

        return await axios.put(postResponse?.data?.signed_url?.URL, file, {
          headers: putHeaders,
          onUploadProgress: (event) => {
            const progress: number = Math.round(
              (event.loaded * 100) / (event.total ?? 0)
            );

            setLoading(progress / 2 + 50);
          },
        });
      } catch (err: any) {
        return err;
      }
    },
    [user]
  );

  const handleFileUpload = useCallback(
    async (newFormAnswerId: string) => {
      const postResponse = await handleAxiosPostURL(file, newFormAnswerId);

      if (axios.isAxiosError(postResponse)) {
        return handleRequestErrors();
      }

      const putResponse = await handleAxiosPutUrl(file, postResponse);

      if (axios.isAxiosError(putResponse)) {
        return handleRequestErrors();
      }

      setLoading(100);
      setUploadSucess(UPLOAD_FILE_STATE.success.messages.UPLOAD_SUCCESS);

      setTimeout(() => {
        onUploadFile(file, postResponse.data.document_id);
      }, 2000);
    },
    [
      file,
      handleAxiosPostURL,
      handleAxiosPutUrl,
      onUploadFile,
      handleRequestErrors,
    ]
  );
  const verifyFormAnswer = useCallback(async () => {
    if (!formAnswerId) {
      await insertFormAnswer({
        variables: {
          form_answer: {
            form_id: formId,
            question_id: questionId,
          },
        },
        onCompleted: (data) => {
          if (data.insert_form_answer_one) {
            onFormAnswerCreation(data.insert_form_answer_one.id);
          }
        },
      });
    } else if (!loading) {
      setLoading(1);
      handleFileUpload(formAnswerId);
    }
    // do not add `loading` to dependency as it will cost re-renderer
    // which will cause duplicate insertFormAnswer() calls
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formAnswerId,
    formId,
    insertFormAnswer,
    questionId,
    handleFileUpload,
    onFormAnswerCreation,
  ]);

  useEffect(() => {
    if (!file) return;

    if (file.size > MAX_FILE_SIZE) {
      onFileUploadError();
      return setDefaultError(UPLOAD_FILE_STATE.error.messages.FILE_TOO_LARGE);
    }

    if (user.status === Status.OK) {
      verifyFormAnswer();
    }
  }, [file, user, verifyFormAnswer, onFileUploadError]);

  const getProgressBarColor = () => {
    if (uploadingError) return UPLOAD_FILE_STATE.error.colorScheme;
    if (uploadSucess) return UPLOAD_FILE_STATE.success.colorScheme;
    return UPLOAD_FILE_STATE.loading.colorScheme;
  };

  const getProgressBarIcon = () => {
    if (uploadingError) return UPLOAD_FILE_STATE.error.icon;
    if (uploadSucess) return UPLOAD_FILE_STATE.success.icon;
    return UPLOAD_FILE_STATE.loading.icon;
  };

  const getProgressBarText = () => {
    if (uploadingError) return uploadingError;
    if (uploadSucess) return uploadSucess;
    return UPLOAD_FILE_STATE.loading.messages.UPLOADING;
  };

  return (
    <>
      <Text
        color="gray.600"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        {file.name}
        {defaultError && (
          <Tooltip label="Dismiss" hasArrow>
            <span>
              <RiCloseLine
                cursor="pointer"
                onClick={() => onRemoveFile(file)}
              />
            </span>
          </Tooltip>
        )}
      </Text>
      {defaultError && (
        <Text
          fontSize="xs"
          display="flex"
          alignItems="center"
          gap={0.5}
          color="red.600"
        >
          <RiErrorWarningLine /> {defaultError}
        </Text>
      )}
      <Box mb={2}>
        {loading && (
          <>
            <Progress
              value={loading}
              borderRadius="300px"
              size="xs"
              colorScheme={getProgressBarColor()}
            />
            <Text
              fontSize="xs"
              display="flex"
              alignItems="center"
              gap={0.5}
              color={`${getProgressBarColor()}.600`}
            >
              <>
                {<Icon as={getProgressBarIcon()} />} {getProgressBarText()}
              </>
            </Text>
          </>
        )}
      </Box>
    </>
  );
};
