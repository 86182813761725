import { Flex, Heading } from "@chakra-ui/react";
import { ColumnDef } from "@tanstack/react-table";
import { useMemo } from "react";
import { Table } from "src/components/Table/Table";
import * as GQL from "src/types/graphql";
import { buildFormIdColumnDef } from "./columns/formIdColumn";
import { useGlossary } from "src/hooks/useGlossary";
import { useOrganization } from "src/hooks/useOrganization";
import { EmptyState } from "src/components/EmptyState";
import { ReactComponent as ClipboardSvg } from "src/images/clipboard.svg";
import { SortType } from "src/hooks/useLocalData";
import { RelatedFormsColumnIds } from "./helpers";

interface RelatedFormsTableProps {
  data: GQL.GetFormsByStudentId_form[];
  onSort: (columnName: string, sortType: SortType) => void;
}

type ColumnType = {
  id: string;
  form_template: GQL.GetFormsByStudentId_form_form_template;
};

export const RelatedFormsTable = ({ data, onSort }: RelatedFormsTableProps) => {
  const { glossary } = useGlossary();
  const organization = useOrganization();

  const columns: ColumnDef<ColumnType>[] = useMemo(
    () => [
      {
        id: RelatedFormsColumnIds.EnrollmentPeriod,
        header: glossary`enrollment period`,
        accessorFn: (row) => row.form_template.enrollment_period.name,
      },
      {
        id: RelatedFormsColumnIds.FormTemplateName,
        header: glossary`form name`,
        accessorFn: (row) => row.form_template.name,
      },
      buildFormIdColumnDef<ColumnType>({ glossary, organization }),
    ],
    [glossary, organization]
  );

  return (
    <Flex direction="column" gap={2}>
      <Heading as="h2" fontSize="2xl" fontWeight="400">
        Forms
      </Heading>
      {data.length === 0 ? (
        <EmptyState
          Svg={ClipboardSvg}
          description="This student has no form yet"
          margin="auto"
        />
      ) : (
        <Table
          data={data}
          columns={columns}
          onChangeSort={onSort}
          sortableColumnIds={[
            RelatedFormsColumnIds.EnrollmentPeriod,
            RelatedFormsColumnIds.FormTemplateName,
            RelatedFormsColumnIds.FormId,
          ]}
        />
      )}
    </Flex>
  );
};
