import { Flex, Tag, Text, TextProps } from "@chakra-ui/react";
import * as Turf from "@turf/helpers";
import Immutable from "immutable";
import { useMemo } from "react";
import { SchoolBoundaries } from "src/components/Boundary/schemas";
import * as Boundary from "src/components/Boundary/services";
import { RemoteDataView } from "src/components/Layout/RemoteDataView";
import { WEGLOT_SKIP_CLASS } from "src/plugins/weglot/constants";
import * as RD from "src/types/remoteData";
import { RemoteData } from "src/types/remoteData";

type School = {
  id: uuid;
  name: string;
  street_address: string | null;
  grades?: { id: uuid; grade_config_id: uuid }[];
};
type Props = {
  school: School;
  addressLocation?: RemoteData<unknown, Turf.Position | undefined>;
  gradeConfigId?: RemoteData<unknown, uuid | undefined>;
  boundariesMap?: RemoteData<unknown, Immutable.Map<uuid, SchoolBoundaries>>;
  showAddress?: boolean;
  textProps?: TextProps;
};
export const SchoolListItem: React.FC<Props> = ({
  school,
  addressLocation,
  boundariesMap,
  gradeConfigId,
  showAddress,
  textProps,
}) => {
  const tags: RemoteData<unknown, string[]> = useMemo(() => {
    if (addressLocation && boundariesMap && gradeConfigId) {
      return RD.toTuple3(addressLocation, boundariesMap, gradeConfigId).map(
        ([addressLocation, boundariesMap, gradeConfigId]) => {
          return Boundary.getBoundaryTags(
            school,
            gradeConfigId,
            addressLocation,
            boundariesMap
          );
        }
      );
    }

    return RD.success([]);
  }, [addressLocation, boundariesMap, gradeConfigId, school]);
  return (
    <Flex direction="column" className={WEGLOT_SKIP_CLASS} gap={2}>
      <Flex direction="column">
        <Text {...textProps}>{school.name}</Text>
        {showAddress && <Text>{school.street_address}</Text>}
      </Flex>
      {
        <RemoteDataView error={() => null} loading={null} remoteData={tags}>
          {(tags) => (
            <Flex direction="row" gap="2">
              {tags.map((tag) => (
                <Tag
                  variant="outline"
                  colorScheme="primary"
                  size="sm"
                  key={tag}
                >
                  {tag}
                </Tag>
              ))}
            </Flex>
          )}
        </RemoteDataView>
      }
    </Flex>
  );
};
