import { useApolloClient } from "@apollo/client";
import { Box, Button, Text } from "@chakra-ui/react";
import React from "react";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useConfirmationDialog } from "src/hooks/useConfirmationDialog";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import * as FormTemplate from "src/services/formTemplate";
import { maybePluralize } from "src/services/format";
import * as AF from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import { UPDATE_QUESTIONS_AND_SECTIONS } from "../../graphql/mutations";
import { GET_FORM_TEMPLATE_BY_ID } from "../../graphql/queries";
import { useEditFormTemplateContext } from "./context";
import { filterChanges, filterSectionChanges } from "./processor";
import { toUpdateVariables } from "./services";
import { ConfirmationDialog } from "src/components/Dialogs/ConfirmationDialog";
import { useBlocker } from "react-router-dom";

type FormButtonsProps = { formTemplate: AF.FormTemplate<AF.WithId> };
export const FormButtons: React.FC<FormButtonsProps> = ({ formTemplate }) => {
  const {
    state,
    dispatch,
    formTemplateId,
    enrollmentPeriodId,
    customQuestionTypes
  } = useEditFormTemplateContext();

  const { confirm, confirmationDialog } = useConfirmationDialog({
    body: "This will remove all of your unpublished changes and reset any questions changed to match what is currently published.",
    header: "Are you sure?",
    confirmButton: {
      label: "Reset",
      colorScheme: "red"
    }
  });

  const questionChanges = state ? filterChanges(state) : [];
  const sectionChanges = state ? filterSectionChanges(state) : [];

  const hasChanges = questionChanges.length > 0 || sectionChanges.length > 0;

  const toast = useAvelaToast();
  const [updateQuestionsAndSections] = useRemoteDataMutation<
    GQL.UpdateQuestionsAndSections,
    GQL.UpdateQuestionsAndSectionsVariables
  >(UPDATE_QUESTIONS_AND_SECTIONS, {
    refetchQueries: [GET_FORM_TEMPLATE_BY_ID]
  });

  const client = useApolloClient();

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      !!hasChanges && currentLocation.pathname !== nextLocation.pathname
  );

  const [isSubmitting, setSubmitting] = React.useState(false);
  const handleSubmit = React.useCallback(async () => {
    if (!state) {
      console.error("State is undefined");
      return;
    }

    setSubmitting(true);
    try {
      if (!formTemplateId) {
        throw new Error("Invalid data: missing formTemplateId");
      }

      if (!enrollmentPeriodId) {
        throw new Error("Invalid data: missing enrollmentPeriodId");
      }

      if (!customQuestionTypes) {
        throw new Error("Invalid data: missing customQuestionTypes");
      }

      const variables = toUpdateVariables(
        customQuestionTypes,
        enrollmentPeriodId,
        formTemplateId,
        state
      );

      await updateQuestionsAndSections({
        variables
      });

      const data = await client.query<
        GQL.GetFormTemplateById,
        GQL.GetFormTemplateByIdVariables
      >({
        query: GET_FORM_TEMPLATE_BY_ID,
        variables: { form_template_id: formTemplateId },
        fetchPolicy: "network-only"
      });

      if (dispatch) {
        if (data.data.form_template_by_pk) {
          const updatedForm = FormTemplate.fromGQL(
            data.data.form_template_by_pk,
            "lenient"
          );
          dispatch({ type: "Refresh", formTemplate: updatedForm });
          dispatch({ type: "Reset", formTemplate: updatedForm });
        }
      }

      const questionOrQuestions = maybePluralize(
        questionChanges.length,
        "question"
      );
      const sectionOrSections = maybePluralize(
        sectionChanges.length,
        "section"
      );

      let toastTitle = "";
      let toastDescription = "";

      if (sectionChanges.length > 0 && questionChanges.length > 0) {
        toastTitle = `${sectionChanges.length} section and ${questionChanges.length} question changes published!`;

        toastDescription = `The updated ${sectionOrSections} and ${questionOrQuestions} will be visible to parents immediately.`;
      } else if (sectionChanges.length > 0) {
        toastTitle = maybePluralize(
          sectionChanges.length,
          "1 section change published!",
          `${sectionChanges.length} section changes published!`
        );

        toastDescription = `The updated ${sectionOrSections} will be visible to parents immediately.`;
      } else {
        toastTitle = maybePluralize(
          questionChanges.length,
          "1 question change published!",
          `${questionChanges.length} question changes published!`
        );

        toastDescription = `The updated ${questionOrQuestions} will be visible to parents immediately.`;
      }

      toast({
        id: "saving-form-form",
        title: toastTitle,
        description: toastDescription,
        isClosable: true,
        status: "success",
        containerStyle: {
          marginBottom: "6rem"
        }
      });
    } catch (error) {
      toast.error({
        title: "Something went wrong!",
        description: "Check your network and try again.",
        containerStyle: {
          marginBottom: hasChanges ? "7em" : "6rem"
        }
      });
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  }, [
    state,
    formTemplateId,
    enrollmentPeriodId,
    customQuestionTypes,
    updateQuestionsAndSections,
    client,
    dispatch,
    questionChanges.length,
    sectionChanges.length,
    toast,
    hasChanges
  ]);

  if (!dispatch || !formTemplateId) {
    return null;
  }

  return (
    <>
      <Box
        display="grid"
        gridTemplateColumns="min-content min-content"
        rowGap="1"
        columnGap="2"
      >
        {hasChanges && (
          <Button
            variant="ghost"
            colorScheme="gray"
            onClick={async () => {
              if (await confirm()) {
                dispatch({
                  type: "Reset",
                  formTemplate
                });
              }
            }}
            gridColumn="3"
          >
            Clear unpublished changes
          </Button>
        )}
        <Button
          variant="solid"
          type="submit"
          isDisabled={!hasChanges || isSubmitting}
          isLoading={isSubmitting}
          onClick={handleSubmit}
          gridColumn="4"
        >
          Publish changes
        </Button>
        {sectionChanges.length > 0 && (
          <Text
            gridColumn="2"
            gridColumnEnd="5"
            gridRow="2"
            textAlign="right"
            fontSize="xs"
            color="gray.500"
          >
            {`${sectionChanges.length} ${
              sectionChanges.length === 1 ? "section" : "sections"
            } will be updated`}
          </Text>
        )}
        {questionChanges.length > 0 && (
          <Text
            gridColumn="2"
            gridColumnEnd="5"
            gridRow="3"
            textAlign="right"
            fontSize="xs"
            color="gray.500"
          >
            {`${questionChanges.length} ${
              questionChanges.length === 1 ? "question" : "questions"
            } will be updated`}
          </Text>
        )}
      </Box>
      {confirmationDialog}
      <ConfirmationDialog
        isOpen={blocker.state === "blocked"}
        header={<Text fontSize="lg">Exit without publishing?</Text>}
        body={
          <Text>
            There are{" "}
            <Text as="span" fontWeight={600}>
              unpublished
            </Text>{" "}
            changes on this form. If you exit now, you can come back and publish
            later.
          </Text>
        }
        onCancel={() => blocker.reset && blocker.reset()}
        onConfirm={() => blocker.proceed && blocker.proceed()}
        confirmButton={{ label: "Exit without publishing" }}
      />
    </>
  );
};
