import { useApolloClient } from "@apollo/client";
import _ from "lodash";
import { CHUNKED_BATCH_SIZE } from "src/constants";
import {
  FETCH_FORM_RECIPIENTS,
  FETCH_FORM_RECIPIENTS_MAX_INPUT_SIZE,
} from "src/scenes/orgAdmin/forms/graphql/queries";
import { asyncFlatMap } from "src/services/asyncHelpers";
import { getRecipientsForApplicant } from "src/services/person";
import * as GQL from "src/types/graphql";
import { useOrganization } from "./useOrganization";

export const useGetMessageRecipients = () => {
  const organization = useOrganization();
  const client = useApolloClient();

  const getApplicantAndRecipientsForForms = async (formIds: uuid[]) => {
    const uniqueFormIds = _.uniq(formIds);
    const records = await asyncFlatMap(
      _.chunk(uniqueFormIds, FETCH_FORM_RECIPIENTS_MAX_INPUT_SIZE),
      async (formsIdChunk) => {
        const result = await client.query<
          GQL.FetchFormRecipients,
          GQL.FetchFormRecipientsVariables
        >({
          query: FETCH_FORM_RECIPIENTS,
          variables: {
            organization_id: organization.toNullable()?.id ?? "",
            form_ids: formsIdChunk,
          },
        });
        if (result.error) throw result.error;
        if (!result.data) throw new Error("data");
        return result.data.form;
      },
      CHUNKED_BATCH_SIZE
    );

    const applicants = _.uniq(records.map((record) => record.person.id));
    const recipients = _.uniq(
      records
        .flatMap((record) => getRecipientsForApplicant(record.person))
        .map((parent) => parent.id)
    );

    return { applicants, recipients };
  };

  return {
    getApplicantAndRecipientsForForms,
  };
};
