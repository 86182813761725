import { useField } from "formik";
import { SelectControl } from "formik-chakra-ui";
import React from "react";
import {
  WEGLOT_APPLY_CLASS,
  WEGLOT_SKIP_CLASS,
} from "src/plugins/weglot/constants";
import { FormikFieldValue } from "src/services/formTemplate/answer";
import { BaseInputProps, Option } from "./Question";

export interface SingleSelectProps extends BaseInputProps {
  isRequired?: boolean;
  options: Option[];
  onOptionSelected?: (option: Option) => void;
  onSelectionClear?: () => void;
}

export const SingleSelectInput: React.FC<SingleSelectProps> = ({
  id,
  options,
  onOptionSelected,
  onSelectionClear,
  isDisabled = false,
  isRequired = false,
}) => {
  const [field, , helpers] = useField<FormikFieldValue>(id);

  const value = field.value;

  React.useEffect(() => {
    if (!onOptionSelected) return;
    for (const option of options) {
      if (option.key === value) {
        onOptionSelected(option);
        continue;
      }
    }
    if (value === "" && onSelectionClear) {
      onSelectionClear();
    }
  }, [value, options, onOptionSelected, onSelectionClear]);
  return (
    <SelectControl
      isDisabled={isDisabled}
      name={id}
      selectProps={{ placeholder: "Select answer" }}
      isRequired={isRequired}
      onChange={(event) => {
        // set as touched so it triggers autosave on change instead of on blur.
        helpers.setTouched(true);
        field.onChange(event);
      }}
    >
      {options.map((o) => (
        <option
          className={o.translate ? WEGLOT_APPLY_CLASS : WEGLOT_SKIP_CLASS}
          value={o.key}
          key={o.key}
        >
          {o.label ?? o.key}
        </option>
      ))}
    </SelectControl>
  );
};
