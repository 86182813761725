import type { ReactElement } from "react";
import { GenericError } from "src/components/Feedback/GenericError";
import { Loading } from "src/components/Feedback/Loading";
import type { RemoteData } from "src/types/remoteData";
import { RemoteDataContent } from "../RemoteData/RemoteDataContent";
import { RemoteDataLoadingIndicator } from "../RemoteData/RemoteDataLoadingIndicator";

type ParentRemoteDataLayoutProps<E, T> = {
  children: (data: T) => ReactElement | null;
  error?: (e: E) => ReactElement | null;
  loading?: ReactElement | null;
  reloading?: ReactElement | null;
  remoteData: RemoteData<E, T>;
};

/**
 * The content layout for Parent pages using RemoteData.
 */
export function ParentRemoteDataLayout<E extends Error, T>(
  props: ParentRemoteDataLayoutProps<E, T>
) {
  const { children, error, loading, reloading, remoteData } = props;

  let errorContent = null;
  if (remoteData.hasError()) {
    if (error) {
      errorContent = error(remoteData.error);
    } else {
      errorContent = <GenericError message={remoteData.error.message} />;
    }
  }

  return (
    <>
      <RemoteDataLoadingIndicator
        loadingElement={loading ?? <Loading />}
        position="absolute"
        reloadingElement={reloading}
        remoteData={remoteData}
      />

      {errorContent}

      <RemoteDataContent children={children} remoteData={remoteData} />
    </>
  );
}
