import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text
} from "@chakra-ui/react";
import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useOrganization } from "src/hooks/useOrganization";
import { EnrollmentPeriodFormTemplates } from "src/services/url/OrgAdmin";

enum CreationMethodTypes {
  Scratch = "scratch",
  File = "file"
}

const CreationMethods = [
  // We are not using from scratch yet
  // {
  //   type: CreationMethodTypes.Scratch,
  //   title: "From scratch",
  //   description: "Create the form’s sections and questions from zero"
  // },
  {
    type: CreationMethodTypes.File,
    title: "From file",
    description: "Import or write a .JSON file with the form’s structure"
  }
];

type Props = {
  isOpen: boolean;
  onCancel: () => void;
  onCreateFromScratch: () => void;
};
export const CreateFormTemplateDialog: React.FC<Props> = ({
  isOpen,
  onCancel,
  onCreateFromScratch
}) => {
  const organization = useOrganization();
  const { enrollmentPeriodId = "" } = useParams();

  const [creationMethod, setCreationMethod] =
    React.useState<CreationMethodTypes>(CreationMethodTypes.File);

  const navigate = useNavigate();

  const handleNextClick = useCallback(() => {
    if (creationMethod === CreationMethodTypes.Scratch) {
      onCreateFromScratch();
    }

    if (creationMethod === CreationMethodTypes.File) {
      onCancel();

      navigate(
        organization
          .map((org) =>
            EnrollmentPeriodFormTemplates.new(org, enrollmentPeriodId)
          )
          .withDefault("#")
      );
    }
  }, [
    creationMethod,
    navigate,
    onCancel,
    onCreateFromScratch,
    organization,
    enrollmentPeriodId
  ]);

  return (
    <Modal isOpen={isOpen} onClose={onCancel}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="lg" fontWeight="700">
          Create form
        </ModalHeader>
        <ModalBody as={Flex} direction="column" gap={6}>
          <Text fontWeight={400}>How would you like to create this form?</Text>
          <Flex gap={2}>
            {CreationMethods.map((method) => (
              <Button
                key={method.type}
                variant="outline"
                sx={{
                  background:
                    creationMethod === method.type
                      ? "primary.50"
                      : "blackAlpha.16",
                  color:
                    creationMethod === method.type
                      ? "primary.500"
                      : "blackAlpha.80",
                  height: "115px"
                }}
                onClick={() => setCreationMethod(method.type)}
              >
                <Box>
                  <Text fontWeight={600} mb={2}>
                    {method.title}
                  </Text>

                  <Text fontWeight={400} fontSize="sm" whiteSpace="pre-line">
                    {method.description}
                  </Text>
                </Box>
              </Button>
            ))}
          </Flex>
        </ModalBody>
        <ModalFooter gap={2}>
          <Button variant="ghost" colorScheme="gray" onClick={onCancel}>
            Cancel
          </Button>
          <Button onClick={handleNextClick}>Next</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
