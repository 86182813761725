import { Box, Flex, Text } from "@chakra-ui/react";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  CardAlertProps,
  CardWithAlert,
} from "src/components/Layout/CardWithAlert";
import { RouterLink } from "src/components/Links/RouterLink";
import { FORM_STATUS } from "src/constants";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useOrganization } from "src/hooks/useOrganization";
import { useStartForm } from "src/scenes/parent/forms/hooks/useStartForm";
import * as FormService from "src/services/form";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { FormCardActionMenu } from "./ActionMenu";
import { CardAction } from "./CardAction";
import { FormSchoolRankDetail } from "./FormSchoolRanks/FormSchoolRankDetail";
import { FormSchoolRanksDisclosure } from "./FormSchoolRanksDisclosure";
import { StatusesAndNextSteps } from "./StatusesAndNextSteps";
import { getAssignedForms, getOfferAlert } from "./helpers";

type AssignedForm = {
  id: uuid;
  form_template: {
    id: uuid;
    name: string;
  };
  person_id: uuid;
  previous_form_id: uuid | null;
  previous_offer_id: uuid | null;
  previous_waitlist_id: uuid | null;
};

type Form = Pick<
  GQL.GetParentFormsIndex_person_forms,
  | "status"
  | "id"
  | "form_template"
  | "previous_offer"
  | "previous_waitlist"
  | "form_school_ranks"
>;

type Props = {
  actionItems: GQL.FormSummaryFragment_action_items[];
  formTemplateId: uuid;
  form: Form;
  assignedForms: AssignedForm[];
  formSchoolRanks: GQL.FormSummaryFragment_form_school_ranks[];
  enrollmentPeriod: Pick<GQL.EnrollmentPeriodBasics, "name">;
  gradeLabel: string;

  statusDescriptions: Pick<
    GQL.FormStatusDescription,
    "description" | "status"
  >[];

  studentName: string;
};

export const FormTemplateCard: React.FC<Props> = ({
  actionItems,
  formTemplateId,
  form,
  assignedForms,
  formSchoolRanks,
  enrollmentPeriod,
  gradeLabel,
  statusDescriptions,
  studentName,
}) => {
  const organization = useOrganization();

  const offers = formSchoolRanks.filter(
    (schoolRank) =>
      schoolRank.offers[0]?.status === GQL.offer_status_enum.Offered
  );

  // This check is needed because there is a gap in requirements on admin side
  // in the case when an form has offers/waitlist on school ranks but
  // the entire form then gets set to "Cancelled" or "In Progress".
  // This flag should be unnecessary once that gap is addressed.
  const showSubstatus =
    form.status === GQL.form_status_enum.Submitted ||
    form.status === GQL.form_status_enum.Verified ||
    form.status === GQL.form_status_enum.LotteryReady ||
    form.status === GQL.form_status_enum.Admissions;

  let rankContent;
  if (formSchoolRanks.length === 1) {
    const schoolRank = formSchoolRanks[0]!;

    const acceptedOfferSchoolName = formSchoolRanks.find(
      (schoolRank) =>
        schoolRank.offers[0]?.status === GQL.offer_status_enum.Accepted
    )?.school?.name;

    rankContent = (
      <FormSchoolRankDetail
        formTemplateId={formTemplateId}
        acceptedOfferSchoolName={acceptedOfferSchoolName}
        form={form}
        offer={schoolRank.offers[0] ?? null}
        formSchoolRank={schoolRank}
        waitlist={schoolRank.waitlists[0] ?? null}
        gradeLabel={gradeLabel}
        key={schoolRank.rank}
        school={schoolRank.school}
        studentName={studentName}
      />
    );
  } else if (formSchoolRanks.length > 1) {
    rankContent = (
      <FormSchoolRanksDisclosure
        form={form}
        formTemplateId={formTemplateId}
        formSchoolRanks={formSchoolRanks}
        gradeLabel={gradeLabel}
        offerCount={offers.length}
        studentName={studentName}
      />
    );
  }

  const alerts: CardAlertProps[] = [];
  if (showSubstatus && offers.length > 0) {
    alerts.push(
      getOfferAlert({
        formId: form.id,
        offerCount: offers.length,
        studentName,
        organization,
      })
    );
  }

  const { startAssignedForm } = useStartForm();
  const navigate = useNavigate();

  const toast = useAvelaToast();
  const startForm = useCallback(
    async (assignedForm: AssignedForm) => {
      if (!organization.hasData()) {
        return;
      }

      try {
        const formId = await startAssignedForm(assignedForm);
        if (formId) {
          navigate(Url.Parent.Form.edit(organization.data, formId));
        }
      } catch (e) {
        console.error(e);
        toast.error({
          title: "Oops! Something went wrong",
        });
      }
    },
    [navigate, organization, startAssignedForm, toast]
  );

  const relatedAssignedForms = getAssignedForms(form, assignedForms);
  for (const assignedForm of relatedAssignedForms) {
    alerts.push({
      content: {
        description: `🎉 ${studentName}'s ready to ${assignedForm.form_template.name.toLocaleLowerCase()}`,
        button: {
          type: "button",
          label: "Continue here",
          onClick: async () => {
            await startForm(assignedForm);
          },
        },
      },
      status: "info",
    });
  }

  const relatedSchool = FormService.Related.getRelatedSchoolName(form);
  const { accentColor, colorScheme } = FORM_STATUS[form.status];
  return (
    <Box>
      <CardWithAlert
        cardProps={{
          display: "flex",
          gap: 3,
          flexDirection: "column",
          accentColor: accentColor ?? colorScheme,
        }}
        alerts={alerts}
        colorScheme={colorScheme}
      >
        <Flex justifyContent="space-between">
          <Flex direction="column">
            <RouterLink
              fontSize="sm"
              fontWeight={600}
              to={organization
                .map(Url.Parent.ImportantDate.index)
                .withDefault("#")}
            >
              {form.form_template.name} for {enrollmentPeriod.name}
            </RouterLink>
            {relatedSchool && <Text fontSize="0.875rem">{relatedSchool}</Text>}
          </Flex>
          <FormCardActionMenu form={form} />
        </Flex>
        {rankContent}
        <StatusesAndNextSteps
          actionItems={actionItems}
          formStatus={form.status}
          closed_at={form.form_template.closed_at}
          statusDescriptions={statusDescriptions}
        />
        <CardAction form={form} />
      </CardWithAlert>
    </Box>
  );
};
