import { IconButton, Menu, MenuButton, MenuList } from "@chakra-ui/react";
import { RiMore2Fill } from "react-icons/ri";
import { useOrganization } from "src/hooks/useOrganization";
import * as GQL from "src/types/graphql";
import { AcceptOffer } from "../../ActionItem/AcceptOffer";
import { DeclineOffer } from "../../ActionItem/DeclineOffer";
import { WithdrawApp } from "../../ActionItem/WithdrawApp";
import {
  canAcceptApplicationOffer,
  canDeclineApplicationOffer,
  canWithdrawApplication,
} from "../helpers";

interface OptionsMenuProps {
  acceptedOfferSchoolName: string | undefined;
  formId: string;
  formTemplateId: uuid;

  offer: Pick<
    GQL.FormSummaryFragment_form_school_ranks_offers,
    "id" | "status"
  > | null;

  waitlist: Pick<
    GQL.FormSummaryFragment_form_school_ranks_waitlists,
    "status"
  > | null;

  gradeLabel: string;
  school: Pick<GQL.SchoolFragment, "id" | "name">;
  studentName: string;
}

export function OptionsMenu(props: OptionsMenuProps) {
  const {
    acceptedOfferSchoolName,
    formId,
    formTemplateId,
    offer,
    waitlist,
    gradeLabel,
    school,
    studentName,
  } = props;

  const organization = useOrganization();

  let acceptAction;
  let declineAction;
  let withdrawAction;

  if (offer) {
    if (canAcceptApplicationOffer(offer)) {
      acceptAction = (
        <AcceptOffer
          formTemplateId={formTemplateId}
          acceptedOfferSchoolName={acceptedOfferSchoolName}
          offerId={offer.id}
          school={school.name}
          studentName={studentName}
        />
      );
    }

    if (
      organization.hasData() &&
      canDeclineApplicationOffer(offer, organization.data)
    ) {
      <DeclineOffer offerId={offer.id} school={school.name} />;
    }
  } else if (canWithdrawApplication(waitlist)) {
    withdrawAction = (
      <WithdrawApp
        formId={formId}
        grade={gradeLabel}
        school={school.name}
        schoolId={school.id}
      />
    );
  }

  if (!acceptAction && !declineAction && !withdrawAction) {
    return null;
  }

  return (
    <Menu placement="bottom-end">
      <MenuButton
        as={IconButton}
        icon={<RiMore2Fill />}
        marginInlineStart="auto"
        onClick={() => {}}
        size="sm"
        variant="ghost"
      />

      <MenuList>
        {acceptAction}
        {declineAction}
        {withdrawAction}
      </MenuList>
    </Menu>
  );
}
