import { Skeleton } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { useParams } from "react-router-dom";
import { GenericError } from "src/components/Feedback/GenericError";
import { GQLRemoteDataView } from "src/components/Layout/RemoteDataView";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import * as GQL from "src/types/graphql";
import { GET_MESSAGE_TEMPLATE_TYPES } from "../graphql/queries";
import { formatInitialValues, formatMessageTemplates } from "./helpers";
import { EditFormTemplateMessagesProvider } from "./context";
import { MessageTemplatesForm } from "./MessageTemplatesForm";

export const ListMessageTemplates: FunctionComponent = () => {
  const { formTemplateId = "" } = useParams();

  const { remoteData } = useRemoteDataQuery<
    GQL.GetMessageTemplateTypes,
    GQL.GetMessageTemplateTypesVariables
  >(GET_MESSAGE_TEMPLATE_TYPES, {
    variables: {
      formTemplateId: formTemplateId
    },
    skip: !formTemplateId
  });

  return (
    <GQLRemoteDataView
      remoteData={remoteData}
      error={() => <GenericError />}
      loading={<Skeleton width="16rem" height="2.25rem" />}
    >
      {(data) => {
        const messageTemplates = formatMessageTemplates(data, formTemplateId);

        const initialValues = formatInitialValues(messageTemplates);

        return (
          <EditFormTemplateMessagesProvider
            formTemplateId={formTemplateId}
            listMessagesInitialValues={initialValues}
          >
            <MessageTemplatesForm messageTemplates={messageTemplates} />
          </EditFormTemplateMessagesProvider>
        );
      }}
    </GQLRemoteDataView>
  );
};
