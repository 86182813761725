import { ApolloError } from "@apollo/client";
import { useToast } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React, { useCallback } from "react";
import { AddressAnswer } from "src/components/Form/QuestionForm/formik";
import { useUpdateFormAddress } from "src/components/Form/useUpdateFormAddress";
import { RankedSchool } from "src/components/Inputs/MultiSelectSchoolRank";
import {
  FormStepLayout,
  StepProps,
} from "src/components/Layout/FormStepLayout";
import { useConfirmationDialog } from "src/hooks/useConfirmationDialog";
import { useFormSteps } from "src/hooks/useFormSteps";
import { useGlossary } from "src/hooks/useGlossary";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import useRankedSchools from "src/hooks/useSchoolRank";
import { Answer, Question } from "src/services/formTemplate";
import { FormikValues } from "src/services/formTemplate/answer";
import {
  calculateSchoolsToRemoveByEligibilityQuestions,
  calculateSchoolsToRemoveByGrade,
  getAllNotEligibleSchools,
} from "src/services/formTemplate/preRankingSection";
import * as AFF from "src/services/formTemplateFilters";
import { validateFormTemplate } from "src/services/formTemplateValidations";
import * as AF from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import * as RemoteData from "src/types/remoteData";
import { QuestionList } from "./QuestionList";
import { RankedSchoolsRemovalConfirmationDialogBody } from "./components/Dialogs/RankedSchoolsRemovalConfirmationDialogBody";
import { FormButtons } from "./components/Layout/FormButtons";
import { INSERT_FORM_SCHOOLS_RANK } from "./graphql/mutations";
import { useAutosaveReducer } from "./hooks/useAutosaveReducer";
import { deletedRankedSchoolsVar, rankedSchoolsVar } from "./store";
import {
  getAllEligibilityQuestions,
  getGradesAnswer,
} from "src/services/formTemplate/preRankingSection/determineEligibility";
import { getContext, saveContext } from "src/scenes/parent/state/localStorage";
import { ExploreSavedSchoolsDialog } from "./components/Dialogs/ExploreSavedSchools";

function getMaxSchools(
  allSections: AF.Sections<AF.WithId>
): number | undefined {
  const section = allSections.find(
    (item) => item?.type === AF.SchoolRankingSectionType
  );
  return section
    ? (section as AF.SchoolRankingSection<AF.WithId>).maxSchools
    : undefined;
}

type Props = {
  answers: GQL.GetFormAnswersById;
  formId: uuid;
  applicant: AFF.Types.Applicant;
  refetchSchoolRanks: () => Promise<unknown>;
  previousFormSchoolIds: uuid[];
  schoolRanksRemoteData: RemoteData.RemoteData<ApolloError, GQL.GetSchoolsRank>;
  section: AF.PreRankingSection<AF.WithId>;
  allSections: AF.Sections<AF.WithId>;
  verificationResults: GQL.FormFragment_form_verification_results[];
  hasBeenSubmittedBefore: boolean | null;
  onChangeFormQuestion?: (questionId: string) => void;
} & StepProps;

export const StepPreRanking: React.FC<Props> = ({
  answers,
  formId,
  applicant,
  refetchSchoolRanks,
  previousFormSchoolIds,
  schoolRanksRemoteData,
  section,
  allSections,
  verificationResults,
  hasBeenSubmittedBefore,
  onChangeFormQuestion,
  ...stepProps
}) => {
  const { glossary } = useGlossary();
  const { confirm, confirmationDialog } = useConfirmationDialog({
    header: glossary`Edit family/student information`,
    body: null,
    cancelButton: { label: "No, cancel" },
    confirmButton: { label: "Yes, continue" },
    translate: true,
  });

  const schoolRankingSection = allSections.find(
    (section): section is AF.SchoolRankingSection<AF.WithId> =>
      section?.type === "SchoolRankingSection"
  );

  const [
    insertSchoolRank,
    {
      remoteData: insertSchoolRanksRemoteData,
      reset: resetInsertSchoolRanksRemoteData,
    },
  ] = useRemoteDataMutation<
    GQL.InsertFormSchoolsRank,
    GQL.InsertFormSchoolsRankVariables
  >(INSERT_FORM_SCHOOLS_RANK);

  const {
    getDeletedRanks,
    getUpsertedRanks,
    setRanks,
    getDeletedOffers,
    getDeletedWaitlists,
  } = useRankedSchools([]);

  // When grades change, we need to delete the schools that are no longer eligible, this is a workaround
  // for a weird bug where the useRankedSchools hook doesn't show the correct state after the parent navigate
  // from schoolRanksStep to preRankingStep.
  const getDeleteSchoolsAfterGradesChange = useCallback(
    (schoolsToRemove: uuid[]): GQL.form_school_rank_bool_exp => ({
      _or: schoolRanksRemoteData.hasData()
        ? schoolRanksRemoteData.data.form_school_rank
            .filter((rank) => schoolsToRemove.includes(rank.school.id))
            .map((rank) => ({
              form_id: { _eq: formId },
              schools_ranking_section_id: { _eq: schoolRankingSection?.id },
              school_id: { _eq: rank.school.id },
            }))
        : [],
    }),
    [formId, schoolRanksRemoteData, schoolRankingSection?.id]
  );

  const setSchoolRanks = useCallback(
    async (schoolIds: string[], schoolsToRemove?: string[]) => {
      const upsertedSchoolRanks = getUpsertedRanks(
        schoolIds.map((schoolId, rank) => ({
          form_id: formId,
          schools_ranking_section_id: schoolRankingSection!.id,
          school_id: schoolId,
        }))
      );

      const deletedSchoolRanks = schoolsToRemove
        ? getDeleteSchoolsAfterGradesChange(schoolsToRemove)
        : getDeletedRanks(upsertedSchoolRanks);

      const deletedOffers = getDeletedOffers(upsertedSchoolRanks);
      const deletedWaitlists = getDeletedWaitlists(upsertedSchoolRanks);

      await insertSchoolRank({
        variables: {
          delete_offers_where: deletedOffers,
          delete_waitlists_where: deletedWaitlists,
          deleted_school_ranks: deletedSchoolRanks,
          upserted_school_ranks: upsertedSchoolRanks,
        },
      });
      await refetchSchoolRanks();
    },
    [
      formId,
      getDeleteSchoolsAfterGradesChange,
      getDeletedOffers,
      getDeletedRanks,
      getDeletedWaitlists,
      getUpsertedRanks,
      insertSchoolRank,
      refetchSchoolRanks,
      schoolRankingSection,
    ]
  );

  React.useEffect(() => {
    // This is a workaround for weird bug where the
    // rankedSchoolsRemoteData inside the confirmEligibilityQuestionUpdate function
    // doesn't get updated after a refetch.
    // So instead of directly accessing the rankedSchools in the remote data, we're using reactive variable.
    if (schoolRanksRemoteData.kind === RemoteData.RemoteDataKind.Success) {
      rankedSchoolsVar(schoolRanksRemoteData.data.form_school_rank);
    }

    setRanks(
      rankedSchoolsVar().map((rankedSchool) => ({
        form_id: rankedSchool.form.id,
        schools_ranking_section_id: schoolRankingSection!.id,
        school_id: rankedSchool.school.id,
      }))
    );
  }, [schoolRanksRemoteData, schoolRankingSection, setRanks]);

  const deleteRankedSchools = useCallback(
    async (rankedSchools: readonly RankedSchool[], schoolsToRemove: uuid[]) => {
      const updatedSelectedSchoolIds = rankedSchools
        .filter((s) => !schoolsToRemove.find((id) => s.school.id === id))
        .map((s) => s.school.id);
      await setSchoolRanks(updatedSelectedSchoolIds, schoolsToRemove);

      const currentDeletedRankedSchools = deletedRankedSchoolsVar();
      deletedRankedSchoolsVar(
        currentDeletedRankedSchools.concat(schoolsToRemove)
      );
    },
    [setSchoolRanks]
  );

  const {
    confirm: confirmAddressChange,
    confirmationDialog: addressChangeConfirmationDialog,
    calculateSchoolsToRemove,
  } = useUpdateFormAddress({ formId });

  const confirmAddressQuestionUpdate = useCallback(
    async (
      completeQuestions: readonly AF.Question<AF.WithId>[],
      question: AF.Question<AF.WithId>,
      address: AddressAnswer
    ): Promise<boolean> => {
      if (question.type !== AF.AddressType) {
        throw new Error("TODO"); // TODO
      }

      const rankedSchools = rankedSchoolsVar();
      const rankedSchoolsToRemove = await calculateSchoolsToRemove(
        rankedSchools,
        completeQuestions,
        question,
        address
      );
      const isConfirmed = await confirmAddressChange(rankedSchoolsToRemove);
      if (isConfirmed) {
        // do this in the background so it doesn't block the UI
        deleteRankedSchools(
          rankedSchools,
          rankedSchoolsToRemove.map(({ school: { id } }) => id)
        );
      }

      return isConfirmed;
    },
    [calculateSchoolsToRemove, confirmAddressChange, deleteRankedSchools]
  );

  const confirmGradesQuestionUpdate = useCallback(
    async (
      completeQuestions: readonly AF.Question<AF.WithId>[],
      answers: FormikValues,
      question: AF.Question<AF.WithId>,
      newGradeConfigId: uuid | undefined
    ): Promise<boolean> => {
      const rankedSchools = rankedSchoolsVar();
      const newAnswers = {
        ...answers,
        [question.id]: newGradeConfigId,
      };
      const schoolsToRemove = calculateSchoolsToRemoveByGrade(
        rankedSchools,
        completeQuestions,
        newAnswers
      );
      if (schoolsToRemove.length === 0) return true;

      const isConfirmed = await confirm({
        body: (
          <RankedSchoolsRemovalConfirmationDialogBody
            schoolsToRemove={schoolsToRemove}
            body={glossary`By changing this selection, your eligibility will change and these schools will be removed from your list:`}
            confirmation="Are you sure?"
          />
        ),
      });
      if (isConfirmed) {
        // do this in the background so it doesn't block the UI
        deleteRankedSchools(
          rankedSchools,
          schoolsToRemove.map(({ school: { id } }) => id)
        );
      }

      return isConfirmed;
    },
    [confirm, deleteRankedSchools, glossary]
  );

  const confirmEligibilityQuestionUpdate = useCallback(
    async (
      completeQuestions: readonly AF.Question<AF.WithId>[],
      answers: FormikValues,
      question: AF.Question<AF.WithId>,
      answer?: AF.Option<AF.WithId>
    ) => {
      if (question.category !== "Eligibility") return true;

      const rankedSchools = rankedSchoolsVar();

      const newAnswers = {
        ...answers,
        [question.id]: answer?.id,
      };

      const schoolsToRemove = calculateSchoolsToRemoveByEligibilityQuestions(
        rankedSchools,
        completeQuestions,
        newAnswers
      );

      if (schoolsToRemove.length === 0) return true;

      const isConfirmed = await confirm({
        body: (
          <RankedSchoolsRemovalConfirmationDialogBody
            schoolsToRemove={schoolsToRemove}
            body={glossary`By changing this selection, your eligibility will change and these schools will be removed from your list:`}
            confirmation="Are you sure?"
          />
        ),
      });
      if (isConfirmed) {
        // do this in the background so it doesn't block the UI
        deleteRankedSchools(
          rankedSchools,
          schoolsToRemove.map(({ school: { id } }) => id)
        );
      }

      return isConfirmed;
    },
    [confirm, deleteRankedSchools, glossary]
  );

  const toast = useToast();
  React.useEffect(() => {
    if (
      insertSchoolRanksRemoteData.kind === RemoteData.RemoteDataKind.Success
    ) {
      resetInsertSchoolRanksRemoteData();
    }
  }, [insertSchoolRanksRemoteData, resetInsertSchoolRanksRemoteData, toast]);

  const { autosaveStatus, onAutosave } = useAutosaveReducer();

  const handleExploreSavedSchools = React.useCallback(
    async (
      ineligibleSchoolIDs: readonly string[],
      selectedGradeConfigID: string
    ): Promise<boolean> => {
      const ctx = getContext();
      if (!ctx) return true;
      if (!ctx.exploreSavedSchools.length) return true;

      const allEligibleSchools = ctx.exploreSavedSchools
        .filter((item) => !ineligibleSchoolIDs.includes(item.id))
        .filter(
          (item) =>
            item.gradeConfigIDs.includes(selectedGradeConfigID) ||
            selectedGradeConfigID === ""
        );

      const ineligibleSchools = ctx.exploreSavedSchools.filter(
        (item) => !allEligibleSchools.map((item) => item.id).includes(item.id)
      );

      const maxSchools = getMaxSchools(allSections);
      const eligibleSchools = maxSchools
        ? allEligibleSchools.slice(0, maxSchools)
        : allEligibleSchools;
      const removedSchools = maxSchools
        ? allEligibleSchools.slice(maxSchools, undefined)
        : [];

      const cancelLabel =
        !eligibleSchools.length && ineligibleSchools.length ? "Cancel" : "No";
      const confirmLabel =
        !eligibleSchools.length && ineligibleSchools.length
          ? "Continue"
          : "Yes, add schools";
      const isConfirmed = await confirm({
        header: "School ranking",
        body: (
          <ExploreSavedSchoolsDialog
            eligibleSchools={eligibleSchools}
            ineligibleSchools={ineligibleSchools}
            removedSchools={removedSchools}
            numUnresolvedSchools={ctx.unresolvedReferenceIDs.length}
          ></ExploreSavedSchoolsDialog>
        ),
        cancelButton: { label: cancelLabel },
        confirmButton: { label: confirmLabel },
      });

      if (isConfirmed) {
        saveContext({
          exploreSavedSchools: eligibleSchools,
          unresolvedReferenceIDs: ctx.unresolvedReferenceIDs,
        });
      }

      return isConfirmed;
    },
    [confirm, allSections]
  );
  const { onNext } = useFormSteps(stepProps);
  const handleSubmit = async (answers: Answer.FormikValues) => {
    if (autosaveStatus === "Saving") {
      return;
    }
    const eligibilityQuestions = getAllEligibilityQuestions(section.questions);

    const selectedGradeConfigID =
      getGradesAnswer(section.questions, answers) ?? "";

    const ineligibleSchoolIDs = getAllNotEligibleSchools(
      eligibilityQuestions,
      answers
    );

    if (
      await handleExploreSavedSchools(
        ineligibleSchoolIDs,
        selectedGradeConfigID
      )
    )
      onNext();
  };

  const initialValues = Answer.getFormikInitialValues(
    section.questions,
    answers.form_answer,
    answers.grades_answer,
    answers.form_address,
    answers.custom_question_answer
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validateFormTemplate(section.questions, {
        rankedSchoolIds: [],
        previousFormSchoolIds,
      })}
      validateOnBlur={true}
      validateOnMount={false} // need to disable this since it's causing weird validation behavior since we introduce refetchAnswer. See: https://app.asana.com/0/0/1203247877978830/1203832069318649/f
      enableReinitialize
    >
      {(formikProps) => {
        const completeQuestions = Question.getCompleteQuestions(
          section.questions
        );
        const applicableQuestions = Question.groupByVerifications(
          Question.getCompleteApplicableQuestions(
            section.questions,
            formikProps.values,
            { rankedSchoolIds: [], previousFormSchoolIds }
          )
        );

        return (
          <FormStepLayout
            noValidate
            as={Form}
            title={section.title}
            description={section.description}
            buttons={(buttonsProps) => (
              <FormButtons
                saveStatus={autosaveStatus}
                {...buttonsProps}
                overridePreviousButton={{
                  hide: true,
                }}
                overrideNextButton={{
                  disabled: !formikProps.isValid,
                  action: () => {
                    // Do nothing here, and instead, next button will trigger form submission
                  },
                }}
                hasBeenSubmittedBefore={hasBeenSubmittedBefore}
              />
            )}
            {...stepProps}
            content={
              <>
                <QuestionList
                  formId={formId}
                  applicant={applicant}
                  completeQuestions={completeQuestions}
                  applicableQuestions={applicableQuestions}
                  formikProps={formikProps}
                  verificationResults={verificationResults}
                  confirmGradesChange={confirmGradesQuestionUpdate}
                  confirmEligibilityChange={confirmEligibilityQuestionUpdate}
                  confirmAddressChange={confirmAddressQuestionUpdate}
                  onAutosave={onAutosave}
                  onChangeFormQuestion={onChangeFormQuestion}
                />
                {confirmationDialog}
                {addressChangeConfirmationDialog}
              </>
            }
          />
        );
      }}
    </Formik>
  );
};
