import _ from "lodash";
import * as GQL from "src/types/graphql";

enum FormMessageType {
  Adhoc = "adhoc",
  Automatic = "form_status",
}

export enum OfferTransition {
  NewOffer = "NEW_OFFER",
  OfferedToAccepted = "OFFERED_TO_ACCEPTED",
  OfferedToDeclined = "OFFERED_TO_DECLINED",
  OfferedToRevoked = "OFFERED_TO_REVOKED",
  AcceptedToDeclined = "ACCEPTED_TO_DECLINED",
  AcceptedToRevoked = "ACCEPTED_TO_REVOKED",
}

type MessageTransition = OfferTransition & GQL.form_transition_enum;

export interface FormattedFormMessage {
  type: FormMessageType;
  messageType: GQL.message_type_enum;
  sentAt: string | null;
  subject?: string;
  body?: string;
  sender?: string | null;
  transition?: MessageTransition;
  recipient?: string | null;
  status: GQL.message_status_enum;
}

function getMessageBody(
  messageType: GQL.message_type_enum,
  message: GQL.GetFormMessages_message_adhoc_receiver_message_adhoc
) {
  switch (messageType) {
    case GQL.message_type_enum.SMS:
      return message.message_adhoc_payload_sms?.message_body;
    case GQL.message_type_enum.email:
      return message.message_adhoc_payload_email?.message_body;
  }
}

export function toFormMessageList(
  formMessages: GQL.GetFormMessages
): FormattedFormMessage[] {
  /* As a quick fix, we are removing messages that were not really sent by trusting
    the message body with the correct contact information. */
  const filteredAdhocMessages = formMessages.message_adhoc_receiver.filter(
    (rawAdhocMessage) =>
      (rawAdhocMessage.message_adhoc.message_adhoc_payload_email
        ?.message_body &&
        rawAdhocMessage.receiver_person?.email_address) ||
      (rawAdhocMessage.message_adhoc.message_adhoc_payload_sms?.message_body &&
        rawAdhocMessage.receiver_person?.phone_number)
  );

  const formattedAdhocMessages = filteredAdhocMessages.map((adhocMessage) => ({
    type: FormMessageType.Adhoc,
    messageType: adhocMessage.message_type,
    sentAt: adhocMessage.sent_at,
    subject:
      adhocMessage.message_adhoc.message_adhoc_payload_email?.message_subject,
    body: getMessageBody(adhocMessage.message_type, adhocMessage.message_adhoc),
    sender:
      adhocMessage.message_adhoc.sender_person?.full_name ??
      adhocMessage.message_adhoc.sender_person?.user?.name,
    transition: undefined,
    recipient: adhocMessage.receiver_contact_information,
    status: adhocMessage.message_status,
  }));

  const formattedFormStatusMessages = formMessages.message_form_status.map(
    (formStatusMessage) => ({
      type: FormMessageType.Automatic,
      messageType: formStatusMessage.message_type,
      sentAt: formStatusMessage.created_at,
      subject: formStatusMessage.message_subject ?? undefined,
      body: formStatusMessage.message_body ?? undefined,
      sender:
        formStatusMessage.form.form_template.enrollment_period.organization
          ?.name,
      transition: formStatusMessage.form_transition as MessageTransition,
      recipient: formStatusMessage.receiver_contact_information,
      status: formStatusMessage.message_status,
    })
  );

  const formatterdOfferStatusMessages = formMessages.message_offer_status.map(
    (offerStatusMessage) => ({
      type: FormMessageType.Automatic,
      messageType: offerStatusMessage.message_type,
      sentAt: offerStatusMessage.created_at,
      subject: offerStatusMessage.message_subject ?? undefined,
      body: offerStatusMessage.message_body ?? undefined,
      sender:
        offerStatusMessage.offer.form.form_template.enrollment_period
          .organization?.name,
      transition: offerStatusMessage.offer_transition as MessageTransition,
      recipient: offerStatusMessage.receiver_contact_information,
      status: offerStatusMessage.message_status,
    })
  );
  const formattedFormMessages = [
    ...formattedAdhocMessages,
    ...formattedFormStatusMessages,
    ...formatterdOfferStatusMessages,
  ];

  return _.sortBy(
    formattedFormMessages,
    (message) => message.sentAt || ""
  ).reverse();
}

export function getFormattedTransition(transition: MessageTransition) {
  if (!transition) {
    return undefined;
  }

  switch (transition) {
    case GQL.form_transition_enum.NEW_FORM:
      return { from: "New Form", to: undefined };
    case GQL.form_transition_enum.SUBMITTED_TO_ADMIN_CANCELLED:
      return { from: "Submitted", to: "Admin canceled" };
    case GQL.form_transition_enum.SUBMITTED_TO_CANCELLED:
      return { from: "Submitted", to: "Canceled" };
    case GQL.form_transition_enum.SUBMITTED_TO_DELETED:
      return { from: "Submitted", to: "Deleted" };
    case GQL.form_transition_enum.SUBMITTED_TO_IN_PROGRESS:
      return { from: "Submitted", to: "In progress" };
    case GQL.form_transition_enum.IN_PROGRESS_TO_ADMIN_CANCELLED:
      return { from: "In progress", to: "Admin canceled" };
    case GQL.form_transition_enum.IN_PROGRESS_TO_CANCELLED:
      return { from: "In progress", to: "Canceled" };
    case GQL.form_transition_enum.IN_PROGRESS_TO_DELETED:
      return { from: "In progress", to: "Deleted" };
    case GQL.form_transition_enum.IN_PROGRESS_TO_SUBMITTED:
      return { from: "In progress", to: "Submitted" };
    case GQL.form_transition_enum.CANCELLED_TO_ADMIN_CANCELLED:
      return { from: "Canceled", to: "Admin canceled" };
    case GQL.form_transition_enum.CANCELLED_TO_DELETED:
      return { from: "Canceled", to: "Deleted" };
    case GQL.form_transition_enum.CANCELLED_TO_IN_PROGRESS:
      return { from: "Canceled", to: "In progress" };
    case GQL.form_transition_enum.CANCELLED_TO_SUBMITTED:
      return { from: "Canceled", to: "Submitted" };
    case OfferTransition.NewOffer:
      return { from: "New Offer", to: undefined };
    case OfferTransition.OfferedToAccepted:
      return { from: "Offered", to: "Accepted" };
    case OfferTransition.OfferedToDeclined:
      return { from: "Offered", to: "Declined" };
    case OfferTransition.OfferedToRevoked:
      return { from: "Offered", to: "Revoked" };
    case OfferTransition.AcceptedToDeclined:
      return { from: "Accepted", to: "Declined" };
    case OfferTransition.AcceptedToRevoked:
      return { from: "Accepted", to: "Revoked" };
    default:
      return undefined;
  }
}
