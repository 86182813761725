import { AuthorizationMap } from "@avela/avela-authorization-sdk";
import React, { useCallback, useMemo, useState } from "react";
import { RemoteData, notAsked } from "src/types/remoteData";

export const FORM_AUTHORIZATION_SCOPE = "form-authorization" as const;
export const PERMISSIONS_AUTHORIZATION_SCOPE = "permissions" as const;

export type AuthorizationScope =
  | typeof FORM_AUTHORIZATION_SCOPE
  | typeof PERMISSIONS_AUTHORIZATION_SCOPE;

export interface AuthorizationContextType {
  scopedPermissions: Record<
    AuthorizationScope,
    RemoteData<Error, AuthorizationMap>
  >;
  setPermissionInScope: (
    scope: AuthorizationScope,
    permissions: RemoteData<Error, AuthorizationMap>
  ) => void;
}

const defaultScopedPermissions: AuthorizationContextType["scopedPermissions"] =
  {
    "form-authorization": notAsked(),
    permissions: notAsked(),
  };

const AuthorizationContext = React.createContext<
  AuthorizationContextType | undefined
>(undefined);

interface AuthorizationProviderProps {}

export function AuthorizationProvider({
  children,
}: React.PropsWithChildren<AuthorizationProviderProps>) {
  const [scopedPermissions, setScopedPermissions] = useState(
    defaultScopedPermissions
  );

  const setPermissionInScope = useCallback(
    (
      scope: AuthorizationScope,
      permissions: RemoteData<Error, AuthorizationMap>
    ) => {
      setScopedPermissions((prev) => ({
        ...prev,
        [scope]: permissions,
      }));
    },
    [setScopedPermissions]
  );

  return (
    <AuthorizationContext.Provider
      value={{ scopedPermissions, setPermissionInScope }}
    >
      {children}
    </AuthorizationContext.Provider>
  );
}

/**
 * Hook to get permissions from the context
 *
 * @param scope AuthorizationScope
 * @returns permissions
 */
export function useAuthorizationScoped(
  scope: AuthorizationScope
): AuthorizationContextType["scopedPermissions"][typeof scope] {
  const context = React.useContext(AuthorizationContext);
  if (context === undefined) {
    console.error(
      "useAuthorization() must be used within an AuthorizationProvider"
    );
  }

  return context?.scopedPermissions[scope] ?? defaultScopedPermissions[scope];
}

/**
 * Hook to dispatch permissions to the context
 *
 * @param scope AuthorizationScope
 * @returns setPermissionInScope
 */
export function usePermissionDispatcher(scope: AuthorizationScope) {
  const context = React.useContext(AuthorizationContext);
  if (context === undefined) {
    console.error(
      "useAuthorization() must be used within an AuthorizationProvider"
    );
    throw new Error(
      "useAuthorization() must be used within an AuthorizationProvider"
    );
  }
  const setPermissionInScope = context.setPermissionInScope;

  const hasData = useMemo(
    () => context.scopedPermissions[scope].hasData(),
    [context.scopedPermissions, scope]
  );

  return useCallback(
    (permissions: RemoteData<Error, AuthorizationMap>) => {
      if (!hasData) {
        setPermissionInScope(scope, permissions);
      }
    },
    [hasData, scope, setPermissionInScope]
  );
}
