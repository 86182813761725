import { Heading } from "@chakra-ui/layout";
import { SwitchInput } from "src/components/Inputs/SwitchInput";
import * as GQL from "src/types/graphql";

export const MatchFormConfiguration: React.FC = () => {
  return (
    <>
      <Heading as="h3" size="md">
        Match
      </Heading>
      <SwitchInput<boolean>
        label="Enable running Match"
        name={`organization_configs[${GQL.organization_config_type_enum.Match}].enabled`}
        checked={[true, ""]}
        unchecked={[false, ""]}
        direction="row"
      />
    </>
  );
};
