import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React from "react";
import { InputWithValidationControl } from "src/components/Inputs/InputWithValidationControl";
import { SwitchInput } from "src/components/Inputs/SwitchInput";
import { TextareaWithValidationControl } from "src/components/Inputs/TextareaWithValidationControl";
import * as Draft from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/types/draft";
import { validateWithZod } from "src/services/formValidations";
import * as GQL from "src/types/graphql";
import { z } from "zod";

const FormSchema = z.object({
  title: z.string().min(1, "Section title is required"),
  description: z.string().optional(),
  key: z.string().nullable(),
  type: z.union([
    z.literal(GQL.form_template_section_type_enum.PreRankingSection),
    z.literal(GQL.form_template_section_type_enum.SchoolRankingSection),
    z.literal(GQL.form_template_section_type_enum.GeneralSection),
    z.literal(GQL.form_template_section_type_enum.DisclaimerSection),
  ]),
  permissionLevel: z.string().nullable(),
});

export type SectionFormType = z.infer<typeof FormSchema>;
type EditProps = {
  isOpen: boolean;
  section?: Draft.Section | Draft.NewSection;
  onCancelButtonClick: () => void;
  onCreate: (section: SectionFormType) => void;
};
export const SectionFormDialog: React.FC<EditProps> = ({
  isOpen,
  section,
  onCancelButtonClick,
  onCreate,
}) => {
  const initialValues = {
    title: section?.title ?? "",
    description: section?.description ?? "",
    key: section?.key ?? "",
    permissionLevel: section?.permissionLevel ?? null,
    type:
      (section?.type as GQL.form_template_section_type_enum) ??
      GQL.form_template_section_type_enum.GeneralSection,
  };

  return (
    <Formik<SectionFormType>
      initialValues={initialValues}
      enableReinitialize
      onSubmit={onCreate}
      validate={validateWithZod(FormSchema)}
    >
      {(formik) => {
        return (
          <Modal isOpen={isOpen} onClose={onCancelButtonClick}>
            <ModalOverlay />
            <ModalContent as={Form}>
              <ModalHeader>
                {section ? "Edit section" : "Add section"}
              </ModalHeader>
              <ModalBody as={Flex} direction="column" gap={2}>
                <FormControl>
                  <FormLabel>Section title</FormLabel>
                  <InputWithValidationControl
                    id="title"
                    name="title"
                    isRequired={true}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Section description</FormLabel>
                  <TextareaWithValidationControl
                    id="description"
                    name="description"
                  />
                </FormControl>

                <FormControl>
                  <FormLabel>Section key (optional)</FormLabel>
                  <InputWithValidationControl id="key" name="key" />
                </FormControl>

                {formik.values.type ===
                  GQL.form_template_section_type_enum.GeneralSection && (
                  <Flex alignItems="start">
                    <SwitchInput<string | null>
                      label=""
                      name="permissionLevel"
                      checked={["admin", ""]}
                      unchecked={[null, ""]}
                      containerProps={{ direction: "row", gap: 2 }}
                    />
                    <Flex direction="column">
                      <Text>Internal-only</Text>
                      <Text fontSize="xs" color="gray.600" fontWeight="400">
                        When enabled, it will not be displayed to parents.
                      </Text>
                    </Flex>
                  </Flex>
                )}
              </ModalBody>
              <ModalFooter gap={2}>
                <Button
                  variant="ghost"
                  colorScheme="gray"
                  onClick={onCancelButtonClick}
                >
                  Cancel
                </Button>
                <Button type="submit">
                  {section ? "Update" : "Add"} section
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        );
      }}
    </Formik>
  );
};
