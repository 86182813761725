import { Flex } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { useCallback, useMemo } from "react";
import { useOutletContext } from "react-router-dom";
import { FORM_DESCRIPTION_MAX_CHARS } from "src/constants";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useTimezoneName } from "src/hooks/useOrganization";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { validateWithZod } from "src/services/formValidations";
import * as GQL from "src/types/graphql";
import { FormTemplateOutletContext } from "../../Edit";
import { UPDATE_FORM_TEMPLATE_SETTINGS_BY_ID } from "../../graphql/mutations";
import { GET_FORM_TEMPLATE_BY_ID } from "../../graphql/queries";
import { EditFormTemplateActionBar } from "../components/ActionBar";
import { AdvancedSettings } from "./AdvancedSettings";
import { BasicInfoForm } from "./BasicInfoForm";
import { ImportantDatesForm } from "./ImportantDatesForm";
import {
  FormTemplateSettingsSchema,
  FormTemplateSettingsType
} from "./schemas";
import { toGQLFormTemplateRule, toGQLFormTemplateSettings } from "./utils";
import useRequiredHasuraRoles from "src/hooks/useRequiredHasuraRoles";
import { HasuraRole } from "src/types/hasuraRole";
import { useEditFormTemplateSettingsContext } from "./context";
import { FormTemplateId, hasChanges, toOriginal } from "../../types/common";
import { GenericError } from "src/components/Feedback/GenericError";

const FORM_ID = "form-template-settings-form";
interface Props {
  isLoading: boolean;
}
export const SettingsForm: React.FC<Props> = ({ isLoading }) => {
  const { state, dispatch } = useEditFormTemplateSettingsContext();
  const { actionBarRef, formTemplate } =
    useOutletContext<FormTemplateOutletContext>();

  const id = formTemplate.id;
  const timezoneName = useTimezoneName();
  const toast = useAvelaToast();
  const isAvelaAdmin = useRequiredHasuraRoles([HasuraRole.ADMIN]);

  const [updateFormTemplateSettings] = useRemoteDataMutation<
    GQL.UpdateFormTemplateSettingsById,
    GQL.UpdateFormTemplateSettingsByIdVariables
  >(UPDATE_FORM_TEMPLATE_SETTINGS_BY_ID);

  const handleSubmit = useCallback(
    async (values: FormTemplateSettingsType) => {
      try {
        const formTemplateSettingsObj = toGQLFormTemplateSettings(
          values,
          timezoneName
        );

        const updatedFormTemplateRules = toGQLFormTemplateRule(
          values.formTemplateRule
        );

        let formTemplateSettingsVariables: GQL.form_template_set_input = {};

        if (isAvelaAdmin) {
          formTemplateSettingsVariables = {
            ...formTemplateSettingsObj,
            form_template_rule_id:
              updatedFormTemplateRules?.rootFormTemplateRuleId
          };
        } else {
          formTemplateSettingsVariables = {
            name: formTemplateSettingsObj.name,
            description: formTemplateSettingsObj.description,
            key: formTemplateSettingsObj.key,
            open_at: formTemplateSettingsObj.open_at,
            closed_at: formTemplateSettingsObj.closed_at,
            reopen_at: formTemplateSettingsObj.reopen_at,
            offer_start_at: formTemplateSettingsObj.offer_start_at
          };
        }

        await updateFormTemplateSettings({
          variables: {
            id,
            form_template_settings: formTemplateSettingsVariables,
            upsert_form_template_rule:
              updatedFormTemplateRules?.upsertFormTemplateRule,
            upsert_rules: updatedFormTemplateRules?.upsertRules,
            upsert_status_rules: updatedFormTemplateRules?.upsertStatusRules,
            deleted_rules: updatedFormTemplateRules?.deletedRules,
            deleted_form_template_rules:
              updatedFormTemplateRules?.deletedFormTemplateRules,
            skipRules: !updatedFormTemplateRules
          },
          refetchQueries: [GET_FORM_TEMPLATE_BY_ID]
        });

        if (dispatch) {
          dispatch({
            type: "Refresh",
            initialValues: { settings: values },
            formTemplateId: id
          });
        }

        toast({
          title: "Success",
          description: "Form settings updated",
          status: "success",
          duration: 2000
        });
      } catch (e) {
        toast({
          title: "Something went wrong",
          description: "Please try again later or contact our team",
          status: "error",
          duration: 2000
        });
      }
    },
    [
      id,
      toast,
      updateFormTemplateSettings,
      timezoneName,
      isAvelaAdmin,
      dispatch
    ]
  );

  const handleValidate = useCallback(
    (values: FormTemplateSettingsType) => {
      if (dispatch) {
        dispatch({
          type: "UpdateSettings",
          settings: {
            isDraft: true,
            ...values
          },
          formTemplateId: id
        });

        dispatch({
          type: "Save",
          formTemplateId: id
        });
      }

      validateWithZod(FormTemplateSettingsSchema)(values);
    },
    [id, dispatch]
  );

  const draft = useMemo(
    () => state?.get("settings")?.get(FormTemplateId(id))?.draft,
    [id, state]
  );

  const original = useMemo(
    () => state?.get("settings")?.get(FormTemplateId(id))?.original,
    [id, state]
  );

  const formikInitialValues = useMemo(() => {
    if (draft) {
      return toOriginal(draft);
    } else {
      return original;
    }
  }, [draft, original]);

  const hasChangesResult = hasChanges(original, draft);

  if (!formikInitialValues) return <GenericError />;

  return (
    <Formik<FormTemplateSettingsType>
      initialValues={formikInitialValues}
      onSubmit={handleSubmit}
      validate={handleValidate}
      enableReinitialize
    >
      {({ values, errors, resetForm }) => {
        const descriptionRemainingChars =
          FORM_DESCRIPTION_MAX_CHARS - values.description.length;

        const handleResetForm = () => {
          resetForm();

          if (dispatch) {
            dispatch({
              type: "Reset",
              formTemplateId: id
            });
          }
        };

        return (
          <>
            <Flex as={Form} id={FORM_ID} direction="column" gap={2}>
              <BasicInfoForm
                errors={errors}
                descriptionRemainingChars={descriptionRemainingChars}
              />

              <ImportantDatesForm />

              <AdvancedSettings />
            </Flex>
            <EditFormTemplateActionBar
              actionBarRef={actionBarRef}
              formId={FORM_ID}
              isLoading={isLoading}
              hasChanges={hasChangesResult}
              onClearChanges={handleResetForm}
            />
          </>
        );
      }}
    </Formik>
  );
};
