import { ApolloQueryResult } from "@apollo/client";
import { ColumnDef } from "@tanstack/table-core";
import { useCallback, useEffect, useMemo } from "react";
import { PaginatedTable } from "src/components/Table/PaginatedTable";
import { SortType } from "src/components/Table/SortButton";
import { FormTabsTypes } from "src/constants";
import { useGlossary } from "src/hooks/useGlossary";
import { useIsOfferEnabled } from "src/hooks/useIsOfferEnabled";
import { Selection } from "src/hooks/useMultiselectState";
import { useOrganization } from "src/hooks/useOrganization";
import { useSchoolAdmin } from "src/hooks/useSchoolAdmin";
import { checkboxColumnDef } from "src/hooks/useTableSelection";
import { buildVisibilityColumnDef } from "src/scenes/orgAdmin/forms/formTables/visibilityColumn";
import * as GQL from "src/types/graphql";
import { TableBanner } from "./components/TableBanner";
import { TableHeader } from "./components/TableHeader";
import { MenuItem } from "./components/menuItem";
import { ColumnId } from "./formTables/constants";
import { buildIdColumnDef } from "./formTables/formIdColumn";
import { buildGradeColumnDef } from "./formTables/gradeColumn";
import { buildSchoolColumnDef } from "./formTables/schoolColumn";
import { buildSchoolIdColumnDef } from "./formTables/schoolIdColumn";
import { buildSchoolRankColumnDef } from "./formTables/schoolRankColumn";
import { buildStatusColumnDef } from "./formTables/statusColumn";
import { buildStudentColumnDef } from "./formTables/studentColumn";
import { buildSubStatusColumnDef } from "./formTables/subStatusColumn";
import {
  buildFormTagsColumnDef,
  buildTagsColumnDef,
} from "./formTables/tagsColumn";
import { buildTiebreakerColumnDef } from "./formTables/tiebreakerColumn";
import { buildVerificationsColumnDef } from "./formTables/verificationsColumn";
import { buildWaitlistPositionColumnDef } from "./formTables/waitlistPositionColumn";
import type { GqlSchoolForm, SchoolFormId, SchoolFormKeyRecord } from "./types";

interface FormsListProps {
  enrollmentPeriodId: uuid;
  forms: GqlSchoolForm[];
  formTemplate: GQL.FormTemplateFragment;
  isLoading?: boolean;
  onSort: (columnName: string, sortType: SortType) => void;
  offset: number;
  limit: number;
  count: number;
  selection: Selection<SchoolFormKeyRecord, GqlSchoolForm>;
  onSelectionChange: (
    selection: Selection<SchoolFormKeyRecord, GqlSchoolForm>
  ) => void;
  onRefetch: () => Promise<ApolloQueryResult<GQL.GetFormsByFormTemplate>>;
  onFetchAll: () => Promise<GqlSchoolForm[]>;
  onFetchAllIds: () => Promise<SchoolFormId[]>;
  onFetchByIds: (ids: SchoolFormId[]) => Promise<GQL.ExportForms>;
  onFetchMore: (limit: number, offset: number) => void;
  onSelectAll: (count: number) => void;
  tabType: FormTabsTypes;
  tagGroups: GQL.GetTagGroupsByEnrollmentPeriod_tag_group[];
  setAttendance: (data: string) => void;
}

export const FormsList: React.FC<FormsListProps> = ({
  enrollmentPeriodId,
  forms,
  formTemplate,
  isLoading,
  onSort,
  offset,
  limit,
  count,
  selection,
  onSelectionChange,
  onRefetch,
  onFetchAll,
  onFetchAllIds,
  onFetchByIds,
  onFetchMore,
  onSelectAll,
  tabType,
  tagGroups,
  setAttendance,
}) => {
  const { glossary } = useGlossary();
  const organization = useOrganization();
  const { isSchoolAdmin } = useSchoolAdmin();
  const hasVerifications =
    formTemplate?.form_verifications &&
    formTemplate?.form_verifications.length > 0;

  const isOfferEnabled = useIsOfferEnabled(organization, formTemplate);

  useEffect(() => {
    if (tabType === FormTabsTypes.FormListImports) {
      onSelectAll(count);
    }
    // only want to perform selectAll when currentTab and count changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabType, count]);

  const columns: ColumnDef<GqlSchoolForm>[] = useMemo(() => {
    //TODO (Loi): hiding/showing columns based on user roles

    const visibilityColumnDef = buildVisibilityColumnDef<GqlSchoolForm>();

    const columns = [
      checkboxColumnDef({
        count,
        id: "is_selected",
        onSelectionChange,
        selection,
      }),
      visibilityColumnDef,
    ];

    const studentColumnDef = buildStudentColumnDef<GqlSchoolForm>({
      glossary,
      organization,
      formTemplateId: formTemplate.id,
    });
    const schoolColumnDef = buildSchoolColumnDef<GqlSchoolForm>({ glossary });
    const gradeColumnDef = buildGradeColumnDef<GqlSchoolForm>();
    const formTagsColumnDef = buildFormTagsColumnDef<GqlSchoolForm>();
    const tagsColumnDef = tagGroups?.map((tagGroup) =>
      buildTagsColumnDef<GqlSchoolForm>(tagGroup)
    );

    if (tabType === FormTabsTypes.All) {
      columns.push(studentColumnDef);

      if (!isSchoolAdmin) {
        columns.push(buildSchoolRankColumnDef());
      }

      columns.push(
        schoolColumnDef,
        gradeColumnDef,
        buildStatusColumnDef<GqlSchoolForm>({ organization }),
        buildSubStatusColumnDef<GqlSchoolForm>({ organization }),
        formTagsColumnDef,
        ...tagsColumnDef
      );
    } else if (tabType === FormTabsTypes.Submissions) {
      columns.push(buildIdColumnDef<GqlSchoolForm>(), studentColumnDef);

      if (!isSchoolAdmin) {
        columns.push(buildSchoolRankColumnDef());
      }

      columns.push(
        schoolColumnDef,
        gradeColumnDef,
        buildStatusColumnDef({ organization }),
        formTagsColumnDef,
        ...tagsColumnDef
      );

      if (hasVerifications) {
        columns.push(
          buildVerificationsColumnDef<GqlSchoolForm>({ formTemplate })
        );
      }
    } else if (tabType === FormTabsTypes.Waitlists) {
      columns.push(
        buildWaitlistPositionColumnDef(),
        studentColumnDef,
        schoolColumnDef,
        gradeColumnDef,
        buildSubStatusColumnDef<GqlSchoolForm>({ organization }),
        formTagsColumnDef,
        ...tagsColumnDef,
        buildTiebreakerColumnDef<GqlSchoolForm>()
      );
    } else if (tabType === FormTabsTypes.Offers) {
      columns.push(
        studentColumnDef,
        schoolColumnDef,
        gradeColumnDef,
        buildSubStatusColumnDef<GqlSchoolForm>({ organization }),
        formTagsColumnDef,
        ...tagsColumnDef
      );
    } else if (tabType === FormTabsTypes.FormListImports) {
      columns.push(
        buildSchoolIdColumnDef<GqlSchoolForm>({ glossary }),
        studentColumnDef
      );

      if (!isSchoolAdmin) {
        columns.push(buildSchoolRankColumnDef());
      }

      columns.push(
        schoolColumnDef,
        gradeColumnDef,
        buildSubStatusColumnDef<GqlSchoolForm>({ organization }),
        formTagsColumnDef,
        ...tagsColumnDef
      );
    }

    return columns;
  }, [
    formTemplate,
    count,
    tabType,
    glossary,
    isSchoolAdmin,
    hasVerifications,
    onSelectionChange,
    organization,
    selection,
    tagGroups,
  ]);

  const handleChangeSort = useCallback(
    (headerId: string, sortType: SortType) => {
      onSort(headerId, sortType);
    },
    [onSort]
  );

  return (
    <PaginatedTable<GqlSchoolForm>
      tableHeader={() => (
        <TableHeader count={count} setAttendance={setAttendance} />
      )}
      data={forms}
      columns={columns}
      isLoading={isLoading}
      sortableColumnIds={[
        ColumnId.WaitlistPosition,
        ColumnId.Student,
        ColumnId.SchoolRank,
        ColumnId.School,
        ColumnId.Grade,
        ColumnId.Status,
        ColumnId.SubStatus,
        ColumnId.Tiebreaker,
      ]}
      onChangeSort={handleChangeSort}
      count={count}
      offset={offset}
      limit={limit}
      onFetchMore={onFetchMore}
      banner={() => (
        <TableBanner
          hiddenMenuItems={[...(isOfferEnabled ? [] : [MenuItem.Offers])]}
          formTemplate={formTemplate}
          enrollmentPeriodId={enrollmentPeriodId}
          includeUpdateStatus={
            tabType !== FormTabsTypes.Waitlists &&
            tabType !== FormTabsTypes.Offers
          }
          onFetchAll={onFetchAll}
          onFetchAllIds={onFetchAllIds}
          onFetchByIds={onFetchByIds}
          onRefetch={onRefetch}
          onSelectionChange={onSelectionChange}
          selection={selection}
        />
      )}
    />
  );
};
