import { useApolloClient } from "@apollo/client";
import React, { createContext, ReactNode, useContext } from "react";
import {
  matchPath,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useOrganization } from "src/hooks/useOrganization";
import * as Url from "src/services/url/OrgAdmin";
import * as GQL from "src/types/graphql";
import * as RD from "src/types/remoteData";
import { GET_FORM_TEMPLATES_BY_ENROLLMENT_PERIOD } from "../graphql/queries";
import { useEnrollmentPeriod } from "./EnrollmentPeriodProvider";

enum FormTemplateScope {
  Nav = "nav",
  Edit = "edit",
}

export interface SelectedFormTemplate {
  id: uuid;
  name: string;
  lottery_offers_enabled: boolean;
  offer_start_at: string | null;
  forms_aggregate: {
    aggregate: {
      count: number;
    } | null;
  };
}
interface State {
  navFormTemplates: RD.RemoteData<unknown, SelectedFormTemplate[]>;
  editFormTemplates: RD.RemoteData<unknown, SelectedFormTemplate[]>;
}
interface FormTemplateContextType extends State {
  selectedNavFormTemplate: SelectedFormTemplate | undefined;
  selectedEditFormTemplate: SelectedFormTemplate | undefined;
  fetchFormTemplates: (
    enrollmentPeriodId: uuid,
    scope: FormTemplateScope
  ) => Promise<void>;
}

const FormTemplateContext = createContext<FormTemplateContextType>({
  navFormTemplates: RD.notAsked(),
  editFormTemplates: RD.notAsked(),
  selectedNavFormTemplate: undefined,
  selectedEditFormTemplate: undefined,
  fetchFormTemplates: async () => {},
});

const initialState: State = {
  navFormTemplates: RD.notAsked(),
  editFormTemplates: RD.notAsked(),
};
interface FormTemplateProviderProps {
  children: ReactNode;
}
export function FormTemplateProvider(props: FormTemplateProviderProps) {
  const { children } = props;
  const [state, setState] = React.useState(initialState);
  const navigate = useNavigate();
  const organization = useOrganization();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { formTemplateId = "", enrollmentPeriodId = "" } = useParams();
  const { selectedEnrollmentPeriod } = useEnrollmentPeriod();

  const client = useApolloClient();

  const navigateAfterFetching = React.useCallback(
    (formTemplates: RD.RemoteData<unknown, SelectedFormTemplate[]>) => {
      if (!formTemplates.hasData() || !organization.hasData()) {
        return;
      }

      if (!matchPath(Url.Forms.indexPath, location.pathname)) {
        // User is currently not in any form pages. We don't need to navigate away
        return;
      }

      // Figure out if we need to navigate away from the current form page after changing enrollment period.
      const firstFormTemplate = formTemplates.data[0];

      if (firstFormTemplate === undefined) {
        // enrollment period doesn't have forms. Navigate to default page of the org.
        navigate(Url.Enrollments.index(organization.data));
      } else {
        const formTemplateUrls = formTemplates.data.map((ft) =>
          Url.Forms.index({
            organization: organization.data,
            formTemplateId: ft.id,
          })
        );

        // Check if we're already on the form page for the current enrollment period.
        if (formTemplateUrls.includes(location.pathname)) {
          // we're already in this enrollment periods form pages. Don't need to navigate away.
          return;
        }

        // We're in the form page belong to a different enrollment period.
        // Navigate to the first form page for the current enrollment period.
        navigate(
          Url.Forms.index({
            organization: organization.data,
            formTemplateId: firstFormTemplate.id,
            params: searchParams.toString(),
          })
        );
      }
    },
    [location.pathname, navigate, organization, searchParams]
  );

  const fetchFormTemplates = React.useCallback(
    async (enrollmentPeriodId: uuid, scope: FormTemplateScope) => {
      if (scope === FormTemplateScope.Edit) {
        setState({ ...state, editFormTemplates: RD.loading() });
      } else {
        setState({ ...state, navFormTemplates: RD.loading() });
      }
      const remoteData = RD.fromApolloResult(
        await client.query<
          GQL.GetFormTemplatesByEnrollmentPeriod,
          GQL.GetFormTemplatesByEnrollmentPeriodVariables
        >({
          query: GET_FORM_TEMPLATES_BY_ENROLLMENT_PERIOD,
          variables: {
            enrollment_period: enrollmentPeriodId,
          },
        })
      );
      const formTemplates = remoteData.map((data) => data.form_template);

      if (scope === FormTemplateScope.Edit) {
        setState({ ...state, editFormTemplates: formTemplates });
      } else {
        setState({ ...state, navFormTemplates: formTemplates });
        navigateAfterFetching(formTemplates);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigateAfterFetching, client]
  );

  React.useEffect(() => {
    if (selectedEnrollmentPeriod?.id) {
      fetchFormTemplates(selectedEnrollmentPeriod.id, FormTemplateScope.Nav);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEnrollmentPeriod]);

  React.useEffect(() => {
    if (enrollmentPeriodId) {
      fetchFormTemplates(enrollmentPeriodId, FormTemplateScope.Edit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enrollmentPeriodId]);

  const selectedNavFormTemplate = React.useMemo(() => {
    return state.navFormTemplates
      .map((formTemplates) =>
        formTemplates.find((formTemplate) => formTemplate.id === formTemplateId)
      )
      .withDefault(undefined);
  }, [formTemplateId, state.navFormTemplates]);

  const selectedEditFormTemplate = React.useMemo(() => {
    return state.editFormTemplates
      .map((formTemplates) =>
        formTemplates.find((formTemplate) => formTemplate.id === formTemplateId)
      )
      .withDefault(undefined);
  }, [formTemplateId, state.editFormTemplates]);

  return (
    <FormTemplateContext.Provider
      value={{
        ...state,
        selectedNavFormTemplate,
        selectedEditFormTemplate,
        fetchFormTemplates,
      }}
    >
      {children}
    </FormTemplateContext.Provider>
  );
}

export function useFormTemplates() {
  const context = useContext(FormTemplateContext);
  if (context === undefined) {
    throw new Error(
      "useFormTemplates must be used within a FormTemplateContext"
    );
  }
  return context;
}
