import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { WEGLOT_SKIP_CLASS } from "src/plugins/weglot/constants";
import { RankedSchool } from "src/services/formTemplate/preRankingSection";

type Props = {
  body: string;
  confirmation: string;
  schoolsToRemove: readonly RankedSchool[];
};
export const RankedSchoolsRemovalConfirmationDialogBody: React.FC<Props> = ({
  schoolsToRemove,
  body,
  confirmation,
}) => {
  return (
    <Flex direction="column" gap={3}>
      <Text>{body}</Text>
      <Box>
        {schoolsToRemove.map((rankedSchool) => {
          return (
            <Text key={rankedSchool.school.id} className={WEGLOT_SKIP_CLASS}>
              {rankedSchool.rank + 1} - {rankedSchool.school.name}
            </Text>
          );
        })}
      </Box>
      <Text fontWeight="bold">{confirmation}</Text>
    </Flex>
  );
};
