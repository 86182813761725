import { Pages } from "src/auth/types";
import z from "zod";

const EmailPasswordSchema = z.object({
  method: z.literal("email"),
});
const SmsSchema = z.object({
  method: z.literal("sms"),
});
const SSOSchema = z.object({
  method: z.literal("sso"),
  identityProvider: z.string(),
  name: z.string(),
});
export type SSO = z.infer<typeof SSOSchema>;

export const LoginMethodSchema = z.discriminatedUnion("method", [
  EmailPasswordSchema,
  SmsSchema,
  SSOSchema,
]);
export const LoginMethodsSchema = z.array(LoginMethodSchema);

export const LoginConfigSchema = z.object({
  admin: LoginMethodsSchema,
  parent: LoginMethodsSchema,
});

export type LoginMethod = z.infer<typeof LoginMethodSchema>;
export type LoginConfig = z.infer<typeof LoginConfigSchema>;

export const DEFAULT_LOGIN_CONFIG: LoginConfig = {
  admin: [{ method: "email" }],
  parent: [{ method: "email" }, { method: "sms" }],
};

export function isOnlySSO(methods: LoginMethod[]): boolean {
  if (methods.length > 1) {
    return false;
  }

  if (methods.find((m) => m.method === "sso") !== undefined) {
    return true;
  }

  return false;
}

export function isEmailPasswordEnabled(methods: LoginMethod[]): boolean {
  return methods.find((m) => m.method === "email") !== undefined;
}

export function isSMSEnabled(methods: LoginMethod[]): boolean {
  return methods.find((m) => m.method === "sms") !== undefined;
}

export function getSSOsForPage(page: Pages, methods: LoginMethod[]): SSO[] {
  if (page !== Pages.Login) {
    return [];
  }

  return methods.filter((m): m is SSO => {
    return m.method === "sso";
  });
}
