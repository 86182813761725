import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Spacer,
  VStack,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { useMemo } from "react";
import { NavLink } from "react-router-dom";
import { InputWithValidationControl } from "src/components/Inputs/InputWithValidationControl";
import { SwitchInput } from "src/components/Inputs/SwitchInput";
import { useOrganization } from "src/hooks/useOrganization";
import { validateWithZod } from "src/services/formValidations";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import {
  EnrollmentPeriodFormSchema,
  EnrollmentPeriodFormType,
} from "../../schemas";

const enrollmentPeriodEmptyValues: EnrollmentPeriodFormType = {
  name: "",
  active: false,
  key: null,
};

interface Props {
  enrollmentPeriod?: GQL.GetEnrollment_enrollment_period_by_pk;
  onSubmit: (values: EnrollmentPeriodFormType) => void;
  submitting: boolean;
}

export const GeneralForm: React.FC<Props> = ({
  onSubmit,
  submitting,
  enrollmentPeriod,
}) => {
  const organization = useOrganization();
  const isNewSeason = !enrollmentPeriod;

  const initialValues: EnrollmentPeriodFormType = useMemo(
    () =>
      isNewSeason
        ? enrollmentPeriodEmptyValues
        : {
            name: enrollmentPeriod.name ?? "",
            active: enrollmentPeriod.active ?? false,
            key: enrollmentPeriod.key || null,
          },
    [isNewSeason, enrollmentPeriod]
  );

  const handleSubmit = (values: EnrollmentPeriodFormType) => {
    onSubmit({
      name: values.name?.trim(),
      active: values.active,
      key: values.key?.trim() || null,
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validateWithZod(EnrollmentPeriodFormSchema)}
    >
      <Form>
        <VStack align="left" spacing={4}>
          {isNewSeason && <Heading>Create new enrollment period</Heading>}

          <FormControl display="flex" alignItems="center">
            <SwitchInput<boolean>
              label="Enrollment period status"
              name="active"
              checked={[true, "Active"]}
              unchecked={[false, "Not Active"]}
              containerProps={{ direction: "row", gap: 2 }}
            />
          </FormControl>

          <FormControl>
            <FormLabel>Enrollment period name</FormLabel>
            <InputWithValidationControl
              id="name"
              name="name"
              isRequired={true}
            />
          </FormControl>

          <FormControl>
            <FormLabel>Enrollment period key</FormLabel>
            <InputWithValidationControl id="key" name="key" />
          </FormControl>

          <Flex align="center">
            <Spacer />
            <Button
              as={NavLink}
              to={organization
                .map((org) => Url.OrgAdmin.Enrollments.index(org))
                .withDefault("#")}
              isLoading={organization.isLoading()}
              variant="link"
            >
              Cancel
            </Button>
            <Button type="submit" marginLeft={4} isLoading={submitting}>
              {isNewSeason ? "Create" : "Update"}
            </Button>
          </Flex>
        </VStack>
      </Form>
    </Formik>
  );
};
