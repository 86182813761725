import { z } from "zod";
import { emptyStringAsNull, nullableWithDefault } from "../services/zodExtra";
import { NameSchema, NameValidator } from "./Name";

export const BASE_INITIAL_PARENT_FORM: ParentFormType = {
  first_name: "",
  middle_name: "",
  last_name: "",
  phone_number: "",
  email_address: "",
  preferred_language: "",
  reference_id: "",
};

export const ParentSchema = NameSchema.extend({
  phone_number: nullableWithDefault(z.string(), ""),
  email_address: nullableWithDefault(z.string(), ""),
  preferred_language: nullableWithDefault(z.string(), ""),
  reference_id: emptyStringAsNull(z.string()),
}).omit({ birth_date: true });

export const ParentValidator = NameValidator.merge(ParentSchema).omit({
  birth_date: true,
  email_address: true,
  phone_number: true,
  preferred_language: true,
});

export const ContactInfoSchema = z.object({
  phone_number: nullableWithDefault(z.string(), ""),
  email_address: nullableWithDefault(z.string(), ""),
});

export type ParentFormType = z.infer<typeof ParentSchema>;
