import { useField } from "formik";
import { InputControl, InputControlProps } from "formik-chakra-ui";
import React from "react";

export const InputWithValidationControl: React.FC<InputControlProps> = (
  props
) => {
  const [, meta] = useField(props.name);

  return (
    <>
      <InputControl
        {...props}
        errorMessageProps={{ height: "1rem", marginBottom: "1rem" }}
        marginBottom={
          !meta.touched || meta.error === undefined ? "1.5rem" : "0"
        }
      />
    </>
  );
};
