import { ApolloError } from "@apollo/client";
import { Alert } from "@chakra-ui/react";
import { Formik } from "formik";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApolloError as ApolloErrorView } from "src/components/Feedback/ApolloError";
import { GenericError } from "src/components/Feedback/GenericError";
import { OrganizationError as OrganizationErrorView } from "src/components/Feedback/OrganizationError";
import { School } from "src/components/Inputs/MultiSelectSchoolRank";
import { Student } from "src/components/Layout/Avatar";
import { FormLayout } from "src/components/Layout/FormLayout";
import { ParentRemoteDataLayout } from "src/components/Layout/Parent/ParentRemoteDataLayout";
import { useGlossary } from "src/hooks/useGlossary";
import {
  isOrganizationError,
  useOrganization,
} from "src/hooks/useOrganization";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import useRankedSchools from "src/hooks/useSchoolRank";
import { useWeglotToast } from "src/plugins/weglot";
import * as FormTemplate from "src/services/formTemplate";
import {
  isFreeTextAnswer,
  isMultiSelectAnswer,
  isSingleSelectAnswer,
} from "src/services/formTemplate/answer";
import { getCompleteApplicableQuestions } from "src/services/formTemplate/question";
import * as AFF from "src/services/formTemplateFilters";
import { validateFormTemplate } from "src/services/formTemplateValidations";
import * as Url from "src/services/url";
import * as AF from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import * as RD from "src/types/remoteData";
import { failure, success } from "src/types/remoteData";
import { FormQuestion } from "./components/Inputs/FormQuestion";
import { StudentHeader } from "./components/Layout/StudentHeader";
import {
  INSERT_FORM_ANSWER_OPTIONS,
  LATE_EDIT_SCHOOLS_RANK,
  UPSERT_FORM_ANSWER_FREE_TEXT,
} from "./graphql/mutations";
import { GET_FORM_BY_ID, GET_SCHOOLS_RANK } from "./graphql/queries";
import { LateEditRankSchools } from "./LateEditSchoolsRank";
import { LateEditFormButtons } from "./components/Layout/LateEditFormButtons";
import { usePreviousFormSchools } from "src/hooks/usePreviousFormSchools";

const TOAST_SUCCESS_ID = "late-edit-success";
const TOAST_ERROR_ID = "late-edit-error";
const TOAST_INFO_ID = "late-edit-info-success";

enum SeletecTab {
  RANK_SCHOOLS = "rank-schools",
  SCHOOL_QUESTIONS = "school-questions",
}

function handleError(error: Error | ApolloError): React.ReactElement {
  if (error instanceof ApolloError) {
    return <ApolloErrorView error={error} />;
  }

  if (isOrganizationError(error)) {
    return <OrganizationErrorView error={error} />;
  }

  return <Alert status="error">{error.message}</Alert>;
}

type Data = {
  form: GQL.FormFragment;
  schoolRankingSection: AF.SchoolRankingSection<AF.WithId>;
  generalQuestionsSection: AF.GeneralSection<AF.WithId>;
  sections: AF.Sections<AF.WithId>;
  enrollmentPeriodName: string;
  student: AFF.Types.Applicant & Student;
  previousFormSchoolIds: uuid[];
};

export type SchoolWithFakeRank = {
  id: uuid;
  name: string;
  street_address: string | null;
  rank: number;
};

export const LateEdit = () => {
  const { glossary } = useGlossary();
  const navigate = useNavigate();
  const toast = useWeglotToast();
  const organization = useOrganization();
  const { id: formId = "" } = useParams();

  const [updatedSchoolRanks, setUpdatedSchoolRanks] = React.useState<
    GQL.GetSchoolsRank_form_school_rank[]
  >([]);
  const [removedSchoolRanks, setRemovedSchoolRanks] = React.useState<
    GQL.GetSchoolsRank_form_school_rank[]
  >([]);
  const [newSchools, setNewSchools] = React.useState<SchoolWithFakeRank[]>([]);
  const [selectedTab, setSelectedTab] = React.useState<SeletecTab>(
    SeletecTab.RANK_SCHOOLS
  );

  const RankedSchools = useRankedSchools([]);

  const { remoteData: schoolRanksData } = useRemoteDataQuery<
    GQL.GetSchoolsRank,
    GQL.GetSchoolsRankVariables
  >(GET_SCHOOLS_RANK, {
    variables: {
      form_id: formId,
    },
    fetchPolicy: "no-cache",
  });

  const { remoteData: formData } = useRemoteDataQuery<
    GQL.GetFormById,
    GQL.GetFormByIdVariables
  >(GET_FORM_BY_ID, {
    variables: { form_id: formId },
    fetchPolicy: "no-cache",
  });

  const previousFormSchoolsRemoteData = usePreviousFormSchools(formId);

  const remoteData = React.useMemo(() => {
    return RD.toTuple3(formData, schoolRanksData, previousFormSchoolsRemoteData)
      .mapError<ApolloError | Error>((error) => error)
      .andThen(([data, schoolData, previousFormSchoolIds]) => {
        const form = data.form[0];
        if (!form?.form_template) {
          return failure<ApolloError | Error, Data>(
            new Error("Form not found")
          );
        }
        try {
          const formTemplate = FormTemplate.fromGQL(form.form_template);

          const enrollmentPeriodName =
            form.form_template.enrollment_period.name;

          const generalQuestionsSection: AF.GeneralSection<AF.WithId> =
            formTemplate.sections.find(
              (section) =>
                section?.type ===
                GQL.form_template_section_type_enum.GeneralSection
            ) as AF.GeneralSection<AF.WithId>;

          const schoolRankingSection: AF.SchoolRankingSection<AF.WithId> =
            formTemplate.sections.find(
              (section) =>
                section?.type ===
                GQL.form_template_section_type_enum.SchoolRankingSection
            ) as AF.SchoolRankingSection<AF.WithId>;

          setUpdatedSchoolRanks(schoolData.form_school_rank);

          RankedSchools.setRanks(
            schoolData.form_school_rank.map((item) => ({
              form_id: formId,
              schools_ranking_section_id: schoolRankingSection.id,
              school_id: item.school.id,
            }))
          );

          return success<ApolloError, Data>({
            previousFormSchoolIds,
            form,
            enrollmentPeriodName,
            schoolRankingSection,
            generalQuestionsSection,
            sections: formTemplate.sections,
            student: {
              first_name: form.person.first_name ?? "",
              last_name: form.person.last_name ?? "",
              avatar: form.person.avatar,
              birth_date: form.person.birth_date,
            },
          });
        } catch (error: unknown) {
          console.error(error);
          return failure<ApolloError | Error, Data>(error as Error);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formId, formData, schoolRanksData]);

  const [lateEditRankSchools] = useRemoteDataMutation<
    GQL.LateEditSchoolRanks,
    GQL.LateEditSchoolRanksVariables
  >(LATE_EDIT_SCHOOLS_RANK);

  const [upsertFreeText] = useRemoteDataMutation<
    GQL.UpsertFormAnswerFreeText,
    GQL.UpsertFormAnswerFreeTextVariables
  >(UPSERT_FORM_ANSWER_FREE_TEXT, { fetchPolicy: "no-cache" });

  const [insertAnswerOptions] = useRemoteDataMutation<
    GQL.InsertFormAnswerOptions,
    GQL.InsertFormAnswerOptionsVariables
  >(INSERT_FORM_ANSWER_OPTIONS, { fetchPolicy: "no-cache" });

  const handleDeletedSchoolRank = (
    schoolRank: GQL.GetSchoolsRank_form_school_rank
  ) => {
    if (schoolRank.offers[0]?.status === GQL.offer_status_enum.Accepted) return;
    setRemovedSchoolRanks([...removedSchoolRanks, schoolRank]);
    const newSchoolRanks = updatedSchoolRanks.filter(
      (s) => s.id !== schoolRank.id
    );

    newSchoolRanks.forEach((s, index) => {
      s.rank = index;
    });

    const newSchoolsUpdatedRanks = newSchools.map((s, index) => {
      s.rank = newSchoolRanks.length + index;
      return s;
    });

    setUpdatedSchoolRanks(newSchoolRanks);
    setNewSchools(newSchoolsUpdatedRanks);

    toast({
      id: TOAST_INFO_ID,
      status: "info",
      title: glossary`School removed from ranking list.`,
      isClosable: true,
    });
  };

  const handleLateSelectSchool = (school: School) => {
    setNewSchools([
      ...newSchools,
      { ...school, rank: updatedSchoolRanks.length + newSchools.length },
    ]);
  };

  const handleDeleteNewSchool = (school: School) => {
    const updatedNewSchools = newSchools.filter((s) => s.id !== school.id);
    updatedNewSchools.forEach((s, index) => {
      s.rank = updatedSchoolRanks.length + index;
    });
    setNewSchools(updatedNewSchools);

    if (!toast.isActive(TOAST_INFO_ID)) {
      toast({
        id: TOAST_INFO_ID,
        status: "info",
        title: glossary`School removed from ranking list.`,
        isClosable: true,
      });
    }
  };

  const handleUpdateSchoolRanks = async (
    answers?: FormTemplate.Answer.FormikValues,
    specificToNewSchoolsQuestions?: readonly AF.Question<AF.WithId>[]
  ) => {
    if (!remoteData.hasData()) return;

    try {
      if (newSchools.length) {
        if (answers) {
          Object.keys(answers).map(async (questionId) => {
            const question = specificToNewSchoolsQuestions?.find(
              (question) => question.id === questionId
            );

            if (question?.type === GQL.question_type_enum.SingleSelect) {
              if (!isSingleSelectAnswer(answers[questionId])) {
                console.error(
                  `Expecting a "string" for question ${question.id}, but got ${answers.questionId} instead`
                );
                return;
              }

              await insertAnswerOptions({
                variables: {
                  form_id: remoteData.data.form.id,
                  question_id: question.id,
                  options: answers[questionId]
                    ? [
                        {
                          form_question_option_id:
                            answers[questionId]?.toString(),
                        },
                      ]
                    : [],
                },
              });
            } else if (question?.type === GQL.question_type_enum.MultiSelect) {
              if (!isMultiSelectAnswer(answers[questionId])) {
                console.error(
                  `Expecting a "string[]" for question ${question.id}, but got ${answers.questionId} instead`
                );
                return;
              }

              const answersOptions = answers[questionId] as string[];

              await insertAnswerOptions({
                variables: {
                  form_id: remoteData.data.form.id,
                  question_id: question.id,
                  options: answersOptions.length
                    ? answersOptions.map((value) => ({
                        form_question_option_id: value,
                      }))
                    : [],
                },
              });
            } else if (question?.type === GQL.question_type_enum.FreeText) {
              if (!isFreeTextAnswer(answers[questionId])) {
                console.error(
                  `Expecting a "string" for question ${question.id}, but got ${answers.questionId} instead`
                );
                return;
              }
              await upsertFreeText({
                variables: {
                  form_id: remoteData.data.form.id,
                  question_id: question.id,
                  free_text_answer: answers[questionId]?.toString(),
                },
              });
            }
          });
        }
      }

      const schoolRanksData = updatedSchoolRanks.map((schoolRank) => ({
        form_id: remoteData.data.form.id,
        schools_ranking_section_id: remoteData.data.schoolRankingSection.id,
        school_id: schoolRank.school.id,
        rank: schoolRank.rank,
      }));

      const newSchoolsData = newSchools.map((school) => ({
        form_id: remoteData.data.form.id,
        schools_ranking_section_id: remoteData.data.schoolRankingSection.id,
        school_id: school.id,
        rank: school.rank,
      }));

      const upsertedSchoolRanks = RankedSchools.getUpsertedRanks(
        schoolRanksData.concat(newSchoolsData)
      );
      const deletedSchoolRanks =
        RankedSchools.getDeletedRanks(upsertedSchoolRanks);
      const deletedOffers = RankedSchools.getDeletedOffers(upsertedSchoolRanks);
      const deletedWaitlists =
        RankedSchools.getDeletedWaitlists(upsertedSchoolRanks);

      await lateEditRankSchools({
        variables: {
          deleted_school_ranks: deletedSchoolRanks,
          upserted_school_ranks: upsertedSchoolRanks,
          delete_offers_where: deletedOffers,
          delete_waitlists_where: deletedWaitlists,
        },
      });

      if (!toast.isActive(TOAST_SUCCESS_ID)) {
        toast({
          id: TOAST_ERROR_ID,
          status: "success",
          title: glossary`Schools updated`,
          isClosable: true,
        });
      }

      navigate(organization.map(Url.Parent.index).withDefault("#"));
    } catch (err) {
      if (!toast.isActive(TOAST_ERROR_ID)) {
        toast({
          id: TOAST_ERROR_ID,
          status: "error",
          title: glossary`Error while updating schools`,
          description:
            "Please try again later or report the problem to our support team.",
          isClosable: true,
        });
      }
    }
  };

  const verifyNextStep = (generalSection: AF.GeneralSection<AF.WithId>) => {
    if (!newSchools.length) {
      return handleUpdateSchoolRanks();
    }

    const validQuestions = FormTemplate.Question.getSpecificToSchoolsQuestions(
      generalSection.questions,
      newSchools.map((s) => s.id)
    );

    if (!validQuestions.length) {
      return handleUpdateSchoolRanks();
    }

    setSelectedTab(SeletecTab.SCHOOL_QUESTIONS);
  };

  const getInitialValues = (section: AF.GeneralSection<AF.WithId>) =>
    FormTemplate.Answer.getFormikInitialValues(
      section.questions,
      [],
      [],
      [],
      []
    );

  return (
    <ParentRemoteDataLayout error={handleError} remoteData={remoteData}>
      {({
        form,
        schoolRankingSection,
        generalQuestionsSection,
        sections,
        enrollmentPeriodName,
        student,
        previousFormSchoolIds,
      }) => {
        if (
          form.status !== GQL.form_status_enum.Verified &&
          form.status !== GQL.form_status_enum.Admissions
        )
          return <GenericError message="This form it's not locked." />;

        const specificToNewSchoolsQuestions =
          FormTemplate.Question.getSpecificToSchoolsQuestions(
            generalQuestionsSection.questions,
            newSchools.map((s) => s.id)
          );
        return (
          <Formik
            initialValues={getInitialValues(generalQuestionsSection)}
            onSubmit={() => {}}
            validate={validateFormTemplate(specificToNewSchoolsQuestions, {
              rankedSchoolIds: newSchools.map((s) => s.id),
              previousFormSchoolIds,
            })}
            validateOnMount={false} // need to disable this since it's causing weird validation behavior since we introduce refetchAnswer. See: https://app.asana.com/0/0/1203247877978830/1203832069318649/f
          >
            {(formikProps) => {
              const completeQuestions = getCompleteApplicableQuestions(
                specificToNewSchoolsQuestions,
                formikProps.values,
                {
                  rankedSchoolIds: newSchools.map((s) => s.id),
                  previousFormSchoolIds,
                }
              );

              return (
                <FormLayout
                  heading={glossary`Edit schools`}
                  buttons={
                    <LateEditFormButtons
                      overridePreviousButton={{
                        label:
                          selectedTab === SeletecTab.RANK_SCHOOLS
                            ? "Cancel"
                            : "Back",
                        action: () =>
                          selectedTab === SeletecTab.RANK_SCHOOLS
                            ? navigate(
                                organization
                                  .map(Url.Parent.index)
                                  .withDefault("#")
                              )
                            : setSelectedTab(SeletecTab.RANK_SCHOOLS),
                      }}
                      overrideNextButton={{
                        label:
                          selectedTab === SeletecTab.RANK_SCHOOLS
                            ? "Next"
                            : glossary`Update schools`,
                        action: () =>
                          selectedTab === SeletecTab.RANK_SCHOOLS
                            ? verifyNextStep(generalQuestionsSection)
                            : formikProps.isValid &&
                              handleUpdateSchoolRanks(
                                formikProps.values,
                                completeQuestions
                              ),
                        hide: !newSchools.length && !removedSchoolRanks.length,
                        disabled:
                          updatedSchoolRanks.length + newSchools.length === 0 ||
                          (schoolRankingSection.minSchools
                            ? updatedSchoolRanks.length + newSchools.length <
                              schoolRankingSection?.minSchools
                            : false),
                      }}
                    />
                  }
                  backLink={{
                    url: organization.map(Url.Parent.index).withDefault("#"),
                    label: "Back to forms",
                  }}
                >
                  <StudentHeader
                    student={student}
                    enrollmentPeriodName={enrollmentPeriodName}
                  />
                  {selectedTab === SeletecTab.RANK_SCHOOLS ? (
                    <LateEditRankSchools
                      formId={formId}
                      section={schoolRankingSection}
                      allSections={sections}
                      newSchools={newSchools}
                      schoolRanks={updatedSchoolRanks}
                      onLateDeleteSchoolRank={handleDeletedSchoolRank}
                      onLateSelectSchool={handleLateSelectSchool}
                      onLateDeleteNewSchool={handleDeleteNewSchool}
                    />
                  ) : (
                    completeQuestions.map((question) => (
                      <FormQuestion
                        formId={form.id}
                        applicant={student}
                        key={question.id}
                        question={question}
                        marginBottom={4}
                      />
                    ))
                  )}
                </FormLayout>
              );
            }}
          </Formik>
        );
      }}
    </ParentRemoteDataLayout>
  );
};
