import _ from "lodash";
import {
  FilterTypes,
  FormTabsTypes,
  SearchAndFilterTypes,
} from "src/constants";
import * as GQL from "src/types/graphql";

const defaultFilters = [
  FilterTypes.School,
  FilterTypes.Tags,
  FilterTypes.DynamicQuestions,
  FilterTypes.PreferredLanguage,
  FilterTypes.Visibility,
  FilterTypes.AttendingSchool,
];

export const FiltersByTab: Record<string, string[]> = {
  [FormTabsTypes.Submissions]: [
    ...defaultFilters,
    FilterTypes.Status,
    FilterTypes.Rank,
    FilterTypes.VerificationStatus,
    FilterTypes.Badges,
  ],
  [FormTabsTypes.Waitlists]: [
    ...defaultFilters,
    FilterTypes.SubStatus,
    FilterTypes.WithinCapacity,
  ],
  [FormTabsTypes.Offers]: [...defaultFilters, FilterTypes.SubStatus],
  [FormTabsTypes.All]: [
    ...defaultFilters,
    FilterTypes.Status,
    FilterTypes.Rank,
    FilterTypes.VerificationStatus,
  ],
  [FormTabsTypes.FormListImports]: [
    ...defaultFilters,
    FilterTypes.Status,
    FilterTypes.Rank,
    FilterTypes.VerificationStatus,
  ],
  [FormTabsTypes.DropoffForms]: [
    FilterTypes.School,
    FilterTypes.Tags,
    FilterTypes.Status,
    FilterTypes.SubStatus,
    FilterTypes.DynamicQuestions,
    FilterTypes.Visibility,
  ],
};

export const verifyFilterInclusion = (
  searchParams: URLSearchParams,
  filter: FilterTypes
) =>
  FiltersByTab[
    searchParams.get(SearchAndFilterTypes.Tab) ?? FormTabsTypes.Submissions
  ]?.includes(filter);

export const applyFilterByCapacity = (
  forms: GQL.GetFormsByFormTemplate_form_by_form_school_rank[],
  capacities: GQL.GetMetadataByFormTemplate_form_template_by_pk_enrollment_period_grades[]
): GQL.GetFormsByFormTemplate_form_by_form_school_rank[] => {
  if (!capacities.length) {
    return forms;
  }
  const filteredAppIds = capacities.flatMap(
    ({ school_id, grade_config_id, seats_available }) => {
      const appsInSchoolGrade = forms.filter((app) => {
        const appSchoolId = app.form_school_rank?.school.id;
        const appGradeConfigId =
          app.form_school_rank?.waitlists[0]?.grade.grade_config_id;
        return (
          appSchoolId === school_id && appGradeConfigId === grade_config_id
        );
      });
      return _.sortBy(appsInSchoolGrade, (app) => app.waitlist_position ?? 0)
        .slice(0, seats_available && seats_available > 0 ? seats_available : 0)
        .map((app) => app.form_school_rank?.id);
    }
  );
  return forms.filter((app) =>
    filteredAppIds.includes(app.form_school_rank?.id)
  );
};
