import { gql } from "@apollo/client";

export const GET_FORM_ADDRESS_ANSWERS_FOR_FILTER = gql`
  query GetFormAddressAnswersForFilter($form_id: uuid!) {
    form_address(
      where: {
        form_id: { _eq: $form_id }
        form_question: {
          filters: {
            _contains: [
              { type: "LocationBoundariesFilter", config: { rule: "Filter" } }
            ]
          }
        }
      }
    ) {
      id
      street_address
      street_address_line_2
      city
      state
      zip_code
    }
  }
`;

export const GET_GRADE_CONFIG_ANSWER = gql`
  query GetGradeConfigAnswer($form_id: uuid!) {
    grades_answer(where: { form_id: { _eq: $form_id } }) {
      grade_config_id
    }
  }
`;
