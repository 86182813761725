import { Box, Flex, Tag, useDisclosure } from "@chakra-ui/react";
import { FormSchoolRankSubStatusIndex } from "src/constants";
import { useOrganization } from "src/hooks/useOrganization";
import { WEGLOT_SKIP_CLASS } from "src/plugins/weglot/constants";
import * as GQL from "src/types/graphql";
import { shouldShowApplicationSchoolRankOptionsMenu } from "../helpers";
import { OptionsMenu } from "./OptionsMenu";
import { SubStatusTag } from "./SubStatusTag";
import _ from "lodash";
import { useMemo } from "react";
import { TaggingInfoDialog } from "src/components/Dialogs/TaggingInfoDialog";

interface FormSchoolRankDetailProps {
  formTemplateId: uuid;
  acceptedOfferSchoolName: string | undefined;
  form: Pick<GQL.FormSummaryFragment, "id" | "status">;

  offer: Pick<
    GQL.FormSummaryFragment_form_school_ranks_offers,
    "id" | "status"
  > | null;

  formSchoolRank: Pick<
    GQL.FormSummaryFragment_form_school_ranks,
    "sub_status" | "tags"
  >;

  waitlist: Pick<
    GQL.FormSummaryFragment_form_school_ranks_waitlists,
    "waitlist_position" | "status"
  > | null;

  gradeLabel: string;
  school: Pick<GQL.SchoolFragment, "id" | "name">;
  studentName: string;
}

export function FormSchoolRankDetail(props: FormSchoolRankDetailProps) {
  const {
    formTemplateId,
    acceptedOfferSchoolName,
    form,
    offer,
    formSchoolRank,
    waitlist,
    gradeLabel,
    school,
    studentName,
  } = props;

  const organization = useOrganization();

  const { onOpen, onClose, isOpen } = useDisclosure();

  const showSubstatus =
    (form.status === GQL.form_status_enum.Submitted ||
      form.status === GQL.form_status_enum.Verified ||
      form.status === GQL.form_status_enum.LotteryReady ||
      form.status === GQL.form_status_enum.Admissions) &&
    !!formSchoolRank.sub_status;

  const showOptionsMenu =
    organization.hasData() &&
    shouldShowApplicationSchoolRankOptionsMenu(
      offer,
      waitlist,
      organization.data
    );

  const { tags, firstTag } = useMemo(
    () => ({
      tags: _.orderBy(formSchoolRank.tags, (tag) =>
        tag.enrollment_period_tag.name.toLocaleLowerCase()
      ),
      firstTag: _.orderBy(formSchoolRank.tags, (tag) =>
        tag.enrollment_period_tag.name.toLocaleLowerCase()
      )[0],
    }),
    [formSchoolRank.tags]
  );

  const formSchoolRankTagging = useMemo(() => {
    if (showSubstatus && tags.length > 0) {
      return (
        <Flex>
          <Tag
            key="form-school-tag"
            variant="tag"
            fontSize="xs"
            cursor="pointer"
          >
            <Box>+{tags.length} more</Box>
          </Tag>
        </Flex>
      );
    }

    if (!showSubstatus && tags.length > 1) {
      return (
        <Flex>
          <Tag
            key="form-school-tag"
            variant="tag"
            fontSize="xs"
            cursor="pointer"
          >
            <Box>+{tags.length - 1} more</Box>
          </Tag>
        </Flex>
      );
    }
  }, [showSubstatus, tags]);

  return (
    <Flex alignItems="center" gap={2} flexGrow={1}>
      <Flex direction="column" onClick={onOpen} cursor="pointer">
        <Flex alignItems="start" gap="2">
          <div className={WEGLOT_SKIP_CLASS}>{school.name}</div>
          {showSubstatus ? (
            <SubStatusTag
              formSchoolRankSubStatus={
                formSchoolRank.sub_status as FormSchoolRankSubStatusIndex
              }
              waitlist={waitlist}
              hasOffer={!!offer}
            />
          ) : firstTag ? (
            <Tag
              key="main-form-school-tag"
              variant="tag"
              fontSize={{ base: "xxs", sm: "xs" }}
            >
              <Box whiteSpace="nowrap">
                {firstTag.enrollment_period_tag.name}
              </Box>
            </Tag>
          ) : null}
        </Flex>

        {formSchoolRankTagging}
      </Flex>

      {showOptionsMenu && (
        <OptionsMenu
          formTemplateId={formTemplateId}
          acceptedOfferSchoolName={acceptedOfferSchoolName}
          formId={form.id}
          offer={offer}
          waitlist={waitlist}
          gradeLabel={gradeLabel}
          school={school}
          studentName={studentName}
        />
      )}

      <TaggingInfoDialog
        isOpen={isOpen}
        offer={offer}
        waitlist={waitlist}
        onCancel={onClose}
        schoolName={school.name}
        tags={tags}
        subStatus={formSchoolRank.sub_status as FormSchoolRankSubStatusIndex}
      />
    </Flex>
  );
}
