import * as _ from "lodash";
import { useState } from "react";
import * as GQL from "src/types/graphql";

export type RankedSchool = {
  form_id: string;
  schools_ranking_section_id: string;
  school_id: string;
  rank?: number;
};

type UseSchoolRank = {
  setRanks: (initalRanks: RankedSchool[]) => void;
  getDeletedRanks: (
    newRanks: GQL.form_school_rank_insert_input[]
  ) => GQL.form_school_rank_bool_exp;
  getDeletedOffers: (
    newRanks: GQL.form_school_rank_insert_input[]
  ) => GQL.offer_bool_exp;
  getDeletedWaitlists: (
    newRanks: GQL.form_school_rank_insert_input[]
  ) => GQL.waitlist_bool_exp;
  getUpsertedRanks: (
    newRanks: RankedSchool[]
  ) => GQL.form_school_rank_insert_input[];
};

export default function useRankedSchools(
  initialRankedSchools: RankedSchool[]
): UseSchoolRank {
  const [ranks, setRanks] = useState<RankedSchool[]>(initialRankedSchools);

  const getDeletedRanks = (
    newRanks: GQL.form_school_rank_insert_input[]
  ): GQL.form_school_rank_bool_exp => {
    return {
      _or: ranks
        .filter(
          (rank) =>
            !_.find(newRanks, (item) => areEquals(rank, item as RankedSchool))
        )
        .map((rank) => ({
          form_id: { _eq: rank.form_id },
          schools_ranking_section_id: { _eq: rank.schools_ranking_section_id },
          school_id: { _eq: rank.school_id },
        })),
    };
  };

  const getDeletedOffers = (
    newRanks: GQL.form_school_rank_insert_input[]
  ): GQL.offer_bool_exp => {
    return {
      _or: ranks
        .filter(
          (rank) =>
            !_.find(newRanks, (item) => areEquals(rank, item as RankedSchool))
        )
        .map((rank) => ({
          deleted_at: { _is_null: true },
          form_id: { _eq: rank.form_id },
          school_id: { _eq: rank.school_id },
        })),
    };
  };

  const getDeletedWaitlists = (
    newRanks: GQL.form_school_rank_insert_input[]
  ): GQL.waitlist_bool_exp => {
    return {
      _or: ranks
        .filter(
          (rank) =>
            !_.find(newRanks, (item) => areEquals(rank, item as RankedSchool))
        )
        .map((rank) => ({
          deleted_at: { _is_null: true },
          form_id: { _eq: rank.form_id },
          school_id: { _eq: rank.school_id },
        })),
    };
  };

  const getUpsertedRanks = (
    newRanks: RankedSchool[]
  ): GQL.form_school_rank_insert_input[] => {
    return newRanks.map((rank, index) => ({
      form_id: rank.form_id,
      schools_ranking_section_id: rank.schools_ranking_section_id,
      school_id: rank.school_id,
      rank: index,
    }));
  };

  return {
    getDeletedRanks,
    getDeletedOffers,
    getDeletedWaitlists,
    getUpsertedRanks,
    setRanks,
  };
}

function areEquals(rank1: RankedSchool, rank2: RankedSchool) {
  return (
    rank1.form_id === rank2.form_id &&
    rank1.schools_ranking_section_id === rank2.schools_ranking_section_id &&
    rank1.school_id === rank2.school_id
  );
}
