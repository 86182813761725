import { Grid, ListItem } from "@chakra-ui/react";
import * as GQL from "src/types/graphql";
import { FormSchoolRankDetail } from "./FormSchoolRankDetail";

interface FormSchoolRankProps {
  formTemplateId: uuid;
  acceptedOfferSchoolName: string | undefined;
  form: Pick<GQL.FormSummaryFragment, "id" | "status">;

  offer: Pick<
    GQL.FormSummaryFragment_form_school_ranks_offers,
    "id" | "status"
  > | null;

  formSchoolRank: Pick<
    GQL.FormSummaryFragment_form_school_ranks,
    "rank" | "sub_status" | "tags"
  >;

  waitlist: Pick<
    GQL.FormSummaryFragment_form_school_ranks_waitlists,
    "waitlist_position" | "status"
  > | null;

  gradeLabel: string;
  school: Pick<GQL.SchoolFragment, "id" | "name">;
  studentName: string;
}

export function FormSchoolRank(props: FormSchoolRankProps) {
  const { formSchoolRank } = props;

  return (
    <ListItem alignItems="center" display="flex" gap={2}>
      <Grid
        alignSelf="stretch"
        borderInlineEndColor="gray.400"
        borderInlineEndStyle="solid"
        borderInlineEndWidth="1px"
        flexBasis={6}
        flexGrow={0}
        flexShrink={0}
        placeContent="center"
      >
        {formSchoolRank.rank + 1}
      </Grid>

      <FormSchoolRankDetail {...props} />
    </ListItem>
  );
}
