import { useLoadScript } from "@react-google-maps/api";
import * as Turf from "@turf/helpers";
import { useEffect, useMemo, useState } from "react";
import { GET_BOUNDARIES_BY_ENROLLMENT_PERIOD } from "src/components/Boundary/queries";
import * as Boundary from "src/components/Boundary/services";
import { useFormAddresForFilter } from "src/components/Form/useFormAddressForFilter";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import * as GQL from "src/types/graphql";
import {
  failure,
  loading,
  notAsked,
  RemoteData,
  success,
} from "src/types/remoteData";

const googleApiLibraries: Array<"places"> = ["places"];

export function useFormAddressPosition({ formId }: { formId: uuid }) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY || "",
    libraries: googleApiLibraries,
  });
  const [address, setAddress] = useState<
    RemoteData<Error, Turf.Position | undefined>
  >(notAsked());

  const remoteData = useFormAddresForFilter({ formId });

  useEffect(() => {
    if (remoteData.hasData() && isLoaded) {
      setAddress(loading());
      Boundary.addressLookup(new google.maps.Geocoder(), remoteData.data)
        .then((location) => {
          setAddress(success(location));
        })
        .catch((e) => setAddress(failure(e)));
    }
  }, [isLoaded, remoteData]);

  return address;
}

export function useBoundariesMap({ formId }: { formId: uuid }) {
  const { remoteData } = useRemoteDataQuery<
    GQL.GetBoundariesByEnrollmentPeriod,
    GQL.GetBoundariesByEnrollmentPeriodVariables
  >(GET_BOUNDARIES_BY_ENROLLMENT_PERIOD, {
    variables: {
      form_id: formId,
    },
  });

  const boundariesMapRemoteData = useMemo(() => {
    return remoteData.map((data) => {
      const boundariesMap = Boundary.fromGQL(data);
      return boundariesMap;
    });
  }, [remoteData]);

  return boundariesMapRemoteData;
}
