import { Button, Text } from "@chakra-ui/react";
import { useCallback, useMemo } from "react";
import { useConfirmationDialog } from "src/hooks/useConfirmationDialog";
import { useNavigate, useParams } from "react-router-dom";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { useOrganization } from "src/hooks/useOrganization";
import { maybePluralize } from "src/services/format";
import { SUBMIT_FORM } from "../../graphql/mutations";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { useWeglotToast } from "src/plugins/weglot";
import { useFormikContext } from "formik";
import { Step } from "src/components/Layout/FormStepLayout";

type SubmitFormButtonProps = {
  steps: Step[];
  isLoading?: boolean;
};

export const SubmitFormButton = ({
  steps,
  isLoading,
}: SubmitFormButtonProps) => {
  const navigate = useNavigate();
  const organization = useOrganization();
  const toast = useWeglotToast();
  const { formId = "", step = "" } = useParams();
  const formik = useFormikContext();

  const [submitForm, submitStatus] = useRemoteDataMutation<
    GQL.SubmitForm,
    GQL.SubmitFormVariables
  >(SUBMIT_FORM);

  const handleSubmit = useCallback(async () => {
    try {
      await submitForm({
        variables: {
          form_id: formId,
        },
      });

      organization.do((org) => {
        navigate(Url.Parent.index(org));
      });

      toast({
        title: "Hooray!",
        description: "Form successfully submitted.",
        status: "success",
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error submitting form",
        description:
          "Please try again later or report the problem to our support team.",
        status: "error",
        isClosable: true,
      });
    }
  }, [formId, navigate, organization, submitForm, toast]);

  const stepsWithMissingRequiredFields = useMemo(
    () =>
      steps
        .map((stepObj, index) => ({
          missingRequiredQuestions: stepObj.initialMissingRequiredQuestions,
          index: index + 1,
        }))
        .filter((stepObj) => {
          /* 
            If is the current step, we need to verify if the user is changing answers 
            and trying to submit the form wihout required fields.
            Case 1: User is in the current step and has not filled all required fields, it will keep the step in the error list and populate the error fields list.
            Case 2: User is in the current step and has filled all required fields, it will remove the step from the error list.
          */
          if (step === stepObj.index.toString()) {
            // If formik is not defined, it means that the user is on the school ranks step, which doesn't apply the logic.
            if (!formik) return false;

            // If the form was not updated and has missing required questions, it will trust the initial missing fields list.
            if (!formik.dirty && stepObj.missingRequiredQuestions?.length) {
              return true;
            }

            if (formik.isValid) {
              return false;
            }

            stepObj.missingRequiredQuestions = Object.keys(formik.errors);
            return true;
          }

          /*
            If it's not the current step, we need to verify if the user has not filled all required fields.
            Case 1: User has not filled all required fields, it will keep the step in the error list.
            Case 2: User has filled all required fields, it will remove the step from the error list.
          */
          return stepObj.missingRequiredQuestions?.length;
        }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [steps, formik?.isValid, formik?.errors, step]
  );

  const missingRequiredFieldsCount = useMemo(() => {
    return stepsWithMissingRequiredFields.flatMap(
      (step) => step.missingRequiredQuestions
    ).length;
  }, [stepsWithMissingRequiredFields]);

  const handleNavigateToNextRequiredField = useCallback(() => {
    if (stepsWithMissingRequiredFields[0]) {
      organization.do((org) =>
        navigate(
          Url.Parent.Form.edit(
            org,
            formId,
            stepsWithMissingRequiredFields[0]?.index
          )
        )
      );
    }
  }, [formId, navigate, organization, stepsWithMissingRequiredFields]);

  const {
    confirm,
    confirmationDialog: nextRequiredQuestionDialog,
    setBody,
  } = useConfirmationDialog({
    header: "Missing required answers",
    body: <></>,
    cancelButton: {
      label: "Cancel",
      hidden: true,
    },
    confirmButton: {
      label: "Go to next required field",
    },
    translate: true,
  });

  const handleSubmitClick = useCallback(async () => {
    if (missingRequiredFieldsCount > 0) {
      setBody(
        <Text>
          There are{" "}
          <Text
            as={"span"}
            fontWeight={700}
          >{`${missingRequiredFieldsCount} more required ${maybePluralize(
            missingRequiredFieldsCount,
            "field",
            "fields"
          )}`}</Text>{" "}
          that need to be filled in before this form can be submitted.
        </Text>
      );
      if (!(await confirm())) {
        return;
      }

      handleNavigateToNextRequiredField();
    } else {
      handleSubmit();
    }
  }, [
    confirm,
    handleNavigateToNextRequiredField,
    missingRequiredFieldsCount,
    setBody,
    handleSubmit,
  ]);

  return (
    <>
      <Button
        onClick={handleSubmitClick}
        isLoading={submitStatus.remoteData.isLoading()}
        isDisabled={isLoading}
      >
        Submit
      </Button>

      {nextRequiredQuestionDialog}
    </>
  );
};
