import { Button, Flex } from "@chakra-ui/react";
import _ from "lodash";
import React, { useMemo } from "react";
import {
  MultiSelectSchoolRank,
  normalizeRankedSchools,
  School,
} from "src/components/Inputs/MultiSelectSchoolRank";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import useRankedSchools from "src/hooks/useSchoolRank";
import { GET_SCHOOLS_RANK_VIEW } from "src/scenes/orgAdmin/forms/graphql/queries";
import * as AFF from "src/services/formTemplateFilters";
import * as AF from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import { SchoolRankWithTags } from "src/types/schoolRank";
import { SAVE_FORM_SCHOOLS_RANK_ADMIN } from "./graphql/mutations";

type Props = {
  formId: uuid;
  applicant: AFF.Types.Applicant;
  allSections: AF.Sections<AF.WithId>;
  schoolRankingSection: AF.SchoolRankingSection<AF.WithId>;
  schoolsRank: SchoolRankWithTags[];
  onCloseEdit: () => void;
};
export const SchoolRankingEditForm: React.FC<Props> = ({
  formId,
  applicant,
  allSections,
  schoolRankingSection,
  schoolsRank: initialSchoolsRank,
  onCloseEdit,
}) => {
  const [inMemorySchools, setInMemorySchools] = React.useState<
    readonly School[]
  >(normalizeRankedSchools(initialSchoolsRank));

  const [saveSchoolsRank, saveSchoolsRankStatus] = useRemoteDataMutation<
    GQL.SaveFormSchoolsRankAdmin,
    GQL.SaveFormSchoolsRankAdminVariables
  >(SAVE_FORM_SCHOOLS_RANK_ADMIN);

  const {
    getDeletedRanks,
    getDeletedOffers,
    getDeletedWaitlists,
    getUpsertedRanks,
  } = useRankedSchools(
    initialSchoolsRank.map((item) => ({
      form_id: formId,
      schools_ranking_section_id: schoolRankingSection.id,
      school_id: item.school.id,
      rank: item.rank,
    }))
  );

  const onSave = async () => {
    if (
      !_.isEqual(
        inMemorySchools.map((school) => school.id),
        normalizeRankedSchools(initialSchoolsRank).map((school) => school.id)
      )
    ) {
      const upsertedSchoolRanks = getUpsertedRanks(
        inMemorySchools.map((school) => ({
          form_id: formId,
          schools_ranking_section_id: schoolRankingSection.id,
          school_id: school.id,
        }))
      );
      const deletedSchoolRanks = getDeletedRanks(upsertedSchoolRanks);
      const deletedOffers = getDeletedOffers(upsertedSchoolRanks);
      const deletedWaitlists = getDeletedWaitlists(upsertedSchoolRanks);

      await saveSchoolsRank({
        variables: {
          deleted_school_ranks: deletedSchoolRanks,
          delete_offers_where: deletedOffers,
          delete_waitlists_where: deletedWaitlists,
          upserted_school_ranks: upsertedSchoolRanks,
        },
        refetchQueries: [GET_SCHOOLS_RANK_VIEW],
        awaitRefetchQueries: true,
      });
    }
    onCloseEdit();
  };

  const preRankingSection = useMemo(
    () =>
      allSections.find(
        (section): section is AF.PreRankingSection<AF.WithId> =>
          section?.type === "PreRankingSection"
      ) as AF.PreRankingSection<AF.WithId> | undefined,
    [allSections]
  );

  if (!preRankingSection) return null;

  return (
    <Flex direction="column" gap={3}>
      <Flex maxWidth="28rem">
        <MultiSelectSchoolRank
          formId={formId}
          applicant={applicant}
          selectedSchools={inMemorySchools}
          preRankingSection={preRankingSection}
          schoolRankingSection={schoolRankingSection}
          onSort={async (schools: readonly School[]) => {
            setInMemorySchools(schools);
          }}
          onDelete={async (
            schools: readonly School[],
            deletedSchool: School
          ) => {
            setInMemorySchools(
              schools.filter((school) => school.id !== deletedSchool.id)
            );
          }}
          onSelectedItemsChanged={async (schools: readonly School[]) => {
            setInMemorySchools(schools);
          }}
        />
      </Flex>
      <Flex justifyContent="flex-end" gap={3}>
        <Button
          type="button"
          variant="ghost"
          colorScheme="gray"
          onClick={onCloseEdit}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          onClick={onSave}
          isLoading={saveSchoolsRankStatus.remoteData.isLoading()}
        >
          Save
        </Button>
      </Flex>
    </Flex>
  );
};
