import { Button, ButtonProps, useDisclosure } from "@chakra-ui/react";
import React, { useCallback } from "react";
import { RiEditLine } from "react-icons/ri";
import {
  SectionFormDialog,
  SectionFormType,
} from "src/components/Form/SectionForm/SectionFormDialog";
import { useEditFormTemplateContext } from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/EditFormTemplateTabs/Content";
import * as Draft from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/types/draft";

type AddSectionButtonProps = ButtonProps & {
  section: Draft.Section | Draft.NewSection;
};
export const EditSectionButton: React.FC<AddSectionButtonProps> = ({
  section,
}) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { dispatch, formTemplateId } = useEditFormTemplateContext();

  const handleOnUpdate = useCallback(
    (values: SectionFormType) => {
      if (!dispatch) {
        return;
      }

      const updatedSection = {
        ...section,
        title: values.title,
        description: values.description,
        key: values.key,
        permissionLevel: values.permissionLevel,
      };
      if (updatedSection.isNew) {
        dispatch({
          type: "UpdateNewSection",
          newSection: { ...updatedSection, isNew: true },
        });
      }
      dispatch({ type: "UpdateSection", section: updatedSection });
      if (formTemplateId) {
        dispatch({ type: "Save", formTemplateId });
      }

      onClose();
    },
    [dispatch, formTemplateId, section, onClose]
  );

  return (
    <>
      <Button
        variant="outline"
        colorScheme="gray"
        leftIcon={<RiEditLine />}
        alignSelf="flex-end"
        onClick={onOpen}
      >
        Edit section
      </Button>

      <SectionFormDialog
        isOpen={isOpen}
        section={section}
        onCreate={handleOnUpdate}
        onCancelButtonClick={onClose}
      />
    </>
  );
};
