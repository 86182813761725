import { Button, Flex, Heading } from "@chakra-ui/react";
import { GQLRemoteDataView } from "src/components/Layout/RemoteDataView";
import { WithUserPermissions } from "src/components/Permissions/WithUserPermissions";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import { useSchoolAdminPermissions } from "src/hooks/useSchoolAdminPermissions";
import { useSendMessage } from "src/hooks/useSendMessage";
import { getGuardians, isEligibleForMessaging } from "src/services/person";
import * as GQL from "src/types/graphql";
import { GET_FORM_MESSAGES } from "../../graphql/queries";
import { FormMessageCard } from "./FormMessageCard";
import { NoMessages } from "./NoMessages";
import { toFormMessageList } from "./helpers";

interface Props {
  form: GQL.GetFormViewById_form_by_pk;
}

export const FormMessages: React.FC<Props> = ({ form }) => {
  const { handleSendMessage } = useSendMessage(form.form_template.id);
  const formParents = getGuardians(form.person);
  const parentIds = formParents.map((parent) => parent.id);
  const isEligibleForMessage = formParents.some(isEligibleForMessaging);
  const { messageAdhocReceiverFilter } = useSchoolAdminPermissions();

  const { remoteData } = useRemoteDataQuery<
    GQL.GetFormMessages,
    GQL.GetFormMessagesVariables
  >(GET_FORM_MESSAGES, {
    variables: {
      form_id: form.id,
      recipient_person_ids: parentIds,
      message_receiver_filter: messageAdhocReceiverFilter(),
    },
    skip: !form.id,
    fetchPolicy: "no-cache",
  });

  return (
    <GQLRemoteDataView remoteData={remoteData}>
      {(data) => {
        const formattedFormMessages = toFormMessageList(data);

        return formattedFormMessages.length ? (
          <Flex direction="column" gap={4}>
            <Flex justifyContent="space-between" alignItems="center" my={2}>
              <Heading as="h2" fontSize="sm" color="gray.600">
                All messages (latest to oldest)
              </Heading>

              {isEligibleForMessage && (
                <WithUserPermissions permissions={["message:create"]}>
                  <Button
                    onClick={() =>
                      handleSendMessage(parentIds, [form.person.id], [form.id])
                    }
                    size="xs"
                  >
                    Send new message
                  </Button>
                </WithUserPermissions>
              )}
            </Flex>
            {formattedFormMessages.map((formMessage, index) => (
              <FormMessageCard key={index} formMessage={formMessage} />
            ))}
          </Flex>
        ) : (
          <NoMessages form={form} />
        );
      }}
    </GQLRemoteDataView>
  );
};
