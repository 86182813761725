import { gql } from "@apollo/client";

export const FETCH_ANNOUNCEMENTS = gql`
  query FetchAnnouncements(
    $organization_id: uuid!
    $entry_point: announcement_entry_point_enum!
    $condition: announcement_condition_enum!
  ) {
    announcement(
      where: {
        organization_id: { _eq: $organization_id }
        entry_point: { _eq: $entry_point }
        condition: { _eq: $condition }
        active: { _eq: true }
      }
    ) {
      id
      organization_id
      type
      entry_point
      condition
      title
      description
    }
  }
`;
