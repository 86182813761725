import { Box, Button, Flex, InputGroup, StyleProps } from "@chakra-ui/react";
import MonacoEditor, {
  Monaco,
  OnMount,
  OnValidate,
} from "@monaco-editor/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { RiDownloadLine, RiUploadLine } from "react-icons/ri";
import { AdminHeading } from "src/components/Text/AdminHeading";
import { triggerDownload } from "src/services/dataTransfer";

export type JsonEditorProps = StyleProps & {
  title?: string;
  schema: object;
  text: string;
  onChange: (text: string) => void;
  onChangeValidity?: (isValid: boolean) => void;
  filename: string;
};

export function JsonEditor({
  title,
  schema,
  text,
  onChange,
  onChangeValidity,
  filename,
  ...containerProps
}: JsonEditorProps) {
  const [monaco, setMonaco] = useState<Monaco>();
  const onEditorMount: OnMount = useCallback(
    (editor, monaco) => setMonaco(monaco),
    []
  );
  useEffect(() => {
    if (!monaco) return;
    // This is actually a global setting, and may create issues if we have
    // multiple instances of this editor.  So far it doesn't seem to be an issue
    // though.
    monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
      schemas: [
        {
          uri: "schema.json", // I think this can be anything.
          fileMatch: ["*"],
          schema,
        },
      ],
    });
  }, [monaco, schema]);

  const [isValid, setIsValid] = useState(true);
  const onValidate: OnValidate = (markers) => {
    const noErrors = markers.length === 0;
    setIsValid(noErrors);
    if (onChangeValidity) onChangeValidity(noErrors);
  };

  const fileRef = useRef<HTMLInputElement>(null);
  const loadFileHandler = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.currentTarget?.files
      ? event.currentTarget.files[0]
      : undefined;
    if (file === undefined) return;

    const text = await file.text();
    onChange(text);

    // Clear selected file so that it can be reloaded if changes have been made.
    if (fileRef.current) {
      fileRef.current.value = "";
    }
  };
  const clearHandler = () => {
    onChange("");
    if (fileRef.current) {
      fileRef.current.value = "";
    }
  };

  return (
    <Flex direction="column" gap={6} {...containerProps}>
      <Flex alignItems="center" justifyContent="space-between">
        {title && <AdminHeading title={title} />}
        <Flex gap={2}>
          <Button
            type="button"
            variant="outline"
            colorScheme="gray"
            onClick={clearHandler}
          >
            Clear
          </Button>

          <Button
            leftIcon={<RiDownloadLine />}
            variant="outline"
            onClick={() =>
              triggerDownload(new Blob([text], { type: "text/json" }), filename)
            }
          >
            Export JSON
          </Button>
          <InputGroup flexBasis="fit-content">
            <input
              type="file"
              accept="*.json"
              ref={fileRef}
              style={{ display: "none" }}
              onChange={loadFileHandler}
            ></input>
            <Button
              leftIcon={<RiUploadLine />}
              onClick={() => fileRef.current?.click()}
            >
              Import JSON
            </Button>
          </InputGroup>
        </Flex>
      </Flex>
      <Box
        height="100%"
        minHeight="20rem"
        borderWidth="1px"
        borderStyle="solid"
        borderColor={isValid ? "gray.200" : "red"}
      >
        <MonacoEditor
          path={filename}
          language="json"
          value={text}
          onChange={(text) => onChange(text ?? "")}
          onValidate={onValidate}
          onMount={onEditorMount}
          options={{ scrollBeyondLastLine: false }}
        />
      </Box>
    </Flex>
  );
}

/**
 * Create a copy of the provided JSON schema that restricts the values of the
 * specified types to the provided values.
 */
export function injectSchemaEnums<
  S extends { definitions: Record<string, any> }
>(
  schema: S,
  enums: Partial<Record<keyof S["definitions"], (string | number | null)[]>>
): S {
  const {
    definitions: { ...definitions }, // Make a copy of definitions to edit.
    ...refs
  } = schema;
  for (const key in enums) {
    const values = enums[key];
    definitions[key] = {
      ...definitions[key],
      enum: values?.length ? values : definitions[key].enum,
    };
  }
  return { definitions, ...refs } as S;
}
