import { ApolloError } from "@apollo/client";
import React, { useContext, useMemo } from "react";
import { NotFound } from "src/components/Feedback/NotFound";
import {
  getMissingRequiredQuestions,
  isSectionApplicable,
} from "src/services/formTemplate/section";
import * as AFF from "src/services/formTemplateFilters";
import * as AF from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import { RemoteData } from "src/types/remoteData";
import { StepDisclaimerSection } from "./StepDisclaimerSection";
import { StepGeneralSection } from "./StepGeneralSection";
import { StepPreRanking } from "./StepPreRanking";
import { StepRankSchools } from "./StepRankSchools";
import { Step as StepType } from "src/components/Layout/FormStepLayout";
import { EditFormContext } from "./state/provider";
import { setCurrentQuestionId } from "./state/actions";

type Props = {
  answers: GQL.GetFormAnswersById;
  currentStep: number;
  refetchSchoolRanks: () => Promise<unknown>;
  schoolRanksRemoteData: RemoteData<ApolloError, GQL.GetSchoolsRank>;
  previousFormSchoolIds: uuid[];
  sections: AF.Sections<AF.WithId>;
  formId: uuid;
  applicant: AFF.Types.Applicant;
  verificationResults: GQL.FormFragment_form_verification_results[];
  headers?: React.ReactNode;
  hasBeenSubmittedBefore: boolean | null;
};
export const Step: React.FC<Props> = ({
  answers,
  refetchSchoolRanks,
  schoolRanksRemoteData,
  previousFormSchoolIds,
  sections,
  verificationResults,
  hasBeenSubmittedBefore,
  ...props
}) => {
  const { dispatch } = useContext(EditFormContext);

  const rankedSchoolIds = useMemo(() => {
    if (!schoolRanksRemoteData.hasData()) {
      return [];
    }

    return schoolRanksRemoteData.data.form_school_rank.map(
      (rankedSchool) => rankedSchool.school.id
    );
  }, [schoolRanksRemoteData]);
  const applicableSections = useMemo<AF.Sections<AF.WithId>>(() => {
    if (!schoolRanksRemoteData.hasData()) {
      /*
       * Since the remote data is resolved in the parent component, this should
       * never be reached.
       */
      return sections;
    }

    return sections.filter((section) => {
      return isSectionApplicable(
        section,
        answers.form_answer,
        answers.grades_answer,
        answers.form_address,
        answers.custom_question_answer,
        { rankedSchoolIds, previousFormSchoolIds }
      );
    }) as AF.Sections<AF.WithId>;
  }, [
    answers,
    schoolRanksRemoteData,
    sections,
    rankedSchoolIds,
    previousFormSchoolIds,
  ]);

  const section = applicableSections[props.currentStep - 1];
  if (!section) return <NotFound />;

  const steps: StepType[] = applicableSections
    .filter((s): s is AF.Section<AF.WithId> => !!s)
    .map((s) => ({
      id: s?.id,
      title: s?.title,
      initialMissingRequiredQuestions: getMissingRequiredQuestions(
        s,
        answers.form_answer,
        answers.grades_answer,
        answers.form_address,
        answers.custom_question_answer,
        answers.form_disclaimer,
        { rankedSchoolIds, previousFormSchoolIds }
      ),
    }));

  const onChangeFormQuestion = (questionId: string) => {
    dispatch(setCurrentQuestionId(questionId));
  };

  switch (section.type) {
    case AF.PreRankingSectionType:
      return (
        <StepPreRanking
          answers={answers}
          refetchSchoolRanks={refetchSchoolRanks}
          schoolRanksRemoteData={schoolRanksRemoteData}
          section={section}
          allSections={applicableSections}
          steps={steps}
          verificationResults={verificationResults}
          hasBeenSubmittedBefore={hasBeenSubmittedBefore}
          previousFormSchoolIds={previousFormSchoolIds}
          onChangeFormQuestion={onChangeFormQuestion}
          {...props}
        />
      );
    case AF.SchoolRankingSectionType:
      return (
        <StepRankSchools
          refetchSchoolRanks={refetchSchoolRanks}
          schoolRanksRemoteData={schoolRanksRemoteData}
          section={section}
          allSections={applicableSections}
          steps={steps}
          hasBeenSubmittedBefore={hasBeenSubmittedBefore}
          {...props}
        />
      );
    case AF.GeneralSectionType:
      return (
        <StepGeneralSection
          answers={answers}
          schoolRanksRemoteData={schoolRanksRemoteData}
          previousFormSchoolIds={previousFormSchoolIds}
          section={section}
          steps={steps}
          verificationResults={verificationResults}
          hasBeenSubmittedBefore={hasBeenSubmittedBefore}
          onChangeFormQuestion={onChangeFormQuestion}
          {...props}
        />
      );
    case AF.DisclaimerSectionType:
      return (
        <StepDisclaimerSection
          section={section}
          steps={steps}
          hasBeenSubmittedBefore={hasBeenSubmittedBefore}
          {...props}
        />
      );
  }
};
