import { SortField } from "src/hooks/useCommonSearchParams";

import * as GQL from "src/types/graphql";

export enum RelatedFormsColumnIds {
  EnrollmentPeriod = "enrollment_period",
  FormId = "form_id",
  FormTemplateName = "form_template_name",
}

const defaultRelatedFormsOrderBy: GQL.form_order_by = {
  form_template: { enrollment_period: { name: GQL.order_by.asc } },
};

export const buildOrderBy = (orderBy: SortField | null) => {
  if (!orderBy) {
    return [defaultRelatedFormsOrderBy];
  }

  if (orderBy.sortKey === RelatedFormsColumnIds.EnrollmentPeriod) {
    return [
      { form_template: { enrollment_period: { name: orderBy.sortType } } },
    ];
  }

  if (orderBy.sortKey === RelatedFormsColumnIds.FormTemplateName) {
    return [{ form_template: { name: orderBy.sortType } }];
  }

  if (orderBy.sortKey === RelatedFormsColumnIds.FormId) {
    return [{ id: orderBy.sortType }];
  }

  return [{}];
};
