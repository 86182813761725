import { ApolloError } from "@apollo/client";
import { Box, Flex, Skeleton, Text, VisuallyHidden } from "@chakra-ui/react";
import _ from "lodash";
import React from "react";
import {
  useBoundariesMap,
  useFormAddressPosition,
} from "src/components/Boundary/useBoundary";
import { NoDataInfoAlert } from "src/components/Feedback/NoDataInfoAlert";
import { useGradeAnswer } from "src/components/Form/useGradeAnswer";
import { SchoolListItem } from "src/components/Inputs/MultiSelectSchoolRank/SchoolListItem";
import { Glossary } from "src/components/Text/Glossary";
import { useConfirmationDialog } from "src/hooks/useConfirmationDialog";
import { useGlossary } from "src/hooks/useGlossary";
import { WEGLOT_SKIP_CLASS } from "src/plugins/weglot/constants";
import * as AFF from "src/services/formTemplateFilters";
import * as AF from "src/types/formTemplate";
import { SortableList } from "../../DragAndDrop";
import { GQLRemoteDataView } from "../../Layout/RemoteDataView";
import { MultiSelectOrderableList } from "../../MultiSelectOrderableList";
import { schoolSelectionLimitText } from "./helpers";
import { useEligibleSchools } from "./useEligibleSchools";

export type School = {
  id: uuid;
  name: string;
  street_address: string | null;
  grades?: { id: uuid; grade_config_id: uuid }[];
};
export type RankedSchool = {
  rank: number;
  school: School;
};
type ComponentProps = {
  applicant: AFF.Types.Applicant;
  formId: uuid;
  preRankingSection: AF.PreRankingSection<AF.WithId>;
  schoolRankingSection: AF.SchoolRankingSection<AF.WithId>;
  onSort: (schools: readonly School[]) => Promise<void>;
  onDelete: (schools: readonly School[], school: School) => void;
  onSelectedItemsChanged: (schools: readonly School[]) => void;
  onIsDropdownOpenChange?: (isOpen: boolean) => void;
  selectedSchools: readonly School[];
};
export const MultiSelectSchoolRank: React.FC<ComponentProps> = ({
  applicant,
  formId,
  selectedSchools,
  preRankingSection,
  schoolRankingSection,
  onIsDropdownOpenChange,
  onSort,
  onDelete,
  onSelectedItemsChanged,
}) => {
  const { glossary } = useGlossary();
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

  const addressLocationRemoteData = useFormAddressPosition({
    formId,
  }).mapError((e) => new ApolloError({ errorMessage: e.message }));
  const boundariesMapRemoteData = useBoundariesMap({ formId });
  const gradeConfigRemoteData = useGradeAnswer({ formId });

  const eligibleSchoolsRemoteData = useEligibleSchools({
    formId,
    preRankingSection,
    selectedSchools,
  });
  const schoolLimitReached =
    schoolRankingSection.maxSchools === undefined
      ? false
      : selectedSchools.length >= schoolRankingSection.maxSchools;
  const renderListItem = (school: School) => (
    <SchoolListItem
      school={school}
      addressLocation={addressLocationRemoteData}
      boundariesMap={boundariesMapRemoteData}
      gradeConfigId={gradeConfigRemoteData}
      showAddress
      textProps={{ fontWeight: 600 }}
    />
  );

  const renderSelectItem = (school: School) => (
    <SchoolListItem
      school={school}
      addressLocation={addressLocationRemoteData}
      boundariesMap={boundariesMapRemoteData}
      gradeConfigId={gradeConfigRemoteData}
      textProps={{ fontWeight: 600 }}
    />
  );

  const {
    confirm: confirmDeletion,
    confirmationDialog: deleteConfirmationDialog,
  } = useConfirmationDialog({
    header: glossary`Remove school?`,
    body: (
      <Flex direction="column" gap={3}>
        <Text>
          <Glossary>
            This will remove it from your child's form. The school can be added
            again later through the “Add school” button.
          </Glossary>
        </Text>
      </Flex>
    ),
    cancelButton: {
      label: "No, cancel",
    },
    confirmButton: {
      label: "Yes, remove",
      colorScheme: "red",
    },
    translate: true,
  });

  const studentReference = applicant?.first_name || `this ${glossary`student`}`;

  const renderSelectedItems = (
    schools: readonly School[],
    eligibleSchools: readonly School[]
  ) => (
    <Flex
      direction="column"
      alignItems="center"
      maxWidth="25rem"
      width="100%"
      alignSelf="center"
    >
      {schools.length === 0 ? (
        <>
          <Text fontSize="xs" fontWeight="600" textAlign="center">
            <Glossary>There are no schools selected</Glossary>
          </Text>

          {eligibleSchools.length === 0 && (
            <Box mt={2}>
              <NoDataInfoAlert
                text={`No schools available for ${studentReference}. Review the previous steps answers or`}
              />
            </Box>
          )}
        </>
      ) : (
        <SortableList
          droppableId="RankSchools"
          items={schools}
          toId={(school) => school.id}
          render={renderSelectItem}
          onSort={onSort}
          helpTextId="school-ranking-draggable-description"
          deleteButton={{
            onClick: async (school: School) => {
              if (!(await confirmDeletion())) {
                return;
              }
              onDelete(schools, school);
            },
            label: glossary`Remove school`,
          }}
          sortableItemProps={{ className: WEGLOT_SKIP_CLASS }}
        />
      )}
      <VisuallyHidden id="school-ranking-draggable-description">
        Press space bar to start a drag. When dragging you can use the arrow
        keys to move the item around and escape to cancel. Some screen readers
        may require you to be in focus mode or to use your pass through key.
      </VisuallyHidden>
    </Flex>
  );

  let schoolSelectionLimit: React.ReactNode = null;
  if (!isDropdownOpen) {
    const text = schoolSelectionLimitText(glossary, {
      limitReached: schoolLimitReached,
      maxSchools: schoolRankingSection.maxSchools,
      minSchools: schoolRankingSection.minSchools,
    });

    if (text) {
      schoolSelectionLimit = (
        <Text
          textAlign="center"
          alignSelf="center"
          color="gray.500"
          fontSize="sm"
        >
          {text}
        </Text>
      );
    }
  }

  return (
    <Box width="100%" position="relative" display="block">
      <GQLRemoteDataView
        remoteData={eligibleSchoolsRemoteData}
        loading={<Loading />}
      >
        {(eligibleSchools) => {
          return (
            <>
              <MultiSelectOrderableList<School>
                items={eligibleSchools}
                selectedItems={selectedSchools}
                keyAttr="id"
                searchableFields={["name", "street_address"]}
                onSelectedItemChange={(school) =>
                  onSelectedItemsChanged([...selectedSchools, school])
                }
                renderListItem={renderListItem}
                renderSelectedItems={(schools) =>
                  renderSelectedItems(schools, eligibleSchools)
                }
                button={{
                  label: glossary`Add school`,
                  disabled: !eligibleSchools.length || schoolLimitReached,
                }}
                onIsDropdownOpenChange={(isOpen) => {
                  setIsDropdownOpen(isOpen);
                  if (onIsDropdownOpenChange) onIsDropdownOpenChange(isOpen);
                }}
              />

              {schoolSelectionLimit}
            </>
          );
        }}
      </GQLRemoteDataView>
      {deleteConfirmationDialog}
    </Box>
  );
};

const Loading = () => (
  <Flex width="25rem" direction="column" padding="2rem" gap="6">
    <Flex border="px solid red" height="1rem" width="100%" paddingX="2rem">
      <Skeleton height="1rem" width="100%" borderRadius="md" />
    </Flex>
    <Flex direction="column" gap="3" alignItems="center">
      <Skeleton height="2rem" width="100%" borderRadius="md" />
      <Skeleton height="2rem" width="100%" borderRadius="md" />
      <Skeleton height="2rem" width="100%" borderRadius="md" />
    </Flex>
  </Flex>
);

export function normalizeRankedSchools(
  schoolsRank: readonly RankedSchool[]
): School[] {
  return _.sortBy(schoolsRank, (schoolRank) => schoolRank.rank).map(
    (schoolRank) => schoolRank.school
  );
}
