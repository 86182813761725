import { useApolloClient } from "@apollo/client";
import {
  concretePermissions,
  PermissionEvaluateRequestBuilder,
} from "@avela/avela-authorization-sdk";
import { useFlags } from "flagsmith/react";
import React, { useCallback, useState } from "react";
import { ForceLogout } from "src/components/Feedback/ForceLogout";
import { PERMISSIONS_AUTHORIZATION_SCOPE } from "src/components/Providers/AuthorizationProvider";
import { useAuthorizationApi } from "src/hooks/useAuthorizationApi";
import * as Env from "src/services/env";
import * as GQL from "src/types/graphql";
import { HasuraRole } from "src/types/hasuraRole";
import { Permission } from "src/types/permissions";
import useUser from "../useUser";
import {
  FETCH_USER_ATTRIBUTES,
  FETCH_USER_PERMISSIONS,
} from "./graphql/queries";

const INIITAL_STATE = {
  isLoading: true,
  isError: false,
  isRequired: false,
  permissions: [],
  attributes: {
    currentSchoolIds: [],
    applyingSchoolIds: [],
  },
};

export const UserPermissionsContext =
  React.createContext<UserPermissionState>(INIITAL_STATE);

const HASURA_ROLES_WITH_PERMISSIONS = [
  HasuraRole.ADMIN,
  HasuraRole.ORG_ADMIN,
  HasuraRole.SCHOOL_ADMIN,
];

type UserPermissionState = {
  isLoading: boolean;
  isError: boolean;
  isRequired: boolean;
  permissions: Permission[];
  attributes: {
    currentSchoolIds: uuid[];
    applyingSchoolIds: uuid[];
  };
};

type Props = { children: React.ReactNode };
export const UserPermissionsContextProvider = ({ children }: Props) => {
  const user = useUser();
  const [state, setState] = useState<UserPermissionState>(INIITAL_STATE);
  const client = useApolloClient();
  const env = Env.read();
  const { evaluateWithPrincipalOverride, isReadyToEvaluate } =
    useAuthorizationApi(PERMISSIONS_AUTHORIZATION_SCOPE);
  const flags = useFlags(["abac-authorization"]);

  const fetchLocalUserPermissions = React.useCallback(
    async (userID: string): Promise<Permission[]> => {
      const result = await client.query<
        GQL.FetchUserPermissions,
        GQL.FetchUserPermissionsVariables
      >({
        query: FETCH_USER_PERMISSIONS,
        variables: {
          userID,
        },
      });

      const groupPermissions =
        result.data.user_by_pk?.user_group?.user_group_permissions?.map(
          (item) => item.permission
        ) || [];

      const schoolAccessPermissions = Array.from(
        new Set(
          result.data.user_by_pk?.school_users
            ?.map((x) => x.school_access_permission ?? "")
            ?.filter((x) => x) ?? []
        )
      );

      return Array.from(
        new Set([...groupPermissions, ...schoolAccessPermissions])
      ).map((item) => item as Permission);
    },
    [client]
  );

  const fetchLocalUserAttributes = React.useCallback(
    async (userID: string): Promise<UserPermissionState["attributes"]> => {
      const result = await client.query<
        GQL.FetchUserAttributes,
        GQL.FetchUserAttributesVariables
      >({
        query: FETCH_USER_ATTRIBUTES,
        variables: {
          userID,
        },
      });

      const currentSchoolIds = Array.from(
        new Set(
          result.data.user_by_pk?.school_users
            ?.filter(
              (x) =>
                x.school_access_permission === "school:all" ||
                x.school_access_permission === "school:attending"
            )
            ?.map((x) => x.school_id) ?? []
        )
      );

      const applyingSchoolIds = Array.from(
        new Set(
          result.data.user_by_pk?.school_users
            ?.filter(
              (x) =>
                x.school_access_permission === "school:all" ||
                x.school_access_permission === "school:applying"
            )
            ?.map((x) => x.school_id) ?? []
        )
      );

      return { currentSchoolIds, applyingSchoolIds };
    },
    [client]
  );

  const evaluateAbacPermissions = useCallback(
    async (permissions: Permission[]) => {
      const abacPermissions: Permission[] = [];
      const builder = new PermissionEvaluateRequestBuilder();
      for (const permission of concretePermissions) {
        builder.buildPermissionPerform(permission);
      }
      const evaluationResult = await evaluateWithPrincipalOverride(
        { legacyPermissions: permissions },
        builder
      );
      if (evaluationResult.hasData()) {
        const data =
          evaluationResult.data["Perform::Action::with_permission"] || {};
        for (const [
          permissionFullyQualifiedName,
          policyEffect,
        ] of Object.entries(data)) {
          if (policyEffect === "allow") {
            const permissionId = permissionFullyQualifiedName.split(
              "::"
            )[1] as Permission;
            abacPermissions.push(permissionId);
          }
        }
        return abacPermissions;
      }
      throw new Error("Failed to evaluate permissions");
    },
    [evaluateWithPrincipalOverride]
  );

  const fetchUserPermissions = useCallback(async () => {
    // TODO: (Loi) Remove when permissions are applicable to all role.
    const isRequired =
      user.status === "ok" &&
      HASURA_ROLES_WITH_PERMISSIONS.includes(user.data.role);

    try {
      let userPermissionState: UserPermissionState = {
        isLoading: false,
        isError: false,
        isRequired,
        permissions: [],
        attributes: {
          currentSchoolIds: [],
          applyingSchoolIds: [],
        },
      };
      if (env.NODE_ENV === "development") {
        if (user.status === "ok") {
          userPermissionState = {
            isLoading: false,
            isError: false,
            isRequired,
            permissions: await fetchLocalUserPermissions(user.data.id),
            attributes: await fetchLocalUserAttributes(user.data.id),
          };
        }
      } else if (user.status === "ok") {
        userPermissionState = {
          isLoading: false,
          isError: false,
          isRequired,
          permissions: user.data.permissions,
          attributes: {
            applyingSchoolIds: user.data.applyingSchoolIds,
            currentSchoolIds: user.data.currentSchoolIds,
          },
        };
      }
      const abacEnabled = flags["abac-authorization"].enabled;
      // Patching required for feature flags:
      // Admins are evaluated against ABAC
      // while in the legacy system they were not.
      // TODO: Remove when feature flag is not needed
      if (
        user.status === "ok" &&
        user.data.role === HasuraRole.ADMIN &&
        !abacEnabled
      ) {
        userPermissionState.isRequired = false;
      }

      if (userPermissionState.isRequired && abacEnabled) {
        userPermissionState.permissions = await evaluateAbacPermissions(
          userPermissionState.permissions
        );
      }

      setState(userPermissionState);
    } catch (error: unknown) {
      setState({
        isLoading: false,
        isError: true,
        isRequired,
        permissions: [],
        attributes: { currentSchoolIds: [], applyingSchoolIds: [] },
      });
    }
  }, [
    fetchLocalUserPermissions,
    fetchLocalUserAttributes,
    evaluateAbacPermissions,
    user,
    env.NODE_ENV,
    flags,
  ]);

  React.useEffect(() => {
    if (isReadyToEvaluate) fetchUserPermissions();
  }, [fetchUserPermissions, isReadyToEvaluate]);

  if (state.isError) {
    return (
      <ForceLogout>
        <UserPermissionsContext.Provider value={state}>
          {children}
        </UserPermissionsContext.Provider>
      </ForceLogout>
    );
  }

  if (state.isRequired && state.permissions.length === 0) {
    return (
      <ForceLogout
        title="Log in"
        description="You cannot log in because you are not assigned to a team. Please contact the organization owner to request addition to a team."
        action={{
          label: "Done",
          variant: "link",
        }}
      >
        <UserPermissionsContext.Provider value={state}>
          {children}
        </UserPermissionsContext.Provider>
      </ForceLogout>
    );
  }

  return (
    <UserPermissionsContext.Provider value={state}>
      {children}
    </UserPermissionsContext.Provider>
  );
};
