import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Flex, Heading } from "@chakra-ui/layout";
import { Box, Text } from "@chakra-ui/react";

import { useFormikContext } from "formik";
import { useMemo } from "react";
import { MultiSelectInput } from "src/components/Inputs/MultiSelectInput";
import { SwitchInput } from "src/components/Inputs/SwitchInput";
import { useWeglot } from "src/plugins/weglot";
import * as GQL from "src/types/graphql";
import { FormikOrganizationForm } from "../Form";
import {
  LanguageMultiSelect,
  transformLanguageToMultiSelect,
  transformMultiSelectToLanguage,
} from "../services";

interface LanguagesFormConfigurationProps {
  initialLanguages: string[];
}

export const LanguagesFormConfiguration: React.FC<
  LanguagesFormConfigurationProps
> = ({ initialLanguages }) => {
  const {
    values: { organization_configs },
    setFieldValue,
  } = useFormikContext<FormikOrganizationForm>();

  const translationOptions =
    organization_configs[GQL.organization_config_type_enum.TranslationOptions];
  const { enabled: translationsEnabled } = translationOptions ?? {};
  const weglot = useWeglot();

  const handleChangeLanguages = (values: LanguageMultiSelect[]) => {
    const languages = transformMultiSelectToLanguage(values);
    setFieldValue(
      `organization_configs[${GQL.organization_config_type_enum.TranslationOptions}].data`,
      languages
    );
  };

  const renderDropdownItem = (item: LanguageMultiSelect) => {
    return (
      <Box>
        <span>{item.name}</span>
      </Box>
    );
  };

  const initialValues = useMemo(() => {
    if (!weglot) return [];
    return transformLanguageToMultiSelect(
      initialLanguages,
      weglot.getLanguageName
    );
  }, [initialLanguages, weglot]);

  const options = useMemo(() => {
    if (!weglot?.options.languages) return [];

    return transformLanguageToMultiSelect(
      weglot.options.languages.map((l) => l.language_to),
      weglot.getLanguageName
    );
  }, [weglot]);

  return (
    <>
      <Heading as="h3" size="md">
        Languages
      </Heading>

      <Flex direction="column">
        <SwitchInput<boolean>
          label="Translate into different languages"
          name={`organization_configs[${GQL.organization_config_type_enum.TranslationOptions}].enabled`}
          checked={[true, ""]}
          unchecked={[false, ""]}
          direction="row"
        />

        <Flex direction="column" flex={1} marginLeft={12} gap={2}>
          <Text fontSize="xs" color="blackAlpha.700">
            Enables translation for the Parent portal into multiple languages.
          </Text>

          {translationsEnabled && (
            <FormControl>
              <FormLabel
                htmlFor={`organization_configs[${GQL.organization_config_type_enum.TranslationOptions}].data`}
              >
                Translate to
                <MultiSelectInput<LanguageMultiSelect>
                  size="md"
                  onChange={handleChangeLanguages}
                  options={options}
                  initialValues={initialValues}
                  renderDropdownItem={renderDropdownItem}
                  labelAttr="name"
                />
              </FormLabel>
            </FormControl>
          )}
        </Flex>
      </Flex>
    </>
  );
};
