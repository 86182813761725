import { Flex, Text } from "@chakra-ui/react";
import { useField } from "formik";
import _ from "lodash";
import { FunctionComponent, useEffect, useMemo } from "react";
import { NoDataInfoAlert } from "src/components/Feedback/NoDataInfoAlert";
import { FormikCustomSelect } from "src/components/Inputs/CustomSelect";
import { fullName } from "src/services/format";
import { FormTemplate, StudentPerson } from "../../types";
import { isNotNull } from "src/services/predicates";

type Props = {
  formTemplates: FormTemplate[];
  selectedStudent: StudentPerson | undefined;
};

/**
 * A dependent Select field that appears when an enrollment period is selected.
 * Displays the list of available forms for the student.
 */
type Option = {
  label: string;
  description: string | null;
  key: string;
  value: string;
  disabled?: boolean;
};
export const SelectFormTemplate: FunctionComponent<Props> = (props) => {
  const { formTemplates, selectedStudent } = props;

  /**
   * Keep track of which forms are disabled because the selected student
   * already have the form
   */
  const disabledFormTemplateId: Set<uuid> = useMemo(() => {
    const set = new Set<uuid>();
    for (const formTemplate of formTemplates) {
      if (!selectedStudent) {
        set.add(formTemplate.id);
        continue;
      }

      if (
        selectedStudent.forms.find(
          (form) => form.form_template_id === formTemplate.id
        ) !== undefined
      ) {
        set.add(formTemplate.id);
      }
    }

    return set;
  }, [formTemplates, selectedStudent]);

  const formTemplateOptions: Option[] = useMemo(() => {
    const assignedFormTemplates =
      selectedStudent?.assigned_forms.map((form) => form.form_template) ?? [];

    const allFormTemplates = formTemplates
      .concat(assignedFormTemplates)
      .filter(isNotNull); // remove assigned forms that may have been deleted (null)

    const allUniqueFormTemplates = _.sortBy(
      _.uniqBy(allFormTemplates, "id"),
      "open_at"
    );

    return allUniqueFormTemplates.map((formTemplate) => {
      const { id, name, description } = formTemplate;
      return {
        label: name,
        description,
        key: id,
        value: id,
        disabled: disabledFormTemplateId.has(id),
      };
    });
  }, [disabledFormTemplateId, formTemplates, selectedStudent?.assigned_forms]);

  const noFormTemplatesAreAvailable =
    formTemplateOptions.filter((option) => !option.disabled).length === 0;

  const [enrollmentPeriodIdField] = useField("enrollmentPeriodId");
  const [studentId] = useField("studentId");
  const [, , formTemplateIdHelper] = useField("formTemplateId");

  useEffect(() => {
    const enableOptions = formTemplateOptions.filter(
      (option) => !option.disabled
    );
    if (enableOptions.length === 1 && enableOptions[0]) {
      formTemplateIdHelper.setValue(enableOptions[0].key);
    } else {
      formTemplateIdHelper.setValue("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formTemplateOptions]);

  if (!enrollmentPeriodIdField.value || !studentId.value) {
    return null;
  }

  return (
    <>
      <FormikCustomSelect
        alert={
          noFormTemplatesAreAvailable && (
            <NoDataInfoAlert
              text={`No available forms${
                selectedStudent ? ` for ${fullName(selectedStudent)}` : ""
              }. Review the student's forms or`}
            ></NoDataInfoAlert>
          )
        }
        renderEmptyItems={() => <Text>No available options</Text>}
        disabled={noFormTemplatesAreAvailable}
        name="formTemplateId"
        label={<>What do you want to do?</>}
        items={formTemplateOptions}
        renderItem={(item) => (
          <Flex direction="column">
            <Text color="gray.900">{item.label}</Text>
            <Text color="blackAlpha.700">{item.description}</Text>
          </Flex>
        )}
        renderSelectedItem={(item) => (item ? item.label : "Select form")}
        openDirection={window.innerHeight < 800 ? "up" : "down"}
        maxHeight="250px"
      />
    </>
  );
};
