import { Button, Flex, Text } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { Card } from "src/components/Layout/Card";
import { RiEditLine, RiEyeLine } from "react-icons/ri";

interface Props {
  title: string;
  description?: string | null;
  tag?: React.ReactNode;
  actionSwitch: React.ReactNode;
  footerInfo?: React.ReactNode;
  previewRoute?: string;
  editRoute?: string;
  onEditClick?: () => void;
}

export const FormTemplateDefaultCard: React.FC<Props> = ({
  tag,
  title,
  description,
  actionSwitch,
  footerInfo,
  previewRoute,
  editRoute,
  onEditClick
}) => {
  return (
    <Card
      display="flex"
      showBorder
      padding={6}
      width="100%"
      justifyContent="space-between"
    >
      <Flex direction="column" gap={4} width="100%">
        {tag}

        <Text fontSize="lg">{title}</Text>

        {description && (
          <Text color="blackAlpha.600" fontWeight="400">
            {description}
          </Text>
        )}

        {footerInfo && <Flex direction="column">{footerInfo}</Flex>}
      </Flex>

      {actionSwitch || previewRoute || editRoute || onEditClick ? (
        <Flex gap={2} alignItems="center" height="fit-content">
          <Flex mr={4}>{actionSwitch}</Flex>

          {previewRoute && (
            <Button
              as={NavLink}
              to={previewRoute}
              aria-label={`Preview ${previewRoute}`}
              colorScheme="gray"
              variant="outline"
              leftIcon={<RiEyeLine />}
              size="sm"
            >
              Preview
            </Button>
          )}

          {editRoute && (
            <Button
              as={NavLink}
              to={editRoute}
              aria-label={`Edit ${editRoute}`}
              colorScheme="gray"
              variant="outline"
              leftIcon={<RiEditLine />}
              size="sm"
            >
              Edit
            </Button>
          )}

          {onEditClick && (
            <Button
              aria-label="Edit action click"
              colorScheme="gray"
              variant="outline"
              leftIcon={<RiEditLine />}
              size="sm"
              onClick={onEditClick}
            >
              Edit
            </Button>
          )}
        </Flex>
      ) : null}
    </Card>
  );
};
