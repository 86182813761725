import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import { ReactComponent as InfoIcon } from "src/images/announcement-info.svg";
import * as GQL from "src/types/graphql";
import { FETCH_ANNOUNCEMENTS } from "./graphql/queries";
import { useOrganization } from "src/hooks/useOrganization";
import React from "react";
import Markdown from "markdown-to-jsx";

export interface ParentAnnouncementProps {
  condition: GQL.announcement_condition_enum;
}

export const ParentAnnouncement = ({ condition }: ParentAnnouncementProps) => {
  const organization = useOrganization();
  const { remoteData } = useRemoteDataQuery<
    GQL.FetchAnnouncements,
    GQL.FetchAnnouncementsVariables
  >(FETCH_ANNOUNCEMENTS, {
    variables: {
      organization_id: organization.map((org) => org.id).withDefault(""),
      entry_point: GQL.announcement_entry_point_enum.PARENT_PORTAL,
      condition,
    },
  });

  const announcement = React.useMemo(() => {
    return remoteData.toNullable()?.announcement[0];
  }, [remoteData]);

  if (!announcement) return <></>;

  return (
    <HStack
      width="100%"
      gap={4}
      bg="blue.100"
      borderRadius="md"
      boxShadow="md"
      padding="0.75em 1.0em 0.75em 1.0em"
    >
      <Box h="100%">
        {announcement.type === GQL.announcement_type_enum.INFO && (
          <InfoIcon width="1.5em" height="1.5em" />
        )}
      </Box>
      <VStack>
        <Text color="gray.700" fontSize="md" fontWeight="700" width="100%">
          {announcement.title}
        </Text>
        <Text color="gray.700" fontSize="md" width="100%">
          <Markdown>{announcement.description}</Markdown>
        </Text>
      </VStack>
    </HStack>
  );
};
