import { Flex, FlexProps } from "@chakra-ui/react";
import { useField } from "formik";
import _ from "lodash";
import React, { RefCallback, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { ParentQuestionAnswer } from "src/components/Form/QuestionAnswer";
import { AddressAnswer } from "src/components/Form/QuestionForm/formik";
import {
  Option,
  Question,
  QuestionProps,
} from "src/components/Inputs/QuestionDynamicInputs/Question";
import { FormikFieldValue } from "src/services/formTemplate/answer";
import * as AFF from "src/services/formTemplateFilters";
import { DEBOUNCE_WAIT_IN_MILIS } from "src/services/url/constants";
import * as AF from "src/types/formTemplate";
import {
  SingleSelectChangeProps,
  useFormQuestion,
} from "../../hooks/useFormQuestion";
import { useGradesQuestion } from "../../hooks/useGradesQuestion";

export type WithAutoSaveProps = Props & {
  confirmEligibilityChange?: (
    value: SingleSelectChangeProps
  ) => Promise<boolean>;
  confirmGradesChange?: (value: SingleSelectChangeProps) => Promise<boolean>;
  confirmAddressChange?: (value: AddressAnswer) => Promise<boolean>;
  onAutosave?: (questionId: uuid, saving: boolean) => void;
  afterAutosave?: () => Promise<void>;
};

export type Props = {
  formId: string;
  applicant: AFF.Types.Applicant;
  question: AF.Question<AF.WithId>;
  readOnly?: boolean;
  isDisabled?: boolean;
  disableAddressBook?: boolean;
  confirmAddressChange?: (value: AddressAnswer) => Promise<boolean>;
  onChangeFormQuestion?: (questionId: string) => void;
} & FlexProps;

export function FormQuestion({
  formId,
  applicant,
  question,
  readOnly = false,
  isDisabled = false,
  disableAddressBook = false,
  confirmAddressChange,
  onChangeFormQuestion,
  ...flexProps
}: Props): React.ReactElement {
  const questionProps = extractQuestionProps(
    question,
    formId,
    applicant,
    disableAddressBook
  );

  const location = useLocation();

  const scrollToQuestionAfterNavigate: RefCallback<HTMLDivElement> =
    useCallback(
      (node) => {
        if (node && location.state?.questionId === question.id) {
          // use a timeout to delay scroll to next event cycle
          setTimeout(() => {
            node.scrollIntoView();
          });
        }
      },
      [location.state?.questionId, question.id]
    );

  const [field] = useField<FormikFieldValue>(question.id);
  const answer = field.value;
  return (
    <Flex
      id={question.id}
      position="relative"
      width="100%"
      onChange={() => {
        onChangeFormQuestion?.(question.id);
      }}
      {...flexProps}
      ref={scrollToQuestionAfterNavigate}
    >
      {readOnly ? (
        <ParentQuestionAnswer
          question={question}
          answer={answer}
          formId={formId}
        />
      ) : (
        <Question {...questionProps} isDisabled={isDisabled} />
      )}
    </Flex>
  );
}

const withAutoSave =
  (Component: typeof FormQuestion) => (props: WithAutoSaveProps) => {
    const {
      formId,
      question,
      confirmEligibilityChange,
      confirmGradesChange,
      confirmAddressChange,
      onAutosave,
      afterAutosave,
      ...flexProps
    } = props;
    const [field, meta] = useField<FormikFieldValue>(question.id);
    const fieldValue = field.value;

    const {
      saveFreeTextAnswer,
      saveMultiSelectAnswer,
      saveSingleSelectAnswer,
      saveAddressAnswer,
      saveCustomQuestionAnswer,
    } = useFormQuestion(formId, question);
    const { saveGradesAnswer } = useGradesQuestion(formId, question);

    const onAutosaveStart = React.useCallback(() => {
      onAutosave && onAutosave(question.id, true);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSave = React.useCallback(
      _.debounce(async (value: FormikFieldValue) => {
        switch (question.type) {
          case AF.FreeTextType:
          case AF.EmailType:
          case AF.PhoneNumberType:
            await saveFreeTextAnswer(value);
            break;

          case AF.MultiSelectType:
            await saveMultiSelectAnswer(value);
            break;

          case AF.SingleSelectType:
            await saveSingleSelectAnswer(value, confirmEligibilityChange);
            break;

          case AF.FileUploadType:
            break;

          case AF.GradesType:
            await saveGradesAnswer(value, confirmGradesChange);
            break;

          case AF.AddressType:
            await saveAddressAnswer(value, confirmAddressChange);
            break;

          case AF.CustomQuestionType:
            await saveCustomQuestionAnswer(value);
            break;

          default:
            const _exhaustiveCheck: never = question;
            return _exhaustiveCheck;
        }
        afterAutosave && (await afterAutosave());
        onAutosave && onAutosave(question.id, false);
        // formik.validateForm();
      }, DEBOUNCE_WAIT_IN_MILIS),
      []
    );

    React.useEffect(() => {
      if (meta.touched && !meta.error) {
        onAutosaveStart();
        debouncedSave(fieldValue);
      }
    }, [debouncedSave, fieldValue, meta.touched, onAutosaveStart, meta.error]);

    return <Component {...{ question, ...flexProps }} formId={formId} />;
  };

export const FormQuestionWithAutoSave = withAutoSave(FormQuestion);

export function extractQuestionProps(
  question: AF.Question<AF.WithId>,
  formId: string,
  applicant: AFF.Types.Applicant,
  disableAddressBook: boolean
): QuestionProps {
  const questionType = question.type;
  const questionId = question.id;
  const commonProps = {
    formId,
    question: question.question,
    isRequired: question.requirement === "Required",
    id: questionId,
    key: question.key,
    link_text: question.link_text,
    link_url: question.link_url,
    permissionLevel: question.permissionLevel,
  };

  switch (questionType) {
    case AF.SingleSelectType:
    case AF.MultiSelectType:
    case AF.GradesType:
      return {
        applicant,
        kind: questionType,
        ...commonProps,
        options: getQuestionOptions(
          question.options ?? [],
          applicant,
          question.filters ?? []
        ),
      };
    case AF.FreeTextType:
    case AF.FileUploadType:
    case AF.EmailType:
    case AF.PhoneNumberType:
      return {
        applicant,
        kind: questionType,
        ...commonProps,
      };
    case AF.AddressType:
      return {
        applicant,
        kind: questionType,
        disableAddressBook,
        ...commonProps,
      };
    case AF.CustomQuestionType:
      return {
        applicant,
        kind: questionType,
        ...commonProps,
        customQuestionTypeId: question.customQuestionTypeId,
        nestedQuestions: question.nestedQuestions,
      };
    default:
      const _exhaustiveCheck: never = question;
      return _exhaustiveCheck;
  }
}

function getQuestionOptions(
  options: AF.Option<AF.WithId>[],
  applicant: AFF.Types.Applicant,
  filters: AFF.Types.FormTemplateFilters
): Option[] {
  return AFF.performFilters(
    options.map((o) => ({
      key: o.id,
      translate: o.translate_options === true,
      label: o.label,
      value: o.value,
    })),
    applicant,
    filters
  );
}
