import Immutable from "immutable";
import React from "react";
import * as AF from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import { EditableSection, FormTemplateSection } from "./FormTemplateSection";
import { useEditFormTemplateContext } from "./context";

type Props = {
  organizationId: uuid;
  verificationOptions: AF.FormVerification<AF.WithId>[];
  gradesConfig: GQL.GetGradesConfigByOrganization_grade_config[];
};
export const Tab: React.FC<Props> = ({
  organizationId,
  verificationOptions,
  gradesConfig
}) => {
  const { state } = useEditFormTemplateContext();
  const sortedSections = state?.get("sortedSections");
  const newSections = state?.get("newSections") ?? Immutable.Map();
  const sections = state?.get("sections") ?? Immutable.Map();

  return (
    <>
      {sortedSections?.map((section, index) => {
        const newSectionObject = newSections.get(section);
        const existingSectionObject = sections.get(section);
        const sectionObject =
          newSectionObject ?? existingSectionObject?.original;

        return (
          <FormTemplateSection
            key={sectionObject?.id}
            section={sectionObject as EditableSection}
            verificationOptions={verificationOptions}
            organizationId={organizationId}
            gradesConfig={gradesConfig}
            order={index}
            isLastSection={index === sortedSections.size - 1}
            isNewSection={newSectionObject !== undefined}
          />
        );
      })}
    </>
  );
};
