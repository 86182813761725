import {
  Attributes,
  AuthorizationEvaluator,
  AuthorizationMap,
  EvaluateRequestBuilder,
  buildUserContext,
} from "@avela/avela-authorization-sdk";
import React from "react";
import {
  AuthorizationScope,
  usePermissionDispatcher,
} from "src/components/Providers/AuthorizationProvider";
import useAccessToken from "src/hooks/useAccessToken";
import { useOrganization } from "src/hooks/useOrganization";
import { useTeam } from "src/hooks/useTeam";
import useUser from "src/hooks/useUser";
import { Status } from "src/types/authData";
import * as RD from "src/types/remoteData";

export class AuthorizationError extends Error {
  constructor(message: string, readonly cause?: any) {
    super(message);
    this.cause = cause;
  }
}

export const useAuthorizationApi = (scope: AuthorizationScope) => {
  const baseUrl = process.env.REACT_APP_AUTHORIZATION_URL;
  const user = useUser();
  const accessToken = useAccessToken();
  const organizationRD = useOrganization();
  const teamRD = useTeam();
  const [bearerToken, setBearerToken] = React.useState<string>();
  const [organizationId, setOrganizationId] = React.useState<string>();
  const [teamId, setTeamId] = React.useState<string | null>();

  const [isReadyToEvaluate, setIsReadyToEvaluate] =
    React.useState<boolean>(false);

  const evaluator = React.useMemo(
    () => new AuthorizationEvaluator(`${baseUrl!}/evaluate`),
    [baseUrl]
  );

  const dispatchPermission = usePermissionDispatcher(scope);

  React.useEffect(() => {
    if (accessToken.status === Status.OK)
      setBearerToken(`Bearer ${accessToken.data}`);
  }, [accessToken]);

  React.useEffect(() => {
    if (organizationRD.kind === RD.RemoteDataKind.Success)
      setOrganizationId(organizationRD.data.id);
  }, [organizationRD]);

  React.useEffect(() => {
    if (teamRD.kind === RD.RemoteDataKind.Success) setTeamId(teamRD.data);
  }, [teamRD]);

  React.useEffect(() => {
    if (!baseUrl || !bearerToken) return;
    if (user.status !== "ok") return;
    if (organizationId === undefined) return;
    if (!teamRD.hasData()) return;
    setIsReadyToEvaluate(true);
  }, [bearerToken, baseUrl, user, organizationId, teamRD]);

  const evaluateWithPrincipalOverride = React.useCallback(
    async (
      overridePrincipal: Attributes,
      ...builders: EvaluateRequestBuilder[]
    ): Promise<RD.RemoteData<Error, AuthorizationMap>> => {
      dispatchPermission(RD.loading());
      if (
        !isReadyToEvaluate ||
        user.status !== "ok" ||
        organizationId === undefined
      ) {
        const err = RD.failure<Error, AuthorizationMap>(
          new AuthorizationError("Not ready to evaluate permissions")
        );
        dispatchPermission(err);
        return err;
      }

      const userContext = buildUserContext({
        userId: user.data.id,
        attributes: {
          legacyPermissions: user.data.permissions,
          ...user.data.loginType,
          ...overridePrincipal,
        },
        roleId: user.data.role,
      });

      const topLevelPolicies =
        user.data.role === "admin"
          ? ["avela-admin.cedar"]
          : [`organizations/${organizationId}.cedar`];

      const teamPolicyKeys = teamId
        ? [`organizations/${organizationId}/teams/${teamId}.cedar`]
        : [];

      let response: AuthorizationMap;
      try {
        response = await evaluator.evaluate({
          builders,
          token: bearerToken!,
          userContext,
          policyKeys: [...topLevelPolicies, ...teamPolicyKeys],
          salt: user.data.tokenIssuedAt,
        });
      } catch (error) {
        const err = RD.failure<Error, AuthorizationMap>(
          new AuthorizationError("Failed evaluate permissions", error)
        );
        dispatchPermission(err);
        return err;
      }

      const remoteDataResponse: RD.RemoteData<Error, AuthorizationMap> =
        RD.success(response);

      dispatchPermission(remoteDataResponse);
      return remoteDataResponse;
    },
    [
      user,
      teamId,
      evaluator,
      bearerToken,
      organizationId,
      isReadyToEvaluate,
      dispatchPermission,
    ]
  );

  const evaluate = React.useCallback(
    async (
      ...builders: EvaluateRequestBuilder[]
    ): Promise<RD.RemoteData<Error, AuthorizationMap>> => {
      return evaluateWithPrincipalOverride({}, ...builders);
    },
    [evaluateWithPrincipalOverride]
  );

  return {
    evaluate,
    evaluateWithPrincipalOverride,
    isReadyToEvaluate,
  };
};
