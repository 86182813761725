import { Button, ButtonProps, useDisclosure } from "@chakra-ui/react";
import React, { useCallback } from "react";
import { RiAddCircleLine } from "react-icons/ri";
import { useEditFormTemplateContext } from "../EditFormTemplateTabs/Content/context";
import { createNewSection } from "src/components/Form/SectionForm/services";
import {
  SectionFormDialog,
  SectionFormType
} from "src/components/Form/SectionForm/SectionFormDialog";

type AddSectionButtonProps = ButtonProps & {
  children?: string;
  newOrder: number;
};
export const AddSectionButton: React.FC<AddSectionButtonProps> = ({
  children,
  onClick,
  newOrder,
  ...buttonProps
}) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { dispatch, formTemplateId } = useEditFormTemplateContext();

  const handleAddNewSection = useCallback(
    (values: SectionFormType) => {
      if (!dispatch) {
        return;
      }

      const newSection = createNewSection(values);

      dispatch({
        type: "AddNewSection",
        atIndex: newOrder === 1 ? 2 : newOrder,
        newSection
      });

      if (formTemplateId) {
        dispatch({ type: "Save", formTemplateId });
      }

      onClose();
    },
    [dispatch, formTemplateId, newOrder, onClose]
  );

  return (
    <>
      <Button
        variant="outline"
        colorScheme="gray"
        leftIcon={<RiAddCircleLine />}
        size="sm"
        alignSelf="flex-end"
        onClick={onOpen}
        {...buttonProps}
      >
        {children ?? "Add section"}
      </Button>

      <SectionFormDialog
        isOpen={isOpen}
        onCreate={handleAddNewSection}
        onCancelButtonClick={onClose}
      />
    </>
  );
};
