import { ApolloError } from "@apollo/client";
import { Button, Flex } from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { identity } from "lodash";
import React from "react";
import { useNavigate } from "react-router-dom";
import { EmptyState } from "src/components/EmptyState";
import { AuthLayout } from "src/components/Layout/AuthLayout";
import { ParentRemoteDataLayout } from "src/components/Layout/Parent/ParentRemoteDataLayout";
import { useOrganization } from "src/hooks/useOrganization";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import useUser from "src/hooks/useUser";
import { UserProfileError, useUserProfile } from "src/hooks/useUserProfile";
import { ReactComponent as LaunchAppSvg } from "src/images/launch-app.svg";
import { useWeglotToast } from "src/plugins/weglot";
import { emptyNameForm, NameSchema, NameValidator } from "src/schemas/Name";
import { validateWithZod } from "src/services/formValidations";
import * as Url from "src/services/url";
import { Status } from "src/types/authData";
import * as GQL from "src/types/graphql";
import { failure, success } from "src/types/remoteData";
import { z } from "zod";
import { NameForm } from "../../components/Forms/NameForm";
import { UPSERT_PROFILE_NAME } from "../graphql/mutations";
import { GET_PROFILE_BY_USER_ID_AND_ORG_ID } from "../graphql/queries";

const FormSchema = NameSchema.omit({ birth_date: true });
const FormValidator = NameValidator.omit({ birth_date: true });
type FormType = z.infer<typeof FormSchema>;

export const Name: React.FC = () => {
  const user = useUser();
  const organization = useOrganization();
  const userProfile = useUserProfile()
    .map<GQL.ProfileFragment | null>(identity)
    .recover<ApolloError>((error: UserProfileError) => {
      switch (error.kind) {
        case "ServerError":
          return failure(error.error);

        case "ProfileNotFoundError":
        case "UnauthenticatedError":
          return success(null);
      }
    });
  const toast = useWeglotToast();
  const genericErrorToast = () => {
    toast({
      title: "Error updating profile",
      description:
        "Please try again later or report the problem to our support team.",
      status: "error",
      isClosable: true,
    });
  };
  const [upsertProfileName, { remoteData }] = useRemoteDataMutation<
    GQL.UpsertProfileName,
    GQL.UpsertProfileNameVariables
  >(UPSERT_PROFILE_NAME);
  const submitHandler = async (values: FormType) => {
    if (user.status !== Status.OK || !organization.hasData()) {
      genericErrorToast();
      return;
    }

    try {
      const validated = FormValidator.parse(values);
      await upsertProfileName({
        variables: {
          user_id: user.data.id,
          organization_id: organization.data.id,
          ...validated,
        },
        refetchQueries: [GET_PROFILE_BY_USER_ID_AND_ORG_ID],
      });
      navigate(Url.Parent.Profile.new(organization.data, 2));
    } catch (err: unknown) {
      console.error(err);
      genericErrorToast();
    }
  };

  const navigate = useNavigate();

  return (
    <AuthLayout>
      <ParentRemoteDataLayout remoteData={userProfile}>
        {(data) => {
          const parsed = NameSchema.safeParse(data);
          const initialValues = parsed.success ? parsed.data : emptyNameForm;
          return (
            <Formik<FormType>
              initialValues={initialValues}
              onSubmit={submitHandler}
              validate={validateWithZod(FormValidator)}
              validateOnMount={true}
            >
              <Flex
                as={Form}
                direction="column"
                gap={4}
                width="100%"
                alignItems="stretch"
              >
                <EmptyState
                  description="Tell us a bit more about yourself"
                  heading="Welcome"
                  Svg={LaunchAppSvg}
                />

                <NameForm
                  paddingBottom={6}
                  firstName={{ label: "Parent/Guardian first name" }}
                  middleName={{ label: "Parent/Guardian middle name" }}
                  lastName={{ label: "Parent/Guardian last name" }}
                />
                <Button type="submit" isLoading={remoteData.isLoading()}>
                  Next
                </Button>
              </Flex>
            </Formik>
          );
        }}
      </ParentRemoteDataLayout>
    </AuthLayout>
  );
};
