import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Tag,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { FormSchoolRankSubStatusIndex } from "src/constants";
import { SubStatusTag } from "src/scenes/parent/formTemplates/components/FormTemplateCard/FormSchoolRanks/SubStatusTag";
import * as GQL from "src/types/graphql";
import {
  WEGLOT_APPLY_CLASS,
  WEGLOT_SKIP_CLASS,
} from "src/plugins/weglot/constants";

type Props = {
  tags: GQL.FormSummaryFragment_form_school_ranks_tags[];
  schoolName: string;
  subStatus: FormSchoolRankSubStatusIndex;
  isOpen: boolean;
  onCancel: () => void;
  waitlist: Pick<
    GQL.FormSummaryFragment_form_school_ranks_waitlists,
    "waitlist_position" | "status"
  > | null;
  offer: Pick<
    GQL.FormSummaryFragment_form_school_ranks_offers,
    "id" | "status"
  > | null;
};
export const TaggingInfoDialog: React.FC<Props> = ({
  tags,
  schoolName,
  subStatus,
  isOpen,
  onCancel,
  waitlist,
  offer,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onCancel}
      closeOnOverlayClick
      size="sm"
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody
          as={Flex}
          direction="column"
          gap={4}
          padding={4}
          className={WEGLOT_APPLY_CLASS}
        >
          <Text fontSize="sm" fontWeight="600" className={WEGLOT_SKIP_CLASS}>
            {schoolName}
          </Text>
          {subStatus && (
            <SubStatusTag
              formSchoolRankSubStatus={subStatus}
              waitlist={waitlist}
              hasOffer={!!offer}
            />
          )}

          {tags.map((tag) => (
            <Flex direction="column" gap={2}>
              <Flex>
                <Tag key={tag.id} variant="tag" fontSize="xs">
                  <Box whiteSpace="nowrap">
                    {tag.enrollment_period_tag.name}
                  </Box>
                </Tag>
              </Flex>
              {tag.enrollment_period_tag.description && (
                <Text fontSize="sm">
                  {tag.enrollment_period_tag.description}
                </Text>
              )}
            </Flex>
          ))}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
