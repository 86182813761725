import { Button, Flex, Portal, Text } from "@chakra-ui/react";
import { FunctionComponent, PropsWithChildren, RefObject } from "react";
import {
  EditFormTemplateBackButton,
  EditFormTemplateBackButtonProps
} from "./BackButton";
import { useBlocker } from "react-router-dom";
import { ConfirmationDialog } from "src/components/Dialogs/ConfirmationDialog";

type Props = {
  actionBarRef: RefObject<HTMLDivElement>;
  formId?: string;
  isLoading?: boolean;
  hasChanges?: boolean;
  onClearChanges?: () => void;
} & EditFormTemplateBackButtonProps;

/**
 * Used as refs
 */
export const EditFormTemplateActionBar: FunctionComponent<
  PropsWithChildren<Props>
> = (props) => {
  const {
    actionBarRef,
    formId,
    backPath,
    children,
    hasChanges,
    onClearChanges
  } = props;

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      !!hasChanges && currentLocation.pathname !== nextLocation.pathname
  );

  return (
    <Portal containerRef={actionBarRef}>
      <Flex justifyContent="space-between">
        {children ? (
          children
        ) : (
          <StandardActions
            formId={formId}
            backPath={backPath}
            hasChanges={hasChanges}
            onClearChanges={onClearChanges}
          />
        )}
      </Flex>

      <ConfirmationDialog
        isOpen={blocker.state === "blocked"}
        header={<Text fontSize="lg">Exit without publishing?</Text>}
        body={
          <Text>
            There are{" "}
            <Text as="span" fontWeight={600}>
              unpublished
            </Text>{" "}
            changes on this form. If you exit now, you can come back and publish
            later.
          </Text>
        }
        onCancel={() => blocker.reset && blocker.reset()}
        onConfirm={() => blocker.proceed && blocker.proceed()}
        confirmButton={{ label: "Exit without publishing" }}
      />
    </Portal>
  );
};

type StandardActionProps = Omit<Props, "actionBarRef">;

const StandardActions: FunctionComponent<StandardActionProps> = (props) => {
  const { formId, backPath, isLoading, hasChanges, onClearChanges } = props;

  return (
    <>
      <EditFormTemplateBackButton backPath={backPath} />
      <Flex direction="column" gap={2}>
        <Flex gap={2}>
          {hasChanges && (
            <Button
              variant="ghost"
              colorScheme="gray"
              onClick={onClearChanges}
              gridColumn="3"
            >
              Clear unpublished changes
            </Button>
          )}
          <Button form={formId} type="submit" isLoading={isLoading}>
            Publish changes
          </Button>
        </Flex>
        {hasChanges && (
          <Text textAlign="right" fontSize="xs" color="gray.500">
            Unpublished changes
          </Text>
        )}
      </Flex>
    </>
  );
};
