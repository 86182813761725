import { DragHandleIcon } from "@chakra-ui/icons";
import { Flex, FlexProps, Icon, IconButton, Text } from "@chakra-ui/react";
import { DraggableProvided, DraggableStateSnapshot } from "@hello-pangea/dnd";
import React from "react";
import { RiIndeterminateCircleLine } from "react-icons/ri";

type Props<ITEM> = {
  item: Readonly<ITEM>;
  index: number;
  draggedIndex: number | undefined;
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
  render: (item: Readonly<ITEM>) => React.ReactNode;
  helpTextId: string;
  deleteButton: {
    onClick: (item: Readonly<ITEM>) => void;
    label: string;
  };
} & FlexProps;
export function SortableItem<ITEM>({
  item,
  index,
  draggedIndex,
  provided,
  snapshot,
  render,
  helpTextId,
  deleteButton,
  ...props
}: Props<ITEM>) {
  const ref = React.useRef<HTMLDivElement>(null);
  const [outerHeight, setOuterHeight] = React.useState<number>(0);

  React.useEffect(() => {
    if (!ref.current) {
      return;
    }
    setOuterHeight(calculateOuterHeight(ref.current));
  }, []);

  const updatedProvided = updateProvided(
    provided,
    index,
    draggedIndex,
    snapshot,
    outerHeight
  );

  return (
    <Flex
      height="5.5rem"
      width="100%"
      ref={ref}
      alignItems="center"
      justifyContent="space-between"
      {...props}
      aria-describedby={helpTextId}
    >
      <Text
        fontWeight="600"
        _after={{
          content: "'00'",
          display: "block",
          height: 0,
          visibility: "hidden",
        }}
      >
        {index + 1}
      </Text>
      <Flex
        marginLeft={1}
        marginRight={1}
        height="100%"
        flexGrow={1}
        alignItems="center"
        aria-describedby={helpTextId}
      >
        <Flex
          height="100%"
          width="100%"
          alignItems="center"
          cursor="pointer"
          ref={updatedProvided.innerRef}
          {...updatedProvided.draggableProps}
          {...updatedProvided.dragHandleProps}
          aria-describedby={helpTextId}
        >
          <Flex width="100%" height="4.75rem">
            <Flex
              backgroundColor="primary.500"
              width={7}
              flexShrink={0}
              borderLeftRadius="md"
              borderColor="primary.500"
              borderWidth={1}
              borderStyle="solid"
              height="100%"
              alignItems="center"
              justifyContent="center"
            >
              <DragHandleIcon color="white" w={3} h={3} />
            </Flex>
            <Flex
              bg="white"
              borderRightRadius="md"
              borderColor="gray.300"
              flexGrow={1}
              paddingRight={3}
              borderWidth={1}
              borderStyle="solid"
              height="100%"
              alignItems="center"
              borderLeft="none"
              paddingX={3}
              fontSize="sm"
              fontWeight="600"
            >
              {render(item)}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <IconButton
        aria-label={deleteButton.label}
        variant="link"
        colorScheme="red"
        size="xs"
        icon={<Icon w={5} h={5} as={RiIndeterminateCircleLine} />}
        onClick={() => deleteButton.onClick(item)}
      />
    </Flex>
  );
}

function updateProvided(
  provided: DraggableProvided,
  index: number,
  draggedIndex: number | undefined,
  snapshot: DraggableStateSnapshot,
  outerHeight: number
): DraggableProvided {
  if (!snapshot.isDragging) {
    if (draggedIndex !== undefined) {
      if (draggedIndex < index) {
        // for card below the dragged card, we need to adjust the translate position based on the outer height of the card.
        const transform = provided.draggableProps.style?.transform
          ? undefined
          : `translate(0px, -${outerHeight}px)`;
        return updateTransform(provided, transform);
      }
    } else {
      // this to handle a period of time where dragging has started, but we don't have draggedIndex value yet.
      // in this case, don't do transformation so we don't see the element jumping around.
      return updateTransform(provided, undefined);
    }
  }

  return provided;
}

function updateTransform(
  provided: DraggableProvided,
  transform: string | undefined
): DraggableProvided {
  const style = provided.draggableProps.style
    ? { ...provided.draggableProps.style, transform }
    : provided.draggableProps.style;
  const draggableProps = {
    ...provided.draggableProps,
    style,
  };
  const updated = {
    ...provided,
    draggableProps,
  };

  return updated;
}

function calculateOuterHeight(el: HTMLDivElement) {
  let height = el.offsetHeight;
  const style = getComputedStyle(el);

  height += parseInt(style.marginTop) + parseInt(style.marginBottom);
  return height;
}
