import { useLoadScript } from "@react-google-maps/api";
import * as Env from "src/services/env";

export const placesApi: Array<"places"> = ["places"];
export function useLoadGooglePlacesScript() {
  const env = Env.read();
  useLoadScript({
    googleMapsApiKey: env.REACT_APP_GOOGLE_API_KEY || "",
    libraries: placesApi,
  });
}
