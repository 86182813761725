import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import { InputControl } from "formik-chakra-ui";
import React, { useCallback } from "react";
import { validateWithZod } from "src/services/formValidations";
import { z } from "zod";

// TODO: NOT WORKING FOR NOW! This whole form needs to be revisit when we activate the create from scratch feature
const FormSchema = z.object({
  organizationId: z.string().nonempty("Organization ID is required"),
  enrollmentPeriodId: z.string().nonempty("Enrollment period ID is required"),
  /*
  Needs refinement on how to show this in the UI
  formType: z.string(),
  */
  formName: z.string().nonempty("Form name is required"),
  formKey: z.string().nonempty("Form key is required"),
  formDescription: z.string()
});

type FormType = z.infer<typeof FormSchema>;

type Props = {
  isOpen: boolean;
  onCancel: () => void;
};

export const CreateFromScratchDialog: React.FC<Props> = ({
  isOpen,
  onCancel
}) => {
  const initialValues = {
    organizationId: "",
    enrollmentPeriodId: "",
    formName: "",
    formKey: "",
    formDescription: ""
  };

  const handleSubmitForm = useCallback(() => {
    onCancel();
  }, [onCancel]);

  return (
    <Formik<FormType>
      initialValues={initialValues}
      validateOnBlur={false}
      onSubmit={handleSubmitForm}
      validate={validateWithZod(FormSchema)}
    >
      <Modal isOpen={isOpen} onClose={onCancel}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="lg" fontWeight="700">
            Create form from scratch
          </ModalHeader>

          <ModalBody>
            <Flex as={Form} direction="column" gap={2}>
              <InputControl
                id="organizationId"
                name="organizationId"
                label="Organization ID"
              />

              <InputControl
                id="enrollmentPeriodId"
                name="enrollmentPeriodId"
                label="Enrollment period ID"
              />

              <InputControl id="formName" name="formName" label="Form name" />

              <InputControl id="formKey" name="formKey" label="Form key" />

              <InputControl
                id="formDescription"
                name="formDescription"
                label="Form description"
              />
            </Flex>
          </ModalBody>

          <ModalFooter gap={2}>
            <Button variant="ghost" colorScheme="gray" onClick={onCancel}>
              Cancel
            </Button>
            <Button type="submit">Create form</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Formik>
  );
};
