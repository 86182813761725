import { useApolloClient } from "@apollo/client";
import React from "react";
import { Props as TagsProps } from "src/components/Tags/TagsList";
import { TagsPopover } from "src/components/Tags/TagsPopover";
import { Selection } from "src/hooks/useMultiselectState";
import { useRemoteDataQueryPromise } from "src/hooks/useRemoteDataQuery";
import { getSchool } from "src/services/form/school";
import { sleep } from "src/services/sleep";
import * as GQL from "src/types/graphql";
import * as RemoteData from "src/types/remoteData";
import { FormSearchContext } from "../forms/context";
import {
  GET_FORM_IDS_AND_SCHOOL_IDS,
  GET_TAG_GROUPS_FOR_FILTERS,
} from "../forms/graphql/queries";
import {
  GqlSchoolForm,
  SchoolFormId,
  SchoolFormKeyRecord,
  SchoolFormKeyRecordFactory,
} from "../forms/types";
import { useTags } from "./useTags";

type Props = {
  enrollmentPeriodId: uuid;
  formTemplateId: uuid;
  selection: Selection<SchoolFormKeyRecord, GqlSchoolForm>;
  refetchForms: () => Promise<unknown>;
} & Omit<TagsProps, "tags" | "onSearch" | "onTagUpdate">;
export const BulkTagsPopover: React.FC<Props> = ({
  enrollmentPeriodId,
  formTemplateId,
  selection,
  refetchForms,
  ...overrideTagsProps
}) => {
  const search = React.useContext(FormSearchContext);
  if (!search) {
    throw new Error("Missing search value from FormSearchContext");
  }

  const fetchAllFormIdsAndSchoolIds = useRemoteDataQueryPromise<
    GQL.GetFormIdsAndSchoolIds,
    GQL.GetFormIdsAndSchoolIdsVariables
  >(GET_FORM_IDS_AND_SCHOOL_IDS, {
    variables: {
      form_template_id: formTemplateId,
      search: search,
    },
  });

  const handleFetchFormIdsAndSchoolIds = React.useCallback(async (): Promise<
    SchoolFormId[]
  > => {
    const result = await fetchAllFormIdsAndSchoolIds();
    if (result.error) throw new Error(result.error.message);
    return result.data.form_by_form_school_rank.flatMap((row) => {
      const school = getSchool(row);
      if (!row.form_id) {
        throw new Error("form_id is null, this shouldn't be possible.");
      }

      return [
        {
          formId: row.form_id,
          formSchoolRankId: row.form_school_rank?.id ?? null,
          schoolId: school?.id ?? null,
        },
      ];
    });
  }, [fetchAllFormIdsAndSchoolIds]);

  const {
    setSelectedRows,
    tagsProps,
    isDirty,
    resetTags,
    setSelectedTagGroupId,
    tagGroups,
  } = useTags({
    enrollmentPeriodId,
    tagGroupId: null,
  });

  const onOpen = React.useCallback(async () => {
    setSelectedRows(RemoteData.loading());

    // sleep for 100ms to allow popover animation to run before doing heavy task.
    await sleep(100);
    const ids = await selection.materializeKeys(async () =>
      (await handleFetchFormIdsAndSchoolIds()).map(SchoolFormKeyRecordFactory)
    );

    setSelectedRows(RemoteData.success(ids));
  }, [handleFetchFormIdsAndSchoolIds, selection, setSelectedRows]);

  const client = useApolloClient();
  const onClose = React.useCallback(async () => {
    if (isDirty) {
      refetchForms();
      client.refetchQueries({
        include: [GET_TAG_GROUPS_FOR_FILTERS],
      });
    }
    resetTags(true);
  }, [client, isDirty, refetchForms, resetTags]);

  return (
    <TagsPopover
      onOpen={onOpen}
      onClose={onClose}
      buttonProps={{ variant: "banner", size: "sm" }}
      onSelectTagGroup={setSelectedTagGroupId}
      isBulkAction={true}
      tagGroups={tagGroups}
      onBackClick={() => resetTags(true)}
      {...tagsProps}
      {...overrideTagsProps}
    />
  );
};
