import { Flex, FormLabel, Switch, Text } from "@chakra-ui/react";
import { useField } from "formik";
import { ChangeEvent, FunctionComponent, useCallback, useMemo } from "react";
import { STATUS_ACTIVE, STATUS_INACTIVE } from "src/constants";
import * as GQL from "src/types/graphql";

type Props = {
  templateType: GQL.message_template_type_enum;
  lotteryAndOffersEnabled: boolean;
};

/**
 * Variant of src/components/Inputs/SwitchInput.tsx
 */
export const StatusSwitch: FunctionComponent<Props> = (props) => {
  const { templateType, lotteryAndOffersEnabled } = props;
  // nested field
  const [{ value }, , { setValue }] = useField(`${templateType}.enabled`);

  const isSwitchDisabled = useMemo(() => {
    if (
      templateType === GQL.message_template_type_enum.OfferAccepted ||
      templateType === GQL.message_template_type_enum.OfferDeclined ||
      templateType === GQL.message_template_type_enum.OfferExtended ||
      templateType === GQL.message_template_type_enum.OfferRevoked
    ) {
      return !lotteryAndOffersEnabled;
    }
    return false;
  }, [templateType, lotteryAndOffersEnabled]);

  const onToggleSwitch = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.checked);
    },
    [setValue]
  );

  return (
    <FormLabel margin="0">
      <Flex alignItems="center" gap={2}>
        <Switch
          isChecked={value}
          onChange={onToggleSwitch}
          isDisabled={isSwitchDisabled}
        />
        <Text>{value ? STATUS_ACTIVE : STATUS_INACTIVE}</Text>
      </Flex>
    </FormLabel>
  );
};
