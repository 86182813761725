import { gql } from "@apollo/client";

export const GET_ORGANIZATION_CONFIG = gql`
  query GetOrganizationConfig(
    $organization_id: uuid!
    $types: [organization_config_type_enum!]
  ) {
    organization_config(
      where: {
        organization_id: { _eq: $organization_id }
        type: { _in: $types }
      }
    ) {
      id
      type
      enabled
      data
    }
  }
`;
