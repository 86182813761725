import * as AF from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import * as AFF from "../formTemplateFilters";

export function fromGQL(
  value: GQL.FormTemplateSectionFragment
): AF.SchoolRankingSection<AF.WithId> {
  if (value.type !== GQL.form_template_section_type_enum.SchoolRankingSection) {
    throw new Error(
      `Invalid section type, expecting a "SchoolRankingSection", but got ${value.type} instead`
    );
  }
  if (!value.schools_ranking_section) {
    throw new Error("Missing schools_ranking_section data");
  }
  return {
    id: value.schools_ranking_section.id,
    type: AF.SchoolRankingSectionType,
    title: value.title,
    description: value.description ?? "",
    key: value.key,
    permissionLevel: value.permission_level,
    exploreUrl: value.schools_ranking_section.explore_url ?? undefined,
    minSchools: value.schools_ranking_section.min_schools ?? undefined,
    maxSchools: value.schools_ranking_section.max_schools ?? undefined,
    filters: AFF.parseFilters(value.filters),
  };
}
