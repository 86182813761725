import { ColumnDef, Row } from "@tanstack/table-core";
import { useCallback } from "react";
import { PaginatedTable } from "src/components/Table/PaginatedTable";
import { SortType } from "src/components/Table/SortButton";
import { useGlossary } from "src/hooks/useGlossary";
import { useOrganization } from "src/hooks/useOrganization";
import useRequiredHasuraRoles from "src/hooks/useRequiredHasuraRoles";
import { useUserPermissions } from "src/hooks/useUserPermissions";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { HasuraRole } from "src/types/hasuraRole";

interface MemberListProps {
  members: GQL.GetMembers_person[];
  limit: number;
  offset: number;
  count: number;
  onFetchMore: (limit: number, offset: number) => void;
  onSort: (columnName: string, sortType: SortType) => void;
}

const toSchoolList = (items: GQL.GetMembers_person): string =>
  items.user?.school_users.map((x) => x?.school?.name || "").join(",") || "";

export const MemberList: React.FC<MemberListProps> = ({
  members,
  limit,
  offset,
  count,
  onFetchMore,
  onSort,
}) => {
  const { glossary } = useGlossary();
  const organization = useOrganization();
  const userPermissions = useUserPermissions();
  const canEdit =
    useRequiredHasuraRoles([HasuraRole.ADMIN, HasuraRole.ORG_ADMIN]) &&
    userPermissions.hasOne("user:update");

  const columns: ColumnDef<GQL.GetMembers_person>[] = [
    {
      id: "id",
      accessorFn: (row) => row.id || "",
    },
    {
      id: "team_member",
      header: "team member",
      accessorFn: (row) =>
        (row?.first_name || "") + " " + (row.last_name || ""),
    },
    {
      id: "email_address",
      header: "email address",
      accessorFn: (row) => row?.email_address || "",
    },
    {
      id: "role",
      header: "role",
      accessorFn: (row) => row.user?.user_group?.label,
    },
    {
      id: "team",
      header: "team",
      accessorFn: (row) => row.person_teams[0]?.team.name,
    },
    {
      id: "schools",
      header: glossary`schools`,
      accessorFn: (row) =>
        row.person_type === GQL.person_type_enum.schoolAdmin
          ? toSchoolList(row)
          : "All",
    },
  ];

  const handleChangeSort = useCallback(
    (headerId: string, sortType: SortType) => {
      onSort(headerId, sortType);
    },
    [onSort]
  );

  function createRowURL(row: Row<GQL.GetMembers_person>): string {
    return organization
      .map((org) => Url.OrgAdmin.Team.editMember(org, row.original.id || ""))
      .withDefault("#");
  }

  if (members.length === 0)
    return <span>You do not have any team members</span>;

  return (
    <PaginatedTable<GQL.GetMembers_person>
      data={members}
      columns={columns}
      limit={limit}
      offset={offset}
      count={count}
      onFetchMore={onFetchMore}
      sortableColumnIds={["role", "email_address"]}
      onChangeSort={handleChangeSort}
      createRowURL={canEdit ? createRowURL : undefined}
    />
  );
};
