import { Box, Flex, HStack, Spinner, useToast, VStack } from "@chakra-ui/react";
import { useOutletContext } from "react-router-dom";
import { GQLRemoteDataView } from "src/components/Layout/RemoteDataView";
import { useOrganization } from "src/hooks/useOrganization";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import * as GQL from "src/types/graphql";
import { EnrollmentPeriodOutletContext } from "../../Edit";
import { BulkUploadEnrollmentSchools } from "./BulkUploadEnrollmentSchools";
import { ExportEnrollmentPeriodSchoolGradesButton } from "./ExportEnrollmentPeriodSchoolGradesButton";
import { GET_ENROLLMENT_SCHOOLS_AND_CONFIGS } from "./graphql/queries";
import { SchoolGradeProgramsCard } from "./SchoolGradeProgramsCard";
import { SchoolGradesCard } from "./SchoolGradesCard";

export const EnrollmentSchoolsTab: React.FC = () => {
  const organization = useOrganization();
  const { enrollmentPeriod } =
    useOutletContext<EnrollmentPeriodOutletContext>();
  const toast = useToast();

  const { remoteData, refetch } = useRemoteDataQuery<
    GQL.GetEnrollmentSchoolsAndConfigs,
    GQL.GetEnrollmentSchoolsAndConfigsVariables
  >(GET_ENROLLMENT_SCHOOLS_AND_CONFIGS, {
    variables: {
      organization_id: organization.map((org) => org.id).toNullable(),
      order_by: { name: GQL.order_by.asc },
    },
    skip: !organization.hasData(),
    notifyOnNetworkStatusChange: true,
  });

  const handleBulkUploadComplete = () => {
    refetch();
    toast({
      id: "updload-grades-success",
      title: "Grades uploaded",
      isClosable: true,
      status: "success",
    });
  };

  if (!organization.hasData()) {
    return null;
  }

  return (
    <VStack align="left">
      <HStack justifyContent="end">
        <Box>
          <BulkUploadEnrollmentSchools onComplete={handleBulkUploadComplete} />
        </Box>
        <Box>
          <ExportEnrollmentPeriodSchoolGradesButton
            organizationId={organization.data.id}
            enrollmentPeriodId={enrollmentPeriod.id}
          />
        </Box>
      </HStack>

      {remoteData.isLoading() ? (
        <Flex
          position="relative"
          top="0"
          left="0"
          width="100%"
          height="40rem"
          alignItems="center"
          justifyContent="center"
        >
          <Spinner color="primary.500" size="lg" />
        </Flex>
      ) : (
        <GQLRemoteDataView remoteData={remoteData}>
          {({
            school: schools,
            grade_config: gradeConfigs,
            program_group: programGroups,
          }) => (
            <VStack gap={6}>
              {schools.map((school) => {
                // For now, assume there is 0 or 1 program group per org.
                const programs = programGroups[0]?.programs;
                return programs && programs.length > 0 ? (
                  <SchoolGradeProgramsCard
                    schoolId={school.id}
                    schoolName={school.name}
                    enrollmentPeriodId={enrollmentPeriod.id}
                    gradeConfigs={gradeConfigs}
                    programConfigs={programs}
                    key={school.id}
                  />
                ) : (
                  <SchoolGradesCard
                    schoolId={school.id}
                    schoolName={school.name}
                    enrollmentPeriodId={enrollmentPeriod.id}
                    gradeConfigs={gradeConfigs}
                    key={school.id}
                  />
                );
              })}
            </VStack>
          )}
        </GQLRemoteDataView>
      )}
    </VStack>
  );
};
