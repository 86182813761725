import * as Draft from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/types/draft";
import { SectionFormType } from "./SectionFormDialog";

export const createNewSection = (
  values: SectionFormType
): Draft.NewSection => ({
  id: window.crypto.randomUUID(),
  disclaimerFormSectionId: window.crypto.randomUUID(),
  title: values.title,
  type: values.type,
  description: values.description,
  key: values.key,
  questions: [],
  isDraft: true,
  isNew: true,
  permissionLevel: values.permissionLevel,
  disclaimer: "",
});
