import { useToast } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { useLazyRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { FormikOrganizationForm, OrganizationForm } from "./Form";
import { CREATE_ORGANIZATION } from "./graphql/mutations";
import { GET_ORGANIZATIONS } from "./graphql/queries";

export const New = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [createOrganization, { remoteData }] = useRemoteDataMutation<
    GQL.CreateOrganization,
    GQL.CreateOrganizationVariables
  >(CREATE_ORGANIZATION);
  const [getOrganizations, refetchStatus] =
    useLazyRemoteDataQuery<GQL.GetOrganizations>(GET_ORGANIZATIONS, {
      fetchPolicy: "network-only",
    });

  const handleSubmit = React.useCallback(
    async (values: FormikOrganizationForm) => {
      try {
        await createOrganization({
          variables: {
            organization: {
              name: values.name,
              path: values.path,
              timezone_name: values.timezone_name,
              organization_configs: {
                data: Object.entries(values.organization_configs).map(
                  ([key, config]) => {
                    return {
                      type: key as GQL.organization_config_type_enum,
                      enabled: config.enabled,
                      data: config.data,
                    };
                  }
                ),
              },
            },
          },
        });
        await getOrganizations();
        navigate(Url.Admin.Organizations.index());
      } catch (error) {
        console.error(error);
        const id = "NewOrganization";
        if (!toast.isActive(id))
          toast({
            id,
            title: "Error creating organization",
            description:
              "Please try again later or report the problem to our support team.",
            isClosable: true,
            status: "error",
          });
      }
    },
    [createOrganization, getOrganizations, navigate, toast]
  );

  return (
    <OrganizationForm
      onSubmit={handleSubmit}
      submitting={remoteData.isLoading() || refetchStatus.called}
    />
  );
};
