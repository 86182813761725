import React from "react";
import * as GQL from "src/types/graphql";
import { isNotNull } from "./predicates";

export enum ContactType {
  Email = "email",
  Phone = "phone",
  All = "all",
  None = "none",
}

type Person = {
  email_address: string | null;
  phone_number: string | null;
  email_okay: boolean | null;
  sms_okay: boolean | null;
};
type ContactInformation = Email | Phone | All | None;
type Email = {
  type: ContactType.Email;
  email_address: string;
};
type Phone = {
  type: ContactType.Phone;
  phone_number: string;
};
type All = {
  type: ContactType.All;
  phone_number: string;
  email_address: string;
};
type None = {
  type: ContactType.None;
};
export function getContactInformation(person: Person): ContactInformation {
  if (
    person.phone_number &&
    person.sms_okay &&
    person.email_address &&
    person.email_okay
  ) {
    return {
      type: ContactType.All,
      phone_number: person.phone_number,
      email_address: person.email_address,
    };
  }

  if (person.email_address && person.email_okay) {
    return {
      type: ContactType.Email,
      email_address: person.email_address,
    };
  }

  if (person.phone_number && person.sms_okay) {
    return {
      type: ContactType.Phone,
      phone_number: person.phone_number,
    };
  }

  return {
    type: ContactType.None,
  };
}

export function view(
  contactInformation: ContactInformation,
  customRenderer?: {
    email?: (email: string) => React.ReactNode;
    phone?: (phone: string) => React.ReactNode;
    none?: React.ReactNode;
  }
): React.ReactNode {
  switch (contactInformation.type) {
    case ContactType.Email:
      return customRenderer?.email
        ? customRenderer.email(contactInformation.email_address)
        : contactInformation.email_address;

    case ContactType.Phone:
      return customRenderer?.phone
        ? customRenderer.phone(contactInformation.phone_number)
        : contactInformation.phone_number;

    case ContactType.All:
      const phoneNumber = customRenderer?.phone
        ? customRenderer.phone(contactInformation.phone_number)
        : contactInformation.phone_number;
      const email = customRenderer?.email
        ? customRenderer.email(contactInformation.email_address)
        : contactInformation.email_address;
      return `${phoneNumber}, ${email}`;

    case ContactType.None:
      return customRenderer?.none ?? null;
    default:
      const _exhaustiveCheck: never = contactInformation;
      return _exhaustiveCheck;
  }
}

type PartialPersonType = {
  id: uuid;
  person_type?: GQL.person_type_enum | null;
  user?: GQL.PersonFragment_user | null;
} & Partial<GQL.PersonBasicFragment>;

type PersonRelationshipsType = {
  id: uuid;
  first_relationship: { second: PartialPersonType }[];
  second_relationship: { first: PartialPersonType }[];
};

export function isEligibleForMessaging(parent: PartialPersonType) {
  return parent.email_okay || parent.sms_okay;
}

// get guardian from either first or second relationship
export function getGuardians(
  applicant?: PersonRelationshipsType
): PartialPersonType[] {
  const guardians: PartialPersonType[] = [];
  if (!applicant) return guardians;

  for (const relationship of applicant.first_relationship ?? []) {
    if (relationship.second.person_type === GQL.person_type_enum.guardian)
      guardians.push(relationship.second);
  }

  for (const relationship of applicant.second_relationship ?? []) {
    if (relationship.first.person_type === GQL.person_type_enum.guardian)
      guardians.push(relationship.first);
  }

  return guardians;
}

// get applicants from either first or second relationship
export function getApplicants(
  guardian?: PersonRelationshipsType
): PartialPersonType[] {
  const applicants: PartialPersonType[] = [];
  if (!guardian) return applicants;

  for (const relationship of guardian.first_relationship ?? []) {
    if (relationship.second.person_type === GQL.person_type_enum.applicant)
      applicants.push(relationship.second);
  }

  for (const relationship of guardian.second_relationship ?? []) {
    if (relationship.first.person_type === GQL.person_type_enum.applicant)
      applicants.push(relationship.first);
  }

  return applicants;
}

// gets the guardians that have eligbile messaging information (sms/email)
export function getRecipientsForApplicant(applicant: PersonRelationshipsType) {
  return getGuardians(applicant).filter(
    (parent) => isNotNull(parent) && isEligibleForMessaging(parent)
  );
}

type Attendance = {
  attendance_status?: GQL.attendance_status_type_enum | null;
  school_id?: string | null;
  description?: string | null;
};
export function formatAttendingSchool<T extends Attendance>({
  attendance_status,
  school_id,
  description,
  ...data
}: T) {
  return {
    ...data,
    attendance_status: attendance_status ?? null,
    school_id:
      (attendance_status === GQL.attendance_status_type_enum.CURRENT &&
        school_id) ||
      null,
    description:
      (attendance_status === GQL.attendance_status_type_enum.NEW &&
        description) ||
      null,
  };
}
