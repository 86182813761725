import * as AF from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import { getFormikInitialValues } from "./answer";
import { getCompleteApplicableQuestions } from "./question";
import { validateRequiredQuestions } from "../formTemplateValidations";
import { SelectedSchoolIds } from "./generalSection/question";

export function toQuestions(
  section: AF.Section<AF.WithId> | undefined
): AF.Question<AF.WithId>[] {
  if (!section) return [];
  switch (section.type) {
    case "GeneralSection":
    case "PreRankingSection":
      return section.questions;
    case "DisclaimerSection":
    case "SchoolRankingSection":
      return [];

    default:
      const _exhaustiveCheck: never = section;
      return _exhaustiveCheck;
  }
}

/**
 * Determines if a form section is applicable to a user based on their answers
 * and their school rankings.
 */
export function isSectionApplicable(
  section: AF.Section<AF.WithId> | undefined,
  applicationAnswers: GQL.FormAnswerFragment[],
  gradesAnswers: GQL.GradesAnswersFragment[],
  addressAnswers: GQL.AddressAnswersFragment[],
  customQuestionAnswers: GQL.CustomQuestionAnswersFragment[],
  selectedSchoolIds: SelectedSchoolIds
): boolean {
  if (!section) {
    return false;
  }

  if (!("questions" in section)) {
    /*
     * Sections without questions are considered applicable without applying
     * any other filtering criteria. These include:
     *
     * - School Ranking Section
     * - Disclaimer Section
     */
    return true;
  }

  const initialValues = getFormikInitialValues(
    section.questions,
    applicationAnswers,
    gradesAnswers,
    addressAnswers,
    customQuestionAnswers
  );

  const applicableQuestions = getCompleteApplicableQuestions(
    section.questions,
    initialValues,
    selectedSchoolIds
  );

  return applicableQuestions.length > 0;
}

export function getMissingRequiredQuestions(
  section: AF.Section<AF.WithId> | undefined,
  applicationAnswers: GQL.FormAnswerFragment[],
  gradesAnswers: GQL.GradesAnswersFragment[],
  addressAnswers: GQL.AddressAnswersFragment[],
  customQuestionAnswers: GQL.CustomQuestionAnswersFragment[],
  disclaimerAnswers: GQL.GetFormAnswersById_form_disclaimer[],
  selectedSchoolIds: SelectedSchoolIds
) {
  if (!section) {
    return [];
  }

  // Check if disclaimer section has signature
  if (section.type === GQL.form_template_section_type_enum.DisclaimerSection) {
    return disclaimerAnswers && disclaimerAnswers.length > 0
      ? []
      : [GQL.form_template_section_type_enum.DisclaimerSection];
  }

  // Check if school rank section has school ranks
  if (
    section.type === GQL.form_template_section_type_enum.SchoolRankingSection
  ) {
    return selectedSchoolIds.rankedSchoolIds &&
      selectedSchoolIds.rankedSchoolIds.length > 0
      ? []
      : [GQL.form_template_section_type_enum.SchoolRankingSection];
  }

  const formValues = getFormikInitialValues(
    section.questions,
    applicationAnswers,
    gradesAnswers,
    addressAnswers,
    customQuestionAnswers
  );

  const missingAnswerRequiredQuestions = validateRequiredQuestions(
    section.questions,
    selectedSchoolIds,
    formValues
  );

  return missingAnswerRequiredQuestions;
}
