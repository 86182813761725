import * as Turf from "@turf/helpers";
import Immutable from "immutable";
import _ from "lodash";
import {
  areMatchingAddresses,
  BaseAddress,
  BaseAddressSchema,
} from "src/components/Inputs/Address/Book";
import * as Format from "src/services/format";
import { transformGeocoderAddressComponentsToAddress } from "src/services/googlePlaces/transforms";
import * as GQL from "src/types/graphql";
import { z } from "zod";
import * as GeoJSON from "./geoJSON";
import {
  AssociationLevel,
  Boundary,
  GeoEligibility,
  SchoolBoundaries,
  Tag,
} from "./schemas";

export function fromGQL(
  gql: GQL.GetBoundariesByEnrollmentPeriod
): Immutable.Map<uuid, SchoolBoundaries> {
  return gql.geojson.reduce(
    addGeoJSONTOMap,
    Immutable.Map<uuid, SchoolBoundaries>()
  );
}

function tagFromGQL(
  gql: GQL.GetBoundariesByEnrollmentPeriod["geojson"][0]["boundary_tags"][0]
): Tag {
  return {
    name: gql.name,
    rule: gql.is_inside ? "Inside" : "Outside",
  };
}

function addGeoJSONTOMap(
  map: Immutable.Map<uuid, SchoolBoundaries>,
  gql: GQL.GetBoundariesByEnrollmentPeriod["geojson"][0]
): Immutable.Map<uuid, SchoolBoundaries> {
  const geoJSON = GeoJSON.safeParse(gql.geojson);
  if (!geoJSON.success) {
    console.error(geoJSON.error);
    return map;
  }

  const tags: Tag[] = gql.boundary_tags.map(tagFromGQL);

  return gql.school_boundaries.reduce(
    (
      map: Immutable.Map<uuid, SchoolBoundaries>,
      schoolBoundary: GQLSchoolBoundary
    ) => {
      return addSchoolBoundaryToMap(
        map,
        schoolBoundary,
        geoJSON.data,
        gql.geojson_property_key,
        tags
      );
    },
    map
  );
}

type GQLSchoolBoundary =
  GQL.GetBoundariesByEnrollmentPeriod["geojson"][0]["school_boundaries"][0];
function addSchoolBoundaryToMap(
  map: Immutable.Map<uuid, SchoolBoundaries>,
  schoolBoundary: GQLSchoolBoundary,
  geoJson: Turf.GeoJSONObject,
  geoJsonPropertyKey: string,
  tags: Tag[]
): Immutable.Map<uuid, SchoolBoundaries> {
  const schoolId = schoolBoundary.school_id;
  const grade = schoolBoundary.grade;
  const associationLevel: AssociationLevel = !grade ? "school" : "grade";

  const geoEligibility = geoEligibilityFromGQL(
    schoolBoundary.geo_eligibilities
  );
  const geoJsonId = schoolBoundary.geojson_id;
  const geoJsonPropertyValue = schoolBoundary.geojson_property_value;

  const geometry = GeoJSON.safeGetGeometry(geoJson, {
    key: geoJsonPropertyKey,
    value: geoJsonPropertyValue,
  });

  if (!geometry.success) {
    console.error(geometry.error);
    return map;
  }

  const boundary: Boundary = {
    geoJsonId,
    geoJsonPropertyValue,
    geometry: geometry.data,
    geoEligibility,
    tags,
  };

  const key = grade?.id ?? schoolId;
  return map.update(
    key,
    {
      associationLevel,
      boundaries: [boundary],
    },
    (existing: SchoolBoundaries): SchoolBoundaries => {
      // check if gradeId is actually belong to schoolId
      if (grade && grade?.school_id !== schoolId) {
        throw new Error(
          `Invalid boundary data, gradeId: ${grade?.id} doesn't belong to schoolId: ${schoolId}`
        );
      }

      if (associationLevel !== existing.associationLevel) {
        // connection level is not the same. We have bad data!
        throw new Error(
          "Invalid boundary data, mixing grade level and school level boundary"
        );
      }

      return {
        associationLevel,
        boundaries: existing.boundaries.concat([boundary]),
      };
    }
  );
}

type GQLGeoEligibilities = GQLSchoolBoundary["geo_eligibilities"];
function geoEligibilityFromGQL(gql: GQLGeoEligibilities): GeoEligibility {
  const geoEligibility = gql[0];
  if (geoEligibility === undefined) {
    return "NotApplicable";
  }

  return geoEligibility.is_eligible_inside
    ? "EligibleInside"
    : "EligibleOutside";
}

export function isEligibleToSchool(
  schoolId: uuid,
  gradeId: uuid | undefined,
  location: Turf.Position | undefined,
  boundariesMap: Immutable.Map<uuid, SchoolBoundaries>
): boolean {
  const gradeBoundaries: SchoolBoundaries | undefined = gradeId
    ? boundariesMap.get(gradeId)
    : undefined;
  const schoolBoundaries: SchoolBoundaries | undefined =
    boundariesMap.get(schoolId);

  if (gradeBoundaries === undefined && schoolBoundaries === undefined) {
    // no boundaries, no need to check
    return true;
  }

  const boundaries = (schoolBoundaries?.boundaries ?? []).concat(
    gradeBoundaries?.boundaries ?? []
  );

  if (!location) {
    // no location, automatic ineligible
    return false;
  }

  for (const boundary of boundaries) {
    if (isEligibleToBoundary(boundary, location)) {
      // we just need 1 eligibility for the location to be eligible.
      return true;
    }
  }

  return false;
}

export function isEligibleToBoundary(
  boundary: Boundary,
  location: Turf.Position
): boolean {
  switch (boundary.geoEligibility) {
    case "EligibleInside":
      return GeoJSON.isInside(boundary.geometry, location).recoverWithDefault(
        (error) => {
          console.error(error);
          return true;
        }
      );

    case "EligibleOutside":
      return GeoJSON.isOutside(boundary.geometry, location).recoverWithDefault(
        (error) => {
          console.error(error);
          return true;
        }
      );

    case "NotApplicable":
      return true;

    default:
      const _exhaustiveCheck: never = boundary.geoEligibility;
      return _exhaustiveCheck;
  }
}

type School = {
  id: uuid;
  grades?: {
    id: uuid;
    grade_config_id: uuid;
  }[];
};
export function getBoundaryTags(
  school: School,
  gradeConfigId: uuid | undefined,
  location: Turf.Position | undefined,
  boundariesMap: Immutable.Map<uuid, SchoolBoundaries>
): string[] {
  if (!location) {
    return [];
  }

  const gradeId = school.grades?.find(
    (grade) => grade.grade_config_id === gradeConfigId
  )?.id;

  const gradeBoundaries: SchoolBoundaries | undefined = gradeId
    ? boundariesMap.get(gradeId)
    : undefined;
  const schoolBoundaries: SchoolBoundaries | undefined = boundariesMap.get(
    school.id
  );

  if (gradeBoundaries === undefined && schoolBoundaries === undefined) {
    // no boundaries, no need to check
    return [];
  }

  const boundaries = (schoolBoundaries?.boundaries ?? []).concat(
    gradeBoundaries?.boundaries ?? []
  );

  const tags = boundaries
    .flatMap((boundary) =>
      (boundary.tags ?? []).map((tag) => ({ tag, boundary }))
    )
    .filter(({ tag, boundary }) => {
      return tag.rule === "Inside"
        ? GeoJSON.isInside(boundary.geometry, location).withDefault(false)
        : GeoJSON.isOutside(boundary.geometry, location).withDefault(false);
    });

  return _.uniq(tags.map(({ tag }) => tag.name));
}

export async function addressLookup(
  geocoder: google.maps.Geocoder,
  data: BaseAddress | undefined
): Promise<Turf.Position | undefined> {
  const address = data;
  if (!address) {
    return undefined;
  }

  const result = await geocoder.geocode({
    address: Format.address(address),
  });

  const location = result.results[0]?.geometry.location;
  if (!location) {
    return undefined;
  }

  return [location.lng(), location.lat()];
}

export async function addressLookupFromAnswers(
  geocoder: google.maps.Geocoder,
  address: BaseAddress | undefined
): Promise<Turf.Position | undefined> {
  if (address === undefined) {
    return undefined;
  }

  return addressLookup(geocoder, address);
}

export interface AddressValidationResult {
  wasFound: boolean;
  isValid: boolean;
  originalAddress: BaseAddress;
  suggestedAddress?: BaseAddress;
}

/**
 * Use Google Places API text search to approximately validate an address.
 * The entered address is compared to the address returned from the API response.
 *
 * TODO: https://app.asana.com/0/1200299036901049/1205725330742546
 * Google recommends using their Address Validation API instead of using Places API and "address_components"
 * https://developers.google.com/maps/documentation/address-validation/understand-response
 * Conventionally we'd use the recommended "verdict" property to construct our own validation logic.
 * Alternatively, consider Smarty https://www.smarty.com/products/us-address-verification
 */
export async function getSuggestedAddress(
  geocoder: google.maps.Geocoder,
  address: BaseAddress
): Promise<AddressValidationResult> {
  const failedAddressValidationResult = {
    wasFound: false,
    isValid: false,
    originalAddress: address,
  };

  try {
    const { results } = await geocoder.geocode({
      address: Format.address(address),
    });

    if (results.length === 0 || results[0] === undefined) {
      return failedAddressValidationResult;
    }

    const suggestedAddress = results[0];
    const parsedAddress = BaseAddressSchema.parse(
      transformGeocoderAddressComponentsToAddress(
        suggestedAddress.address_components
      )
    );

    // successful parse means the returned address is well formed and eligible to be suggested
    const addressesMatch = areMatchingAddresses(address, parsedAddress);

    // exact match, do not prompt the user for action
    if (addressesMatch) {
      return { wasFound: true, isValid: true, originalAddress: address };
    }

    // non-matching means that the returned address should be suggested
    return {
      wasFound: true,
      isValid: false,
      originalAddress: address,
      suggestedAddress: parsedAddress,
    };
  } catch (error) {
    // encountered a incomplete address returned by the Places API
    if (error instanceof z.ZodError) {
      return failedAddressValidationResult;
    }

    // @react-google-maps/api throws an exception when there are no results
    // this conflicts with Google's Places API documentation
    if (error instanceof google.maps.MapsRequestError) {
      return failedAddressValidationResult;
    }
  }

  return failedAddressValidationResult;
}
