import { useCallback, useEffect, useMemo } from "react";
import {
  ADMISSION_TABS,
  DropoffFormTab,
  FilterTypes,
  FormTabsTypes,
  getAdmissionTab,
  SearchAndFilterTypes,
  Tab,
  VisibilityOptions,
} from "src/constants";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import * as GQL from "src/types/graphql";
import { z } from "zod";
import { FiltersByTab } from "../formFilters/utils";
import { GET_PREVIOUS_FORM_TEMPLATE } from "../graphql/queries";
import { useIsOfferEnabled } from "src/hooks/useIsOfferEnabled";
import { useOrganization } from "src/hooks/useOrganization";
import { useFormTemplates } from "src/components/Providers/FormTemplateProvider";

export type UseFormsListProps = {
  formTemplateId: uuid | undefined;
  searchParams: URLSearchParams;
  setSearchParamsAndClearSelection: (a: any) => void; // TODO replace any
  setFormListImportData: (l: any[]) => void; // TODO replace any
  searchParamDefaultOverrides?: SearchParamDefaultOverrides;
};

export type FormsListTab = {
  current: Tab;
  currentIndex: number;
  onChange: (tab: Tab) => void;
  visibleTabs: readonly Tab[];
};

// allows the caller to override the defaults specified in the useFormsListTab hook
export type SearchParamDefaultOverrides = {
  [SearchAndFilterTypes.Visibility]?: string;
};

const FormTabParser = z.nativeEnum(FormTabsTypes);

export function useFormsListTab({
  formTemplateId,
  searchParams,
  setSearchParamsAndClearSelection,
  setFormListImportData,
  searchParamDefaultOverrides,
}: UseFormsListProps): FormsListTab {
  useEffect(() => {
    const tab = getTabByParams();
    if (!tab || !tab.isVisible) {
      onChange(ADMISSION_TABS[0]);
    }
  });

  // On tab change, verifies if there are filters that should be cleared in the SearchParams
  // e.g. there's some filters which exists on the forms/all tabs and don't exist on the offers/waitlist tab
  const onChange = useCallback(
    (newTab: Tab) => {
      searchParams.set(SearchAndFilterTypes.Tab, newTab.type);

      if (newTab.type === FormTabsTypes.DropoffForms) {
        searchParams.set(
          SearchAndFilterTypes.DropoffFormTemplateId,
          newTab.formTemplateId
        );
      } else {
        searchParams.delete(SearchAndFilterTypes.DropoffFormTemplateId);
      }

      // if moving away from Imports tab, then clear data and hide tab
      if (newTab.key !== FormTabsTypes.FormListImports) {
        setFormListImportData([]);

        const formListImportsTab = getAdmissionTab(
          FormTabsTypes.FormListImports
        );

        if (!formListImportsTab) {
          throw new Error("Something went wrong!");
        }
      }

      const filtersToBeClear = Object.keys(FilterTypes).filter(
        (filterKey) =>
          !FiltersByTab[newTab.key]?.includes(
            FilterTypes[filterKey as keyof typeof FilterTypes]
          )
      );

      searchParams.delete(SearchAndFilterTypes.SubStatus);

      filtersToBeClear.forEach((filter) => {
        searchParams.delete(
          SearchAndFilterTypes[filter as keyof typeof SearchAndFilterTypes]
        );
      });

      searchParams.set(SearchAndFilterTypes.Offset, "0");

      if (!searchParams.get(SearchAndFilterTypes.Visibility)) {
        searchParams.set(
          SearchAndFilterTypes.Visibility,
          searchParamDefaultOverrides?.[SearchAndFilterTypes.Visibility] ??
            VisibilityOptions.Visible
        );
      }

      setSearchParamsAndClearSelection(searchParams);
    },
    [
      searchParams,
      setFormListImportData,
      setSearchParamsAndClearSelection,
      searchParamDefaultOverrides,
    ]
  );

  const { remoteData: getDropoffFormsData } = useRemoteDataQuery<
    GQL.GetPreviousFormTemplate,
    GQL.GetPreviousFormTemplateVariables
  >(GET_PREVIOUS_FORM_TEMPLATE, {
    variables: { form_template_id: formTemplateId ?? "" },
    skip: formTemplateId === undefined,
    fetchPolicy: "network-only",
  });

  const dropoffFormTabs: readonly DropoffFormTab[] = useMemo(
    () =>
      getDropoffFormsData
        .map((data) =>
          data.form_template.map((formTemplate) => {
            return {
              type: FormTabsTypes.DropoffForms as const,
              key: formTemplate.id,
              formTemplateId: formTemplate.id,
              label: formTemplate.name,
              isVisible: true,
            };
          })
        )
        .withDefault([]),
    [getDropoffFormsData]
  );

  const allTabs: readonly Tab[] = useMemo(() => {
    return [...dropoffFormTabs, ...ADMISSION_TABS];
  }, [dropoffFormTabs]);

  const current = useMemo((): Tab => {
    const rawTab = searchParams.get(SearchAndFilterTypes.Tab);
    const formTabType = FormTabParser.safeParse(rawTab);
    const defaultTab = ADMISSION_TABS[0];

    if (formTabType.success) {
      switch (formTabType.data) {
        case FormTabsTypes.DropoffForms:
          const dropoffFormTemplateId = searchParams.get(
            SearchAndFilterTypes.DropoffFormTemplateId
          );
          return (
            dropoffFormTabs.find(
              (tab) =>
                tab.type === FormTabsTypes.DropoffForms &&
                tab.formTemplateId === dropoffFormTemplateId
            ) ?? defaultTab
          );

        default:
          return (
            ADMISSION_TABS.find((tab) => tab.key === formTabType.data) ??
            defaultTab
          );
      }
    }

    return defaultTab;
  }, [dropoffFormTabs, searchParams]);

  const currentIndex: number | undefined = useMemo(() => {
    const currentKey: string =
      current.type === FormTabsTypes.DropoffForms
        ? current.formTemplateId
        : current.key;
    return allTabs.findIndex((tab) => tab.key === currentKey && tab.isVisible);
  }, [allTabs, current]);

  const organization = useOrganization();
  const { selectedNavFormTemplate } = useFormTemplates();
  const isOfferEnabled = useIsOfferEnabled(
    organization,
    selectedNavFormTemplate
  );

  const visibleTabs: readonly Tab[] = useMemo(() => {
    return allTabs.filter((tab) => {
      if (
        tab.key === FormTabsTypes.Offers ||
        tab.key === FormTabsTypes.Waitlists
      ) {
        return isOfferEnabled && tab.isVisible;
      }

      return tab.isVisible;
    });
  }, [allTabs, isOfferEnabled]);

  const getTabByParams = useCallback(() => {
    const searchParamsTab = searchParams.get(SearchAndFilterTypes.Tab);
    const dropoffFormTemplateId = searchParams.get(
      SearchAndFilterTypes.DropoffFormTemplateId
    );

    if (searchParamsTab === FormTabsTypes.DropoffForms) {
      return dropoffFormTabs.find(
        (tab) => tab.formTemplateId === dropoffFormTemplateId
      );
    }

    return allTabs.find((tab) => tab.type === searchParamsTab);
  }, [allTabs, dropoffFormTabs, searchParams]);

  return { current, currentIndex, onChange, visibleTabs };
}
