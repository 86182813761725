import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
} from "@chakra-ui/react";
import { Form, Formik } from "formik";
import React from "react";
import { InputWithValidationControl } from "src/components/Inputs/InputWithValidationControl";
import { SwitchInput } from "src/components/Inputs/SwitchInput";
import { validateWithZod } from "src/services/formValidations";
import { maybePluralize } from "src/services/format";
import * as GQL from "src/types/graphql";
import { z } from "zod";
import { DeleteTagGroup } from "./DeleteTagGroup";
import { WithRequiredHasuraRoles } from "src/components/Permissions/WithRequiredHasuraRoles";
import { HasuraRole } from "src/types/hasuraRole";
import { WithUserPermissions } from "src/components/Permissions/WithUserPermissions";

const FormSchema = z.object({
  name: z.string().min(1, "Group name is required"),
  is_external: z.boolean().default(false),
  max_tags: z.number().nullable(),
});

export type TagGroupFormType = z.infer<typeof FormSchema>;
type ManageTagGroupProps = {
  isOpen: boolean;
  tagGroup?: GQL.GetTagGroupsByEnrollmentPeriod_tag_group;
  onCancelButtonClick: () => void;
  onNewTagGroupSaving?: (
    tagGroup: GQL.tag_group_insert_input,
    onSuccess: () => void
  ) => Promise<void>;
  onEditTagGroupSaving?: (
    id: uuid,
    tagGroup: GQL.tag_group_insert_input,
    onSuccess: () => void
  ) => Promise<void>;
  error?: string;
  onClose: () => void;
  onDeleteTagGroupSaving?: (id: uuid, onSuccess: () => void) => Promise<void>;
};
export const ManageTagGroupDialog: React.FC<ManageTagGroupProps> = ({
  tagGroup,
  onCancelButtonClick,
  onNewTagGroupSaving,
  onEditTagGroupSaving,
  error,
  isOpen,
  onClose,
  onDeleteTagGroupSaving,
}) => {
  const [hasLimit, setHasLimit] = React.useState<boolean>(!!tagGroup?.max_tags);
  const initialValues = {
    name: tagGroup?.name ?? "",
    is_external: tagGroup?.is_external ?? false,
    max_tags: tagGroup?.max_tags ?? null,
  };

  const isExternalText =
    "Tags in this group are visible to parents. Parents will be able to see both their name and description of tags.";
  const notExternalText =
    "Tags in this group are only visible to admins within your organization.";

  const newTagGroupUniqueError = "Tag group with that name already exists.";
  const updateTagGroupUniqueError =
    "There must be a name for this tag group that does not match the name of any other tag groups.";

  const handleSubmit = (values: TagGroupFormType) => {
    if (tagGroup?.id && onEditTagGroupSaving) {
      return onEditTagGroupSaving(tagGroup.id, values, onClose);
    } else if (onNewTagGroupSaving) {
      onNewTagGroupSaving(values, onClose);
    }
  };

  return (
    <Formik<TagGroupFormType>
      initialValues={initialValues}
      enableReinitialize
      onSubmit={handleSubmit}
      validate={validateWithZod(FormSchema)}
    >
      {({ values, setFieldValue, errors, resetForm }) => {
        const handleHasLimitChange = () => {
          setFieldValue("max_tags", hasLimit ? null : 1);
          setHasLimit(!hasLimit);
        };

        const handleLimitChange = (value: number) => {
          if (!value) return setFieldValue("max_tags", null);
          setFieldValue("max_tags", value);
        };

        if (error === "Tag group already exists") {
          errors.name = tagGroup
            ? updateTagGroupUniqueError
            : newTagGroupUniqueError;
        }

        const handleCancelClick = () => {
          resetForm();
          onCancelButtonClick();
        };
        return (
          <Modal isOpen={isOpen} onClose={handleCancelClick}>
            <ModalOverlay />
            <ModalContent as={Form}>
              <ModalHeader>
                {tagGroup?.name ? "Edit tag group" : "Add tag group"}
              </ModalHeader>
              <ModalBody as={Flex} direction="column" gap={2} minH={330}>
                <FormControl>
                  <FormLabel>Group name</FormLabel>
                  <InputWithValidationControl
                    id="name"
                    name="name"
                    isRequired={true}
                  />
                </FormControl>

                <Flex direction="column">
                  <Text fontSize="sm" color="gray.700">
                    Visibility
                  </Text>
                  <SwitchInput<boolean>
                    label=""
                    name="is_external"
                    checked={[true, "Visible to parents"]}
                    unchecked={[false, "Not visible to parents"]}
                    containerProps={{ direction: "row", gap: 2 }}
                  />
                  <Text fontSize="xs" color="gray.600" fontWeight="400">
                    {values.is_external ? isExternalText : notExternalText}
                  </Text>
                </Flex>

                <Flex direction="column" gap={4}>
                  <Text fontSize="sm" color="gray.700">
                    Limit
                  </Text>
                  <Flex direction="column" gap={2}>
                    <Flex gap={2}>
                      <Switch
                        name="has_limit"
                        isChecked={hasLimit}
                        onChange={handleHasLimitChange}
                        isDisabled
                      />
                      <Text fontSize="sm">
                        {hasLimit ? "Has limit" : "Has no limit"}
                      </Text>
                    </Flex>
                    {hasLimit && (
                      <Input
                        type="number"
                        value={values.max_tags ?? ""}
                        onChange={(e) =>
                          handleLimitChange(e.target.valueAsNumber)
                        }
                      />
                    )}
                    <Text fontSize="xs" color="gray.600" fontWeight="400">
                      {hasLimit
                        ? `There can be, at most, ${
                            values.max_tags ?? 0
                          } ${maybePluralize(
                            values.max_tags ?? 0,
                            "tag"
                          )} selected for this group.`
                        : "There can be any number of tags selected for this group."}
                    </Text>
                  </Flex>
                </Flex>

                {tagGroup?.id && !tagGroup.is_default && (
                  <WithRequiredHasuraRoles
                    roles={[HasuraRole.ADMIN, HasuraRole.ORG_ADMIN]}
                  >
                    <WithUserPermissions permissions={["tag_group:delete"]}>
                      <Flex direction="column" gap={4}>
                        <Text fontSize="sm" color="gray.700">
                          Delete tag group
                        </Text>
                        <Flex direction="column" gap={2}>
                          <Flex>
                            <DeleteTagGroup
                              tagGroup={tagGroup}
                              onClose={onClose}
                              onDeleteTagGroupSaving={onDeleteTagGroupSaving}
                            />
                          </Flex>

                          <Text fontSize="xs" color="gray.600" fontWeight="400">
                            Permanantly remove this tag group. All tags within
                            the group will be moved to the “Internal Tags”
                            group.
                          </Text>
                        </Flex>
                      </Flex>
                    </WithUserPermissions>
                  </WithRequiredHasuraRoles>
                )}
              </ModalBody>
              <ModalFooter gap={2}>
                <Button
                  variant="ghost"
                  colorScheme="gray"
                  onClick={handleCancelClick}
                >
                  Cancel
                </Button>
                <Button type="submit">
                  {tagGroup?.name ? "Update" : "Add"}
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        );
      }}
    </Formik>
  );
};
