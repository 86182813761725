import { FetchResult } from "@apollo/client";
import { hasPermission } from "@avela/avela-authorization-sdk";
import { Box, Button, Flex } from "@chakra-ui/react";
import { useFlags } from "flagsmith/react";
import { Form, Formik } from "formik";
import _ from "lodash";
import React from "react";
import { addressLookup } from "src/components/Boundary/services";
import { useBoundariesMap } from "src/components/Boundary/useBoundary";
import { RankedSchoolsRemovalAlert } from "src/components/Feedback/RankedSchoolsRemovalAlert";
import {
  FORM_AUTHORIZATION_SCOPE,
  useAuthorizationScoped,
} from "src/components/Providers/AuthorizationProvider";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useLoadGooglePlacesScript } from "src/hooks/useLoadGoogleMaps";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import useRankedSchools from "src/hooks/useSchoolRank";
import { useUserPermissions } from "src/hooks/useUserPermissions";
import { FormQuestion } from "src/scenes/parent/forms/components/Inputs/FormQuestion";
import { DELETE_ANSWERS } from "src/scenes/parent/forms/graphql/mutations";
import { GET_FORM_ANSWERS_BY_ID } from "src/scenes/parent/forms/graphql/queries";
import { Answer } from "src/services/formTemplate";
import {
  findFormAddressAnswer,
  findGradeAnswer,
  getAddressAnswersDeletes,
  getAddressAnswersInserts,
  getCustomQuestionAnswerUpsertPayload,
  getFreeTextAnswersInserts,
  getGradesAnswersInserts,
  getMultiSelectAnswersInserts,
  getSingleSelectAnswersInserts,
} from "src/services/formTemplate/answer";
import {
  RankedSchool,
  calculateSchoolsToRemove,
} from "src/services/formTemplate/preRankingSection";
import * as Question from "src/services/formTemplate/question";
import * as AFF from "src/services/formTemplateFilters";
import { validateFormTemplate } from "src/services/formTemplateValidations";
import { isNotNull } from "src/services/predicates";
import * as AF from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import * as RD from "src/types/remoteData";
import {
  UPSERT_FORM_ANSWERS,
  UPSERT_FORM_ANSWERS_AND_DELETE_SCHOOLS_RANK,
} from "../../graphql/mutations";
import {
  GET_FORM_VIEW_BY_ID,
  GET_SCHOOLS_RANK_VIEW,
} from "../../graphql/queries";

type Props = {
  formId: uuid;
  personId: uuid;
  applicant: AFF.Types.Applicant;
  schoolRankingSectionId: uuid | undefined;
  questions: AF.Question<AF.WithId>[];
  form_answers: GQL.FormAnswerFragment[];
  grades_answers: GQL.GradesAnswersFragment[];
  address_answers: GQL.AddressAnswersFragment[];
  custom_question_answers: GQL.CustomQuestionAnswersFragment[];
  custom_question_answer_bank_relationships?: GQL.CustomQuestionAnswerBankRelationshipsFragment[];
  customQuestionTypes: GQL.GetCustomQuestionTypesByOrg_custom_question_type[];
  rankedSchools: RankedSchool[];
  onCloseEdit: () => void;
  previousFormSchoolIds: uuid[];
};

export const PreRankingSectionEditForm: React.FC<Props> = ({
  formId,
  personId,
  applicant,
  schoolRankingSectionId,
  questions,
  form_answers,
  grades_answers,
  address_answers,
  custom_question_answers,
  custom_question_answer_bank_relationships,
  customQuestionTypes,
  rankedSchools,
  onCloseEdit,
  previousFormSchoolIds,
}) => {
  const {
    getDeletedRanks,
    getDeletedOffers,
    getDeletedWaitlists,
    getUpsertedRanks,
  } = useRankedSchools(
    rankedSchools.map((item) => ({
      form_id: formId,
      schools_ranking_section_id: schoolRankingSectionId ?? "",
      school_id: item.school.id,
    }))
  );
  const userPermissions = useUserPermissions();
  const flags = useFlags(["abac-authorization"]);
  const authorizationMap = useAuthorizationScoped(FORM_AUTHORIZATION_SCOPE);

  const [upsertFormAnswers, upsertFormAnswersStatus] = useRemoteDataMutation<
    GQL.UpsertFormAnswer,
    GQL.UpsertFormAnswerVariables
  >(UPSERT_FORM_ANSWERS);

  const [deleteAnswers] = useRemoteDataMutation<
    GQL.DeleteAnswers,
    GQL.DeleteAnswersVariables
  >(DELETE_ANSWERS);

  const [
    upsertFormAnswersAndDeleteSchoolsRank,
    upsertFormAnswersAndDeleteSchoolsRankStatus,
  ] = useRemoteDataMutation<
    GQL.UpsertFormAnswerAndDeleteSchoolsRank,
    GQL.UpsertFormAnswerAndDeleteSchoolsRankVariables
  >(UPSERT_FORM_ANSWERS_AND_DELETE_SCHOOLS_RANK);

  const canEditQuestion = React.useCallback(
    (question: AF.Question<AF.WithId>) => {
      // feature flag
      // if abac-authorization is disabled, we will use the old permission system
      const abacEnabled = flags["abac-authorization"].enabled;
      if (!abacEnabled) {
        return userPermissions.hasSome(["form:update"]);
      }

      if (authorizationMap.hasData()) {
        return hasPermission({
          action: {
            type: "Form::Action",
            id: "edit",
          },
          resource: {
            type: "FormQuestion",
            id: question.key || question.id,
          },
          authorizationMap: authorizationMap.data,
        });
      }
      return false;
    },
    [authorizationMap, flags, userPermissions]
  );

  useLoadGooglePlacesScript();

  const upsertStatus = RD.toTuple(
    upsertFormAnswersStatus.remoteData,
    upsertFormAnswersAndDeleteSchoolsRankStatus.remoteData
  );

  const initialAnswers = Answer.getFormikInitialValues(
    questions,
    form_answers,
    grades_answers,
    address_answers,
    custom_question_answers,
    custom_question_answer_bank_relationships
  );
  const rankedSchoolIds = rankedSchools.map(
    (rankedSchool) => rankedSchool.school.id
  );
  const toast = useAvelaToast();
  const boundariesMap = useBoundariesMap({ formId });
  const submitHandler = async (answers: Answer.FormikValues) => {
    try {
      if (!boundariesMap.hasData()) {
        return;
      }

      if (!window.google?.maps) {
        return;
      }

      const completeQuestions = Question.getCompleteQuestions(questions);
      const applicableQuestions = Question.getCompleteApplicableQuestions(
        questions,
        answers,
        { rankedSchoolIds, previousFormSchoolIds }
      );
      const modifiedQuestions = applicableQuestions.filter(
        (q) => !_.isEqual(answers[q.id], initialAnswers[q.id])
      );

      const freeTextAnswers = getFreeTextAnswersInserts(
        formId,
        modifiedQuestions,
        answers
      );
      const singleSelectAnswers = getSingleSelectAnswersInserts(
        formId,
        modifiedQuestions,
        answers
      );
      const multiSelectAnswers = getMultiSelectAnswersInserts(
        formId,
        modifiedQuestions,
        answers
      );
      const singleOrMultiSelectAnswers =
        singleSelectAnswers.concat(multiSelectAnswers);
      const gradesAnswers = getGradesAnswersInserts(
        formId,
        modifiedQuestions,
        answers
      );
      const addressAnswers = getAddressAnswersInserts(
        formId,
        modifiedQuestions,
        answers
      );
      const formAddressDeletes = getAddressAnswersDeletes(
        formId,
        modifiedQuestions,
        answers
      );
      const {
        customQuestionAnswers,
        answerBankIds,
        answerBankAnswers,
        newAnswerBankEntries,
        customQuestionAnswersToDelete,
        customQuestionAnswerFieldsToDelete,
      } = getCustomQuestionAnswerUpsertPayload(
        formId,
        personId,
        modifiedQuestions,
        answers,
        customQuestionTypes
      );

      const customQuestions = modifiedQuestions.filter(
        (q) => q.type === AF.CustomQuestionType
      );

      const customQuestionIds = customQuestions.map((cq) => cq.id);

      const customQuestionSingleSelectQuestionIds: uuid[] =
        customQuestions.flatMap((q) =>
          (q as AF.CustomQuestion<AF.WithId>).nestedQuestions
            .filter((nq) => nq.type === AF.SingleSelectType)
            .map((nq) => nq.id)
        );
      // Existing single and multi select question answers,
      // including those that are nested in custom questions,
      // will be deleted
      const singleAndMultiSelectQuestionsIds: uuid[] =
        singleOrMultiSelectAnswers
          .map((a) => a.question_id)
          .filter(isNotNull)
          .concat(customQuestionSingleSelectQuestionIds)
          .concat(customQuestionAnswerFieldsToDelete); // this deliberately overloads "singleAndMultiSelectQuestionsIds" to support "Do not answer"

      let newSchoolsRank: GQL.form_school_rank_insert_input[];
      let deletedSchoolRanks: GQL.form_school_rank_bool_exp;
      let deletedOffers: GQL.offer_bool_exp;
      let deletedWaitlists: GQL.waitlist_bool_exp;

      if (schoolRankingSectionId === undefined) {
        // no school ranks section, so school ranks should be empty
        newSchoolsRank = [];
        deletedSchoolRanks = {};
        deletedOffers = {
          deleted_at: { _is_null: true },
          _or: [],
        };
        deletedWaitlists = {
          deleted_at: { _is_null: true },
          _or: [],
        };
      } else {
        const gradeConfigId = findGradeAnswer(completeQuestions, answers);
        const address = findFormAddressAnswer(completeQuestions, answers);
        const location = await addressLookup(
          new google.maps.Geocoder(),
          address
        );

        const schoolsIdToRemove = calculateSchoolsToRemove(
          rankedSchools,
          applicableQuestions,
          answers,
          gradeConfigId?.gradeConfigId,
          location,
          boundariesMap.data
        ).map((schoolRank) => schoolRank.school.id);

        newSchoolsRank = getUpsertedRanks(
          _.sortBy(
            rankedSchools.filter(
              (rankedSchool) =>
                !schoolsIdToRemove.includes(rankedSchool.school.id)
            ),
            (schoolRank) => schoolRank.rank
          ).map((schoolRank) => {
            return {
              form_id: formId,
              schools_ranking_section_id: schoolRankingSectionId,
              school_id: schoolRank.school.id,
            };
          })
        );
        deletedSchoolRanks = getDeletedRanks(newSchoolsRank);
        deletedOffers = getDeletedOffers(newSchoolsRank);
        deletedWaitlists = getDeletedWaitlists(newSchoolsRank);
      }

      const allNewAnswers = freeTextAnswers.concat(singleOrMultiSelectAnswers);
      const refetchQueries = [
        GET_FORM_VIEW_BY_ID,
        GET_FORM_ANSWERS_BY_ID,
        GET_SCHOOLS_RANK_VIEW,
      ];

      const inapplicableQuestions = Question.findInapplicableQuestionsToDelete(
        completeQuestions,
        applicableQuestions,
        answers
      );

      const upsertFormAnswersPromise = (): Promise<
        FetchResult<GQL.UpsertFormAnswer>
      > => {
        return upsertFormAnswers({
          variables: {
            form_id: formId,
            answers: allNewAnswers,
            custom_question_ids: customQuestionIds,
            single_or_multi_select_question_ids:
              singleAndMultiSelectQuestionsIds,
            grades_answers: gradesAnswers,
            address_answers: addressAnswers,
            address_answer_deletes: formAddressDeletes,
            custom_question_answers: customQuestionAnswers,
            answer_bank_ids: answerBankIds,
            answer_bank_answers: answerBankAnswers,
            new_answer_bank_entries: newAnswerBankEntries,
            deleted_answers_custom_question_ids: customQuestionAnswersToDelete,
          },
          refetchQueries,
          awaitRefetchQueries: true,
        });
      };
      const upsertFormAnswersAndDeleteSchoolsRankPromise = (): Promise<
        FetchResult<GQL.UpsertFormAnswerAndDeleteSchoolsRank>
      > => {
        return upsertFormAnswersAndDeleteSchoolsRank({
          variables: {
            form_id: formId,
            answers: allNewAnswers,
            custom_question_ids: customQuestionIds,
            single_or_multi_select_question_ids:
              singleAndMultiSelectQuestionsIds,
            grades_answers: gradesAnswers,
            custom_question_answers: customQuestionAnswers,
            answer_bank_ids: answerBankIds,
            answer_bank_answers: answerBankAnswers,
            new_answer_bank_entries: newAnswerBankEntries,
            deleted_answers_custom_question_ids: customQuestionAnswersToDelete,
            delete_offers_where: deletedOffers,
            delete_waitlists_where: deletedWaitlists,
            deleted_school_ranks: deletedSchoolRanks,
            upserted_school_ranks: newSchoolsRank,
            address_answers: addressAnswers,
            address_answer_deletes: formAddressDeletes,
          },
          refetchQueries,
          awaitRefetchQueries: true,
        });
      };
      const deleteAnswersPromise = (): void | Promise<
        FetchResult<GQL.DeleteAnswers>
      > => {
        return inapplicableQuestions.length > 0
          ? deleteAnswers({
              variables: {
                form_id: formId,
                question_ids: inapplicableQuestions,
              },
            })
          : _.noop();
      };

      const chooseUpsertFormAnswerPromise = (): Promise<
        FetchResult<GQL.UpsertFormAnswer>
      > => {
        return schoolRankingSectionId === undefined
          ? upsertFormAnswersPromise()
          : upsertFormAnswersAndDeleteSchoolsRankPromise();
      };
      await Promise.all([
        chooseUpsertFormAnswerPromise(),
        deleteAnswersPromise(),
      ]);

      onCloseEdit();
    } catch (error: unknown) {
      console.error(error);
      toast.error({ title: "Error updating form" });
    }
  };
  return (
    <Formik<Answer.FormikValues>
      initialValues={initialAnswers}
      onSubmit={submitHandler}
      validate={validateFormTemplate(questions, {
        rankedSchoolIds,
        previousFormSchoolIds,
      })}
    >
      {(formikProps) => {
        const completeQuestions = Question.getCompleteApplicableQuestions(
          questions,
          formikProps.values,
          { rankedSchoolIds, previousFormSchoolIds }
        );
        return (
          <Flex direction="column" as={Form} noValidate gap={3}>
            <Box>
              {completeQuestions.map((question) => (
                <FormQuestion
                  formId={formId}
                  applicant={applicant}
                  key={question.id}
                  question={question}
                  marginBottom={4}
                  disableAddressBook
                  isDisabled={!canEditQuestion(question)}
                />
              ))}
            </Box>
            <RankedSchoolsRemovalAlert
              formId={formId}
              rankedSchools={rankedSchools}
              completeQuestions={completeQuestions}
              answers={formikProps.values}
            />
            <Flex justifyContent="flex-end" gap={3}>
              <Button
                type="button"
                variant="ghost"
                colorScheme="gray"
                onClick={onCloseEdit}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                isDisabled={!formikProps.isValid || !boundariesMap.hasData()}
                isLoading={upsertStatus.isLoading()}
              >
                Save
              </Button>
            </Flex>
          </Flex>
        );
      }}
    </Formik>
  );
};
