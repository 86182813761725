import { PropsWithChildren, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useOrganization } from "src/hooks/useOrganization";
import * as GQL from "src/types/graphql";
import { AccessDenied } from "../Feedback/AccessDenied";
import { Loading } from "../Feedback/Loading";
import { useOrgConfig } from "../Providers/OrgConfigProvider";

type RequireConfigProps = PropsWithChildren<{
  configType: GQL.organization_config_type_enum;
}>;

export const RequireConfig: React.FC<RequireConfigProps> = ({
  children,
  configType,
}) => {
  const { fetchOrgConfig, isLoading, [configType]: config } = useOrgConfig();
  const organization = useOrganization();
  const orgId = organization.toNullable()?.id ?? "";
  const navigate = useNavigate();

  useEffect(() => {
    if (!orgId) return;
    fetchOrgConfig(orgId, [configType]);
  }, [orgId, fetchOrgConfig, configType]);

  if (isLoading) return <Loading />;

  if (!config?.enabled) {
    if (organization.hasData()) {
      setTimeout(() => {
        navigate(`/${organization.data.path}/admin`);
      }, 2000);

      return (
        <AccessDenied message="You don't have access to this page, redirecting..." />
      );
    }

    return <AccessDenied />;
  }

  return <>{children}</>;
};
