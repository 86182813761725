import { useField } from "formik";
import { isEmpty } from "lodash";
import { AddressAnswer } from "src/components/Form/QuestionForm/formik";
import {
  AddressBookAnswerSchema,
  BaseAddressSchema,
  isAddressBlank,
} from "src/components/Inputs/Address/Book";
import { UPSERT_USER_ADDRESS } from "src/components/Inputs/Address/Book/mutations";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import {
  FormikFieldValue,
  getSingleCustomQuestionAnswerUpsertPayload,
  isAddressAnswer,
  isCustomQuestionAnswer,
  isFreeTextAnswer,
  isMultiSelectAnswer,
  isSingleSelectAnswer,
} from "src/services/formTemplate/answer";
import * as AF from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import {
  DELETE_CUSTOM_QUESTION_ANSWERS,
  DELETE_FORM_ADDRESS,
  INSERT_FORM_ANSWER_OPTIONS,
  UPSERT_CUSTOM_QUESTION_ANSWERS,
  UPSERT_FORM_ADDRESS,
  UPSERT_FORM_ANSWER_FREE_TEXT,
} from "../graphql/mutations";

export type SingleSelectChangeProps = {
  before?: AF.Option<AF.WithId>;
  after?: AF.Option<AF.WithId>;
};

export function useFormQuestion(
  formId: uuid,
  question: AF.Question<AF.WithId>
) {
  const [upsertFreeText] = useRemoteDataMutation<
    GQL.UpsertFormAnswerFreeText,
    GQL.UpsertFormAnswerFreeTextVariables
  >(UPSERT_FORM_ANSWER_FREE_TEXT, { fetchPolicy: "no-cache" });

  const [insertAnswerOptions] = useRemoteDataMutation<
    GQL.InsertFormAnswerOptions,
    GQL.InsertFormAnswerOptionsVariables
  >(INSERT_FORM_ANSWER_OPTIONS, { fetchPolicy: "no-cache" });

  const [upsertFormAddress] = useRemoteDataMutation<
    GQL.UpsertFormAddress,
    GQL.UpsertFormAddressVariables
  >(UPSERT_FORM_ADDRESS, { fetchPolicy: "no-cache" });

  const [upsertUserAddress] = useRemoteDataMutation<
    GQL.UpsertUserAddress,
    GQL.UpsertUserAddressVariables
  >(UPSERT_USER_ADDRESS, { fetchPolicy: "no-cache" });

  const [deleteFormAddress] = useRemoteDataMutation<
    GQL.DeleteFormAddress,
    GQL.DeleteFormAddressVariables
  >(DELETE_FORM_ADDRESS, { fetchPolicy: "no-cache" });

  const [upsertCustomQuestionAnswers] = useRemoteDataMutation<
    GQL.UpsertCustomQuestionAnswers,
    GQL.UpsertCustomQuestionAnswersVariables
  >(UPSERT_CUSTOM_QUESTION_ANSWERS, { fetchPolicy: "no-cache" });

  const [deleteCustomQuestionAnswers] = useRemoteDataMutation<
    GQL.DeleteCustomQuestionAnswers,
    GQL.DeleteCustomQuestionAnswersVariables
  >(DELETE_CUSTOM_QUESTION_ANSWERS, { fetchPolicy: "no-cache" });

  const [field, , helpers] = useField<FormikFieldValue>(question.id);

  return {
    saveFreeTextAnswer: async (value: FormikFieldValue) => {
      if (!isFreeTextAnswer(value)) {
        console.error(
          `Expecting a "string" for question ${question.id}, but got ${value} instead`
        );
        return;
      }

      await upsertFreeText({
        variables: {
          form_id: formId,
          question_id: question.id,
          free_text_answer: value,
        },
      });
    },

    saveMultiSelectAnswer: async (value: FormikFieldValue) => {
      if (!isMultiSelectAnswer(value)) {
        console.error(
          `Expecting a "string[]" for question ${question.id}, but got ${value} instead`
        );
        return;
      }

      await insertAnswerOptions({
        variables: {
          form_id: formId,
          question_id: question.id,
          options:
            value?.map((value) => ({
              form_question_option_id: value,
            })) ?? [],
        },
      });
    },

    saveSingleSelectAnswer: async (
      value: FormikFieldValue,
      confirmEligibilityChange?: (
        value: SingleSelectChangeProps
      ) => Promise<boolean>
    ) => {
      if (!isSingleSelectAnswer(value)) {
        console.error(
          `Expecting a "string" for question ${question.id}, but got ${value} instead`
        );
        return;
      }

      if (question.type !== "SingleSelect") {
        console.error(
          `Expecting SingleSelect type, but got a ${question.type} instead`
        );
        return;
      }

      if (question.category === "Eligibility") {
        const options = question.options;
        const beforeValue = field.value;
        const before = options.find((o) => o.id === beforeValue);
        const after = options.find((o) => o.id === value);

        if (
          confirmEligibilityChange &&
          !(await confirmEligibilityChange({ before, after }))
        ) {
          helpers.setValue(beforeValue);
          return;
        }
      }

      await insertAnswerOptions({
        variables: {
          form_id: formId,
          question_id: question.id,
          options: value ? [{ form_question_option_id: value }] : [],
        },
      });
    },

    saveAddressAnswer: async (
      value: FormikFieldValue,
      confirmAddressChange?: (value: AddressAnswer) => Promise<boolean>
    ) => {
      if (!isAddressAnswer(value)) {
        console.error(
          `Expecting an address answer for question ${
            question.id
          }, but got ${JSON.stringify(value)} instead`
        );
        return;
      }

      const beforeValue = field.value;

      if (question.type !== AF.AddressType) {
        console.error(
          `Expecting Address type, but got a ${question.type} instead`
        );
        return;
      }

      if (isAddressBlank(value)) {
        await deleteFormAddress({
          variables: {
            form_id: formId,
            question_id: question.id,
          },
        });
        helpers.setTouched(false);
        return;
      }

      if (confirmAddressChange && !(await confirmAddressChange(value))) {
        // set touched to false to prevent address form from triggering save on change instead of on blur
        helpers.setTouched(false);

        // address change can't be confirmed, reverting to previous value
        helpers.setValue(beforeValue);
        return;
      }

      const address = BaseAddressSchema.parse(value);
      await upsertFormAddress({
        variables: {
          address: {
            form_id: formId,
            question_id: question.id,
            ...address,
          },
        },
      });

      const addressBookAnswer = AddressBookAnswerSchema.safeParse(value);
      if (addressBookAnswer.success) {
        await upsertUserAddress({
          variables: {
            user_address: {
              user_id: addressBookAnswer.data.user_id,
              ...address,
            },
          },
        });
      }

      // set touched to false to prevent address form from triggering save on change instead of on blur
      helpers.setTouched(false);
    },

    /**
     * A question using a custom_question_type with N custom_question_type_fields
     * will have N associated custom_answer records.
     */
    saveCustomQuestionAnswer: async (value: FormikFieldValue) => {
      if (!isCustomQuestionAnswer(value)) {
        console.error(
          `Expecting an "object" for question ${question.id}, but got ${value} instead`
        );
        return;
      }
      if (question.type !== AF.CustomQuestionType) {
        console.error(
          `Expecting question ${question.id} to be a custom question type.`
        );
      }
      const customQuestion = question as AF.CustomQuestion<AF.WithId>;

      const answersByQuestionId = value.answersByQuestionId;

      if (isEmpty(answersByQuestionId)) {
        await deleteCustomQuestionAnswers({
          variables: {
            form_id: formId,
            question_id: question.id,
            nested_question_ids: customQuestion.nestedQuestions.map(
              (nestedQuestion) => {
                return nestedQuestion.id;
              }
            ),
          },
        });
        helpers.setTouched(false);
        return;
      }

      await upsertCustomQuestionAnswers({
        variables: {
          form_id: formId,
          question_id: customQuestion.id,
          single_select_question_ids: customQuestion.nestedQuestions
            .filter(
              (nestedQuestion) => nestedQuestion.type === AF.SingleSelectType
            )
            .map((nestedQuestion) => nestedQuestion.id),
          custom_question_answers: getSingleCustomQuestionAnswerUpsertPayload(
            formId,
            customQuestion,
            answersByQuestionId
          ),
          no_answer_bank_relationship: value.answerBankId === undefined,
          answer_bank_id: value.answerBankId,
        },
      });
    },
  };
}
