import { Button } from "@chakra-ui/button";
import { FormControl, FormLabel } from "@chakra-ui/form-control";
import { Input } from "@chakra-ui/input";
import { Flex, Heading, Spacer, VStack } from "@chakra-ui/layout";
import { Select } from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import React, { useMemo } from "react";
import { NavLink } from "react-router-dom";
import { useOrgConfig } from "src/components/Providers/OrgConfigProvider";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import * as OrganizationConfig from "src/types/organizationConfig";
import { AccountLookupToggle } from "./components/AccountLookupToggle";
import { LanguagesFormConfiguration } from "./components/LanguagesFormConfiguration";
import { MatchFormConfiguration } from "./components/MatchFormConfiguration";
import { ScheduleExportToggle } from "./components/ScheduleExportToggle";

export interface FormikOrganizationForm {
  name: string;
  path: string;
  timezone_name: string;
  organization_configs: FormikOrganizationConfigs;
}

type FormikOrganizationConfigs = {
  [GQL.organization_config_type_enum.TranslationOptions]: {
    enabled: boolean;
    data: OrganizationConfig.Configs[GQL.organization_config_type_enum.TranslationOptions]["data"];
  };
  [GQL.organization_config_type_enum.Match]: {
    enabled: boolean;
    data: OrganizationConfig.Configs[GQL.organization_config_type_enum.Match]["data"];
  };
  [GQL.organization_config_type_enum.AccountLookup]: {
    enabled: boolean;
    data: OrganizationConfig.Configs[GQL.organization_config_type_enum.AccountLookup]["data"];
  };
  [GQL.organization_config_type_enum.ScheduleExport]: {
    enabled: boolean;
    data: OrganizationConfig.Configs[GQL.organization_config_type_enum.ScheduleExport]["data"];
  };
};

interface OrganizationFormProps {
  organization?: GQL.GetOrganization_organization_by_pk;
  onSubmit: (values: FormikOrganizationForm) => void;
  submitting: boolean;
}

export const OrganizationForm = (props: OrganizationFormProps) => {
  const isNew = !props.organization;
  const { TranslationOptions, Match, AccountLookup, ScheduleExport } =
    useOrgConfig();

  const timezoneNames = React.useMemo(() => {
    return (Intl as any).supportedValuesOf("timeZone");
  }, []);

  const initialValues: FormikOrganizationForm = useMemo(() => {
    return {
      name: props?.organization?.name || "",
      path: props?.organization?.path || "",
      timezone_name: props?.organization?.timezone_name || "+00:00",
      organization_configs: {
        [GQL.organization_config_type_enum.TranslationOptions]: {
          enabled: TranslationOptions?.enabled || false,
          data: TranslationOptions?.data || [],
        },
        [GQL.organization_config_type_enum.Match]: {
          enabled: Match?.enabled || false,
          data: Match?.data || {},
        },
        [GQL.organization_config_type_enum.AccountLookup]: {
          enabled: AccountLookup?.enabled || false,
          data: AccountLookup?.data || { supportUrl: "" },
        },
        [GQL.organization_config_type_enum.ScheduleExport]: {
          enabled: ScheduleExport?.enabled || false,
          data: ScheduleExport?.data || { secretArn: "" },
        },
      },
    };
  }, [
    props?.organization,
    TranslationOptions,
    Match,
    AccountLookup,
    ScheduleExport,
  ]);

  function editPolicyUrl(
    organization: GQL.GetOrganization_organization_by_pk
  ): string {
    return Url.Admin.Organizations.editPolicy(organization.id);
  }

  // TODO: implement form validation
  return (
    <Formik<FormikOrganizationForm>
      initialValues={initialValues}
      onSubmit={props.onSubmit}
    >
      {(form) => (
        <Form>
          <VStack align="left" spacing={8}>
            <Heading as="h1" size="xl">
              {isNew
                ? "Create Organization"
                : `Edit ${props?.organization?.name || "Organization"}`}
            </Heading>
            <Heading as="h2" size="lg">
              Basic information
            </Heading>
            <Flex direction="column">
              <FormControl>
                <FormLabel htmlFor="name">Organization Name</FormLabel>
                <Input as={Field} type="text" id="name" name="name" />
              </FormControl>
            </Flex>
            <Flex direction="column">
              <FormControl>
                <FormLabel htmlFor="path">Path</FormLabel>
                <Input as={Field} type="text" id="path" name="path" />
              </FormControl>
            </Flex>
            <Flex direction="column">
              <FormControl>
                <FormLabel htmlFor="timezone_name">
                  Timezone offset from UTC
                </FormLabel>
                <Field as={Select} id="timezone_name" name="timezone_name">
                  {timezoneNames.map((value: string) => (
                    <option value={value} key={value}>
                      {value}
                    </option>
                  ))}
                </Field>
              </FormControl>
            </Flex>

            <hr />

            {!!props.organization && (
              <>
                <Heading as="h2" size="lg">
                  Access & policies
                </Heading>
                <p>Configure access policies for the organization.</p>
                <Flex align="center">
                  <Button
                    as={NavLink}
                    colorScheme="gray"
                    to={editPolicyUrl(props.organization)}
                    aria-label={`Edit access policies`}
                    variant="outline"
                  >
                    Edit access policies
                  </Button>
                  <Spacer />
                </Flex>
                <hr />
              </>
            )}

            <Heading as="h2" size="lg">
              General settings
            </Heading>

            <LanguagesFormConfiguration
              initialLanguages={TranslationOptions?.data || []}
            />
            <ScheduleExportToggle />
            <MatchFormConfiguration />
            <AccountLookupToggle />

            <Flex align="center">
              <Spacer />
              <Button
                as={NavLink}
                to={Url.Admin.Organizations.index()}
                variant="link"
              >
                Cancel
              </Button>
              <Button type="submit" marginLeft={4} isLoading={props.submitting}>
                {isNew ? "Create" : "Update"}
              </Button>
            </Flex>
          </VStack>
        </Form>
      )}
    </Formik>
  );
};
