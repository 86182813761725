import * as RD from "src/types/remoteData";
import { LoginMethod, DEFAULT_LOGIN_CONFIG } from "src/types/loginMethod";
import { usePublicOrgConfig } from "./usePublicOrgConfig";
import * as GQL from "src/types/graphql";

export function useLoginMethods(
  pathType: "admin" | "parent"
): RD.RemoteData<unknown, LoginMethod[]> {
  const loginConfig = usePublicOrgConfig(
    GQL.organization_config_type_enum.Login
  );

  const loginConfigs = loginConfig.map((config) => {
    return config === null ? DEFAULT_LOGIN_CONFIG[pathType] : config[pathType];
  });

  return loginConfigs;
}
