import {
  Box,
  Flex,
  FlexProps,
  FormLabel,
  FormLabelProps,
  Switch,
  SwitchProps,
} from "@chakra-ui/react";
import { Field, FieldProps } from "formik";

function isTuple<FieldType>(
  value: FieldType | [FieldType, string]
): value is [FieldType, string] {
  return (
    Array.isArray(value) && value.length === 2 && typeof value[1] === "string"
  );
}

type Props<FieldType> = {
  name: string;
  checked: FieldType | [FieldType, string];
  unchecked?: FieldType | [FieldType, string];
  label: string;
  formLabelProps?: FormLabelProps;
  containerProps?: FlexProps;
  direction?: "row" | "column";
} & SwitchProps;
export function SwitchInput<FieldType, FormType = unknown>({
  name,
  checked,
  unchecked,
  label,
  formLabelProps,
  containerProps,
  ...props
}: Props<FieldType>) {
  const [checkedValue, checkedString] = isTuple(checked)
    ? checked
    : [checked, `${checked}`];
  const [uncheckedValue, uncheckedString] = isTuple(unchecked)
    ? unchecked
    : [unchecked, unchecked ? `${unchecked}` : undefined];
  return (
    <FormLabel
      htmlFor={name}
      gap={3}
      display="flex"
      flexDir={props.direction === "row" ? "row-reverse" : "column"}
      alignSelf="flex-start"
      {...formLabelProps}
    >
      <span>{label}</span>
      <Field name={name}>
        {({ form, field }: FieldProps<FieldType, FormType>) => (
          <Flex direction="column" {...containerProps}>
            <Switch
              {...props}
              {...field}
              id={name}
              value={
                field.value === checkedValue ? checkedString : uncheckedString
              }
              isChecked={field.value === checkedValue}
              onChange={() => {
                const nextValue =
                  field.value === checkedValue ? uncheckedValue : checkedValue;
                form.setFieldValue(name, nextValue);
              }}
            />
            <Box fontSize="sm">
              {field.value === checkedValue ? checkedString : uncheckedString}
            </Box>
          </Flex>
        )}
      </Field>
    </FormLabel>
  );
}
