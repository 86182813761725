import { Flex, Text } from "@chakra-ui/react";
import { StudentAvatar } from "src/components/Layout/Avatar";
import { useGlossary } from "src/hooks/useGlossary";
import { WEGLOT_SKIP_CLASS } from "src/plugins/weglot/constants";
import * as Format from "src/services/format";
import * as GQL from "src/types/graphql";
import { FormTemplateCard } from "./FormTemplateCard";
import { useMemo } from "react";
import _ from "lodash";

type StudentFormTemplateCardsProps = {
  forms: GQL.GetParentFormsIndex_person_forms[];
  assignedForms: GQL.GetParentAssignedForms_assigned_form[];
  student: GQL.GetParentFormsIndex_person;
};

type SortedForm = GQL.GetParentFormsIndex_person_forms & { sortDate: string };

export function StudentFormTemplateCards(props: StudentFormTemplateCardsProps) {
  const { forms, student, assignedForms } = props;

  const { glossary } = useGlossary();

  const fullName = Format.fullName(student) || glossary`Student`;
  const shortName = Format.shortName(student) || glossary`Student`;

  const sortedForms = useMemo(() => {
    const unsortedForms: SortedForm[] = [];

    forms.forEach((form) => {
      if (form.form_template.closed_at) {
        return unsortedForms.push({
          ...form,
          sortDate: form.form_template.closed_at,
        });
      }

      if (form.form_template.open_at) {
        return unsortedForms.push({
          ...form,
          sortDate: form.form_template.open_at,
        });
      }

      return unsortedForms.push({
        ...form,
        sortDate: "",
      });
    });
    return _.orderBy(unsortedForms, (form) => form.sortDate, "desc");
  }, [forms]);

  return (
    <Flex flexDirection="column" gap={4}>
      <Flex alignItems="center" gap={2}>
        <StudentAvatar student={student} size="sm" />

        <Text
          as="h2"
          fontSize="lg"
          color="gray.700"
          className={WEGLOT_SKIP_CLASS}
          fontWeight={700}
        >
          {fullName}
        </Text>
      </Flex>

      {sortedForms.map((form) => (
        <FormTemplateCard
          actionItems={form.action_items}
          formTemplateId={form.form_template.id}
          form={form}
          assignedForms={assignedForms}
          formSchoolRanks={form.form_school_ranks}
          enrollmentPeriod={form.form_template.enrollment_period}
          gradeLabel={form.grades_answers[0]?.grade_config?.label ?? ""}
          key={form.id}
          statusDescriptions={form.form_template.form_status_descriptions}
          studentName={shortName}
        />
      ))}
    </Flex>
  );
}
