import { useToast } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router";
import { NotFound } from "src/components/Feedback/NotFound";
import { GQLRemoteDataView } from "src/components/Layout/RemoteDataView";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import {
  useLazyRemoteDataQuery,
  useRemoteDataQuery,
} from "src/hooks/useRemoteDataQuery";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { OrganizationForm, FormikOrganizationForm } from "./Form";
import { UPDATE_ORGANIZATION } from "./graphql/mutations";
import { GET_ORGANIZATIONS, GET_ORGANIZATION_BY_ID } from "./graphql/queries";
import React, { useEffect } from "react";
import { useOrgConfig } from "src/components/Providers/OrgConfigProvider";

export const Edit = () => {
  const { id = "" } = useParams();
  const toast = useToast();
  const { fetchOrgConfig } = useOrgConfig();
  const [configLoaded, setConfigLoaded] = React.useState<boolean>(false);

  useEffect(() => {
    async function loadConfig() {
      fetchOrgConfig(id, Object.values(GQL.organization_config_type_enum));
      setConfigLoaded(true);
    }

    if (id) {
      loadConfig();
    }
  }, [fetchOrgConfig, id]);

  const { remoteData } = useRemoteDataQuery<
    GQL.GetOrganization,
    GQL.GetOrganizationVariables
  >(GET_ORGANIZATION_BY_ID, {
    variables: { id },
  });

  const [updateOrganization, { remoteData: updateData }] =
    useRemoteDataMutation<
      GQL.UpdateOrganization,
      GQL.UpdateOrganizationVariables
    >(UPDATE_ORGANIZATION);
  const [getOrganizations, refetchStatus] =
    useLazyRemoteDataQuery<GQL.GetOrganizations>(GET_ORGANIZATIONS, {
      fetchPolicy: "network-only",
    });
  const navigate = useNavigate();

  if (!configLoaded) return null;

  const handleSubmit = async (values: FormikOrganizationForm) => {
    try {
      const { organization_configs, ...transformedOrganization } = values;
      const transformedConfig =
        Object.entries(organization_configs).map(([key, value]) => {
          return {
            organization_id: id,
            data: value.data,
            enabled: value.enabled,
            type: key as GQL.organization_config_type_enum,
          };
        }) || [];

      await updateOrganization({
        variables: {
          id,
          organization: transformedOrganization,
          organization_config: transformedConfig,
        },
      });
      await getOrganizations();
      navigate(Url.Admin.Organizations.index());
    } catch (error) {
      console.error(error);
      const id = "EditOrganization";
      if (!toast.isActive(id))
        toast({
          id,
          title: "Error editing organization",
          description:
            "Please try again later or report the problem to our support team.",
          isClosable: true,
          status: "error",
        });
    }
  };

  return (
    <GQLRemoteDataView remoteData={remoteData}>
      {(data) => {
        if (data.organization_by_pk === null) {
          return <NotFound />;
        }
        return (
          <OrganizationForm
            organization={data.organization_by_pk}
            onSubmit={handleSubmit}
            submitting={updateData.isLoading() || refetchStatus.called}
          />
        );
      }}
    </GQLRemoteDataView>
  );
};
