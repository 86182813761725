import { useField } from "formik";
import { InputControl } from "formik-chakra-ui";
import React from "react";
import { BaseInputProps } from "./Question";

export const FreeTextInput: React.FC<BaseInputProps> = ({
  id,
  isDisabled = false,
}) => {
  const [field, , helpers] = useField(id);
  return (
    <>
      <InputControl
        id={id}
        name={id}
        onChange={(event) => {
          // set as touched so it triggers autosave on change instead of on blur.
          helpers.setTouched(true);
          field.onChange(event);
        }}
        isDisabled={isDisabled}
      />
    </>
  );
};
