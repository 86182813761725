import { Flex, Text } from "@chakra-ui/react";
import { ColumnDef, Row } from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import {
  SortPagination,
  SortType,
  UNSORTED,
  useLocalData,
} from "src/hooks/useLocalData";
import { getLanguageName } from "src/services/languages";
import * as Person from "src/services/person";
import { PaginatedTable } from "./PaginatedTable";
import * as GQL from "src/types/graphql";
import { MessageStatusTag } from "src/scenes/orgAdmin/messages/components/MessageStatusTag";
import { useLocation } from "react-router-dom";

export type Recipient =
  GQL.GetMessageById_message_adhoc_by_pk_message_adhoc_receivers_receiver_person & {
    message_status?: GQL.message_status_enum;
  };

interface RecipientsTableProps {
  recipients: Recipient[];
}

export const RecipientsTable: React.FC<RecipientsTableProps> = ({
  recipients,
}) => {
  const { pathname } = useLocation();
  const isViewPage = useMemo(() => pathname.includes("view"), [pathname]);

  const [pagination, setPagination] = useState<SortPagination>({
    limit: 25,
    offset: 0,
    sortKey: "full_name",
    sortType: UNSORTED,
  });

  const [pagedRecipients, setPagedRecipients] = useState<Recipient[]>([]);

  const [getSortedAndPaginatedData] = useLocalData(recipients);

  const onChangeSort = (columnName: string, sortType: SortType) => {
    const updatedPagination = {
      limit: pagination.limit,
      offset: pagination.offset,
      sortKey: columnName,
      sortType,
    };

    setPagedRecipients(getSortedAndPaginatedData(updatedPagination));
    setPagination(updatedPagination);
  };

  const onFetchMore = (limit: number, offset: number) => {
    const updatedPagination = {
      offset,
      limit,
      sortKey: pagination.sortKey,
      sortType: pagination.sortType,
    };

    setPagedRecipients(getSortedAndPaginatedData(updatedPagination));
    setPagination(updatedPagination);
  };

  useEffect(() => {
    setPagedRecipients(getSortedAndPaginatedData(pagination));
  }, [pagination, getSortedAndPaginatedData]);

  const columns: ColumnDef<Recipient>[] = useMemo(
    () => [
      {
        id: "full_name",
        header: "to: parent",
        accessorFn: (row) => row.full_name,
      },
      {
        header: "contact information",
        cell: (props) => {
          const contactInformation = Person.getContactInformation(
            props.row.original
          );
          return Person.view(contactInformation, {
            none: <Text variant="placeholder">No contact information</Text>,
          });
        },
      },
      {
        id: "preferred_language",
        header: "preferred language",
        cell: (props) =>
          props.row.original.preferred_language ? (
            getLanguageName(props.row.original.preferred_language)
          ) : (
            <Text variant="placeholder">No preferred language</Text>
          ),
      },
      ...(isViewPage
        ? [
            {
              id: "status",
              header: "status",
              cell: ({ row }: { row: Row<Recipient> }) => (
                <MessageStatusTag status={row.original.message_status} />
              ),
            },
          ]
        : []),
    ],
    [isViewPage]
  );

  return (
    <Flex direction="column" gap={4} width="100%">
      <Text as="h2" fontWeight="700" fontSize="lg">
        Recipients
      </Text>
      <PaginatedTable<Recipient>
        data={pagedRecipients}
        columns={columns}
        limit={pagination.limit}
        offset={pagination.offset}
        count={recipients.length}
        onFetchMore={onFetchMore}
        sortableColumnIds={["full_name"]}
        onChangeSort={onChangeSort}
      />
    </Flex>
  );
};
