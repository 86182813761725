import { Flex, Heading, Text } from "@chakra-ui/react";
import { useField } from "formik";
import React, { useCallback, useMemo } from "react";
import { InputWithValidationControl } from "src/components/Inputs/InputWithValidationControl";
import { TextareaWithValidationControl } from "src/components/Inputs/TextareaWithValidationControl";
import { MESSAGE_CONTENT_LIMIT } from "src/constants";

type Props = {
  hasRecipientsWithEmail: boolean;
  hasRecipientsWithPhone: boolean;
};
export const MessageForm: React.FC<Props> = ({
  hasRecipientsWithEmail,
  hasRecipientsWithPhone,
}) => {
  const [field, fieldValidation, helpers] = useField("sms_body");

  const smsTextCounter = useMemo(
    () => MESSAGE_CONTENT_LIMIT - field.value.length,
    [field.value]
  );

  const handleOnChangeSmsBody = useCallback(() => {
    if (fieldValidation.touched) return;

    helpers.setTouched(true);
  }, [helpers, fieldValidation.touched]);

  return (
    <Flex direction="column" gap={4}>
      <Heading as="h2" size="md">
        Email
      </Heading>

      {hasRecipientsWithEmail ? (
        <Flex direction="column" gap={2}>
          <InputWithValidationControl
            inputProps={{ placeholder: "Message subject", my: 2 }}
            name="subject"
            id="subject"
          />
          <TextareaWithValidationControl
            textareaProps={{ placeholder: "Email content", my: 2 }}
            name="email_body"
            id="email_body"
          />
        </Flex>
      ) : (
        <Text color="blackAlpha.700" fontWeight="400">
          No email recipients
        </Text>
      )}

      <Heading as="h2" size="md">
        SMS
      </Heading>

      {hasRecipientsWithPhone ? (
        <Flex direction="column" gap={2}>
          <TextareaWithValidationControl
            textareaProps={{ placeholder: "SMS content", my: 2 }}
            name="sms_body"
            id="sms_body"
            onChange={handleOnChangeSmsBody}
          />
          {(!fieldValidation.touched || !fieldValidation.error) && (
            <Text fontSize="sm" lineHeight="1rem">
              {smsTextCounter}
            </Text>
          )}
        </Flex>
      ) : (
        <Text color="blackAlpha.700" fontWeight="400">
          No SMS recipients
        </Text>
      )}
    </Flex>
  );
};
