import {
  Badge,
  Box,
  Divider,
  Flex,
  HStack,
  IconButton,
  Link,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import * as DateFns from "date-fns";
import React from "react";
import { RiChat2Line } from "react-icons/ri";
import { Link as RouterLink } from "react-router-dom";
import { DataLabel } from "src/components/Inputs/DataLabel";
import { WithUserPermissions } from "src/components/Permissions/WithUserPermissions";
import { useOrganization } from "src/hooks/useOrganization";
import useRequiredHasuraRoles from "src/hooks/useRequiredHasuraRoles";
import { useSendMessage } from "src/hooks/useSendMessage";
import { useUserPermissions } from "src/hooks/useUserPermissions";
import { address, fullName } from "src/services/format";
import { getReferenceId } from "src/services/id";
import { getGuardians } from "src/services/person";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { HasuraRole } from "src/types/hasuraRole";

type Props = {
  form: GQL.GetFormViewById_form_by_pk;
};
export const StudentProfile: React.FC<Props> = ({ form }) => {
  const hasAdminOrgAdminPermission = useRequiredHasuraRoles([
    HasuraRole.ADMIN,
    HasuraRole.ORG_ADMIN,
    HasuraRole.DISTRICT_ADMIN,
  ]);
  const organization = useOrganization();
  const { handleSendMessage } = useSendMessage(form.form_template.id);
  const formParents = getGuardians(form.person);

  const onSendMessageClick = () => {
    const parentIds = formParents.map((parent) => parent.id);
    handleSendMessage(parentIds, [form.person.id], [form.id]);
  };

  const userPermissions = useUserPermissions();

  return (
    <Flex
      borderRadius="md"
      border="1px solid"
      borderColor="gray.300"
      direction="column"
      padding={4}
      minWidth="15rem"
    >
      <Flex direction="column" gap={3}>
        {/* LOI: needs better way handle multiple role level permissions vs user level permissions */}
        <Flex direction="column">
          {hasAdminOrgAdminPermission &&
          userPermissions.hasOne("user:update") ? (
            <Link
              alignSelf="center"
              fontSize="lg"
              color="primary.500"
              fontWeight="600"
              as={RouterLink}
              to={Url.OrgAdmin.Students.edit(organization, form.person.id)}
            >
              {fullName(form.person)}
            </Link>
          ) : (
            <Text alignSelf="center" fontSize="lg" fontWeight="600">
              {fullName(form.person)}
            </Text>
          )}
          <Text alignSelf="center" color="gray.500" fontSize="sm">
            ID: {getReferenceId(form.person)}
          </Text>
        </Flex>
        {form.person.birth_date && (
          <Box>
            <DataLabel>Date of birth</DataLabel>
            <Text>
              {DateFns.format(
                DateFns.parse(form.person.birth_date, "yyyy-MM-dd", new Date()),
                "MM/dd/yyyy"
              )}
            </Text>
          </Box>
        )}
        <Box>
          <DataLabel>Address</DataLabel>
          <Text>{address(form.person)}</Text>
        </Box>
        {form.form_attending_school?.school?.name && (
          <Box>
            <DataLabel>Current School</DataLabel>
            <Text>{form.form_attending_school.school.name}</Text>
          </Box>
        )}
        <Divider />
        {formParents.map((parent) => (
          <React.Fragment key={parent.id}>
            <Box>
              <DataLabel>Parent or Guardian</DataLabel>
              {hasAdminOrgAdminPermission &&
              userPermissions.hasOne("user:update") ? (
                <Link
                  color="primary.500"
                  as={RouterLink}
                  to={Url.OrgAdmin.Parents.edit(organization, parent.id)}
                >
                  {fullName(parent)}
                </Link>
              ) : (
                fullName(parent)
              )}
            </Box>
            {parent.email_address && (
              <Box>
                <HStack>
                  <DataLabel>Email</DataLabel>
                  {parent.email_address === parent?.user?.name && (
                    <Badge colorScheme="gray" variant="solid" marginLeft="1rem">
                      {"Linked"}
                    </Badge>
                  )}
                </HStack>
                <HStack justifyContent="space-between">
                  <Text
                    max-width="calc(100% - 40px)"
                    overflow="hidden"
                    white-space="nowrap"
                    text-overflow="ellipsis"
                  >
                    {parent.email_address}
                  </Text>
                  <WithUserPermissions permissions={["message:create"]}>
                    {parent.email_okay && (
                      <Tooltip label="Send Message">
                        <IconButton
                          aria-label="send-message"
                          icon={<RiChat2Line />}
                          variant="ghost"
                          onClick={onSendMessageClick}
                        />
                      </Tooltip>
                    )}
                  </WithUserPermissions>
                </HStack>
              </Box>
            )}
            {parent.phone_number && (
              <Box>
                <HStack>
                  <DataLabel>Phone number</DataLabel>
                  {parent.phone_number === parent?.user?.name && (
                    <Badge colorScheme="gray" variant="solid" marginLeft="1rem">
                      {"Linked"}
                    </Badge>
                  )}
                </HStack>
                <HStack justifyContent="space-between">
                  <Text>{parent.phone_number}</Text>
                  <WithUserPermissions permissions={["message:create"]}>
                    {parent.sms_okay && (
                      <Tooltip label="Send Message">
                        <IconButton
                          aria-label="send-message"
                          icon={<RiChat2Line />}
                          variant="ghost"
                          onClick={onSendMessageClick}
                        />
                      </Tooltip>
                    )}
                  </WithUserPermissions>
                </HStack>
              </Box>
            )}
          </React.Fragment>
        ))}
      </Flex>
    </Flex>
  );
};
