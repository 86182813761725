import Immutable from "immutable";
import _ from "lodash";
import * as Draft from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/types/draft";
import { toAdditionalQuestions, toQuestion } from "src/services/formTemplate";
import * as Question from "src/services/formTemplate/question";
import { flattenQuestion } from "src/services/formTemplate/question";
import { isNotNull } from "src/services/predicates";
import * as AF from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import { Change, QuestionId, SectionId, State } from "../../types/state";

export function toInsertFormVerificationVariables(
  formTemplateId: uuid,
  parentQuestion: Draft.Question
): GQL.InsertFormVerificationsVariables {
  const questions = [
    parentQuestion,
    ...Draft.getAdditionalQuestions(parentQuestion)
  ];
  const labels: string[] = _.uniq(
    questions.flatMap((question) => {
      if (question.newFormVerification === undefined) {
        return [];
      }

      return [question.newFormVerification.label];
    })
  );

  const inserts: GQL.form_verification_insert_input[] = labels.map((label) => {
    return {
      form_template_id: formTemplateId,
      label
    };
  });

  return { inserts };
}

export function toUpdateVariables(
  customQuestionTypes: GQL.GetQuestionTypesByOrganization_custom_question_type[],
  enrollmentPeriodId: uuid,
  formTemplateId: uuid,
  state: State
): GQL.UpdateQuestionsAndSectionsVariables {
  const disclaimers: Immutable.Map<
    SectionId,
    AF.DisclaimerSection<AF.WithId>
  > = getAllDrafts(state.get("disclaimers"));

  const sections: Immutable.Map<
    SectionId,
    AF.Section<AF.WithId>
  > = getAllDrafts(state.get("sections"));

  const sectionUpdates = toSectionUpdates(sections);

  const { sectionInserts, sectionUpdates: sectionOrderUpdates } =
    insertNewSections(
      customQuestionTypes,
      formTemplateId,
      enrollmentPeriodId,
      state
    );

  const { questionUpdates } = toQuestionUpdates(state);

  const { gradesQuestionUpdates } = toGradesQuestionFiltersUpdates(state);

  const {
    eligibilityQuestionSchoolsDeletes,
    eligibilityQuestionSchoolsInserts,
    formQuestionUpdates: formCategoryUpdates
  } = toEligibilityUpdates(state);

  const disclaimerUpdates = toDisclaimerUpdates(disclaimers);

  const formVerificationUpdates = toFormVerificationUpdates(state);
  const formFiltersUpdates = toFormFiltersUpdates(state);

  const questionOptionInserts = toQuestionOptionInserts(
    enrollmentPeriodId,
    state,
    formTemplateId
  );

  const questionOptionUpdates = toQuestionOptionUpdates(state);

  const { questionInserts, questionUpdates: questionOrderUpdates } =
    insertNewQuestions(
      customQuestionTypes,
      enrollmentPeriodId,
      formTemplateId,
      state
    );

  const {
    additionalQuestionInserts,
    questionUpdates: additionalQuestionUpdates
  } = toAdditionalQuestionInserts(enrollmentPeriodId, state, formTemplateId);

  return {
    questionUpdates: [
      questionUpdates,
      questionOrderUpdates,
      additionalQuestionUpdates
    ]
      .filter(isNotNull)
      .flat(),
    disclaimerUpdates,
    formQuestionUpdates: [
      formVerificationUpdates,
      formCategoryUpdates,
      formFiltersUpdates
    ]
      .filter(isNotNull)
      .flat(),
    eligibilityQuestionSchoolsDeletes,
    eligibilityQuestionSchoolsInserts,
    questionOptionInserts,
    questionOptionUpdates,
    questionInserts,
    sectionUpdates: [sectionUpdates, sectionOrderUpdates?.reverse()]
      .filter(isNotNull)
      .flat(),
    additionalQuestionInserts,
    gradesQuestionUpdates,
    sectionInserts
  };
}

type AdditionalQuestions = Pick<
  GQL.UpdateQuestionsAndSectionsVariables,
  "additionalQuestionInserts" | "questionUpdates"
>;
function toAdditionalQuestionInserts(
  enrollmentPeriodId: uuid,
  state: State,
  formTemplateId: uuid
): AdditionalQuestions {
  const questions = state.get("questions");
  const drafts = getAllDrafts(questions);
  const options = drafts
    .valueSeq()
    .toArray()
    .flatMap((draft) => (Question.hasOptions(draft) ? draft.options : []));
  const { additionalQuestionInserts, questionUpdates } = options.reduce(
    (acc: AdditionalQuestions, option) => {
      if (option.additionalQuestions === undefined) {
        return acc;
      }

      if (Draft.isNew(option)) {
        return acc;
      }

      return option.additionalQuestions.reduce((acc, question, order) => {
        if (!Draft.isNew(question)) {
          // update order number of existing additional questions
          const update: GQL.question_updates = {
            where: {
              _and: [{ id: { _eq: question.id } }, { order: { _neq: order } }]
            },
            _set: { order }
          };
          return {
            ...acc,
            questionUpdates: [...(acc.questionUpdates ?? []), update]
          };
        }

        return {
          ...acc,
          additionalQuestionInserts: [
            ...(acc.additionalQuestionInserts ?? []),
            {
              form_question_option_id: option.id,
              question: {
                data: toQuestion(
                  [],
                  enrollmentPeriodId,
                  Draft.toQuestionWithoutId(
                    Draft.fromOriginalQuestion(question)
                  ),
                  order,
                  formTemplateId
                )
              }
            }
          ]
        };
      }, acc);
    },
    {
      additionalQuestionInserts: [],
      questionUpdates: []
    } as AdditionalQuestions
  );

  // TODO update order number of existing additional questions
  return {
    additionalQuestionInserts,
    questionUpdates
  };
}

export function insertNewQuestions(
  customQuestionTypes: GQL.GetQuestionTypesByOrganization_custom_question_type[],
  enrollmentPeriodId: uuid,
  formTemplateId: uuid,
  state: State
): Pick<
  GQL.UpdateQuestionsAndSectionsVariables,
  "questionInserts" | "questionUpdates"
> {
  const [inserts, updates] = state.get("sortedQuestions").reduce(
    ([inserts, updates], questionIds, sectionKey) => {
      // Had to do this to avoid adding duplicate new questions, if a question belongs to a new section, will be added together later.
      const isNewSection = state.get("newSections").has(sectionKey);

      if (isNewSection) {
        return [inserts, updates];
      }

      const [newQuestions, questionsOrderUpdates] = toNewQuestionsInserts(
        customQuestionTypes,
        enrollmentPeriodId,
        formTemplateId,
        sectionKey,
        questionIds,
        state
      );

      if (newQuestions.size === 0) {
        // no new questions, don't do anything
        return [inserts, updates];
      }

      return [
        inserts.concat(newQuestions),
        updates.concat(questionsOrderUpdates.reverse())
      ];
    },
    [
      Immutable.List<GQL.question_insert_input>(),
      Immutable.List<GQL.question_updates>()
    ] as const
  );

  return {
    questionInserts: inserts.toArray(),
    questionUpdates: updates.toArray()
  };
}

function toNewQuestionsInserts(
  customQuestionTypes: GQL.GetQuestionTypesByOrganization_custom_question_type[],
  enrollmentPeriodId: uuid,
  formTemplateId: uuid,
  sectionKey: SectionId,
  questionIds: Immutable.List<QuestionId>,
  state: State
): readonly [
  Immutable.List<GQL.question_insert_input>,
  Immutable.List<GQL.question_updates>
] {
  const newQuestionsMap = state.get("newQuestions");

  return questionIds.reduce(
    ([inserts, updates], questionId, order) => {
      const newQuestion = newQuestionsMap.get(questionId);
      if (!newQuestion) {
        // not a new question
        return [
          inserts,
          updates.push({
            where: {
              _and: [
                { id: { _eq: questionId.get("questionId") } },
                { order: { _neq: order } }
              ]
            },
            _set: { order: order }
          })
        ];
      }

      const questionWithoutId = Draft.toQuestionWithoutId(newQuestion);

      return [
        inserts.push({
          ...toQuestion(
            customQuestionTypes,
            enrollmentPeriodId,
            questionWithoutId,
            order,
            formTemplateId
          ),
          form_template_section_id: sectionKey.get("sectionId")
        }),
        updates
      ];
    },
    [
      Immutable.List<GQL.question_insert_input>(),
      Immutable.List<GQL.question_updates>()
    ] as const
  );
}

function toQuestionOptionUpdates(
  state: State
): GQL.form_question_option_updates[] {
  const drafts = getAllDraftQuestions(state);
  const originals = getAllOriginalQuestions(state);
  const allOriginalOptions = getAllOptions(originals);
  return drafts.flatMap((draft) => {
    if (!Question.hasOptions(draft)) {
      return [];
    }

    return draft.options.flatMap(
      (draftOption): GQL.form_question_option_updates[] => {
        const originalOption = allOriginalOptions.get(draftOption.id);
        if (Draft.isNew(draftOption)) {
          return [];
        }

        if (
          originalOption?.label === draftOption.label &&
          originalOption?.value === draftOption.value
        ) {
          return [];
        }

        return [
          {
            where: {
              id: { _eq: draftOption.id }
            },
            _set: {
              label: draftOption.label,
              value: draftOption.value
            }
          }
        ];
      }
    );
  });
}

function getAllOptions(
  questions: AF.Question<AF.WithId>[]
): Immutable.Map<uuid, AF.Option<AF.WithId>> {
  const options: [uuid, AF.Option<AF.WithId>][] = questions.flatMap((q) => {
    if (!Question.hasOptions(q)) {
      return [];
    }

    const nestedOptions: AF.Option<AF.WithId>[] = q.options.flatMap(
      (o) =>
        o.additionalQuestions?.flatMap((nestedQuestion) => {
          if (!Question.hasOptions(nestedQuestion)) {
            return [];
          }

          return nestedQuestion.options;
        }) ?? []
    );

    const keyValues: [uuid, AF.Option<AF.WithId>][] = q.options
      .concat(nestedOptions)
      .map((o) => [o.id, o]);
    return keyValues;
  });

  return Immutable.Map<uuid, AF.Option<AF.WithId>>(options);
}

function toQuestionOptionInserts(
  enrollmentPeriodId: uuid,
  state: State,
  formTemplateId: uuid
): GQL.form_question_option_insert_input[] {
  const questions = getAllDraftQuestions(state);
  return questions.flatMap((draft) => {
    if (!Question.hasOptions(draft)) {
      return [];
    }

    return draft.options.flatMap(
      (option, order): GQL.form_question_option_insert_input[] => {
        if (!Draft.isNew(option)) {
          return [];
        }

        if (Draft.isNew(draft)) {
          return [];
        }

        return [
          {
            label: option.label,
            order: order,
            question_id: draft.id,
            value: option.value,
            eligibility_schools: toEligibilityInserts(option),
            additional_questions: toNewAdditionalQuestions(
              enrollmentPeriodId,
              option,
              formTemplateId
            )
          }
        ];
      }
    );
  });
}

function toNewAdditionalQuestions(
  enrollmentPeriodId: uuid,
  option: Draft.Option,
  formTemplateId: uuid
): GQL.additional_question_arr_rel_insert_input | null {
  if (
    option.additionalQuestions === undefined ||
    option.additionalQuestions.length === 0
  ) {
    return null;
  }

  return toAdditionalQuestions(
    enrollmentPeriodId,
    option.additionalQuestions.map((q) =>
      Draft.toQuestionWithoutId(Draft.fromOriginalQuestion(q))
    ),
    formTemplateId
  );
}

function toEligibilityInserts(
  option: Draft.Option
): GQL.eligibility_question_school_arr_rel_insert_input {
  switch (option.eligibilityFilter) {
    case "NotApplicable":
    case undefined:
      return { data: [] };

    case "Eligible":
      return {
        data: option.eligibleSchoolIds.map((schoolId) => {
          return {
            is_eligible: true,
            school_id: schoolId
          };
        })
      };

    case "NotEligible":
      return {
        data: option.notEligibleSchoolIds.map((schoolId) => {
          return {
            is_eligible: false,
            school_id: schoolId
          };
        })
      };

    default:
      const _exhaustiveCheck: never = option;
      return _exhaustiveCheck;
  }
}

function toDisclaimerUpdates(
  disclaimers: Immutable.Map<SectionId, AF.DisclaimerSection<AF.WithId>>
): GQL.disclaimer_section_updates[] {
  return disclaimers.toArray().map(([_, disclaimer]) => {
    return {
      where: { id: { _eq: disclaimer.id } },
      _set: { disclaimer: disclaimer.disclaimer }
    };
  });
}

function toSectionUpdates(
  sections: Immutable.Map<SectionId, AF.Section<AF.WithId>>
): GQL.form_template_section_updates[] {
  return sections.toArray().map(([_, section]) => {
    let sectionId = section.id;
    if (section.type === AF.DisclaimerSectionType)
      sectionId = section.disclaimerFormSectionId;

    return {
      where: { id: { _eq: sectionId } },
      _set: {
        title: section.title,
        description: section.description,
        key: section.key || null,
        permission_level: section.permissionLevel
      }
    };
  });
}

export function insertNewSections(
  customQuestionTypes: GQL.GetQuestionTypesByOrganization_custom_question_type[],
  formTemplateId: uuid,
  enrollmentPeriodId: uuid,
  state: State
): Pick<
  GQL.UpdateQuestionsAndSectionsVariables,
  "sectionInserts" | "sectionUpdates"
> {
  const sortedSections = state.get("sortedSections");
  const newSectionsMap = state.get("newSections");
  const [inserts, updates] = sortedSections.reduce(
    ([inserts, updates], sectionId, order) => {
      if (newSectionsMap.size === 0) {
        // no new sections, don't do anything
        return [inserts, updates];
      }
      const newSection = newSectionsMap.get(sectionId);

      if (!newSection) {
        // not a new section
        if (order === sortedSections.size - 1) {
          return [
            inserts,
            updates.push({
              where: {
                _and: [
                  {
                    disclaimer_section: {
                      id: { _eq: sectionId.get("sectionId") }
                    }
                  },
                  { order: { _neq: order } }
                ]
              },
              _set: { order: order }
            })
          ];
        }
        return [
          inserts,
          updates.push({
            where: {
              _and: [
                { id: { _eq: sectionId.get("sectionId") } },
                { order: { _neq: order } }
              ]
            },
            _set: { order: order }
          })
        ];
      }

      const questionsRelatedToSection = state
        .get("sortedQuestions")
        .get(sectionId);

      const newQuestions = state.get("newQuestions");

      const questions = questionsRelatedToSection
        ?.map((questionId, index) => {
          const newQuestion = newQuestions.get(questionId);

          if (newQuestion) {
            const questionWithoutId = Draft.toQuestionWithoutId(newQuestion);

            newQuestions.remove(questionId);
            return {
              ...toQuestion(
                customQuestionTypes,
                enrollmentPeriodId,
                questionWithoutId,
                index,
                formTemplateId
              )
            };
          }
          return null;
        })
        .filter(isNotNull);

      return [
        inserts.push({
          title: newSection.title,
          description: newSection.description,
          type: GQL.form_template_section_type_enum.GeneralSection,
          key: newSection.key,
          order: order,
          questions: { data: questions?.toArray() ?? [] },
          form_template_id: formTemplateId
        }),
        updates
      ];
    },
    [
      Immutable.List<GQL.form_template_section_insert_input>(),
      Immutable.List<GQL.form_template_section_updates>()
    ] as const
  );

  return {
    sectionInserts: inserts.toArray(),
    sectionUpdates: updates.toArray()
  };
}

function toQuestionUpdates(
  state: State
): GQL.UpdateQuestionsAndSectionsVariables {
  const questions = getAllDraftQuestions(state);
  return {
    questionUpdates: questions.map((question) => {
      return {
        where: { id: { _eq: question.id } },
        _set: {
          question: question.question,
          key: question.key ?? null,
          link_text: question.link_text ?? null,
          link_url: question.link_url ?? null,
          permission_level: question.permissionLevel ?? null,
          is_required: question.requirement === "Required"
        }
      };
    })
  };
}

function toGradesQuestionFiltersUpdates(
  state: State
): GQL.UpdateQuestionsAndSectionsVariables {
  const questions = getAllDraftQuestions(state).filter(
    (question) => question.filters
  );
  return {
    gradesQuestionUpdates: questions.map((question) => {
      return {
        where: { question_id: { _eq: question.id } },
        _set: {
          filters: question.filters
        }
      };
    })
  };
}

function toFormVerificationUpdates(state: State): GQL.form_question_updates[] {
  const questionChanges = state.get("questions");
  const questions = getAllDraftQuestions(state);
  return questions.flatMap((question) => {
    const original = questionChanges.get(QuestionId(question.id))?.original;
    switch (question.type) {
      case AF.GradesType:
        return [];

      default:
        const formVerificationId = question.formVerification?.id ?? null;

        const update: GQL.form_question_updates[] = [
          {
            where: { question_id: { _eq: question.id } },
            _set: {
              form_verification_id: formVerificationId
            }
          }
        ];

        if (!original || original?.type === AF.GradesType) {
          return update;
        }

        if (original.formVerification?.id === formVerificationId) {
          return [];
        }

        return update;
    }
  });
}

function toFormFiltersUpdates(state: State): GQL.form_question_updates[] {
  const questionChanges = state.get("questions");
  const questions = getAllDraftQuestions(state);
  return questions.flatMap((question): GQL.form_question_updates[] => {
    const original = questionChanges.get(QuestionId(question.id))?.original;
    switch (question.type) {
      case AF.GradesType:
      case AF.CustomQuestionType:
        return [];

      case AF.EmailType:
      case AF.SingleSelectType:
      case AF.MultiSelectType:
      case AF.AddressType:
      case AF.FreeTextType:
      case AF.FileUploadType:
      case AF.PhoneNumberType:
        const update: GQL.form_question_updates[] = [
          {
            where: { question_id: { _eq: question.id } },
            _set: {
              filters: question.filters
            }
          }
        ];

        if (_.isEqual(original?.filters, question.filters)) {
          return [];
        }

        return update;

      default:
        const _exhaustiveCheck: never = question;
        return _exhaustiveCheck;
    }
  });
}

function getAllDrafts<T, U, K>(
  changes: Immutable.Map<K, Change<T, U>>
): Immutable.Map<K, U> {
  return changes.flatMap((change: Change<T, U>, key: K): [K, U][] => {
    return change.draft ? [[key, change.draft]] : [];
  });
}

function getAllDraftQuestions(state: State): Draft.Question[] {
  // we can potentially memoize this function for performance improvement when necessary
  const drafts: Draft.Question[] = state
    .get("questions")
    .toArray()
    .flatMap(([_, questionChange]) => {
      return questionChange.draft
        ? flattenQuestion(questionChange.draft).map(Draft.fromOriginalQuestion)
        : [];
    });
  return drafts;
}

function getAllOriginalQuestions(state: State): AF.Question<AF.WithId>[] {
  // we can potentially memoize this function for performance improvement when necessary
  const originalQuestions: AF.Question<AF.WithId>[] = state
    .get("questions")
    .toArray()
    .flatMap(([_, questionChange]) => {
      return questionChange.original
        ? flattenQuestion(questionChange.original)
        : [];
    });

  return originalQuestions;
}

function hasEligiblity(draft: Draft.Question): boolean {
  if (!Question.hasOptions(draft)) {
    return false;
  }

  return !draft.options.every(
    (option) =>
      option.eligibilityFilter === undefined ||
      option.eligibilityFilter === "NotApplicable"
  );
}

export function toEligibilityUpdates(
  state: State
): GQL.UpdateQuestionsAndSectionsVariables {
  const originalQuestions = getAllOriginalQuestions(state);
  const questions = getAllDraftQuestions(state);
  const originalQuestionMap = Immutable.Map(
    originalQuestions.map((q) => [QuestionId(q.id), q])
  );

  const originalEligibilities = getAllEligibilities(originalQuestions);
  const draftEligibilities = getAllEligibilities(questions);

  // update category to be "Eligible" or "General"
  const formQuestionUpdates: GQL.form_question_updates[] = questions.flatMap(
    (draft) => {
      const original = originalQuestionMap.get(QuestionId(draft.id));
      if (Draft.isNew(draft)) {
        // new question, don't update
        return [];
      }
      if (!Draft.hasChanges(original, draft)) {
        // no changes, don't update category
        return [];
      }

      return [
        {
          where: { question_id: { _eq: draft.id } },
          _set: {
            category: hasEligiblity(draft)
              ? GQL.form_question_category_enum.EligibilityQuestion
              : GQL.form_question_category_enum.GeneralQuestion
          }
        }
      ];
    }
  );

  // go through original eligibilies to find the one need to be deleted
  const deletes: GQL.eligibility_question_school_bool_exp[] =
    originalEligibilities.reduce(
      (
        currentDeletes: GQL.eligibility_question_school_bool_exp[],
        original: EligibilityOption
      ) => {
        const draft = draftEligibilities.get(original.optionId);

        const questionInDraft = questions.some(
          (q) => q.id === original.questionId
        );
        if (!questionInDraft) {
          // question for this option is not in drafts, don't delete.
          return currentDeletes;
        }

        if (Draft.isNew(draft)) {
          // don't delete new question
          return currentDeletes;
        }
        if (draft && _.isEqual(draft, original)) {
          // draft is the same as original, we want to keep this
          return currentDeletes;
        }

        // draft is not the same, we should delete the original
        return [
          ...currentDeletes,
          { form_question_option_id: { _eq: original.optionId } }
        ];
      },
      []
    );

  // go through draft to generate inserts
  const inserts: GQL.eligibility_question_school_insert_input[] =
    draftEligibilities.reduce(
      (
        currentInserts: GQL.eligibility_question_school_insert_input[],
        draft: EligibilityOption
      ) => {
        if (Draft.isNew(draft)) {
          // new opton, don't insert since this will inserted through option relationship insert already.
          return currentInserts;
        }
        const original = originalEligibilities.get(draft.optionId);
        if (original && _.isEqual(original, draft)) {
          // draft is the same as equal, don't insert this
          return currentInserts;
        }

        // draft is new or different from original, we should insert this
        return currentInserts.concat(
          draft.eligibility.eligibilityFilter === "Eligible"
            ? draft.eligibility.eligibleSchoolIds.map((schoolId) => ({
                form_question_option_id: draft.optionId,
                school_id: schoolId,
                is_eligible: true
              }))
            : draft.eligibility.notEligibleSchoolIds.map((schoolId) => ({
                form_question_option_id: draft.optionId,
                school_id: schoolId,
                is_eligible: false
              }))
        );
      },
      []
    );

  return {
    eligibilityQuestionSchoolsDeletes:
      deletes.length > 0 ? { _or: deletes } : undefined,
    eligibilityQuestionSchoolsInserts: inserts,
    formQuestionUpdates
  };
}

type EligibilityOption = {
  questionId: uuid;
  optionId: uuid;
  eligibility: AF.Eligible | AF.NotEligible;
  isNew: boolean;
};
export function getAllEligibilities(
  questions: AF.Question<AF.WithId>[]
): Immutable.Map<uuid, EligibilityOption> {
  const keyValues = questions.flatMap((q) => {
    if (!Question.hasOptions(q)) {
      return [];
    }

    return q.options.flatMap((o): [uuid, EligibilityOption][] => {
      switch (o.eligibilityFilter) {
        case "NotApplicable":
        case undefined:
          return [];

        case "Eligible":
          return [
            [
              o.id,
              {
                questionId: q.id,
                optionId: o.id,
                eligibility: {
                  eligibilityFilter: "Eligible",
                  eligibleSchoolIds: o.eligibleSchoolIds
                },
                isNew: Draft.isNew(o)
              }
            ]
          ];

        case "NotEligible":
          return [
            [
              o.id,
              {
                questionId: q.id,
                optionId: o.id,
                eligibility: {
                  eligibilityFilter: "NotEligible",
                  notEligibleSchoolIds: o.notEligibleSchoolIds
                },
                isNew: Draft.isNew(o)
              }
            ]
          ];

        default:
          const _exhaustiveCheck: never = o;
          return _exhaustiveCheck;
      }
    });
  });

  return Immutable.Map(keyValues);
}
