import { gql } from "@apollo/client";

export const FORM_VERIFICATION_FRAGMENT = gql`
  fragment FormVerificationFragment on form_verification {
    id
    label
  }
`;

export const ELIGIBILITY_FRAGMENT = gql`
  fragment EligibilityFragment on eligibility_question_school {
    is_eligible
    school_id
  }
`;

export const QUESTION_OPTION_FRAGMENT = gql`
  ${ELIGIBILITY_FRAGMENT}
  fragment QuestionOptionFragment on form_question_option {
    id
    order
    label
    value
    translate_options
    requires_verification
    eligibility_schools {
      ...EligibilityFragment
    }
  }
`;

export const FORM_QUESTION_WITHOUT_BRANCHING_FRAGMENT = gql`
  ${FORM_VERIFICATION_FRAGMENT}
  fragment FormQuestionWithoutBranchingFragment on question {
    id
    order
    question
    type
    is_required
    key
    link_url
    link_text
    permission_level
    form_question_schools {
      school_id
    }
    form_question {
      category
      form_question_options {
        ...QuestionOptionFragment
      }
      form_verification {
        ...FormVerificationFragment
      }
      filters
    }
  }
`;

export const FORM_QUESTION_OPTION_FRAGMENT = gql`
  ${QUESTION_OPTION_FRAGMENT}
  ${FORM_QUESTION_WITHOUT_BRANCHING_FRAGMENT}
  fragment FormQuestionOptionFragment on form_question_option {
    ...QuestionOptionFragment
    additional_questions {
      question {
        ...FormQuestionWithoutBranchingFragment
      }
    }
  }
`;

export const FORM_QUESTION_FRAGMENT = gql`
  ${FORM_QUESTION_OPTION_FRAGMENT}
  ${FORM_QUESTION_WITHOUT_BRANCHING_FRAGMENT}

  fragment FormQuestionFragment on question {
    id
    ...FormQuestionWithoutBranchingFragment
    form_question {
      category
      filters
      form_question_options {
        ...FormQuestionOptionFragment
      }
    }
  }
`;

const GRADE_FRAGMENT = gql`
  fragment GradeFragment on grades_by_grade_question_id {
    grade_config {
      id
      label
      order
    }
  }
`;

export const QUESTION_FRAGMENT = gql`
  ${FORM_QUESTION_FRAGMENT}
  ${FORM_QUESTION_WITHOUT_BRANCHING_FRAGMENT}
  ${GRADE_FRAGMENT}
  fragment QuestionFragment on question {
    ...FormQuestionFragment
    grades_question {
      grades(order_by: { order: asc_nulls_first }) {
        ...GradeFragment
      }
      grades_additional_questions {
        grade_config_id
        question {
          ...FormQuestionWithoutBranchingFragment
        }
      }
      filters
    }
    custom_question {
      custom_question_type {
        id
        name
      }
      custom_question_relationships(
        order_by: { cloned_question: { order: asc } }
      ) {
        id
        cloned_question {
          ...FormQuestionWithoutBranchingFragment
        }
        custom_question_type_field_id
      }
    }
  }
`;

export const SCHOOLS_RANKING_SECTION_FRAGMENT = gql`
  fragment SchoolsRankingSectionFragment on schools_ranking_section {
    id
    explore_url
    max_schools
    min_schools
  }
`;

export const DISCLAIMER_SECTION_FRAGMENT = gql`
  fragment DisclaimerSectionFragment on disclaimer_section {
    id
    disclaimer
  }
`;

export const FORM_TEMPLATE_SECTION_FRAGMENT = gql`
  ${SCHOOLS_RANKING_SECTION_FRAGMENT}
  ${DISCLAIMER_SECTION_FRAGMENT}
  ${QUESTION_FRAGMENT}
  fragment FormTemplateSectionFragment on form_template_section {
    id
    title
    description
    key
    order
    type
    permission_level
    filters
    schools_ranking_section {
      ...SchoolsRankingSectionFragment
    }
    disclaimer_section {
      ...DisclaimerSectionFragment
    }
    questions {
      ...QuestionFragment
    }
  }
`;

export const FORM_TEMPLATE_FRAGMENT = gql`
  ${FORM_TEMPLATE_SECTION_FRAGMENT}
  ${FORM_VERIFICATION_FRAGMENT}
  fragment FormTemplateFragment on form_template {
    id
    enrollment_period_id
    name
    key
    description
    offer_start_at
    lottery_offers_enabled
    active
    locked
    sections {
      ...FormTemplateSectionFragment
    }
    form_verifications {
      ...FormVerificationFragment
    }
  }
`;

export const ENROLLMENT_PERIOD_BASICS_FRAGMENT = gql`
  fragment EnrollmentPeriodBasics on enrollment_period {
    id
    name
    active
  }
`;

export const BASIC_FORM_TEMPLATE_FRAGMENT = gql`
  ${FORM_TEMPLATE_FRAGMENT}
  ${ENROLLMENT_PERIOD_BASICS_FRAGMENT}

  fragment BasicFormTemplateFragment on form_template {
    ...FormTemplateFragment
    enrollment_period {
      ...EnrollmentPeriodBasics
    }
  }
`;

export const FORM_STATUS_DESCRIPTION = gql`
  fragment FormStatusDescription on form_status_description {
    id
    form_template_id
    status
    description
  }
`;
