import * as AF from "src/types/formTemplate";
import * as GQL from "src/types/graphql";
import * as AFF from "../formTemplateFilters";

export function fromGQL(
  value: GQL.FormTemplateSectionFragment
): AF.DisclaimerSection<AF.WithId> {
  if (value.type !== GQL.form_template_section_type_enum.DisclaimerSection)
    throw new Error(
      `Invalid section type, expecting a "DisclaimerSection", but got ${value.type} instead`
    );
  if (!value.disclaimer_section) {
    throw new Error("Missing disclaimer_section data");
  }

  return {
    id: value.disclaimer_section.id,
    disclaimerFormSectionId: value.id,
    type: AF.DisclaimerSectionType,
    title: value.title,
    description: value.description ?? "",
    key: value.key,
    permissionLevel: value.permission_level,
    disclaimer: value.disclaimer_section.disclaimer,
    filters: AFF.parseFilters(value.filters),
  };
}
