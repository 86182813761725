import * as GQL from "src/types/graphql";
import { z } from "zod";
import { LoginConfigSchema } from "./loginMethod";

const AccountLookupConfigSchema = z.object({
  supportUrl: z.string(),
});
export type AccountLookupConfig = z.infer<typeof AccountLookupConfigSchema>;

const ScheduleExportSchema = z.object({
  secretArn: z.string(),
});

export const ConfigDataSchemas = {
  [GQL.organization_config_type_enum.TranslationOptions]: z.array(z.string()),
  [GQL.organization_config_type_enum.Match]: z.object({}),
  [GQL.organization_config_type_enum.AccountLookup]: AccountLookupConfigSchema,
  [GQL.organization_config_type_enum.Login]: LoginConfigSchema,
  [GQL.organization_config_type_enum.ScheduleExport]: ScheduleExportSchema,
};

export type ConfigData<Type extends GQL.organization_config_type_enum> =
  z.infer<(typeof ConfigDataSchemas)[Type]>;

export type Configs = {
  [Type in keyof typeof ConfigDataSchemas]: Omit<
    GQL.GetOrganizationConfig_organization_config,
    "data"
  > & {
    type: Type;
    data: z.infer<(typeof ConfigDataSchemas)[Type]>;
  };
};
export type Config = Configs[GQL.organization_config_type_enum];
export type OrgConfigStateType = Partial<Configs>;
