import { gql } from "@apollo/client";

export const GET_BOUNDARIES_BY_ENROLLMENT_PERIOD = gql`
  query GetBoundariesByEnrollmentPeriod($form_id: uuid) {
    geojson(
      where: {
        organization: {
          enrollment_periods: {
            deleted_at: { _is_null: true }
            form_templates: {
              deleted_at: { _is_null: true }
              forms: { id: { _eq: $form_id } }
            }
          }
        }
      }
    ) {
      id
      geojson_property_key
      geojson
      school_boundaries(
        where: {
          enrollment_period: {
            deleted_at: { _is_null: true }
            form_templates: {
              deleted_at: { _is_null: true }
              forms: { id: { _eq: $form_id } }
            }
          }
        }
      ) {
        geo_eligibilities {
          id
          is_eligible_inside
        }
        school_id
        grade {
          id
          school_id
        }
        geojson_property_value
        geojson_id
      }
      boundary_tags {
        id
        name
        is_inside
      }
    }
  }
`;
